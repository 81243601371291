import { universalRenderPropType } from 'helpers/propTypes';
import { isFn, ternaryCall } from 'helpers/utility';

/**
 * Renders children whether a given as a function or node.
 * Uses `ternaryCall` to safeguard executing code that might fail unless guarded.
 * @param {*} children
 * @return {*}
 */
const UniversalChildren = ({ children }) =>
  ternaryCall(
    isFn(children),
    () => children(),
    () => children,
  );

UniversalChildren.propTypes = {
  children: universalRenderPropType,
};

UniversalChildren.defaultProps = {
  // defaults to null instead of undefined so we always return a node
  children: null,
};

export default UniversalChildren;
