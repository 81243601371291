import { views } from 'modules/signup-v3/constants';

import { stepStatuses } from '../components/ProgressBarStepperV2/constants';

const steps = [
  {
    id: views.businessVerification,
    status: stepStatuses.disabled,
    isActive: false,
    label: 'Business',
    icon: null,
    substeps: [
      {
        id: views.legalBusinessName,
        status: stepStatuses.completed,
        label: 'Legal name',
        isActive: false,
      },
      {
        id: views.businessType,
        status: stepStatuses.progress,
        label: 'Type and incorporation',
        isActive: false,
      },
      {
        id: views.EIN,
        status: stepStatuses.notStarted,
        label: 'EIN',
        isActive: false,
      },
      {
        id: views.businessCategory,
        status: stepStatuses.notStarted,
        label: 'Category and classification',
        isActive: false,
      },
      {
        id: views.businessAddress,
        status: stepStatuses.notStarted,
        label: 'Address',
        isActive: false,
      },
      {
        id: views.businessPhoneNumber,
        status: stepStatuses.notStarted,
        label: 'Phone number',
        isActive: false,
      },
      {
        id: views.uploadDocument,
        status: stepStatuses.notStarted,
        label: 'Document Upload',
        isActive: false,
      },
    ],
  },
  {
    id: views.businessRepresentative,
    status: stepStatuses.notStarted,
    label: 'Representative',
    isActive: false,
    isLocked: true,
    tooltip: {
      waiting: 'We’re waiting for all representatives to complete their information.',
    },
    substeps: [
      {
        id: views.qualification,
        status: stepStatuses.notStarted,
        label: 'Qualification',
        isActive: false,
      },
      {
        id: views.personalInformation,
        status: stepStatuses.notStarted,
        label: 'Personal Information',
        isActive: false,
        tooltip: {
          disabled: 'Please confirm you qualify as a business representative before proceeding to the next step.',
        },
      },
      {
        id: views.representativeAddress,
        status: 'notStarted',
        label: 'Primary address',
        isActive: false,
        tooltip: {
          disabled: 'Please confirm you qualify as a business representative before proceeding to the next step.',
        },
      },
      {
        id: views.representativeIDNumber,
        status: 'notStarted',
        label: 'ID number',
        isActive: false,
        tooltip: {
          disabled: 'Please confirm you qualify as a business representative before proceeding to the next step.',
        },
      },
      {
        id: views.representativeUploadDocument,
        status: 'notStarted',
        label: 'Document upload',
        isActive: false,
        tooltip: {
          disabled: 'Please confirm you qualify as a business representative before proceeding to the next step.',
        },
      },
    ],
  },
  {
    id: 'review',
    hideStatus: true,
    status: stepStatuses.notStarted,
    label: 'Review',
    isActive: false,
  },
];

export default steps;
