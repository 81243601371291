import React from 'react';
import { Text } from 'components';
import { paginationDataTestId } from 'constants/dataTestId';
import { typography } from 'constants/styles';
import PaginationBlockNextButton from 'modules/dashboard/paginationBlock/components/PaginationBlockNextButton';
import PaginationBlockPreviousButton from 'modules/dashboard/paginationBlock/components/PaginationBlockPreviousButton';
import { getPaginationBlockResultText } from 'modules/dashboard/paginationBlock/helpers/component';
import 'modules/dashboard/paginationBlock/PaginationBlock.scss';
import {} from './PaginationBlock.types';
const PaginationBlock = ({ count, filterLabel, isFetching, onGoToPage, page, pages, pageSize, }) => {
    const resultText = getPaginationBlockResultText({
        currentPage: page,
        isFetching,
        pageSize,
        totalResults: count,
    });
    return (React.createElement("div", { className: "pagination-bar" },
        React.createElement("div", { className: "left" },
            React.createElement(Text.Semibold, { className: "display--block line-height--xl--important", color: typography.TextColor.BLUE_DARK, size: typography.TextSize.LEVEL_400 }, filterLabel),
            React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, "data-testid": paginationDataTestId.paginationComponent.HEADER_INDEX, size: typography.TextSize.LEVEL_100 }, resultText)),
        React.createElement("div", { className: "right" },
            React.createElement(PaginationBlockPreviousButton, { isDisabled: page <= 1, onClick: () => onGoToPage('PREV') }),
            React.createElement(PaginationBlockNextButton, { buttonContainerClassname: "no-margin-right", isDisabled: page >= pages, onClick: () => onGoToPage('NEXT') }))));
};
export default PaginationBlock;
