import pluralize from 'pluralize';
import React from 'react';
import { Icon, IconNames } from 'components';
import { iconSizes } from 'constants/styles/sizes';
import { formatNumber } from 'helpers/numbers';
import { concat, isEqual, isFn, ternary } from 'helpers/utility';
export const getTableToolbarSelectionManagerOptions = (props) => {
    const { onClearSelection, onSelectAll, options = [], resourceType, selectableRowsCount, selectedCount } = props;
    if (isFn(onSelectAll)) {
        const countString = ternary(selectableRowsCount, concat(formatNumber(selectableRowsCount), ' '), '');
        const resourceDisplay = pluralize(resourceType, 2);
        const titleText = `Select all ${countString}${resourceDisplay}`;
        const title = (React.createElement("span", null,
            React.createElement(Icon, { className: "margin-right--6", icon: IconNames.ADD_TO_ARTIFACT, size: iconSizes.LARGE }),
            titleText));
        options.unshift({
            isDisabled: isEqual(selectableRowsCount, selectedCount),
            isDisabledWhenNoActionable: false,
            onClick: onSelectAll,
            title,
        });
    }
    if (isFn(onClearSelection)) {
        const title = (React.createElement("span", null,
            React.createElement(Icon, { className: "margin-right--6", icon: IconNames.DELETE, size: iconSizes.LARGE }),
            "Clear all selected"));
        options.push({
            isDisabledWhenNoActionable: false,
            onClick: onClearSelection,
            title,
        });
    }
    return options;
};
