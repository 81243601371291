import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _set from 'lodash/set';
import { v4 } from 'uuid';

import { StaticCountryCode } from 'constants/countries';
import PermissionResourceAction from 'constants/permissions';
import { UserTypes } from 'constants/user';

import { ItemPaymentTerms } from 'enums/items';
import { ItemDateScheduledTypes } from 'enums/temporal';

import { getCurrentDateLocal, getDateStringFromDays } from 'helpers/date';
import { getItemPaymentMethodValues } from 'helpers/formValues';
import { isKindPayable } from 'helpers/items';
import { getInitialAnnotationLineItems } from 'helpers/ocr';
import { fieldMappings } from 'helpers/ocr/constants';
import { checkMemberHasRequiredPermissions } from 'helpers/permissions';
import { isDateScheduledTypeFuture, isItemScheduledToSend } from 'helpers/temporal';
import { ternary } from 'helpers/utility';

import { getEmptyFundingAddressValues } from 'modules/address/helpers/initialValues';
import { isInvoiceGeneratorForcedOpen } from 'modules/dashboard/createItems/helpers/invoiceGenerator';

import { storeAccessor as store } from 'store/accessor';

import { getInitialBankAccountValues } from './bankAccount';

/**
 * Returns initial form values for item.
 * @param {Object} options
 * @param {Object.<string, BillingCodeData>} options.billingDataByCode
 * @param {Object} options.currentCompanySettings
 * @param {boolean} options.hasLedgerIntegration
 * @param {boolean} options.isPoMatchingEnabled
 * @param {ItemKind} options.kind
 * @param {PaymentDeliveryMethod[]} options.payablePaymentMethodsAccepted
 * @param {PaymentDeliveryMethod[]} options.receivablePaymentMethodsAccepted
 * @param {string} [options.purposeCode]
 * @return {Object}
 */
export const getInitialItem = (options) => {
  const {
    billingDataByCode,
    currentCompanySettings,
    hasLedgerIntegration,
    idQueryParam,
    kind,
    payablePaymentMethodsAccepted,
    receivablePaymentMethodsAccepted,
    currentCompanySettingsDefaultItemSendDate,
    currentMemberPermissionSet,
    purposeCode,
  } = options;

  const isPayable = isKindPayable(kind);

  const { paymentDeliveryMethod, paymentDeliveryMethodsAccepted, paymentDeliveryOption, paymentMethods } =
    getItemPaymentMethodValues({
      billingDataByCode,
      isPayable,
      payablePaymentMethodsAccepted,
      receivablePaymentMethodsAccepted,
    });

  const paymentTerms = ternary(hasLedgerIntegration, ItemPaymentTerms.SEE_INVOICE, ItemPaymentTerms.UPON_RECEIPT);

  const hasSendPermission = checkMemberHasRequiredPermissions({
    actualMemberPermissionSet: currentMemberPermissionSet,
    requiredPermissions: [PermissionResourceAction.PAYABLE_SEND, PermissionResourceAction.RECEIVABLE_SEND],
  });

  /**
   * If the user has send permissions and default config for item send date is future, we set item.dateScheduled
   * to the next day (tomorrow by default). If not, dateScheduled is set to null.
   */
  const dateScheduled =
    hasSendPermission && isDateScheduledTypeFuture(currentCompanySettingsDefaultItemSendDate)
      ? getDateStringFromDays(1)
      : null;

  const item = {
    attachments: [],
    bills: [],
    dateScheduled,
    id: idQueryParam,
    kind,
    paymentDeliveryMethod,
    paymentDeliveryMethodsAccepted,
    paymentDeliveryOption,
    paymentMethodOptions: paymentMethods,
    paymentTerms,
    purposeCode,
  };

  if (!isPayable) {
    item.dateDue = getCurrentDateLocal();
    item.dateDue.add(currentCompanySettings.receivableDefaultDueDays, 'days');
  }

  return item;
};

/**
 * Get initial form values for meta.
 * @param {Object} options
 * @param {Object} options.companySettingsIntegration
 * @param {boolean} options.hasLedgerIntegration
 * @returns {Object}
 */
export const getInitialMeta = (options) => {
  const { companySettingsIntegration, hasLedgerIntegration } = options;

  // If no ledger integration, we have no initial metadata
  if (!hasLedgerIntegration) {
    return {};
  }

  return {
    addLedgerAttachments: companySettingsIntegration.addLedgerAttachments,
    ignoreUnsupportedLedgerAttachments: companySettingsIntegration.ignoreUnsupportedLedgerAttachments,
    memo: '',
  };
};

/**
 * Get initial form values for totals on invoice.
 * @returns {Object}
 */
export const getInitialInvoiceTotals = () => ({
  discount: 0,
  subtotal: 0,
  taxes: {},
  total: 0,
});

/**
 * Get initial form values for ui.
 * @param {Object} options
 * @param  {boolean} options.hasLedgerIntegration
 * @param {boolean} options.isBillView
 * @returns {Object}
 */
export const getInitialUi = (options) => {
  const { hasLedgerIntegration, isBillView, currentCompanySettingsDefaultItemSendDate, currentMemberPermissionSet } =
    options;

  const hasPermission = checkMemberHasRequiredPermissions({
    actualMemberPermissionSet: currentMemberPermissionSet,
    requiredPermissions: [PermissionResourceAction.PAYABLE_SEND, PermissionResourceAction.RECEIVABLE_SEND],
  });

  let sendItem;
  let dateScheduledType;
  if (!hasPermission) {
    /**
     * If current user doesn't have permission to send an item, default the sendItem state to false.
     * Date scheduled components will be disabled.
     */
    sendItem = false;
    dateScheduledType = ItemDateScheduledTypes.SKIP;
  } else {
    /**
     * If current user does have permission to send an item, use the company settings default send date
     * value to derive the value of sendItem.
     * dateScheduledType will be whatever the company settings default send date is.
     */
    sendItem = isItemScheduledToSend(currentCompanySettingsDefaultItemSendDate);
    dateScheduledType = currentCompanySettingsDefaultItemSendDate;
  }

  return {
    bankInstitutionName: '',
    billViewRejectedFiles: [],
    blockDetailsSections: true,
    dateScheduledType,
    doesCompanyExist: false,
    hideUnmatchedInvoices: false,
    isBillView,
    partnershipSubmitted: false,
    selectedCompany: null,
    sendItem,
    shouldShowAddressPrintOnCheck: false,
    showAddressPrintOnCheckToggle: true,
    showInvoiceGenerator: isInvoiceGeneratorForcedOpen(hasLedgerIntegration, isBillView),
    showInvoiceDiscount: false,
    showItemMessage: false,
    showMarkAsPaid: false,
    showExternalRef: false,
    warningsDismissed: false,
  };
};

export const setValuesFromKeyMapping = (initialValues, valuesSource, mapping) => {
  Object.entries(mapping).forEach(([key, value]) => {
    const schemaItem = _has(initialValues, value);
    if (schemaItem || schemaItem === '') {
      _set(initialValues, value, valuesSource[key]);
    }
  });
};

/**
 * Get initial form values.
 * @param {Object} options
 * @param  {Object} options.item
 * @param {Object} options.meta
 * @param {Object} options.ui
 * @param {SchemaViewModelManager} options.viewModelManager
 * @returns {Object}
 */
export const getInitialValues = ({
  itemMembersForItem,
  item,
  inboxItem,
  partnership,
  meta,
  ui,
  viewModelManager,
  isInternationalPaymentsEnabled,
  annotationsById,
  annotationLineItemsById,
  itemBillAttachments,
}) => {
  const isBillView = ui?.isBillView || false;

  const isPayable = isKindPayable(item.kind);
  const allowCountrySelection = isInternationalPaymentsEnabled && isPayable;

  const viewModelManagerInitialValues = _cloneDeep(viewModelManager?.initialValues || {});
  const itemCopy = { ...item };
  let primaryAttachment = null;
  let latestOcrAnnotation = null;
  let isForcedConsolidatedLineItem = false;
  let lineItems;
  let consolidatedLineItem;

  if (isBillView) {
    const reduxState = store.getState();
    const itemAttachments = reduxState?.attachments?.byId;
    primaryAttachment = Object.values(itemAttachments ?? {})?.find(
      (attachment) => inboxItem?.primaryAttachment === attachment.id,
    );
    latestOcrAnnotation = annotationsById?.[primaryAttachment?.latestOcrAnnotation];

    if (latestOcrAnnotation?.dateDue) {
      itemCopy.dateDue = latestOcrAnnotation.dateDue;
    }

    if (latestOcrAnnotation) {
      const oneLineItemArray = [];
      if (latestOcrAnnotation.lineItems?.length === 1) {
        oneLineItemArray.push(annotationLineItemsById[latestOcrAnnotation.lineItems?.[0]]);
      } else {
        // Use consolidated line item
        consolidatedLineItem = inboxItem?.fieldProvenanceMap?.consolidatedLineItems?.find(
          (cLineItem) => cLineItem.value.id === latestOcrAnnotation.id,
        )?.value?.data;
        if (consolidatedLineItem) {
          consolidatedLineItem.style =
            (latestOcrAnnotation.lineItems?.[0] &&
              annotationLineItemsById[latestOcrAnnotation.lineItems?.[0]]?.style) ||
            'account';
        }

        if ((latestOcrAnnotation.lineItems || []).length >= 30) {
          isForcedConsolidatedLineItem = true;
        }

        oneLineItemArray.push(consolidatedLineItem);
      }

      lineItems = getInitialAnnotationLineItems(viewModelManagerInitialValues?.line_items || {}, oneLineItemArray);
    }

    // is_consolidated incoming is set to consolidatedLineItem if object then true else false
    itemCopy.isConsolidated = !!consolidatedLineItem;
  }

  const initialState = {
    address: getEmptyFundingAddressValues(),
    bankAccount: getInitialBankAccountValues(),
    countryCode: allowCountrySelection ? null : StaticCountryCode.US,
    idempotencyKey: v4(),
    invoiceTotals: getInitialInvoiceTotals(),
    item: itemCopy,
    itemMembers: ternary(ui.isItemEdit, itemMembersForItem, []),
    ledgerInvoiceRefs: {},
    meta,
    partner: {
      companyType: UserTypes.BUSINESS,
      name: ternary(ui.isItemEdit, partnership?.name, ''),
    },
    partnership: {
      id: ternary(ui.isItemEdit, partnership?.id, null),
    },
    partnershipMembers: [],
    externalRef: undefined,
    ui: {
      ...ui,
      isForcedConsolidatedLineItem,
      filters: [],
    },
    ...viewModelManagerInitialValues,
  };

  if (isBillView) {
    if (latestOcrAnnotation) {
      setValuesFromKeyMapping(initialState, latestOcrAnnotation, fieldMappings);
      if (initialState.main?.general?.bill_currency) {
        initialState.main.general.annotation_bill_currency = initialState.main.general.bill_currency;
      }
    }

    if (lineItems) {
      initialState.line_items = lineItems;
    }
  }

  if (inboxItem) {
    initialState.item.version = inboxItem.version;
  }

  if (itemBillAttachments) {
    initialState.item.bills = itemBillAttachments;
  }

  return initialState;
};
