import { DateFormats } from '@routable/shared';
import dayjs from 'dayjs';
import _pick from 'lodash/pick';

import { MemberAccessProps } from 'data/resources/member';

import { ItemStatuses } from 'enums/items';

import { filterApprovalLevelsByKindAndAmount, isItemApprovalRequired, shouldUseAmountDue } from 'helpers/approvals';
import { getApproverFieldName, getRateEstimateOrItemAmount } from 'helpers/createItem';
import { isCompanyIdNotCurrentCompany } from 'helpers/currentCompany';
import { isItemKindPayable, isKindReceivable, mergeItemWithInvoice } from 'helpers/items';
import { isPartnershipMemberAccessSet } from 'helpers/partnershipMembers';
import { deepMergeWithArrayReplacement, cleanObjectOfEmptyValues } from 'helpers/transform';
import { getBiggestNumberInList, hasLength, ternary } from 'helpers/utility';

import { parseCreateItemMetaFields } from 'modules/dashboard/createItems/helpers/metaFields';
import { hasAllExternallyPaidFields } from 'modules/dashboard/markAsPaid/helpers/form';

import { currentCompanySettingsIsInternationalPaymentsEnabledSelector } from 'selectors/currentCompanySelectors';

import { storeAccessor as store } from 'store/accessor';

/**
 * Transforms data for an item's meta before submitting to the API.
 * @param {Object} meta
 * @param {Object} options
 * @param {LedgerIntegration} options.ledger
 */
export const metaForCreateItem = (meta, options) => {
  const { item, ledger } = options;

  const rawMeta = { ...meta };

  return parseCreateItemMetaFields(rawMeta, item, ledger);
};

/**
 * Transforms data for an item's payment method data before submitting to the API.
 * @param {ReduxFormValues} values
 * @param {Object} options
 * @param {string} options.partnerFundingAccount
 * @param {ItemPaymentDeliveryMethod[]} options.payablePaymentDeliveryMethods
 * @param {ItemPaymentDeliveryMethod[]} options.receivablePaymentDeliveryMethods
 * @return {Object}
 */
export const paymentMethodForCreateItem = (values, options = {}) => {
  const { partnerFundingAccount, payablePaymentDeliveryMethods, receivablePaymentDeliveryMethods } = options;

  const { item, ui } = values;
  const { showMarkAsPaid } = ui;

  // invalid properties on any item, even if null - remove them
  // (these exist in state, but can't be sent)
  const invalidProperties = ['paymentDeliveryMethod', 'paymentMethodOptions'];

  const payload = {};

  const state = store.getState();
  const isInternationalPaymentsEnabled = currentCompanySettingsIsInternationalPaymentsEnabledSelector(state);

  // Partner Currency Code
  if (isInternationalPaymentsEnabled && item.currencyCode) {
    payload.currencyCode = item.currencyCode;
  }
  if (isInternationalPaymentsEnabled && item.currencyCodeReceiver) {
    payload.currencyCodeReceiver = item.currencyCodeReceiver;
  }

  if (showMarkAsPaid) {
    payload.paymentDeliveryMethodsAccepted = ternary(
      isItemKindPayable(item),
      payablePaymentDeliveryMethods,
      receivablePaymentDeliveryMethods,
    );

    // invalid properties when marking an item as paid, even if null - remove them
    const markAsPaidInvalidProperties = ['partnerReceivableAccount', 'paymentDeliveryOption'];

    [...invalidProperties, ...markAsPaidInvalidProperties].forEach((key) => {
      payload[key] = undefined;
    });

    return payload;
  }

  // Handle partnerReceivableAccount, paymentDeliveryOption and paymentDeliveryMethodsAccepted
  if (item.partnerReceivableAccount) {
    payload.paymentDeliveryOption = item.paymentDeliveryOption;

    payload.partnerReceivableAccount = {
      id: partnerFundingAccount.id,
    };

    // invalid property when sending a partner receivable account, even if null - remove it
    invalidProperties.push('paymentDeliveryMethodsAccepted');
  } else {
    const { paymentDeliveryMethodsAccepted } = item;

    payload.paymentDeliveryMethodsAccepted = Object.keys(paymentDeliveryMethodsAccepted).filter(
      (method) => paymentDeliveryMethodsAccepted[method],
    );
  }

  if (isKindReceivable(item.kind)) {
    // invalid property on receivables, even if null - remove it
    invalidProperties.push('paymentDeliveryOption');
  }

  invalidProperties.forEach((key) => {
    payload[key] = undefined;
  });

  return payload;
};

/**
 * Transforms data for an item's dateScheduled before submitting to the API.
 * @param {ReduxFormValues} values
 * @param {Object} [options={}]
 */
export const dateScheduledForCreateItem = (values, options = {}) => {
  const {
    item: { dateScheduled },
    ui: { sendItem },
  } = values;

  const { isDiffingInitialValues } = options;

  const payload = {};

  // Schedule item (unless this item is 'ready to send')
  // This code path is run twice by getParsedPayload to compare the form's initialValues with the form values set by RCTM
  // When this is run in the case of initialValues we just want dateScheduled to be what it was initially
  if (isDiffingInitialValues || (dateScheduled && sendItem)) {
    payload.dateScheduled = dayjs.isDayjs(dateScheduled)
      ? dateScheduled.format(DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY)
      : dateScheduled;
  } else {
    payload.dateScheduled = undefined;
  }

  return payload;
};

/**
 * Transforms data for an item's status before submitting to the API.
 * @param {ReduxFormValues} values
 * @return {Object}
 */
export const statusForCreateItem = (values) => {
  const {
    ui: { sendItem },
  } = values;

  const payload = {};

  if (!sendItem) {
    payload.status = ItemStatuses.READY_TO_SEND;
  }

  return payload;
};

/**
 * Transforms data for an approver before submitting an item to the API.
 * @param {Object} option
 * @param {ApprovalSelectConfig} option.level
 * @param {PartnershipMember} option.membership
 * @return {Object}
 */
export const itemApproverForCreateItem = ({ level, membership }) => ({
  level: { id: level.id },
  membership: { id: membership.id },
});

/**
 * Maps selected approver members by levels and returns an array.
 * @param {ApprovalSelectConfig[]} approvalLevels
 * @param {Item} item
 * @param {number} [itemAmount] - Required argument only when creating an item from the invoice generator
 * @return {Object[]}
 */
export const itemApproversForCreateItem = (approvalLevels, item, itemAmount) => {
  let filteredApprovers = [];

  const useAmountDue = shouldUseAmountDue(item);
  filterApprovalLevelsByKindAndAmount({
    approvalLevels,
    item,
    itemAmount,
    useAmountDue,
  }).forEach((level) => {
    const fieldValues = item[getApproverFieldName(level.position)] || [];

    const allMembershipsForSingleLevel = fieldValues.map((option) => ({
      level,
      membership: option.data,
    }));

    filteredApprovers = [...filteredApprovers, ...allMembershipsForSingleLevel];
  });

  return filteredApprovers.map(itemApproverForCreateItem);
};

/**
 * Item has the item kind, but invoice has the amountDue when importing an invoice. Combine the two objects to get the
 * approvers.
 * @param {Object} options
 * @param {ApprovalSelectConfig[]} options.approvalLevels
 * @param {LedgerInvoice} invoice - from the ledger
 * @param {Item} item - basically the shell of an item containing the ItemKind
 * @returns {Object[]} - Array of approvers
 */
export const itemApproversForSelectedInvoice = ({ approvalLevels, invoice, item }) =>
  itemApproversForCreateItem(approvalLevels, mergeItemWithInvoice(item, invoice));

/**
 * Transforms data for a partnership member into an item member before submitting an item
 * to the API.
 * @param {PartnershipMember} partnershipMember
 * @return {ItemMember}
 */
export const itemMemberForCreateItem = (partnershipMember) => ({
  [MemberAccessProps.accessItem]: partnershipMember[MemberAccessProps.accessItem],
  partnershipMember: {
    id: partnershipMember.id,
  },
});

/**
 * Transforms data for a partnership member into an item member before submitting an item
 * to the API.
 * @param {ItemMember} itemMember
 * @return {ItemMember}
 */
export const transformItemMemberForItemEdit = (itemMember) => ({
  [MemberAccessProps.accessItem]: itemMember[MemberAccessProps.accessItem],
  id: itemMember.id,
  partnershipMember: {
    id: itemMember.partnershipMember,
  },
});

/**
 * Filters partnershipMembers with accessItem set to none, and returns an array of itemMembers.
 * @param {PartnershipMember[]} partnershipMembers
 * @return {ItemMember[]}
 */
export const itemMembersForCreateItem = (partnershipMembers) => {
  const filteredMembers = partnershipMembers.filter(
    (partnershipMember) =>
      isPartnershipMemberAccessSet(partnershipMember[MemberAccessProps.accessItem]) &&
      isCompanyIdNotCurrentCompany(partnershipMember.company),
  );
  return filteredMembers.map(itemMemberForCreateItem);
};

/**
 * Filters partnershipMembers with accessItem set to none, and returns an array of itemMembers.
 * @param {PartnershipMember[]} partnershipMembers
 * @return {ItemMember[]}
 */
export const itemMembersForItemEdit = (partnershipMembers) => {
  const filteredMembers = partnershipMembers.filter(
    (partnershipMember) =>
      isPartnershipMemberAccessSet(partnershipMember[MemberAccessProps.accessItem]) &&
      isCompanyIdNotCurrentCompany(partnershipMember.company),
  );
  return filteredMembers.map(transformItemMemberForItemEdit);
};

/**
 * Transforms data for multiple invoices before submitting to the API.
 * @param {ReduxFormValues} values - Form values
 * @param {Item} values.item
 * @param {Object} options
 * @param {ApprovalSelectConfig[]} options.approvalLevels
 * @param {Object} options.currentCompanySettings
 * @return {Object}
 */
export const multipleInvoicesForCreateItem = (values, options) => {
  const { approvalLevels, invoiceAmounts } = options;
  const { item } = values;

  // the biggest invoice informs which levels of approvals have been presented to the user for selection
  const biggestInvoiceAmount = getBiggestNumberInList(invoiceAmounts);
  // To check if approvals are required, we have to examine the ItemKind and the amountDue.
  const mockInvoice = mergeItemWithInvoice(item, {
    amountDue: biggestInvoiceAmount,
  });

  const payload = {
    fundingAccount: {
      id: item.fundingAccount.id,
    },
    kind: item.kind,
    ...paymentMethodForCreateItem(values, options),
    // Schedule item (unless this item is 'ready to send')
    ...dateScheduledForCreateItem(values),
    // Send or not
    ...statusForCreateItem(values),
  };

  // Add addenda record if it exists
  if (item.fundingProviderMemo) {
    payload.fundingProviderMemo = item.fundingProviderMemo;
  }

  // Add po match type if exists
  if (item.poMatchType) {
    payload.poMatchType = item.poMatchType;
  }

  // Add Swift Charge Option if exists
  payload.swiftChargeOption = values.item?.swiftChargeOption;

  if (isItemApprovalRequired({ approvalLevels, item: mockInvoice })) {
    const itemDataWithApprovers = { ...values, ...item };
    payload.itemApprovers = itemApproversForSelectedInvoice({
      approvalLevels,
      invoice: mockInvoice,
      item: itemDataWithApprovers,
    });
  }

  return payload;
};

/**
 * Transforms data for a single invoice before submitting to the API.
 * @param {ReduxFormValues} values - Form values
 * @param {Item} values.item
 * @param {Object} options
 * @param {ApprovalSelectConfig[]} options.approvalLevels
 * @param {LedgerInvoice} options.invoice
 * @param {boolean} options.isMultiPartner - Is RCTM in the multiple company or single company tab?
 * @return {Object}
 */
export const singleInvoiceForCreateItem = (values, options) => {
  const { approvalLevels, invoice, isMultiPartner } = options;
  const { item, itemMembers } = values;

  const payload = {
    fundingAccount: {
      id: item.fundingAccount.id,
    },
    kind: item.kind,
    // If we're sending one invoice, only include ItemMembers if we're on the "Send item to a specific partner" tab
    itemMembers: ternary(isMultiPartner, [], itemMembersForCreateItem(itemMembers)),
    ...paymentMethodForCreateItem(values, options),
    // Schedule item (unless this item is 'ready to send')
    ...dateScheduledForCreateItem(values),
    // Send or not
    ...statusForCreateItem(values),
  };

  if (item.purposeCode) {
    payload.purposeCode = item.purposeCode;
  }

  // Add addenda record if it exists
  if (item.fundingProviderMemo) {
    payload.fundingProviderMemo = item.fundingProviderMemo;
  }

  // Add po match type if exists
  if (item.poMatchType) {
    payload.poMatchType = item.poMatchType;
  }

  // Add Swift Charge Option if exists
  payload.swiftChargeOption = values.item?.swiftChargeOption;

  if (isItemApprovalRequired({ approvalLevels, item: invoice })) {
    const itemDataWithApprovers = { ...values, ...item };
    payload.itemApprovers = itemApproversForSelectedInvoice({
      approvalLevels,
      invoice,
      item: itemDataWithApprovers,
    });
  }

  return payload;
};

/**
 * Returns the portion of the item payload related to bill files and other attachments.
 * @param {Item} item
 * @param {File[]} item.attachments
 * @param {File[]} item.bills
 * @param {Object} [options={}]
 * @return {{ attachments: File[], bills: undefined }}
 */
export const billsAndAttachmentsForCreateItem = (item) => {
  const payload = {
    attachments: [],
    // spreading the item onto the payload below in singleItemForCreateItem
    // may set this property, which shouldn't be sent to the backend; handle unsetting it here
    bills: undefined,
  };

  // add item bill files to attachments
  if (hasLength(item.bills)) {
    payload.attachments.unshift(...item.bills);
  }

  return payload;
};

/**
 * Transforms data for a single new item before submitting to the API.
 * @param {ReduxFormValues} values - Form values
 * @param {Item} values.item
 * @param {Object} options
 * @param {ApprovalSelectConfig[]} options.approvalLevels
 * @param {Object[]} options.lineItems
 * @return {Object}
 */
export const singleItemForCreateItem = (values, options) => {
  const { approvalLevels, dynamicSectionKeys, isDiffingInitialValues, itemAmount, lineItems } = options;
  const { item, itemMembers, partnership, rateEstimate } = values;

  // the portion of the final data that's set by the dynamic json schema may include
  // arbitrary top-level keys. our form state includes a lot of stuff that shouldn't be
  // sent, so target those dynamic keys specifically, and include any data in the payload.
  const dynamicData = _pick(values, dynamicSectionKeys);
  const itemData = cleanObjectOfEmptyValues(deepMergeWithArrayReplacement(item, dynamicData));

  const payload = {
    ...itemData,

    // Item
    fundingAccount: {
      // item & fundingAccount is undefined when partnership is not yet selected
      id: item.fundingAccount?.id,
    },

    // Partnership members selected as item members
    itemMembers: values.ui.isItemEdit ? itemMembersForItemEdit(itemMembers) : itemMembersForCreateItem(itemMembers),

    kind: item.kind,
    paymentTerms: item.paymentTerms,

    // Line items
    lineItems,

    // Partnership
    partnership: {
      id: partnership.id,
    },

    // Unset the `bill` property, and handle consolidating into the attachments property
    ...billsAndAttachmentsForCreateItem(item),

    // Partner receivable account, accepted delivery methods, related
    ...paymentMethodForCreateItem(values, options),

    // Schedule item (unless this item is 'ready to send')
    ...dateScheduledForCreateItem(values, { isDiffingInitialValues }),

    // Send or not
    ...statusForCreateItem(values),
  };

  // Add addenda record if it exists
  if (item.fundingProviderMemo) {
    payload.fundingProviderMemo = item.fundingProviderMemo;
  }

  if (isItemKindPayable(item)) {
    payload.paymentTerms = undefined;
  }

  // Add invoice number if exists
  if (item.invoiceNumber) {
    payload.invoiceNumber = item.invoiceNumber;
  }

  // Mark as paid
  if (hasAllExternallyPaidFields(item)) {
    payload.dateExternallyPaid = item.dateExternallyPaid;
    payload.externallyPaidMethod = item.externallyPaidMethod;
    payload.externallyPaidReference = item.externallyPaidReference;
    payload.status = ItemStatuses.EXTERNALLY_PAID;

    // Unset fields that are not allowed
    payload.paymentDeliveryMethodsAccepted = undefined;

    // We don't need approvers because this is mark as paid
    payload.itemApprovers = [];
  }

  /**
   * If there's an estimate (meaning that there's a rate conversion), estimate
   * value for amountSender will be taken.
   * If no rate estimate is present, itemAmount will be taken
   * */
  const itemAmountInCompanyCurrency = getRateEstimateOrItemAmount(rateEstimate, itemAmount);

  // Approvers for item
  if (
    !payload.itemApprovers &&
    isItemApprovalRequired({
      approvalLevels,
      item,
      itemAmount: itemAmountInCompanyCurrency,
    })
  ) {
    const itemDataWithApprovers = { ...values, ...item };
    payload.itemApprovers = itemApproversForCreateItem(
      approvalLevels,
      itemDataWithApprovers,
      itemAmountInCompanyCurrency,
    );
  }

  return payload;
};
