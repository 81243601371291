import { isLessThan, isNum, ternary } from 'helpers/utility';

/**
 * Function called from both useQuickJumperKeyPress and useQuickJumperAddonClick to
 * go to the entered page.
 * @param {Object} params
 * @param {Function} params.onGoToPage
 * @param {number} params.pageCount
 * @param {number} params.pageInput
 * @param {Function} params.setPageInput
 */
export const handleGoToPage = (params) => {
  const { onGoToPage, pageCount, pageInput, setPageInput } = params;

  if (!isNum(pageInput)) {
    return;
  }

  const nextPage = ternary(isLessThan(pageInput, 1), 1, Math.min(pageInput, pageCount));

  onGoToPage(nextPage);
  setPageInput(nextPage);
};
