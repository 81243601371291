import React from 'react';
import {} from 'types/quickswitch';
import { colors } from '../../constants/styles';
import IsLoading from '../isLoading/IsLoading';
import { membershipListIsEmpty, removeCurrentMembershipFromList } from './helpers';
import QuickswitchMembershipList from './QuickswitchMembershipList';
import QuickswitchModalEmptySearchResults, {} from './QuickswitchModalEmptySearchResults';
const QuickswitchModalContent = ({ loadingMembershipsList, memberships, executeSwitchMembership, onUndoSearchClick, }) => {
    const membershipData = removeCurrentMembershipFromList(memberships);
    if (loadingMembershipsList) {
        return (React.createElement("div", { className: "margin-bottom--m margin-top--m" },
            React.createElement(IsLoading, { color: colors.colorBlueBoldHex })));
    }
    if (membershipListIsEmpty(membershipData)) {
        return React.createElement(QuickswitchModalEmptySearchResults, { onUndoSearchClick: onUndoSearchClick });
    }
    return React.createElement(QuickswitchMembershipList, { memberships: membershipData, onSwitchMembership: executeSwitchMembership });
};
export default QuickswitchModalContent;
