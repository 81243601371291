import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { routableApi } from '../routableApi';
export const transformResponse = (response) => {
    const data = Object.values(response?.arrivalEstimate).find(Boolean);
    return data?.attributes;
};
const extendedFundingAccountsApi = routableApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        fundingAccountsEstimatedArrivalDate: builder.query({
            query: ({ initiationDate, currencyCodeReceiver, paymentDeliveryMethod, paymentDeliveryOption, paymentSource, }) => ({
                url: '/funding_accounts/estimated_arrival/estimate/',
                method: 'POST',
                payload: payloadToJSONAPI({
                    initiationDate,
                    currencyCodeReceiver,
                    paymentDeliveryMethod,
                    paymentDeliveryOption,
                    paymentSource,
                }, 'ArrivalEstimate'),
            }),
            transformResponse,
        }),
    }),
});
export const { useFundingAccountsEstimatedArrivalDateQuery } = extendedFundingAccountsApi;
