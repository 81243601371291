import { DateFormats } from 'constants/date';

import { formatDateString } from 'helpers/date';
import { isArrayLengthEqual, ternary } from 'helpers/utility';

/**
 * Creates text nodes
 * @param {string} text
 * @param {boolean} emphasize
 * @returns {Object}
 */
export const createTextNodeProps = (text, emphasize = false) => ({
  emphasize,
  key: text,
  text,
});

/**
 * Return text nodes with names separated by commas
 * @param {string[]} users
 * @returns string
 */
export const getUserTextNodes = (users) => {
  // Only one person completed a step
  if (isArrayLengthEqual(users, 1)) {
    return [createTextNodeProps(users[0], true)];
  }

  const lastPerson = users.pop();
  const firstPeople = ternary(
    // two people completed steps (we already `pop`ped one)
    isArrayLengthEqual(users, 1),
    users[0],
    // more than two people completed steps
    users.join(', ').concat(','),
  );

  return [createTextNodeProps(firstPeople, true), createTextNodeProps(' and '), createTextNodeProps(lastPerson, true)];
};

/**
 * Creates a text node with completion verbiage
 * @param {Object} props
 * @param {string} props.completedDate
 * @param {PartnershipRequestUserFriendlyNames|PartnershipRequestNames} props.partnershipRequestUserFriendlyOrInternalName
 * @returns {Object}
 */
export const getCompletedTextNode = ({ completedDate, partnershipRequestUserFriendlyOrInternalName }) => {
  const formattedDate = formatDateString(completedDate, DateFormats.SHORT_MONTH_SHORT_DATE_FULL_YEAR);

  return createTextNodeProps(` completed ${partnershipRequestUserFriendlyOrInternalName} on ${formattedDate}.`);
};

/**
 * Returns text nodes that communicate who has completed external flow.
 * @param {Object} props
 * @param {string} props.completedDate
 * @param {PartnershipRequestUserFriendlyNames|PartnershipRequestNames} props.partnershipRequestUserFriendlyOrInternalName
 * @param {string[]} props.users
 * @returns Object[]
 */
export const getHintText = ({ completedDate, partnershipRequestUserFriendlyOrInternalName, users }) => {
  const usersTextNodes = getUserTextNodes(users);
  const completedTextNodes = getCompletedTextNode({
    completedDate,
    partnershipRequestUserFriendlyOrInternalName,
  });

  return [...usersTextNodes, completedTextNodes];
};
