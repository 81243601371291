import { Text } from 'components/text';

import { isTagTypeVariable } from 'helpers/ui';
import { ternary } from 'helpers/utility';

/**
 * Returns the text component to be used for tags
 * @see {Tag}
 *
 * @param {Node|undefined} TextComponent
 * @param {TagType} tagType
 * @return {Node|Function}
 */
export const getTextComponentForTagLabel = (TextComponent, tagType) => {
  if (TextComponent) {
    return TextComponent;
  }

  return ternary(isTagTypeVariable(tagType), Text.Regular, Text.Bold);
};
