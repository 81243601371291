export const LOCAL_STORAGE_KEY_FEATURE_FLAGS = 'featureFlags';
export const FEATURE_FLAG_NAME_ITEM_EDIT = 'itemEdit';
export const FEATURE_FLAG_OCR_INBOX = 'ocrInbox';
export const FEATURE_FLAG_NAME_BULK_RECEIVABLES = 'bulkReceivables';
export const FEATURE_FLAG_NAME_BULK_PARTNERSHIPS = 'bulkPartnerships';
export const FEATURE_FLAG_NAME_RTP = 'realTimePayments';
export const FEATURE_FLAG_SIDE_NAVIGATION_V2 = 'sideNavigationV2';
export const FEATURE_FLAG_NAME_CROSSBORDER_ITEM_APPROVAL_ENABLED = 'crossborderItemApprovalEnabled';
export const FEATURE_FLAG_NAME_GLOBAL_ONLY_EXTERNAL_FLOW_BACK_BUTTON = 'globalOnlyExternalFlowBackButton';
export const FEATURE_FLAG_PAYABLES_FILTER_DEFAULT = 'payablesFilterDefault';
export const FEATURE_FLAG_NAME_PREFIX = 'FEATURE_FLAG_';
export const DEV_ONLY_FEATURE_FLAG_NAME_PREFIX = 'DEV_ONLY_';
export const FEATURE_FLAG_CROSSBORDER_SELF_SERVE = 'crossborderSelfServe';
export const FEATURE_FLAG_CROSSBORDER_MULTIPLE_PROVIDERS = 'crossborderMultipleProvidersEnabled';
export const FEATURE_FLAG_ENTERPRISE_APPROVAL_RULES = 'enterpriseApprovalRules';
export const FEATURE_FLAG_ENTERPRISE_APPROVAL_RULES_FORM_ONLY = 'enterpriseApprovalRulesFormOnly';
export const FEATURE_FLAG_CUSTOM_ROLES_ENABLED = 'customRolesEnabled';
export const FEATURE_FLAG_CUSTOM_ADESA_BULK_ACTION_ALL = 'customAdesaBulkActionAll';
export const NEW_VENDOR_TABLE = 'vendorsTable';
export const FEATURE_FLAG_ITEM_LIST_OPTIMIZATION = 'itemListOptimization';
export const FEATURE_FLAG_INBOX_TABLE = 'inboxTable';
export const FEATURE_FLAG_INTERNATIONAL_BANK_ACCOUNT_VALIDATION = 'globalOnlyInternationalBankAccountValidation';
