import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DragHandle } from '@routable/gross-ds';
import { useEvent, useDispatchEvent } from '@routable/framework';
import { horizontalChangeWidthEventName, horizontalResizeEventName } from './horizontalResizeEventName';
import { Container, DragIndicator, HorizontalBar, HorizontalBarLeft, HorizontalBarMiddle, HorizontalBarRight, LeftPanel, RightPanel, } from './horizontal.resize.styled';
const parseWidth = (width, containerWidth) => {
    if (width.endsWith('px')) {
        const widthNum = parseFloat(width);
        if (widthNum > 0) {
            return widthNum;
        }
        return containerWidth + widthNum;
    }
    if (width.endsWith('%')) {
        return (parseFloat(width) / 100) * containerWidth;
    }
    throw new Error('Invalid width type');
};
const updateWidth = (rect, animationRef, width, minWidth, maxWidth, dispatch, resizableName, setInternalWidth) => {
    if (rect && !animationRef.current) {
        animationRef.current = requestAnimationFrame(() => {
            let newWidthPx = width - rect.left;
            const minPx = minWidth ? parseWidth(minWidth, rect.width) : undefined;
            const maxPx = maxWidth ? parseWidth(maxWidth, rect.width) : undefined;
            if (minPx !== undefined && newWidthPx < minPx) {
                newWidthPx = minPx;
            }
            if (maxPx !== undefined && newWidthPx > maxPx) {
                newWidthPx = maxPx;
            }
            const newWidthPercent = (newWidthPx / rect.width) * 100;
            if (setInternalWidth) {
                setInternalWidth(`${newWidthPercent}%`);
            }
            dispatch(horizontalResizeEventName(resizableName), {
                percentNum: newWidthPercent,
                leftSize: newWidthPx,
                rightSize: rect.width - newWidthPx,
                cssPercent: `${newWidthPercent}%`,
            });
            animationRef.current = null;
        });
    }
};
export const HorizontalResize = React.memo(({ isResizable = true, resizableName = 'horizontal-resize', leftChildren, rightChildren, minWidth = '140px', maxWidth = '90%', initialWidth = '140px', }) => {
    const containerRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const [internalWidth, setInternalWidth] = useState(initialWidth);
    const animationRef = useRef();
    const dispatch = useDispatchEvent();
    useEvent(horizontalChangeWidthEventName(resizableName), (ev) => {
        setInternalWidth(ev.width ? ev.width : internalWidth);
    });
    const onPointerMove = useCallback((e) => {
        updateWidth(containerRef.current?.getBoundingClientRect(), animationRef, e.clientX, minWidth, maxWidth, dispatch, resizableName, (widthValue) => {
            setInternalWidth(widthValue);
        });
    }, [minWidth, maxWidth, resizableName]);
    useEffect(() => {
        const rect = containerRef.current?.getBoundingClientRect();
        updateWidth(rect, animationRef, parseWidth(internalWidth, rect?.width), minWidth, maxWidth, dispatch, resizableName);
    }, [internalWidth, minWidth, maxWidth, resizableName]);
    const onPointerUp = useCallback(() => {
        window.removeEventListener('pointermove', onPointerMove);
        window.removeEventListener('pointerup', onPointerUp);
        if (containerRef.current) {
            containerRef.current.removeEventListener('pointerleave', onPointerUp);
        }
        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
            animationRef.current = null;
        }
        setIsResizing(false);
        document.body.style.userSelect = 'auto';
    }, [onPointerMove]);
    const onPointerDown = useCallback(() => {
        window.addEventListener('pointermove', onPointerMove);
        window.addEventListener('pointerup', onPointerUp);
        if (containerRef.current) {
            containerRef.current.addEventListener('pointerleave', onPointerUp);
        }
        document.body.style.userSelect = 'none';
        setIsResizing(true);
    }, [onPointerMove]);
    return (React.createElement(Container, { ref: containerRef },
        React.createElement(LeftPanel, { style: {
                transition: isResizing ? 'width 0s' : undefined,
                width: internalWidth,
                minWidth,
            } }, leftChildren),
        isResizable && (React.createElement(HorizontalBar, { "data-testid": "horizontal-bar", onPointerDown: onPointerDown, className: clsx('resizable-bar', {
                'resizing-panels': isResizing,
            }) },
            React.createElement(HorizontalBarLeft, null),
            React.createElement(HorizontalBarMiddle, null,
                React.createElement(DragIndicator, { className: "resizable-handle" },
                    React.createElement(DragHandle, null))),
            React.createElement(HorizontalBarRight, null))),
        React.createElement(RightPanel, { style: { width: `calc(100% - ${internalWidth})` } }, rightChildren)));
});
