import { CornerDialog } from '@routable/components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchItemsRoutine } from 'actions/routines/item';
import { Icon, IconNames } from 'components/icon';
import { ModalFooter, ModalFooterButton, ModalFooterButtonCancel } from 'components/modal';
import { SETTINGS_ACCOUNT_BULK_UPLOAD_HISTORY_ROUTE } from 'constants/routes';
import { allInProgressBulkActionsImports } from 'selectors/bulkActionsSelectors';
import { itemsPaginationSelector } from 'selectors/itemsSelectors';
import './CornerDialogCSVUpload.scss';
const CornerDialogCSVUpload = () => {
    const dispatch = useDispatch();
    const inProgressBulkActions = useSelector(allInProgressBulkActionsImports);
    const pagination = useSelector(itemsPaginationSelector);
    const onFetchList = () => {
        dispatch(fetchItemsRoutine.trigger({ url: pagination.current }));
    };
    return (React.createElement(CornerDialog, { className: "corner-dialog", hasFooter: false, isShown: !!inProgressBulkActions?.length, title: React.createElement("h4", { className: "inline-flex items-center font-medium text-grey-70 text-lg mb-4" },
            React.createElement(Icon, { className: "csv-sync-logo margin-right--xm", icon: IconNames.HISTORY, size: 22 }),
            "A CSV upload is in progress") }, ({ close }) => (React.createElement(React.Fragment, null,
        React.createElement("p", { className: "mt-4 mb-12 text-grey-70 leading-5" },
            'Refresh to see newly updated payments in this list. Visit your ',
            React.createElement(Link, { className: "primary", to: SETTINGS_ACCOUNT_BULK_UPLOAD_HISTORY_ROUTE }, "CSV upload history"),
            ' for details on in progress uploads.'),
        React.createElement(ModalFooter, { className: "ledger-footer items-center" },
            React.createElement(ModalFooterButtonCancel, { buttonText: "Close", onCloseModal: close }),
            React.createElement(ModalFooterButton, { buttonClasses: "main ml-4 primary !pl-7 !pr-7", onClick: onFetchList }, "Refresh"))))));
};
export default CornerDialogCSVUpload;
