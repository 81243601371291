import classNames from 'classnames';
import React from 'react';
import { Text } from 'components';
import { paginationDataTestId } from 'constants/dataTestId';
import { typography } from 'constants/styles';
import { getPaginationBlockResultText } from 'modules/dashboard/paginationBlock/helpers/component';
import { PageNav, PageQuickJumper, PageSizeSelection } from './components';
import {} from './TablePagination.types';
import './TablePagination.scss';
export const TablePagination = ({ className, isFetching, onGoToPage, onPageSizeChange, page, pages, pageSize, count, ...rest }) => {
    const resultText = getPaginationBlockResultText({
        currentPage: page,
        isFetching,
        pageSize,
        totalResults: count,
    });
    return (React.createElement("div", { className: classNames('table-pagination', {
            [className]: !!className,
        }) },
        React.createElement("div", { className: "tlp--control-right" },
            React.createElement(PageQuickJumper, { onGoToPage: onGoToPage, pageCount: pages, pageIndex: page }),
            React.createElement(PageNav, { ...rest, canNextPage: page !== pages, canPreviousPage: page !== 1, onGoToPage: onGoToPage, pageCount: pages, pageIndex: page })),
        React.createElement("hr", null),
        React.createElement("div", { className: "tlp--control-left" },
            React.createElement(PageSizeSelection, { isLoading: isFetching, onPageSizeChange: onPageSizeChange, pageSize: pageSize }),
            React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, "data-testid": paginationDataTestId.paginationComponent.FOOTER_INDEX, size: typography.TextSize.LEVEL_100 }, resultText))));
};
