import React from 'react';

import LinkNewPage from 'components/link/LinkNewPage';
import { Text, WhiteSpace } from 'components/text';

import { colors } from 'constants/styles';

import helpDocs from 'helpers/helpDocs';
import { systemLogger, LogLevel } from 'helpers/systemLogger';
import { isLastIndex, ternary } from 'helpers/utility';

/**
 * Returns correct title for BouncedEmailError SWAL
 * @param {Boolean} isInCreateItemForm
 * @param {Object} options
 * @param {String|undefined} options.itemKind
 * @return {string}
 */
export const getBouncedSwalTitle = (isInCreateItemForm, options = {}) => {
  const { itemKind } = options;

  return ternary(
    isInCreateItemForm,
    `Action is required to send this ${itemKind}`,
    'We are unable to communicate with this contact',
  );
};

/**
 * Returns correct description text for BouncedEmailError SWAL
 * @param {Boolean} isInCreateItemForm
 * @param {Object} options
 * @param {String|undefined} options.itemKind
 * @param {PartnershipMember|undefined} options.partnershipMember
 * @return {string}
 */
export const getBouncedSwalDescription = (isInCreateItemForm, options = {}) => {
  const { itemKind, partnershipMember } = options;

  return ternary(
    isInCreateItemForm,
    `The following contacts cannot receive email notifications for this ${itemKind}
        because their email address has bounced or their contact has been archived.`,
    `The email address for this contact (${partnershipMember?.email}) has been deactivated because
        our attempt to reach it has failed.`,
  );
};

/**
 * Returns correct content rendered in the shaded block section in the BouncedEmailError SWAL
 * @param {Boolean} isInCreateItemForm
 * @param {Object} options
 * @param {Object[]|undefined} options.bouncedContacts
 * @return {StatelessComponent}
 */
export const getBouncedSwalShadedBlockContent = (isInCreateItemForm, options = {}) => {
  const { bouncedContacts = [] } = options;

  const mappedBouncedContacts = (
    <Text className="bounced-email--shaded-text" color={colors.colorSteelHex}>
      {bouncedContacts.map((contact, index) => {
        const isLastElement = isLastIndex(bouncedContacts, index);
        const suffix = ternary(isLastElement, '', ', ');

        return `${contact.email}${suffix}`;
      })}
    </Text>
  );

  const bouncedContactText = (
    <>
      <Text.Bold color={colors.colorSteelHex}>Hard bounce --</Text.Bold>
      <WhiteSpace />
      The server was unable to deliver your message (ex: unknown user, mailbox not found).
    </>
  );

  return ternary(isInCreateItemForm, mappedBouncedContacts, bouncedContactText);
};

/**
 * Return correct instruction text for BouncedEmailError SWAL
 * @param {Boolean} isInCreateItemForm
 * @param {Object} options
 * @param {String|undefined} options.partnerCompanyName
 * @param {String|undefined} options.itemKind
 * @return {StatelessComponent}
 */
export const getBouncedSwalInstruction = (isInCreateItemForm, options = {}) => {
  const { currentCompanyName, partnerCompanyName, itemKind } = options;

  const companyName = ternary(Boolean(partnerCompanyName), partnerCompanyName, currentCompanyName);
  if (isInCreateItemForm && companyName === undefined) {
    systemLogger.log({
      context: 'error/components/text.js - getBouncedSwalInstruction',
      level: LogLevel.WARN,
      name: 'Undefined Company Name Error',
      message: 'Expected one or both of partnerCompanyName and currentCompanyName to be defined',
    });
  }

  const firstSentence = ternary(
    isInCreateItemForm,
    `Please remove these contacts from this ${itemKind} or update their email addresses by
        visiting ${companyName}'s profile.`,
    `To include this contact in your email communications, you will need to update 
          their email address.`,
  );

  return (
    <>
      {firstSentence}
      {' Learn how to '}
      <LinkNewPage className="primary" href={helpDocs.EDITING_CONTACTS}>
        edit the email address
      </LinkNewPage>
      {' for an existing contact.'}
    </>
  );
};
