import { PaymentDeliveryMethodType } from '@routable/shared';

import { updateCompany } from 'actions/companies';
import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import { fetchItemsRoutine, sendSubmitBillToItemRequestRoutine, submitItemRoutine } from 'actions/routines/item';
import {
  submitCreatePartnershipRoutine,
  updatePartnershipGeneralInfoRoutine,
  resendPartnershipInviteRoutine,
} from 'actions/routines/partnership';
import { createPartnershipMemberRoutine } from 'actions/routines/partnershipMember';

import { fetchedPartnershipRelationships } from 'constants/relationships';

import { getPartnershipWithUpdatedPartnershipMembers } from 'helpers/partnerships';
import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';
import { allKeys, firstValue } from 'helpers/utility';

import { FETCH_ITEM_SUCCESS } from 'types/item';
import * as types from 'types/partnership';

const byIdReducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.FETCH_PARTNERSHIPS_SUCCESS:
      return getObjectsByIdWithRelationships(payload.partnership, fetchedPartnershipRelationships);

    case types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_ADDRESS_SUCCESS: {
      // Update partnerReceivableMethod to Check
      const currentPartnership = state[payload.id];
      currentPartnership.partnerReceivableMethod = PaymentDeliveryMethodType.CHECK;
      const updatedPartnership = {
        [payload.id]: currentPartnership,
      };

      return deepMergeWithArrayReplacement(state, updatedPartnership);
    }

    case types.SUBMIT_PARTNERSHIP_FUNDING_ACCOUNT_BANK_SUCCESS: {
      // Update partnerReceivableMethod to ACH
      const currentPartnership = state[payload.id];
      currentPartnership.partnerReceivableMethod = PaymentDeliveryMethodType.ACH;
      const updatedPartnership = {
        [payload.id]: currentPartnership,
      };

      return deepMergeWithArrayReplacement(state, updatedPartnership);
    }

    case createPartnershipMemberRoutine.SUCCESS: {
      // The first key is the new partnership member id
      const newPartnershipMemberId = firstValue(allKeys(payload.partnershipMember));
      // We get the partnership id from the action.payload.meta
      const { partnershipId } = Object(payload.meta);

      if (!partnershipId) {
        // if the partnershipId is not passed as a part of the payload.meta object,
        // we want to return the state as-is
        return state;
      }

      // We update the current partnership[partnershipId] state with the new partnership member
      const updatedPartnerships = {
        [partnershipId]: getPartnershipWithUpdatedPartnershipMembers(state[partnershipId], [newPartnershipMemberId]),
      };

      return deepMergeWithArrayReplacement(state, updatedPartnerships);
    }

    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case fetchItemsRoutine.SUCCESS:
    case FETCH_ITEM_SUCCESS:
    case sendSubmitBillToItemRequestRoutine.SUCCESS:
    case submitCreatePartnershipRoutine.SUCCESS:
    case submitItemRoutine.FULFILL:
    case types.FETCH_PARTNERSHIP_SUCCESS:
    case updatePartnershipGeneralInfoRoutine.SUCCESS:
    case resendPartnershipInviteRoutine.SUCCESS:
    case updateCompany.type:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(payload.partnership, fetchedPartnershipRelationships),
      );

    default:
      return state;
  }
};

export default byIdReducer;
