import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import {} from 'interfaces/partnerships';
export const getPartnershipInviteSwalErrorText = (partnership) => {
    let partnerCompanyName = '';
    if (partnership) {
        partnerCompanyName = partnership.name;
    }
    return partnerCompanyName
        ? `${partnerCompanyName} doesn't have any contacts that can take actions in ${PLATFORM_DISPLAY_SHORT_NAME} (like accepting a payment or paying an invoice). Add a new contact and try again.`
        : `There are no contacts that can take actions in ${PLATFORM_DISPLAY_SHORT_NAME} (like accepting a payment or paying an invoice). Add a new contact and try again.`;
};
