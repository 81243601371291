import React from 'react';

import { ContentWithOptionalText, IconNames } from 'components';

import { colors } from 'constants/styles';

import { hasLength, ternary } from 'helpers/utility';

import { ContactsInteractionBarAddContactButton } from '../components';

import { getContactsInteractionBarLeftContentText, getPastItemMembersInteractionBarLeftContentText } from './text';

/**
 * Returns props for the inner InteractionBar component, returned by the ContactsInteractionBar.
 * @param {ComponentProps} parentProps
 * @param {PartnershipMember[]} parentProps.contacts
 * @param {LedgerContact} parentProps.importedContact
 * @param {Function} parentProps.renderBarButtonLeft
 * @param {Function} parentProps.renderBarButtonRight
 * @param {Boolean} parentProps.hasPastItemMembers
 * @return {{leftContent: string, className: string, buttonRight: *}}
 */
export const getBarPropsForContactsInteractionBarInner = (parentProps = {}) => {
  const {
    contacts,
    hasPastItemMembers,
    importedContact,
    ledger,
    renderBarButtonLeft,
    renderBarButtonRight,
    isVendorContactOptional,
    ...rest
  } = parentProps;

  const hasNoContacts = !hasLength(contacts);

  const barProps = {
    buttonRight: <ContactsInteractionBarAddContactButton renderBarButtons={renderBarButtonRight} {...rest} />,
    className: 'flex-row',
    leftContent: (
      <ContentWithOptionalText optional={isVendorContactOptional}>
        {getContactsInteractionBarLeftContentText({
          contacts,
          importedContact,
          ledger,
          isVendorContactOptional,
        })}
      </ContentWithOptionalText>
    ),
  };
  const noContactsIcon = isVendorContactOptional ? null : IconNames.WARNING_SIGN;

  const barIconProps = {
    className: 'align-self--center',
    color: ternary(hasNoContacts, colors.colorDarkYellowHex, colors.colorAquaBoldHex),
    icon: ternary(hasNoContacts, noContactsIcon, IconNames.TICK_CIRCLE),
  };

  const barPastItemMembersProps = {
    ...barProps,
    leftContent: getPastItemMembersInteractionBarLeftContentText(contacts),
    iconProps: {
      ...barIconProps,
      color: colors.colorMainJordanHex,
      icon: IconNames.BLOCKED_PERSON,
    },
  };

  if (renderBarButtonLeft) {
    barProps.buttonLeft = <ContactsInteractionBarAddContactButton renderBarButtons={renderBarButtonLeft} {...rest} />;
  }

  // We want to show the ledger icon + information only when there are no contacts and there's a contact to import
  if (hasNoContacts && importedContact) {
    barProps.ledgerIconProps = { ...barIconProps, ledger };
  } else {
    barProps.iconProps = barIconProps;
  }

  if (hasPastItemMembers) {
    return barPastItemMembersProps;
  }

  return barProps;
};
