import { useState, useEffect } from 'react';
import { CurrencyCodeUsd } from 'constants/currency';
import { mapCurrencyCodeToSelectOption } from 'helpers/currency';
import { reduceKeys } from 'helpers/utility';
import { usePartnershipPaymentCurrencies } from 'hooks';
import {} from './usePartnershipPaymentOptions.types';
export const usePartnershipPaymentOptions = ({ partnershipId, addCurrency, }) => {
    const { data, isFetching } = usePartnershipPaymentCurrencies(partnershipId);
    const [validCurrencies, setValidCurrencies] = useState([]);
    const [currencyCodesBill, setCurrencyCodesBill] = useState([]);
    useEffect(() => {
        const currencyCodesBillSelectable = data?.currencyCodesBillSelectable
            ? [...data?.currencyCodesBillSelectable]
            : undefined;
        const validCurr = currencyCodesBillSelectable?.length ? currencyCodesBillSelectable : [CurrencyCodeUsd];
        const currCodesBill = [...validCurr];
        if (addCurrency && validCurr.indexOf(addCurrency) === -1) {
            currCodesBill.push(addCurrency);
        }
        setValidCurrencies(validCurr);
        setCurrencyCodesBill(currCodesBill);
    }, [data, addCurrency]);
    const currencyCodesBillOptions = currencyCodesBill.map(mapCurrencyCodeToSelectOption);
    const mapCurrencyReceiverToOptions = (routePayable) => mapCurrencyCodeToSelectOption(routePayable.currencyCodeReceiver);
    const receiverOptions = reduceKeys(data?.routesPayable, (accumulator, key) => ({
        ...accumulator,
        [key.toUpperCase()]: data.routesPayable[key].map(mapCurrencyReceiverToOptions),
    }), {});
    return {
        currencyCodesBillOptions,
        isFetching,
        validCurrencies,
        vendorCurrencyCodeReceiverOptionsByBillCurrency: receiverOptions,
    };
};
