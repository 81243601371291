import { IconNames } from 'components';

import { typography } from 'constants/styles';

import { ternary } from 'helpers/utility';

export const getPermissionDisclosureListColor = (enabled) =>
  ternary(enabled, typography.TextColor.GREY_XX_DARK, typography.TextColor.GREY_DARK);

export const getPermissionDisclosureListRowIconProps = (enabled) => {
  const color = getPermissionDisclosureListColor(enabled);
  const icon = ternary(enabled, IconNames.SMALL_TICK, IconNames.SMALL_CROSS);

  return { color, icon };
};
