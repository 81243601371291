import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2, FlexRow, IconNames, Text } from 'components';

import { typography } from 'constants/styles';
import { DetailsToggleLabel, Intent } from 'constants/ui';

import { ternary } from 'helpers/utility';

import { ExternalBlockItem } from 'modules/external/ExternalBlocksSection';

import { ExternalReceiptLineItems, ExternalReceiptMemo, ExternalReceiptPriceDetails } from './components';

import './ExternalReceiptItemDetails.scss';

/**
 * Component rendering item details in External Receipt single item header
 * @param {ComponentProps} props
 * @param {Item} props.item
 * @returns {FunctionComponent}
 */
const ExternalReceiptItemDetails = ({ item }) => {
  const contentRef = React.useRef();
  const [showDetails, setShowDetails] = React.useState(false);

  const showDetailsButtonIconName = ternary(showDetails, IconNames.CHEVRON_UP, IconNames.CHEVRON_DOWN);
  const showDetailsLabel = ternary(showDetails, DetailsToggleLabel.HIDE, DetailsToggleLabel.SHOW);

  const toggleDetails = () => {
    if (!showDetails) {
      // When we open the details, we want the content to be scrolled to the top, regardless of where the user
      // left when details were collapsed (see https://warrenpay.atlassian.net/browse/DEV-1321 AC #7).
      contentRef.current.scrollTo(0, 0);
    }

    setShowDetails(!showDetails);
  };

  return (
    <div className="external-receipt-item-details">
      <ExternalBlockItem className="external-receipt-item-details__trigger">
        <FlexRow
          className="external-receipt-single-item__details align-items--center justify-content--space-between"
          stackOnMobile={false}
        >
          <Text.Regular color={typography.TextColor.GREY_X_DARK} size={typography.TextSize.LEVEL_200}>
            {showDetailsLabel}
          </Text.Regular>

          <ButtonV2
            intent={Intent.NEUTRAL}
            isIconButton
            leftIconName={showDetailsButtonIconName}
            onClick={toggleDetails}
          />
        </FlexRow>
      </ExternalBlockItem>

      <div
        className={classNames('external-receipt-item-details__content', {
          'external-receipt-item-details__content--expanded': showDetails,
        })}
        ref={contentRef}
      >
        <ExternalReceiptLineItems lineItems={item.lineItems} />
        <ExternalReceiptPriceDetails item={item} />
        <ExternalReceiptMemo memo={item.extended?.memo} />
      </div>
    </div>
  );
};

ExternalReceiptItemDetails.propTypes = {
  item: PropTypes.shape({
    lineItems: PropTypes.arrayOf(PropTypes.shape({})),
    extended: PropTypes.shape({
      memo: PropTypes.string,
    }),
  }).isRequired,
};

export default ExternalReceiptItemDetails;
