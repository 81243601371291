import { DateElement, DateFormats } from '@routable/shared';
import dayjs from 'dayjs';
import _find from 'lodash/find';
import _get from 'lodash/get';
import React from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {} from 'redux-form';
import { FundingErrors } from 'constants/error';
import { commonFormFields, partnershipMemberContactFormFields } from 'constants/formFields';
import { PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MAX, PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MIN } from 'constants/platform';
import * as regexp from 'constants/regex';
import { URL } from 'constants/regex';
import { DateStringLength, TimeZoneId } from 'constants/temporal';
import { ValidationErrorText } from 'constants/validation';
import { MAX_SUBDOMAIN_LENGTH } from 'helpers/constants';
import { getAge, isDateBefore } from 'helpers/date';
import { digitsOnly } from 'helpers/fieldNormalizers';
import { isClientSideValidationDisabled } from 'helpers/formValidation';
import { unformatCurrency } from 'helpers/numbers';
import { any, anyValues, areAllEqual, hasLength, hasZeroLength, isArray, isEqual, isFn, isGreaterOrEqual, isNotEqual, isNum, isObject, isString, isValueEmpty, lengthOf, or, ternary, } from 'helpers/utility';
import { isValidEmail } from 'helpers/validation';
import { BUSINESS_NAME_VALIDATION_REGEX } from 'modules/signup-v3/constants/regex';
import { partnershipMembersFromPartnershipPropSelector } from 'queries/partnershipMemberCompoundSelectors';
import { currentCompanyMembersSelector } from 'selectors/currentCompanySelectors';
import { currentUserSelector } from 'selectors/currentUserSelectors';
import { createPartnershipFormSelector } from 'selectors/forms';
import { storeAccessor as store } from 'store/accessor';
import { hintErrors } from '../components/passwordInput/components/PasswordHints/constants/hintErrors';
import { availableRequirementValidators } from '../components/passwordInput/constants/availableRequirementValidators';
import { isFullAddress } from './addressHelpers';
import { isCompanyTypeBusiness, isCompanyTypePersonal } from './currentCompany';
import {} from './fieldValidation.types';
import { getVendorOrCustomerTitleFromItemKind, isPartnershipTypeVendor } from './partnerships';
import { checkRoutingNumber } from './routingNumbers';
export const bankAccountNumberValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    const regex = /^[0-9]{4,17}$/i;
    if (!regex.test(value)) {
        return ['Invalid bank account number!'];
    }
    return undefined;
};
export const bankRoutingNumberValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (checkRoutingNumber(value)) {
        return undefined;
    }
    return [FundingErrors.INVALID_ROUTING_NUMBER];
};
export const conditionalValidator = (conditionalValueOrFunction, validator) => {
    if (isClientSideValidationDisabled()) {
        return () => undefined;
    }
    if (isFn(conditionalValueOrFunction)) {
        return (...args) => {
            if (!conditionalValueOrFunction(...args)) {
                return undefined;
            }
            if (isArray(validator)) {
                return validator.reduce((previousResult, validate) => {
                    if (previousResult) {
                        return previousResult;
                    }
                    return validate(...args);
                }, undefined);
            }
            return validator(...args);
        };
    }
    if (!conditionalValueOrFunction) {
        return () => undefined;
    }
    return validator;
};
export const dateValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    const error = ['Please enter a valid date (mm/dd/yyyy)'];
    if (!value) {
        return error;
    }
    if (value.length < DateStringLength.BIRTH_DATE) {
        return error;
    }
    const date = dayjs(value, DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY, true);
    if (date.isValid()) {
        return undefined;
    }
    return error;
};
export const generateUseDateNotBeforeValidator = (useMemo) => (lowestDate = dayjs().tz(TimeZoneId.PT).add(1, 'days').startOf('day')) => useMemo(() => (value) => isDateBefore(value, lowestDate) ? [ValidationErrorText.minDateValidation] : undefined, [lowestDate]);
export const useDateNotBeforeValidator = generateUseDateNotBeforeValidator(React.useMemo);
export const EINValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (value && digitsOnly(value).length === 9) {
        return undefined;
    }
    return ['EIN must be a 9-digit number'];
};
export const emailValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (isValidEmail(value)) {
        return undefined;
    }
    return ['Invalid email address'];
};
export const fileInputValidator = (fileList) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (fileList && isArray(fileList) && fileList.length > 0) {
        return undefined;
    }
    return ['Required'];
};
export const internationalPhoneNumberValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (value && value.number && isValidPhoneNumber(value.number)) {
        return undefined;
    }
    return ['Not a valid phone number for this country'];
};
export const makeEnforceByPropCondition = (propName) => (value, allValues, fieldProps, nameOrAccessor, componentProps) => {
    const evalProps = componentProps || fieldProps;
    if (!evalProps) {
        return true;
    }
    if (evalProps[propName] === undefined) {
        return true;
    }
    return evalProps[propName];
};
export const maxAgeValidator = (maxAge) => (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (!value) {
        return undefined;
    }
    if (value.length < DateStringLength.BIRTH_DATE) {
        return undefined;
    }
    const birthDate = dayjs(value, DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY, true);
    if (!birthDate.isValid()) {
        return undefined;
    }
    const age = getAge(birthDate);
    if (age >= maxAge) {
        return [`You must be younger than ${maxAge} to use Routable`];
    }
    return undefined;
};
export const maxCharacterLengthValidator = (maxCharacters) => (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (!value) {
        return undefined;
    }
    if (String(value).length > maxCharacters) {
        return [`Has to be at most ${maxCharacters} characters`];
    }
    return undefined;
};
export const microdepositValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    const unformatted = unformatCurrency(value);
    if (unformatted < PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MIN) {
        return [`Amount must be at least ${PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MIN}`];
    }
    if (unformatted > PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MAX) {
        return [`Amount cannot be over ${PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MAX}`];
    }
    return undefined;
};
export const minAgeValidator = (minAge) => (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (!value) {
        return undefined;
    }
    if (value.length < DateStringLength.BIRTH_DATE) {
        return undefined;
    }
    const birthDate = dayjs(value, DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY, true);
    if (!birthDate.isValid()) {
        return undefined;
    }
    const age = getAge(birthDate);
    if (age < minAge) {
        return [`You must be at least ${minAge} to use Routable`];
    }
    return undefined;
};
export const minCharacterLengthValidator = (minCharacters) => (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (!value) {
        return undefined;
    }
    if (String(value).length < minCharacters) {
        return [`Has to be at least ${minCharacters} characters`];
    }
    return undefined;
};
export const minMaxDateValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (!value) {
        return undefined;
    }
    const maxDate = dayjs('2100-12-31', DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY);
    const minDate = dayjs('1970-01-01', DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY);
    const date = dayjs(value, DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY, true);
    const error = ['You must specify a date between 1/1/1970 and 12/31/2100'];
    if (!date.isValid()) {
        return undefined;
    }
    if (date <= maxDate && date >= minDate) {
        return undefined;
    }
    return error;
};
export const customizableNotYourEmailValidator = (errorMessage) => (value) => {
    if (!value || isClientSideValidationDisabled()) {
        return undefined;
    }
    const reduxState = store.getState();
    const currentUser = currentUserSelector(reduxState);
    if (isNotEqual(value, currentUser.email)) {
        return undefined;
    }
    return [errorMessage];
};
export const notYourEmailValidator = customizableNotYourEmailValidator('You cannot add yourself as a contact');
export const cantAddYourselfAsTeamMemberValidator = customizableNotYourEmailValidator('You cannot add yourself as a team member');
export const customizableNotYourTeamMemberEmailValidator = (errorMessage) => (value) => {
    if (!value || isClientSideValidationDisabled()) {
        return undefined;
    }
    const reduxState = store.getState();
    const currentMembers = currentCompanyMembersSelector(reduxState);
    const foundEmailMatch = _find(currentMembers, (member) => member.email === value);
    if (!foundEmailMatch) {
        return undefined;
    }
    return [errorMessage];
};
export const notYourTeamMemberEmailValidator = customizableNotYourTeamMemberEmailValidator('You cannot add your team member as a contact');
export const alreadyATeamMemberEmailValidator = customizableNotYourTeamMemberEmailValidator('This email already belongs to an existing team member');
export const notAnExistingPartnershipMemberEmailValidator = (value, allValues, props) => {
    const { partnership, sidePanel } = props;
    if (any([
        isValueEmpty(value),
        isValueEmpty(partnership),
        partnership?.__isNew__,
        partnership?.isLedgerOnly,
        isClientSideValidationDisabled(),
    ])) {
        return undefined;
    }
    const reduxState = store.getState();
    const partnershipMembers = partnershipMembersFromPartnershipPropSelector(reduxState, props);
    const foundEmailMatch = _find(partnershipMembers, (member) => isEqual(member.email, value));
    if (!foundEmailMatch) {
        return undefined;
    }
    if (isEqual(value, sidePanel?.initialPartnershipMember?.email)) {
        return undefined;
    }
    return ['You cannot add existing contacts'];
};
export const notAddedInCurrentFormEmailValidator = (value, allValues, props, fieldName) => {
    if (!value || isClientSideValidationDisabled()) {
        return undefined;
    }
    const { initialValues } = props;
    const { partner } = allValues;
    if (isEqual(value, initialValues[fieldName])) {
        return undefined;
    }
    if (isCompanyTypePersonal(partner?.companyType)) {
        return undefined;
    }
    const reduxState = store.getState();
    const formPartnershipMembers = createPartnershipFormSelector(reduxState, commonFormFields.PARTNERSHIP_MEMBERS);
    const foundEmailMatch = _find(formPartnershipMembers, (member) => isEqual(member.email, value));
    if (!foundEmailMatch) {
        return undefined;
    }
    return ['This contact has already been added'];
};
export const createAtLeastOneContactValidator = (validateBusiness = false) => (value, allValues) => {
    if (!value || isClientSideValidationDisabled()) {
        return undefined;
    }
    const { item, partner, partnershipType } = allValues;
    const type = partnershipType || getVendorOrCustomerTitleFromItemKind(item?.kind);
    const isVendor = isPartnershipTypeVendor(type);
    const isPassing = isVendor || (validateBusiness && isCompanyTypeBusiness(partner?.companyType));
    if (isPassing) {
        return undefined;
    }
    if (isGreaterOrEqual(lengthOf(value), 1)) {
        return undefined;
    }
    return ['You must select at least one contact'];
};
export const atLeastOneContactUnlessIsVendorAndIsBusinessValidator = createAtLeastOneContactValidator(true);
export const atLeastOneContactUnlessIsVendorValidator = createAtLeastOneContactValidator();
export const atLeastOnePaymentMethodOptionValidator = (value, allValues, props, name) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    const [formFieldPrefix] = name.split('.');
    const paymentDeliveryMethodsAccepted = allValues[formFieldPrefix]?.paymentDeliveryMethodsAccepted;
    if (!paymentDeliveryMethodsAccepted) {
        return undefined;
    }
    if (Object.values(paymentDeliveryMethodsAccepted).some((el) => el === true)) {
        return undefined;
    }
    return ['At least 1 option is required to continue'];
};
export const numberGreaterOrEqualToZeroValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (typeof value === 'number' && value >= 0) {
        return undefined;
    }
    return ['Has to be greater or equal to zero'];
};
export const numberGreaterThanZeroValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (typeof value === 'number' && value > 0) {
        return undefined;
    }
    return ['Has to be greater than zero'];
};
export const numberLessThanOrEqualToValidator = (lessThanOrEqualTo, options = {}) => (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (value <= lessThanOrEqualTo) {
        return undefined;
    }
    const { formatter } = options;
    const formattedValue = formatter ? formatter(lessThanOrEqualTo) : lessThanOrEqualTo;
    return [`Has to be less than or equal to ${formattedValue}`];
};
export const numberLessThanValidator = (lessThan) => (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (value < lessThan) {
        return undefined;
    }
    return [`Has to be less than ${lessThan}`];
};
export const numberNonNegativeValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (typeof value === 'number' && value >= 0) {
        return undefined;
    }
    return ['Has to be a non-negative number'];
};
export const oneOfManyFieldsRequiredValidator = (allValues, fields) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    let hasAtLeastOneField = false;
    const fieldsCount = Object.keys(fields).length;
    if (!fields || fieldsCount < 2) {
        return undefined;
    }
    for (let i = 0; i < fieldsCount; i += 1) {
        const fieldValue = _get(allValues, fields[i].path);
        if (fieldValue || fieldValue === 0) {
            hasAtLeastOneField = true;
            break;
        }
    }
    if (hasAtLeastOneField) {
        return undefined;
    }
    const displayFieldNames = fields
        .map((fieldName) => {
        if (fieldName.display) {
            return fieldName.display;
        }
        const fieldNamePathParts = fieldName.path.split('.');
        return fieldNamePathParts[fieldNamePathParts.length - 1];
    })
        .join(', ');
    return [`At least one of ${displayFieldNames} is required`];
};
export const phoneNumberLengthValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (value && value.number && digitsOnly(value.number).length >= 7 && digitsOnly(value.number).length <= 16) {
        return undefined;
    }
    return ['Phone number must be 7-16 digits long'];
};
export const approverRequiredValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (hasLength(value)) {
        return undefined;
    }
    return ['At least one approver is required'];
};
export const phoneNumberRequiredValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (value && value.number) {
        return undefined;
    }
    return ['Required'];
};
export const isCurrentPasswordReusedValidator = (currentPasssword, { form }) => currentPasssword === form.newPassword || currentPasssword === form.newPasswordConfirm
    ? ["The new password can't match the current password."]
    : undefined;
export const isNewPasswordSameValidator = (newPassword, { form }) => newPassword === form.currentPassword ? ["The new password can't match the current password."] : undefined;
export const requiredValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (isString(value) && value.trim()) {
        return undefined;
    }
    if (isNum(value)) {
        return undefined;
    }
    if (isArray(value) && hasZeroLength(value)) {
        return ['Required'];
    }
    if (!isString(value) && !isNum(value) && value) {
        return undefined;
    }
    return ['Required'];
};
export const sameAccountNumberValidator = (value, allValues) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    const accountNumber = ternary(allValues?.form?.bankAccount, allValues?.form?.bankAccount?.bankAccountNumber, allValues?.form?.bankAccountNumber);
    return ternary(isEqual(value, accountNumber), undefined, ['Account numbers do not match!']);
};
export const slugValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    const regex = /^[A-Za-z0-9]+(?:[_-][A-Za-z0-9]+)*$/;
    if (!regex.test(value)) {
        return ['Must contain letters or numbers. Can be separated by underscores or hyphens.'];
    }
    return undefined;
};
export const subdomainValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    const regex = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/;
    if (value.length > MAX_SUBDOMAIN_LENGTH) {
        return [`Your entry can't exceed ${MAX_SUBDOMAIN_LENGTH} characters.`];
    }
    if (!regex.test(value)) {
        return ['You can only enter letters, numbers, or hyphens.'];
    }
    return undefined;
};
export const termsOfServiceValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (value === true) {
        return undefined;
    }
    return ['You must agree to the Terms of Service to continue.'];
};
export const createRegExpValidator = (regExpString, errorMessage) => (value) => {
    const regExp = new RegExp(regExpString);
    const stringValue = value?.toString?.();
    if (!regExp.test(stringValue)) {
        return [errorMessage];
    }
    return undefined;
};
export const SSNValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (value && digitsOnly(value).length === 9) {
        return undefined;
    }
    return ['SSN must be 9 digits long'];
};
export const TINValidator = (tinType) => (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (value && digitsOnly(value).length === 9) {
        return undefined;
    }
    return [`${tinType} must be a 9-digit number`];
};
export const getPasswordMatchingValidator = (fieldNames) => (_value, allValues) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (!isArray(fieldNames)) {
        return undefined;
    }
    const passwordValues = fieldNames.map((field) => allValues[field]);
    if (passwordValues.some((val) => val === undefined)) {
        return undefined;
    }
    if (areAllEqual(passwordValues)) {
        return undefined;
    }
    return ['Passwords do not match'];
};
export const getAnyTruthyPropertyValueValidator = (message) => (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (!isObject(value)) {
        return undefined;
    }
    if (anyValues(value)) {
        return undefined;
    }
    return [message];
};
export const createMinLengthValidator = ({ message, minLength = 1 } = {}) => (value) => {
    if (isGreaterOrEqual(lengthOf(value), minLength)) {
        return undefined;
    }
    return [message];
};
export const specialCharactersValidator = (value) => {
    const FORBIDDEN_CHARS_REGEX = /[<>="“”`!?%~${}[\]\\]/;
    if (value && !FORBIDDEN_CHARS_REGEX.test(value)) {
        return undefined;
    }
    return ['Ensure this field has no special characters - <>="“”`!?%~${}[]\\`'];
};
export const partnershipMemberContactFormValidators = {
    email: [
        requiredValidator,
        emailValidator,
        notYourEmailValidator,
        notYourTeamMemberEmailValidator,
        notAddedInCurrentFormEmailValidator,
        notAnExistingPartnershipMemberEmailValidator,
    ],
    emailOptional: conditionalValidator((value) => Boolean(value), [
        emailValidator,
        notYourEmailValidator,
        notYourTeamMemberEmailValidator,
        notAddedInCurrentFormEmailValidator,
        notAnExistingPartnershipMemberEmailValidator,
    ]),
    name: conditionalValidator((value, allValues) => Boolean(value || _get(allValues, partnershipMemberContactFormFields.UI_REQUIRE_NAME_FIELDS)), [requiredValidator, specialCharactersValidator]),
    defaultGeneral: [requiredValidator],
    defaultItem: [requiredValidator],
    phoneNumber: conditionalValidator((value) => Boolean(_get(value, 'number')), [phoneNumberLengthValidator, internationalPhoneNumberValidator]),
    alreadyAssociatedEmailCheck: requiredValidator,
};
export const createFreeformDatetimeValidator = ({ allowEmpty, dateElements }) => (value) => {
    const includesDate = dateElements.includes(DateElement.date);
    const includesTime = dateElements.includes(DateElement.time);
    let testValid;
    if (allowEmpty && !value) {
        testValid = true;
    }
    else if (includesDate && includesTime) {
        testValid = regexp.DATE_TIME.test(value);
    }
    else if (includesDate) {
        testValid = or(regexp.DATE_ONLY.test(value), regexp.ISO_DATE_ONLY.test(value));
    }
    else {
        testValid = regexp.TIME_ONLY.test(value);
    }
    if (testValid) {
        return undefined;
    }
    return [`Please enter a valid ${dateElements.join('')}`];
};
export const inviteTeamMemberFormValidators = {
    EMAIL: [requiredValidator, emailValidator, cantAddYourselfAsTeamMemberValidator],
};
export const urlValidator = (value) => {
    if (isClientSideValidationDisabled()) {
        return undefined;
    }
    if (!URL.test(value)) {
        return ['Must be a full URL starting with http(s)://'];
    }
    return undefined;
};
export const vendorCountryAndAddressCountryMatchValidator = (value, allValues) => {
    const { address } = Object(allValues);
    if (!value || !anyValues(address) || !isFullAddress(address)) {
        return undefined;
    }
    if (value !== address.country) {
        return [
            "This country does not match this vendor's registered address. Change the country or remove the registered address.",
        ];
    }
    return undefined;
};
export const hasLettersValidator = (value) => {
    if (value && /[a-zA-Z]/.test(value)) {
        return undefined;
    }
    return 'Must contain at least 1 letter';
};
export const hasUpperLettersValidator = (value) => {
    if (value && /[A-Z]/.test(value)) {
        return undefined;
    }
    return [hintErrors[availableRequirementValidators.HAS_UPPER_LETTER]];
};
export const hasLowerLettersValidator = (value) => {
    if (value && /[a-z]/.test(value)) {
        return undefined;
    }
    return [hintErrors[availableRequirementValidators.HAS_LOWER_LETTER]];
};
export const hasNumbersValidator = (value) => {
    if (value && /[0-9]/.test(value)) {
        return undefined;
    }
    return [hintErrors[availableRequirementValidators.HAS_NUMBERS]];
};
export const companyNameValidator = (value) => {
    if (value && !BUSINESS_NAME_VALIDATION_REGEX.test(value)) {
        return undefined;
    }
    return ['Ensure this field has no special characters: <>="`!?%~${}\\'];
};
export const shouldWarnEmail = (_, values) => _get(values, partnershipMemberContactFormFields.META_WARNINGS_EMAIL);
export const ownershipPercentageValidator = (value) => {
    const floatValue = parseFloat(value);
    if (floatValue < 25) {
        return ['Minimum percentage of ownership is 25%'];
    }
    if (floatValue > 100) {
        return ['Maximum percentage of ownership is 100%'];
    }
    return undefined;
};
