import indefinite from 'indefinite';
import React from 'react';
import { v4 } from 'uuid';
import validator from 'validator';
import { END_OF_LINE_REGEX } from 'constants/regex';
import { isLastIndex, isLessThan, isString, isStringOrNum, lengthOf, ternary } from 'helpers/utility';
export const convertToLowerCase = (value) => {
    if (!isStringOrNum(value)) {
        return '';
    }
    return value.toString().toLowerCase();
};
export const convertToUpperCase = (value) => {
    if (!isStringOrNum(value)) {
        return '';
    }
    return value.toString().toUpperCase();
};
export const capitalize = (text) => {
    if (!isString(text)) {
        return '';
    }
    if (validator.isUUID(text)) {
        return text;
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
};
export const uncapitalize = (text) => {
    if (!isString(text)) {
        return '';
    }
    if (validator.isUUID(text)) {
        return text;
    }
    return text.charAt(0).toLowerCase() + text.slice(1);
};
export const dashTextToCamelCase = (text) => {
    if (!isString(text)) {
        return '';
    }
    if (validator.isUUID(text)) {
        return text;
    }
    return text.replace(/([-_][a-z0-9])/g, ($1) => $1.toUpperCase().replace(/[-_]/, ''));
};
export const uppercaseToDash = (text) => {
    if (!isString(text)) {
        return '';
    }
    if (validator.isUUID(text)) {
        return text;
    }
    return text.replace(/([A-Z])/g, ($1) => `-${$1.toLowerCase()}`);
};
export const uppercaseToUnderscore = (text) => {
    if (!isString(text)) {
        return '';
    }
    if (validator.isUUID(text)) {
        return text;
    }
    const underscoredText = text.replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`);
    if (underscoredText.charAt(1) === '_') {
        return underscoredText.substring(1);
    }
    return underscoredText;
};
export const uppercaseToSnakeCaseWithNumbers = (text) => {
    if (typeof text !== 'string') {
        return '';
    }
    const snakeCase = uppercaseToUnderscore(text);
    const underscoredText = snakeCase.replace(/\d+/, ($1) => `_${$1.toLowerCase()}`);
    if (underscoredText.charAt(1) === '_' || !!text.match(/^[1-9]/)) {
        return underscoredText.substring(1);
    }
    return underscoredText;
};
export const camelCaseTextToWords = (text) => {
    if (!isString(text)) {
        return '';
    }
    if (validator.isUUID(text)) {
        return text;
    }
    const words = text.split(' ');
    const returnArr = [];
    words.forEach((word) => {
        if (word === word.toUpperCase()) {
            returnArr.push(word);
        }
        else {
            returnArr.push(uppercaseToUnderscore(word)
                .replace(/([-_])/g, ' ')
                .trim());
        }
    });
    return returnArr.join(' ');
};
export const getTextWithLeadingArticle = (text, options = {}) => indefinite(text, options);
export const isStringLengthGreaterThanLimit = (str, limit) => str?.length > limit;
export const newLineToBRTag = (stringWithNewLines, options = {}) => {
    const { useStaticHtml = false } = options;
    return stringWithNewLines
        .replace(END_OF_LINE_REGEX, '\n')
        .split('\n')
        .map((part, idx, arr) => {
        const fragment = (React.createElement(React.Fragment, { key: v4() },
            part,
            React.createElement("br", null)));
        if (isLastIndex(arr, idx)) {
            const htmlStringWithoutMargin = `<span key="${v4()}">${part}</span>`;
            return ternary(useStaticHtml, htmlStringWithoutMargin, part);
        }
        const htmlStringWithMargin = `<span class="margin-bottom--xm" key="${v4()}">${part}</span>`;
        return ternary(useStaticHtml, htmlStringWithMargin, fragment);
    });
};
export const getFieldBaseObjectPath = (fieldName) => {
    const lastPeriodIdx = fieldName.lastIndexOf('.');
    if (lastPeriodIdx < 0) {
        return fieldName;
    }
    return fieldName.substr(0, lastPeriodIdx);
};
export const convertNumberToOrdinalString = (num) => {
    if (!isStringOrNum(num) || isLessThan(Number(num), 1)) {
        return '1st';
    }
    const stringNum = num.toString();
    switch (stringNum) {
        case '1':
            return '1st';
        case '2':
            return '2nd';
        case '3':
            return '3rd';
        default:
            return `${stringNum}th`;
    }
};
export const stripNewlinesFromString = (str) => str.replace(/\n|\r\n/g, '');
export const apostrophiseString = (str) => {
    if (!str || !isString(str)) {
        return '';
    }
    if (str.endsWith('s')) {
        return `${str}'`;
    }
    return `${str}'s`;
};
export const underscoresToSpaces = (str) => {
    if (!isString(str)) {
        return '';
    }
    return str.replace(/_/g, ' ');
};
export const listAllElementsInArray = (strArr = []) => lengthOf(strArr) > 1 ? `${strArr.slice(0, -1).join(', ')} and ${strArr.slice(-1)}` : strArr[0] || '';
