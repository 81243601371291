import { z } from 'zod';
export const taxInfoStatus = z.enum(['completed', 'no_info', 'archived', 'expired', 'requested']);
export const taxInfoStatusFilter = z.enum(['completed', 'missing_info']);
export const countryGroup = z.enum(['international', 'domestic']);
export const entityType = z.enum(['business', 'personal']);
export const taxEmailContactStatus = z.enum(['reachable', 'missing', 'invalid']);
export const taxRequestStatus = z.enum(['none', 'pending', 'expired']);
export const taxFormSubmission = z.enum(['api', 'dashboard', 'internal', 'invitation']);
export const taxRiskSummary = z.enum([
    'not_evaluated',
    'queued',
    'cant_validate',
    'passed',
    'review_required',
    'dismissed',
    'outdated',
]);
