import PropTypes from 'prop-types';
import React from 'react';

import { IconNames } from 'components/icon';

import { colors, sizes } from 'constants/styles';

import { PaginationBlockButton } from 'modules/dashboard/paginationBlock/components/PaginationBlockButton';

/**
 * Previous button for pagination block
 * @param {ComponentProps} props
 * @param {Boolean} props.isDisabled
 * @param {Function} props.onClick
 * @param {RestOfProps} rest
 * @return {StatelessComponent}
 */
const PaginationBlockPreviousButton = ({ isDisabled, onClick, ...rest }) => (
  <PaginationBlockButton
    isDisabled={isDisabled}
    leftIconColor={colors.colorDarkSilverHex}
    leftIconName={IconNames.ARROW_LEFT}
    leftIconSize={sizes.iconSizes.LARGE}
    onClick={onClick}
    {...rest}
  />
);

PaginationBlockPreviousButton.propTypes = {
  buttonContainerClassname: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

PaginationBlockPreviousButton.defaultProps = {
  buttonContainerClassname: undefined,
};

export default PaginationBlockPreviousButton;
