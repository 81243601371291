import { change, resetSection } from 'redux-form';

import { updatePartnershipPaginationCountAdded } from 'actions/partnership';
import { submitCreatePartnershipRoutine } from 'actions/routines/partnership';
import {
  createPartnershipMemberRoutine,
  setFormPartnershipMembers,
  updatePartnershipMemberRoutine,
} from 'actions/routines/partnershipMember';
import { contactSidePanelClose } from 'actions/sidePanels';
import { showSuccessUi } from 'actions/ui';

import { CompanySearchPartnerTypes } from 'constants/company';
import { createItemFormFields } from 'constants/formFields';
import { SuccessIndicatorMessages } from 'constants/ui';

import { isFormCreateItem, isFormCreatePartnership } from 'helpers/forms';

/**
 * Returns the array of actions to dispatch on successful partnership creation.
 * @param {Object} options
 * @param {Object} options.data
 * @param {String} options.form
 * @param {Array.<Object>} options.formattedPartnershipMembers
 * @param {Object} options.originalData
 * @param {Object} options.partnershipData
 * @param {?SchemaViewModelManager} options.viewModelManager
 * @return {Array.<Object>}
 */
export const getActionsForCreatePartnershipSuccess = (options = {}) => {
  const { data, form, formattedPartnershipMembers, originalData, partnershipData, viewModelManager } = options;

  const isCreatePartnershipForm = isFormCreatePartnership(form);
  const isCreateItemForm = isFormCreateItem(form);

  return [
    submitCreatePartnershipRoutine.success(data, {
      partnershipId: originalData.data.id,
      partnershipMembers: formattedPartnershipMembers,
    }),
    change(form, 'ui.partnershipSubmitted', true),
    change(form, 'partner.name', partnershipData.partner.name),
    change(form, 'partnership.id', partnershipData.id),
    change(form, 'ui.blockDetailsSections', false),
    change(form, 'ui.selectedCompany', {
      type: CompanySearchPartnerTypes.EXISTING,
      ...partnershipData,
    }),
    // this is needed because we have a form structure that isn't supported in any official
    // capacity whatsoever by redux-form (nested forms). the manifestation of this is that
    // when submitting the child form, we can sometimes get validation errors in the parent
    // form. this occurs in create items, so those errors need to be silenced.
    isCreateItemForm &&
      resetSection(
        form,
        'item.fundingAccount.id',
        'item.invoiceNumber',
        'item.lineItems',
        'item.id',
        // in the create item form, view model manager is non-optional
        ...viewModelManager.allSchemaPaths,
      ),
    /**
     * The next action is needed since, after resetSection declared above, the
     * middle section gets cleared and no bill currency value is selected.
     * For vendors that can be billed in several currencies, this results on an enabled
     * empty select component. For vendors that can be billed in one single currency,
     * this results on a disabled empty select component, being a blocker for the process.
     * With the action below, we ensure that the component will have an initial value
     * In case of creating new company for invoice, it should be currencyCodeCustomer field.
     */
    isCreateItemForm &&
      change(
        form,
        createItemFormFields.MAIN_GENERAL_BILL_CURRENCY,
        partnershipData.isVendor ? partnershipData.currencyCodePartner : partnershipData.currencyCodeCustomer,
      ),
    isCreatePartnershipForm && updatePartnershipPaginationCountAdded(),
    showSuccessUi(
      partnershipData.isVendor ? SuccessIndicatorMessages.ADD_VENDOR : SuccessIndicatorMessages.ADD_CUSTOMER,
    ),
  ].filter(Boolean);
};

/**
 * Create a partnershipMember.
 * @param {Object} options
 * @param {string} options.contactEmail - Contact email address; may come from form input, or from initialValues
 * @param {string} options.form - Form name
 * @param {string} options.isUpdate - If we're updating a contact, versus creating one.
 * @param {string} options.successMessage
 * @param {ReduxFormValues} options.values
 * @return {ReduxAction[]}
 */
export const getActionsForNestedPartnershipMembersFormSubmission = ({
  contactEmail,
  form,
  isUpdate,
  successMessage = SuccessIndicatorMessages.ADD_CONTACT,
  values,
}) => [
  // should this function be called, we're in a nested form and are
  // submitting to the parent form; we'd like the parent form to track
  // these values from here on.
  setFormPartnershipMembers.trigger({
    contactEmail,
    form,
    values,
  }),
  // the simplest resolution for the above, as far as submission status
  // and success reducers are concerned, is to dispatch with empty data.
  isUpdate
    ? updatePartnershipMemberRoutine.success({ partnershipMember: {} })
    : createPartnershipMemberRoutine.success({ partnershipMember: {} }),
  contactSidePanelClose(),
  showSuccessUi(successMessage),
];
