import { CurrencyInput } from '@routable/components';
import {} from '@routable/framework';
import clsx from 'clsx';
import React, {} from 'react';
import FormFieldErrors from 'components/error/components/FormFieldErrors';
import { getFieldErrors } from 'helpers/errors';
import { inputHelpers } from '../helpers';
export const CurrencyInputField = ({ addon, className, contentBefore, input, isDisabled, meta, ...rest }) => {
    const errors = (meta.touched || meta.submitFailed) && meta.error;
    const hasErrors = Boolean(errors) || getFieldErrors(errors, input.name);
    const { widthClasses, otherClasses } = inputHelpers.getInputClasses({
        addon,
        className,
        isDisabled,
    }, { hasErrors });
    return (React.createElement("div", { className: clsx(widthClasses) },
        React.createElement("div", { className: clsx(otherClasses) },
            Boolean(contentBefore) && contentBefore,
            React.createElement(CurrencyInput, { currencyCode: rest?.suffix?.trim() || 'USD', disabled: isDisabled, hidePlaceholder: rest?.hidePlaceholder, name: input.name, onChange: input.onChange, required: rest.isRequired, value: input?.value })),
        React.createElement(FormFieldErrors, { errors: errors, fieldName: input.name })));
};
