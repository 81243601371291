import pluralize from 'pluralize';
import React from 'react';
import DropdownMenuButton from 'complexComponents/DropdownMenuButton';
import { IconNames, WhiteSpace } from 'components';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';
import { formatNumber } from 'helpers/numbers';
import { isEqual } from 'helpers/utility';
import { getTableToolbarSelectionManagerOptions } from '../../helpers';
import { TableToolbarSelectionManagerOption } from './components';
function getTransformedResourceType(resourceType) {
    switch (resourceType) {
        case 'inbox':
            return 'bill';
        default:
            return resourceType;
    }
}
const TableToolbarSelectionManager = (props) => {
    const { resourceType, selectableRowsCount, selectedCount, ...rest } = props;
    const resourceDisplay = pluralize(getTransformedResourceType(resourceType), selectedCount);
    const options = getTableToolbarSelectionManagerOptions(props);
    const areAllSelected = isEqual(selectableRowsCount, selectedCount);
    return (React.createElement("div", { className: "selection-manager-wrapper" },
        React.createElement(DropdownMenuButton, { className: "selection-manager", intent: Intent.NEUTRAL, leftIconClassName: "margin-right--xm", leftIconName: IconNames.MULTI_SELECT, leftIconSize: sizes.iconSizes.LARGE, menuAlign: "left", options: options, ...rest },
            React.createElement("span", { className: "manager--content" },
                React.createElement("span", { className: "bold" },
                    areAllSelected && (React.createElement(React.Fragment, null,
                        "All",
                        React.createElement(WhiteSpace, null))),
                    formatNumber(selectedCount)),
                React.createElement(WhiteSpace, null),
                React.createElement("span", { className: "regular" }, `${resourceDisplay} selected`)))));
};
TableToolbarSelectionManager.defaultProps = {
    isDisabled: undefined,
    onClearSelection: undefined,
    onSelectAll: undefined,
    OptionComponent: TableToolbarSelectionManagerOption,
    selectedCount: 0,
};
export default TableToolbarSelectionManager;
