import { IconNames } from 'components/icon';

import { TextColor } from 'constants/styles/typography';

import { ternary } from 'helpers/utility';

/**
 * Returns correct icon name for payment method hint based on the passed
 * isActive value.
 * @param {boolean} isActive
 * @returns {string}
 */
export const getFooterIcon = (isActive) => ternary(isActive, IconNames.TICK_CIRCLE, IconNames.TIME);

/**
 * Returns correct color for payment method hint footer background based on
 * the passed isActive value.
 * @param {boolean} isActive
 * @return {string}
 */
export const getFooterTextColor = (isActive) => ternary(isActive, TextColor.GREEN_BOLD, TextColor.YELLOW_MEDIUM);
