import React from 'react';
import { IconNames, Text, TooltipMUIButton } from 'components';
import { colors, sizes, typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
import { Intent } from 'constants/ui';
import { useSideNavigationV2 } from 'hooks/useSideNavigationV2';
import {} from 'types/quickswitch';
import { MINIMUM_COUNT_FOR_SEARCHBAR } from './constants';
import QuickswitchErrorModal from './QuickswitchErrorModal';
import QuickswitchModal, {} from './QuickswitchModal';
const Quickswitch = (props) => {
    const { children, totalNumberOfMemberships = 0, fetchMembershipList, openModal, isErrorModalOpen = false, ...modalProps } = props;
    const handleClick = () => {
        fetchMembershipList();
        openModal();
    };
    const hasSideNavigationV2 = useSideNavigationV2();
    if (totalNumberOfMemberships <= 1) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        hasSideNavigationV2 ? (React.createElement(React.Fragment, null,
            React.createElement("button", { className: "justify-between quickswitch-button w-full", onClick: handleClick, type: "button" }, children))) : (React.createElement("div", { className: "margin-left--auto" },
            React.createElement(TooltipMUIButton, { intent: Intent.LIGHT, isIconButton: true, leftIconColor: colors.colorWhiteHex, leftIconName: IconNames.SWAP_HORIZONTAL, leftIconSize: sizes.iconSizes.LARGE, onClick: handleClick, title: React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, size: typography.TextSize.LEVEL_100 }, "Switch between your Routable accounts"), tooltipProps: {
                    arrow: true,
                    padding: TooltipPadding.SKINNY,
                    position: TooltipPlacement.BOTTOM,
                } }))),
        React.createElement(QuickswitchModal, { showSearchBar: totalNumberOfMemberships > MINIMUM_COUNT_FOR_SEARCHBAR, ...modalProps }),
        React.createElement(QuickswitchErrorModal, { isModalOpen: isErrorModalOpen })));
};
export default Quickswitch;
