export var ItemKinds;
(function (ItemKinds) {
    ItemKinds["PAYABLE"] = "payable";
    ItemKinds["RECEIVABLE"] = "receivable";
})(ItemKinds || (ItemKinds = {}));
export var ItemAmountKey;
(function (ItemAmountKey) {
    ItemAmountKey["COMPANY"] = "amountCompany";
    ItemAmountKey["DUE"] = "amountDue";
    ItemAmountKey["DUE_COMPANY"] = "amountDueCompany";
    ItemAmountKey["DUE_PARTNER"] = "amountDuePartner";
    ItemAmountKey["PAID"] = "amountPaid";
    ItemAmountKey["PARTNER"] = "amountPartner";
    ItemAmountKey["TOTAL"] = "amount";
})(ItemAmountKey || (ItemAmountKey = {}));
export var ItemCurrencyCodeKey;
(function (ItemCurrencyCodeKey) {
    ItemCurrencyCodeKey["GENERAL"] = "currencyCode";
    ItemCurrencyCodeKey["COMPANY"] = "currencyCodeCompany";
    ItemCurrencyCodeKey["PARTNER"] = "currencyCodePartner";
    ItemCurrencyCodeKey["RECEIVER"] = "currencyCodeReceiver";
})(ItemCurrencyCodeKey || (ItemCurrencyCodeKey = {}));
export var ItemLedgerStatuses;
(function (ItemLedgerStatuses) {
    ItemLedgerStatuses["CANCELED"] = "canceled";
    ItemLedgerStatuses["DELETED"] = "deleted";
    ItemLedgerStatuses["DRAFT"] = "draft";
    ItemLedgerStatuses["ERROR"] = "error";
    ItemLedgerStatuses["NEEDS_APPROVAL"] = "needs_approval";
    ItemLedgerStatuses["NEEDS_IMPORT"] = "needs_import";
    ItemLedgerStatuses["NO_LEDGER"] = "no_ledger";
    ItemLedgerStatuses["PAID"] = "paid";
    ItemLedgerStatuses["PENDING"] = "pending";
})(ItemLedgerStatuses || (ItemLedgerStatuses = {}));
export var ItemPaymentTerms;
(function (ItemPaymentTerms) {
    ItemPaymentTerms["NET30"] = "net30";
    ItemPaymentTerms["NET60"] = "net60";
    ItemPaymentTerms["UPON_RECEIPT"] = "upon_receipt";
    ItemPaymentTerms["SEE_INVOICE"] = "see_invoice";
})(ItemPaymentTerms || (ItemPaymentTerms = {}));
export var ItemStatuses;
(function (ItemStatuses) {
    ItemStatuses["BULK_UPLOAD"] = "bulk";
    ItemStatuses["BULK_UPLOAD_ACTION_CONTINUE"] = "action_continue";
    ItemStatuses["BULK_UPLOAD_ACTION_REQUIRED"] = "action_required";
    ItemStatuses["BULK_UPLOAD_COMPLETE"] = "complete";
    ItemStatuses["BULK_UPLOAD_ERROR"] = "error";
    ItemStatuses["BULK_UPLOAD_STARTING"] = "starting";
    ItemStatuses["CANCELED"] = "canceled";
    ItemStatuses["COMPLETED"] = "completed";
    ItemStatuses["COMPLIANCE_HOLD"] = "compliance_hold";
    ItemStatuses["CREATED"] = "created";
    ItemStatuses["DRAFT"] = "draft";
    ItemStatuses["EXTERNALLY_PAID"] = "externally_paid";
    ItemStatuses["FAILED"] = "failed";
    ItemStatuses["IMPORT"] = "import";
    ItemStatuses["INITIATED"] = "initiated";
    ItemStatuses["INVISIBLE"] = "invisible";
    ItemStatuses["ISSUE"] = "issue";
    ItemStatuses["NEEDS_APPROVAL"] = "needs_approval";
    ItemStatuses["NEW"] = "new";
    ItemStatuses["OCR"] = "ocr";
    ItemStatuses["PENDING"] = "pending";
    ItemStatuses["PO_DISCREPANCY"] = "po_discrepancy_hold";
    ItemStatuses["PROCESSING"] = "processing";
    ItemStatuses["READY_TO_SEND"] = "ready_to_send";
    ItemStatuses["SCHEDULED"] = "scheduled";
    ItemStatuses["QUEUED"] = "queued";
})(ItemStatuses || (ItemStatuses = {}));
export var AllowedActions;
(function (AllowedActions) {
    AllowedActions["Accept"] = "accept";
    AllowedActions["AddItemMember"] = "add_item_member";
    AllowedActions["Approve"] = "approve";
    AllowedActions["Cancel"] = "cancel";
    AllowedActions["Edit"] = "edit";
    AllowedActions["ExternallyPaid"] = "externally_paid";
    AllowedActions["FinalizeStatus"] = "finalize_status";
    AllowedActions["Remove"] = "remove";
    AllowedActions["ScheduleSend"] = "schedule_send";
    AllowedActions["UnApprove"] = "un_approve";
})(AllowedActions || (AllowedActions = {}));
export var ItemTimelineDisplayStatus;
(function (ItemTimelineDisplayStatus) {
    ItemTimelineDisplayStatus["CREATED"] = "Added to Inbox";
    ItemTimelineDisplayStatus["NEEDS_APPROVAL"] = "Due by";
})(ItemTimelineDisplayStatus || (ItemTimelineDisplayStatus = {}));
export var ItemSource;
(function (ItemSource) {
    ItemSource["NETSUITE"] = "netsuite";
    ItemSource["PLATFORM"] = "platform";
    ItemSource["QBO"] = "qbo";
    ItemSource["UNKNOWN"] = "unknown";
    ItemSource["XERO"] = "xero";
})(ItemSource || (ItemSource = {}));
