import { RoutableIntervals } from '@routable/framework';
import axios from 'axios';
import normalize from 'json-api-normalizer';
import _cloneDeep from 'lodash/cloneDeep';

import { EnvVariable } from 'constants/env';
import { LOGIN } from 'constants/routes';

import { getAuthToken } from 'helpers/auth';
import { convertTime } from 'helpers/date';
import { getEnvVariable, areDevtoolsEnabled } from 'helpers/env';
import { isResponseErrorCodeTimeout } from 'helpers/http';
import { getCurrentCompanyId, getCurrentMembershipId, localStorageSet } from 'helpers/localStorage';
import {
  allKeys,
  allValues,
  and,
  hasLength,
  isEqual,
  isGreaterOrEqual,
  isGreaterThanZero,
  isLessThan,
  isObject,
  ternary,
} from 'helpers/utility';

import { BASE_API_URL } from 'services/api';
import { generateBaseJSONAPIPayload, payloadToCamelCase, payloadToUnderscore } from 'services/api/formatHelpers';

export const instance = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
    'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone || undefined,
  },
  timeout: convertTime.seconds.to.millis(getEnvVariable(EnvVariable.REQUEST_TIMEOUT_SECONDS)),
});

/**
 * Service to make API requests
 */
class FetchService {
  // *************************************
  // Auth methods
  // *************************************
  static setAuthHeader = (token) => {
    instance.defaults.headers.common.Authorization = token;
  };

  // *************************************
  // Pass-through methods
  // Controls the API, makes testing simpler
  // *************************************
  static fetch = async (...args) => fetch(...args);

  // creates an object with all of routable debug delay keys
  static buildRoutableDebugDelayObject = (method) => {
    const delayObj = window.routable.debug.delay;

    return allKeys(delayObj).reduce((currObject, delayObjectKey) => {
      const value = delayObj[delayObjectKey];

      if (isGreaterThanZero(value)) {
        // eslint-disable-next-line no-param-reassign
        currObject[ternary(isEqual(method, 'GET'), `debug[delay_${delayObjectKey}]`, `delay_${delayObjectKey}`)] =
          value;
      }

      return currObject;
    }, {});
  };

  // *************************************
  // Aux methods
  // *************************************
  static buildConfig = async (props) => {
    // This is for the BE currently it only contains in-response-to
    // for auto logout but can be expanded down range to include
    // other things it is a method because we might need to do some
    // other lookups inside here like local storage etc.
    // that is why we are not just using a spread.
    const getResponseToType = () => {
      const responseToHeaders = {};
      // eslint-disable-next-line no-undef
      if (globalThis.fromSocket) {
        responseToHeaders['in-response-to'] = 'websocket';
      }

      return responseToHeaders;
    };

    const config = {
      headers: {
        Authorization: await getAuthToken(),
        'fe-version': process.env.REACT_APP_VERSION,
        'X-Location': window.location.href,
        'X-Company-Id': getCurrentCompanyId(),
        ...getResponseToType(),
      },
      method: props.method,
      url: props.endpoint,
      params: props.params,
      signal: props.signal,
    };

    const { payload } = props;

    if (areDevtoolsEnabled()) {
      const routableDebugDelayObject = FetchService.buildRoutableDebugDelayObject(props.method);
      const hasRoutableDebugDelayObject = hasLength(allValues(routableDebugDelayObject));

      if (hasRoutableDebugDelayObject) {
        if (isEqual(props.method, 'GET')) {
          config.params = { ...config.params, ...routableDebugDelayObject };
        } else if (payload) {
          payload.debug = routableDebugDelayObject;
        }
      }
    }

    /*
      A cancel token should only be passed in for GET Requests
      since cancelling a POST could have some adverse effects
    */
    if (isEqual(props.method, 'GET')) {
      const controller = new AbortController();
      config.signal = controller.signal;

      if (isEqual(props.method, 'GET')) {
        config.signal = props.signal || controller.signal;
      }
    }

    if (payload) {
      if (props.setRequester) {
        payload.data.relationships = {
          ...(payload.data.relationships || {}),
          ...(payload.data?.attributes?.relationships || {}),
          requester: {
            data: generateBaseJSONAPIPayload(getCurrentMembershipId(), 'Membership', undefined),
          },
        };

        // If we needed to move relationship attributes into the relationships
        // above, clean up the payload so it's not included twice.
        if (payload.data?.attributes?.relationships) {
          payload.data.attributes.relationships = undefined;
        }
      }

      config.data = JSON.stringify(payloadToUnderscore(payload));
    }

    // Headers
    if (props.accept) {
      config.headers.Accept = props.accept;
    }

    if (props.removeAuthToken) {
      // After conferring with AWS docs, a single space works to remove the authorization
      config.headers.Authorization = ' ';
    }

    if (props.idempotencyKey) {
      config.headers['Idempotency-Key'] = props.idempotencyKey;
    }

    if (isObject(props.overrideHeaders)) {
      config.headers = {
        ...config.headers,
        ...props.overrideHeaders,
      };
    }

    if (props.transformResponse) {
      config.transformResponse = props.transformResponse;
    }

    if (props.responseType) {
      config.responseType = props.responseType;
    }

    if (props.pageSize) {
      config.params = { ...config.params, 'page[size]': props.pageSize };
    }

    if (props.skipCache) {
      config.params = {
        ...config.params,
        skip_cache: true,
      };
    }

    return config;
  };

  static isResponseOK = (response) => and(isGreaterOrEqual(response.status, 200), isLessThan(response.status, 300));

  static isResponseAccepted = (response) => isEqual(response.status, 202);

  static isResponseBad = (response) => isEqual(response.status, 400);

  static isResponseNotAuthorized = (response) => isEqual(response.status, 401);

  static isResponseForbidden = (response) => isEqual(response.status, 403);

  static isResponseNotFound = (response) => isEqual(response.status, 404);

  static isResponseOldVersionMode = (response) => isEqual(response.status, 406);

  static isResponseUnprocessableEntity = (response) => isEqual(response.status, 422);

  static isResponseTooManyRequests = (response) => isEqual(response.status, 429);

  static isResponseMaintenanceMode = (response) => isEqual(response.status, 503);

  // *************************************
  // Data transformation methods
  // *************************************
  static transformJSONAPINormalize = (parsedData) => normalize(parsedData);

  static transformJSONAPIMeta = (parsedData, originalData) => {
    if (!originalData.meta) {
      return parsedData;
    }

    const newParsedData = _cloneDeep(parsedData);
    newParsedData.meta = payloadToCamelCase(originalData.meta);

    return newParsedData;
  };

  static transformJSONAPILinks = (parsedData, originalData, props) => {
    if (!originalData.links) {
      return parsedData;
    }

    const newParsedData = _cloneDeep(parsedData);
    newParsedData.links = payloadToCamelCase(originalData.links);

    // Add current page
    newParsedData.links.current = props.endpoint;

    return newParsedData;
  };

  static transformToCamelCase = (parsedData) => payloadToCamelCase(parsedData);

  static getTransformers = (props) => {
    if (props.transformers) {
      return props.transformers;
    }

    // Default JSONAPI transformers
    return FetchService.defaultTransformers;
  };

  static defaultTransformers = [
    FetchService.transformJSONAPINormalize,
    FetchService.transformJSONAPIMeta,
    FetchService.transformJSONAPILinks,
  ];

  // *************************************
  // Request methods
  // *************************************
  static request = async (props) => {
    const config = await FetchService.buildConfig(props);

    const originalResponse = await instance.request(config).catch((rejectionErr) => {
      if (!rejectionErr?.response) {
        if (props.returnRejection) {
          return rejectionErr;
        }

        return undefined;
      }

      return rejectionErr.response;
    });

    // Nginx errors won't return a response, so there's nothing we can do more here
    // Timeout errors should also not be parsed any further
    if (!originalResponse || isResponseErrorCodeTimeout(originalResponse.code)) {
      return originalResponse;
    }

    const parsedResponse = _cloneDeep(originalResponse);

    const forceTimeout = parsedResponse && parsedResponse.headers ? +parsedResponse.headers['auto-logout-force'] : 0;
    const inactivity = parsedResponse && parsedResponse.headers ? +parsedResponse.headers['auto-logout-inactivity'] : 0;

    if (inactivity > 0 && forceTimeout > 0) {
      localStorageSet('auto-logout-force', forceTimeout);
      localStorageSet('auto-logout-inactivity', inactivity);

      // Set the servers logout inactivity values here.
      // note we do not need to use local storage for this.
      RoutableIntervals.ForceLogout.setInactivity(forceTimeout);
      RoutableIntervals.AutoLogout.setInactivity(inactivity);
    }

    parsedResponse.originalData = originalResponse.data;

    // Redirect if bad response and redirect_url is given in payload
    if (
      FetchService.isResponseBad(parsedResponse) &&
      parsedResponse?.data?.redirect_url &&
      parsedResponse?.data?.reason
    ) {
      return window.location.replace(`${parsedResponse.originalData.redirect_url}/${LOGIN}`);
    }

    // Add response ok flag
    parsedResponse.ok = FetchService.isResponseOK(parsedResponse);

    // Apply response data transformations to successful response
    if (parsedResponse.ok) {
      const transformers = FetchService.getTransformers(props);
      transformers.forEach((transformer) => {
        parsedResponse.data = transformer(parsedResponse.data, originalResponse.data, props);
      });

      return parsedResponse;
    }

    // Add errors from the backend if they exist on a failed call
    if (originalResponse.data && originalResponse.data.errors) {
      parsedResponse.errors = originalResponse.data.errors;
    }

    // Log errors only in development
    // (next comment excludes the log from test coverage)
    // /* istanbul ignore next */
    if (areDevtoolsEnabled()) {
      // eslint-disable-next-line no-console
      console.log(`Request failed:
      - Id: ${parsedResponse.headers['request-id']}
      - Status: ${parsedResponse.status}
      - URL: ${config.method} ${config.url}`);
    }

    return parsedResponse;
  };

  /**
   * Requests a file from the API using browser native `fetch`.
   * Sets the auth header unless options.requireAuth is false.
   * Provided additional headers are merged with the defaults.
   * @param {string} url
   * @param {Object} [options={}]
   * @return {Promise<*>}
   */
  static requestFile = async (url, options = {}) => {
    const { accept = 'application/pdf', headers = {}, requireAuth = true, ...rest } = options;

    const requestHeaders = {
      ...headers,
      'Content-Type': accept,
      'fe-version': process.env.REACT_APP_VERSION,
      'X-Location': window.location.href,
    };

    if (requireAuth) {
      const authToken = await getAuthToken();
      requestHeaders.Authorization = authToken;
      requestHeaders['X-Company-Id'] = getCurrentCompanyId();
    }

    const requestParams = {
      cache: 'no-cache',
      method: 'GET',
      mode: 'cors',
      ...rest,
      // no override on the headers object by rest params (other properties can be overridden)
      headers: requestHeaders,
    };

    return FetchService.fetch(url, requestParams);
  };
}

export default FetchService;
