import { getCurrentMembershipId } from 'helpers/localStorage';
import { isArrayLengthEqual, isNotEqual } from 'helpers/utility';
import {} from 'types/quickswitch';
import { modalContentHeight, modalContentWidth } from './constants';
import {} from './QuickswitchModal';
export const generateModalStyleOverride = (showSearchBar) => {
    if (showSearchBar) {
        return {
            maxHeight: modalContentHeight,
            height: modalContentHeight,
            width: modalContentWidth,
        };
    }
    return { maxHeight: modalContentHeight, width: modalContentWidth };
};
export const membershipListIsEmpty = (memberships) => isArrayLengthEqual(memberships, 0);
export const removeCurrentMembershipFromList = (memberships) => memberships.filter((membership) => isNotEqual(membership.id, getCurrentMembershipId()));
