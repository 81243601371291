import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'components/layout';
import { Text } from 'components/text';
import { typography } from 'constants/styles';
import { formatCurrencyExplicit } from 'helpers/currencyFormatter';
import { ternary } from 'helpers/utility';
import { useCurrencyContext } from 'hooks';
import './FundingProviderMemoBankStatement.scss';
const FundingProviderMemoBankStatement = ({ fundingProviderMemo, amount, companyName, currencyCode, isInputFocused, }) => {
    const currencyCodeMap = useCurrencyContext();
    const renderAmount = ternary(amount, formatCurrencyExplicit(amount, currencyCode, currencyCodeMap), React.createElement(React.Fragment, null,
        "$",
        React.createElement("div", { className: "no-amount" })));
    return (React.createElement("div", { className: "funding-provider-memo-bank-statement" },
        React.createElement(Row, { className: "funding-provider-memo-bank-statement__header-row" },
            React.createElement(Col, null,
                React.createElement(Text.Bold, null, "Description")),
            React.createElement(Col, null,
                React.createElement(Text.Bold, null, "Deposit"))),
        React.createElement(Row, { className: "margin-top--m-large" },
            React.createElement(Col, null,
                React.createElement(Text.Bold, { color: typography.TextColor.GREY_DARK, size: typography.TextSize.LEVEL_200 }, companyName)),
            React.createElement(Col, null,
                React.createElement(Text.Bold, { className: "display--flex align-items--center", color: typography.TextColor.GREY_DARK, size: typography.TextSize.LEVEL_200 }, renderAmount))),
        React.createElement(Row, { className: "margin-top--m" },
            React.createElement(Col, { className: "funding-provider-memo--wrapper" },
                React.createElement(Text.Regular, { className: "display--block truncate", color: typography.TextColor.BLUE_DARK, size: typography.TextSize.LEVEL_200 },
                    fundingProviderMemo || React.createElement(React.Fragment, null, "\u00A0"),
                    React.createElement("div", { className: classNames('funding-provider-memo--underline', {
                            'is-focused': !!isInputFocused,
                        }) }))))));
};
export default FundingProviderMemoBankStatement;
