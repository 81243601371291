import React from 'react';
import { ButtonV2, FlexRow, LinkNewPage, Text } from 'components';
import { typography } from 'constants/styles';
import { isExternalItemActionable, isExternalItemClickable } from 'helpers/items';
import { getExternalV2ItemSimilarRoute } from 'helpers/routeHelpers';
import { ternary } from 'helpers/utility';
import {} from 'interfaces/redux';
import {} from 'interfaces/table.types';
import './AcceptPaymentTableCell.scss';
import {} from './AcceptPaymentTableCell.types';
export const generateAcceptPaymentTableCell = ({ fallbackText, buttonText }) => ({ row: { original: payment }, value }) => {
    const isPayment = payment.type === 'Payment';
    const url = getExternalV2ItemSimilarRoute(payment, isPayment ? 'payment' : 'item_id');
    const textChildren = (React.createElement(Text.Semibold, { color: ternary(value, typography.TextColor.BLUE_DARK, typography.TextColor.GREY_DARK), size: typography.TextSize.LEVEL_100 }, value || fallbackText));
    return (React.createElement(FlexRow, { className: "align-items--center flex--1 justify-content--space-between", stackOnMobile: false },
        isExternalItemClickable(payment) ? (React.createElement(LinkNewPage, { href: url }, textChildren)) : (React.createElement("span", null, textChildren)),
        isExternalItemActionable(payment) && (React.createElement(LinkNewPage, { className: "enable-pointer-events", href: url },
            React.createElement(ButtonV2, null, buttonText)))));
};
export const AcceptPaymentTableCell = generateAcceptPaymentTableCell({
    fallbackText: 'Not Provided',
    buttonText: 'Accept',
});
AcceptPaymentTableCell.displayName = 'AcceptPaymentTableCell';
