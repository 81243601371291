import { PaymentDeliveryMethodType } from '@routable/shared';
import { createSelector } from 'reselect';

import {
  isFundingAccountBankAccount,
  isFundingAccountExternal,
  isFundingAccountPendingMicroDeposits,
  isFundingSourceInvalidOrMicroDepositState,
} from 'helpers/funding';
import { initialValuesFunding } from 'helpers/initialValues';
import { and, firstValue, isLength, or, ternary } from 'helpers/utility';

import { fundingAccountsByIdSelector, fundingSourcesByIdSelector } from 'selectors/fundingSelectors';
import { isUpdatePaymentMethodFlowPropSelector } from 'selectors/propSelectors';

import {
  fundingAccountFromUpdatePaymentMethodFormSelector,
  fundingSourcesFromUpdatePaymentMethodFormSelector,
} from './currentWorkflowStepSelector';
import {
  fundingAccountsWithFundingSourceForCompanyIdQuerySelector,
  preferredFundingAccountIdFromCurrentPartnershipRequestSelector,
} from './fundingCompoundSelectors';
import { partnershipFromQuerySelector } from './partnershipCompoundSelectors';

/**
 * Select the funding account id that should be used in the update payment form
 * @type {StandardSelector}
 * @returns {Object} - step
 */
export const initialFundingAccountIdSelector = createSelector(
  [
    partnershipFromQuerySelector,
    preferredFundingAccountIdFromCurrentPartnershipRequestSelector,
    fundingAccountsWithFundingSourceForCompanyIdQuerySelector,
    fundingSourcesByIdSelector,
  ],
  (partnership, preferredFundingAccountId, fundingAccounts, fundingSourcesById) => {
    const { defaultReceivableFundingAccount, defaultPayableFundingAccount } = partnership;
    const hasOnlyOneFundingAccount = isLength(fundingAccounts, 1);
    const pendingAccount = fundingAccounts.find((fundingAccount) =>
      isFundingAccountPendingMicroDeposits(fundingAccount, fundingSourcesById),
    );

    // If partnership only has one funding account, select that account as a default
    // otherwise, return null as we cannot determine funding account ID
    const fallbackFundingAccount = ternary(hasOnlyOneFundingAccount, firstValue(fundingAccounts)?.id, null);

    // If we have pending account, we use that account. If not, we use default accounts and if none exist, we use
    // the fallback funding account id.
    return or(
      pendingAccount?.id,
      preferredFundingAccountId,
      defaultReceivableFundingAccount,
      defaultPayableFundingAccount,
      fallbackFundingAccount,
    );
  },
);

/**
 * Select paymentDeliveryMethod to pass initial value that should be used in the update payment form
 * @type {StandardSelector}
 * @returns {string} paymentDeliveryMethod
 */
export const initialPaymentDeliveryMethodSelector = createSelector(
  [initialFundingAccountIdSelector, fundingAccountsByIdSelector],
  (fundingAccountId, fundingAccountsById) => {
    const initialFundingAccount = Object(fundingAccountsById[fundingAccountId]);
    const hasEmptyFundingAccount = !initialFundingAccount.id;
    const isBankAccount = isFundingAccountBankAccount(initialFundingAccount);

    if (hasEmptyFundingAccount) {
      return PaymentDeliveryMethodType.ACH;
    }

    return ternary(isBankAccount, PaymentDeliveryMethodType.ACH, PaymentDeliveryMethodType.CHECK);
  },
);

/**
 * Selects the initial values used by the update payment form
 * @type {StandardSelector}
 * @returns {Object} - initialValues
 */
export const updatePaymentFormInitialValuesSelector = createSelector(
  [initialFundingAccountIdSelector, initialPaymentDeliveryMethodSelector, isUpdatePaymentMethodFlowPropSelector],
  initialValuesFunding.getUpdatePaymentFormInitialValues,
);

/**
 * Selects international values for the update payment form when used for international context
 *
 * @type {StandardSelector}
 * @returns {Object} initialValues
 */
export const updatePaymentMethodFormInternationalInitialValuesSelector = createSelector(
  [initialFundingAccountIdSelector, isUpdatePaymentMethodFlowPropSelector],
  (fundingAccount, isUpdatePaymentMethodFlow) =>
    initialValuesFunding.getUpdatePaymentFormInitialValues(
      fundingAccount,
      PaymentDeliveryMethodType.INTERNATIONAL,
      isUpdatePaymentMethodFlow,
    ),
);

/**
 * Selects funding account selected in the form then checks if its fundingSources are invalid or in micro-deposit state
 * @type {StandardSelector}
 * @returns {Boolean} - isInvalidPendingAccount
 */
export const areSomeFundingSourceStatesInvalidOrMicroDepositSelector = createSelector(
  [fundingSourcesFromUpdatePaymentMethodFormSelector],
  (fundingSources) => fundingSources.some(isFundingSourceInvalidOrMicroDepositState),
);

/**
 * Selects funding account selected in the form then checks if it is not external
 * and if its fundingSources are invalid or in micro-deposit state
 * @type {StandardSelector}
 * @returns {Boolean} - isInvalidPendingAccount
 */
export const isFormFundingInternalAndInvalidOrPendingSelector = createSelector(
  [fundingAccountFromUpdatePaymentMethodFormSelector, areSomeFundingSourceStatesInvalidOrMicroDepositSelector],
  (fundingAccount, someFundingSourcesInvalidOrMicroDepositState) =>
    and(!isFundingAccountExternal(fundingAccount), someFundingSourcesInvalidOrMicroDepositState),
);
