import PropTypes from 'prop-types';
import React from 'react';

import LockedInputTooltip from 'components/form/LockedInputTooltip';
import { Icon, IconNames } from 'components/icon';

import { colors, sizes } from 'constants/styles';

import { ternary } from 'helpers/utility';

/**
 * Component used in InputFieldWrapper for showing addon icons.
 * @param {ComponentProps} props
 * @param {React.Element} props.addon
 * @param {Function} [props.EditButton]
 * @param {Boolean} props.hasErrors
 * @param {Boolean} [props.isEditProtected]
 * @param {Boolean} [props.isLocked]
 * @param {Object} props.meta
 * @param {Function} [props.onEdit]
 * @param {Boolean} props.showValidationIcon
 * @param {*} [props.tooltipContent]
 * @param {Object} [props.tooltipProps]
 * @param {String|Number} props.value
 * @return {StatelessComponent|null}
 */
const InputAddon = ({
  addon: passedAddon,
  EditButton,
  hasErrors,
  isEditProtected,
  isLocked,
  meta,
  onEdit,
  showValidationIcon,
  tooltipContent,
  tooltipProps,
  value,
}) => {
  // do not show success/error icons if value is empty and there are no errors
  const shouldShowValidationIcon = !!value || hasErrors;

  let addon = passedAddon;

  if (showValidationIcon && shouldShowValidationIcon) {
    // show success icon only when input is valid
    const shouldShowSuccessIcon = meta?.valid;

    // render null if there are no errors and user is changing input
    // render success/error icon otherwise
    addon = ternary(
      !hasErrors && !shouldShowSuccessIcon,
      null,
      <Icon
        className="not-modified"
        color={ternary(hasErrors, colors.colorRedBoldHex, colors.colorAquaBoldHex)}
        icon={ternary(hasErrors, IconNames.WARNING_SIGN, IconNames.TICK_CIRCLE)}
        size={sizes.iconSizes.LARGE}
      />,
    );
  }

  if (isEditProtected && EditButton) {
    addon = <EditButton onEdit={onEdit} tooltipProps={{ content: tooltipContent, ...tooltipProps }} />;
  }

  if (isLocked) {
    addon = (
      <LockedInputTooltip
        className="locked-input-tooltip"
        tooltipContent={tooltipContent}
        tooltipProps={tooltipProps}
      />
    );
  }

  if (!addon) {
    return null;
  }

  return <div className="addon-container">{addon}</div>;
};

InputAddon.propTypes = {
  addon: PropTypes.node,
  EditButton: PropTypes.elementType,
  hasErrors: PropTypes.bool,
  isEditProtected: PropTypes.bool,
  isLocked: PropTypes.bool,
  meta: PropTypes.shape({
    valid: PropTypes.bool,
  }),
  onEdit: PropTypes.func,
  showValidationIcon: PropTypes.bool,
  value: PropTypes.any, // eslint-disable-line
};

InputAddon.defaultProps = {
  addon: undefined,
  EditButton: undefined,
  hasErrors: undefined,
  isEditProtected: undefined,
  isLocked: undefined,
  meta: {},
  onEdit: undefined,
  showValidationIcon: undefined,
  value: undefined,
};

export default InputAddon;
