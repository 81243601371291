import PropTypes from 'prop-types';
import React from 'react';

import { FlexRow, IconNames, Text, TooltipMUIIcon } from 'components';

import { colors, typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { allValues, anyValues, ternary } from 'helpers/utility';

import './ExternalReceiptPriceRow.scss';

/**
 * Component rendering single price row in ExternalReceiptSingleItem
 * price details section
 * @param {ComponentProps} props
 * @param {string} [props.color]
 * @param {boolean} [props.isBold]
 * @param {string} props.label
 * @param {string} [props.size]
 * @param {Object} [props.tooltipProps]
 * @param {string} props.value
 * @returns {StatelessComponent}
 */
const ExternalReceiptPriceRow = ({ color, isBold, label, size, tooltipProps, value }) => {
  const { title, ...restOfTooltipProps } = tooltipProps;
  const TextComponent = ternary(isBold, Text.Bold, Text.Regular);

  return (
    <FlexRow
      className="external-receipt-price-row align-items--center justify-content--space-between"
      stackOnMobile={false}
    >
      <FlexRow className="external-receipt-price-row__left align-items--center" stackOnMobile={false}>
        <TextComponent color={color} size={size}>
          {label}
        </TextComponent>

        {anyValues(tooltipProps) && (
          <TooltipMUIIcon
            icon={IconNames.INFO_SIGN}
            iconClassName="external-receipt-price-row__left__icon"
            iconProps={{
              color: colors.colorGreyDarkHex,
            }}
            title={
              <Text.Regular color={typography.TextColor.GREY_XX_DARK} size={typography.TextSize.LEVEL_200}>
                {title}
              </Text.Regular>
            }
            tooltipProps={{
              arrow: true,
              padding: TooltipPadding.LARGE,
              placement: TooltipPlacement.TOP,
              ...restOfTooltipProps,
            }}
          />
        )}
      </FlexRow>

      <div className="external-receipt-price-row__right">
        <TextComponent color={color} size={size}>
          {value}
        </TextComponent>
      </div>
    </FlexRow>
  );
};

ExternalReceiptPriceRow.propTypes = {
  color: PropTypes.oneOf(allValues(typography.TextColor)),
  isBold: PropTypes.bool,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(allValues(typography.TextSize)),
  tooltipProps: PropTypes.shape({ title: PropTypes.string }),
  value: PropTypes.string.isRequired,
};

ExternalReceiptPriceRow.defaultProps = {
  color: typography.TextColor.GREY_X_DARK,
  isBold: undefined,
  size: typography.TextSize.LEVEL_100,
  tooltipProps: {},
};

export default ExternalReceiptPriceRow;
