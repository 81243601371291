import _findIndex from 'lodash/findIndex';

import { IconNames } from 'components/icon';

import { colors } from 'constants/styles';
import { ExternalStepDisplayStatus } from 'constants/ui';

import {
  and,
  defaultToValueIfNotFound,
  isEqual,
  isLessOrEqual,
  isLessThan,
  isNotEqual,
  lastElementIn,
  lastIndexOf,
  or,
  ternary,
} from 'helpers/utility';

/**
 * getIconNameForStep
 * Returns the icon name for a given step
 * @param {object} step
 * @return {string}
 */
export const getIconNameForStep = (step) => {
  if (step.isCompleted) {
    return IconNames.TICK_CIRCLE;
  }

  if (step.isPending) {
    return IconNames.CIRCLE;
  }

  const defaultIcon = ternary(step.isActive, IconNames.SELECTION, IconNames.CIRCLE);

  return or(step.icon, defaultIcon);
};

/**
 * Returns right icon props config for given step. Returns empty object if step
 * does not have any errors, or config for error icon if step has error(s)
 * @param {Object} step
 * @param {Number} iconSize
 * @returns {Object}
 */
export const getRightIconPropsForStep = (step, iconSize) => {
  // if the step does not have an error, we don't want to set any right icon props
  if (!step.hasError) {
    return {};
  }

  // otherwise, we want the red error icon
  return {
    rightIconClassName: 'margin-left--xm',
    rightIconColor: colors.colorRedBoldHex,
    rightIconName: IconNames.ERROR,
    rightIconSize: iconSize,
  };
};

/**
 * isStepDisabled
 * Returns boolean indicating whether the step should be disabled
 * @param {object} step
 * @return {boolean}
 */
export const isStepDisabled = (step) => or(isEqual(step.display, ExternalStepDisplayStatus.DISABLED), step.isPending);

/**
 * Getter function that returns completion prop from external step
 * @param {ExternalStep} step
 * @return {ExternalStepCompletion}
 */
export const getStepCompletion = (step) => step?.completion;

/**
 * Getter function that returns completedBy prop from external step
 * @param {ExternalStep} step
 * @return {string}
 */
export const getStepCompletionCompletedBy = (step) => getStepCompletion(step)?.completedBy;

/**
 * Getter function that returns timestamp prop from external step
 * @param {ExternalStep} step
 * @return {string}
 */
export const getStepCompletionTimestamp = (step) => getStepCompletion(step)?.timestamp;

/**
 * Predicate function used to determine if step has been completed before
 * @param {ExternalStep} step
 * @return {boolean}
 */
export const isStepCompleted = (step) => Boolean(getStepCompletionTimestamp(step));

/**
 * Returns the index of the first incomplete step from given steps
 * @param {ExternalStep[]} steps
 * @return {Number}
 */
export const findFirstIncompleteStepIndex = (steps) => _findIndex(steps, (step) => !isStepCompleted(step));

/**
 * Returns the index of the first incomplete step or defaults to last step from given steps
 * @param {ExternalStep[]} steps
 * @returns {Number}
 */
export const findIncompleteStepOrDefaultToLastIndex = (steps) => {
  const firstIncompleteStepIndex = findFirstIncompleteStepIndex(steps);
  const defaultStepIndex = lastIndexOf(steps);

  // If all steps are complete, then show last step
  return defaultToValueIfNotFound(firstIncompleteStepIndex, defaultStepIndex);
};

/**
 * findFirstIncompleteStepAndSubstepIndex
 * Returns number (=the index) of the first incomplete step and first incomplete substep of that step
 * @param {ExternalStep[]} steps
 * @return {{stepIndex: number, substepIndex: number}}
 */
export const findFirstIncompleteStepAndSubstepIndex = (steps) => {
  // When all steps are complete return last index, so that user land on last step
  const stepIndex = findIncompleteStepOrDefaultToLastIndex(steps);

  const { substeps = [] } = steps[stepIndex];
  // go deeper through the substeps to find an incompleted step index
  let substepIndex = findIncompleteStepOrDefaultToLastIndex(substeps);
  substepIndex = defaultToValueIfNotFound(substepIndex, 0);

  return { stepIndex, substepIndex };
};

/**
 * @param {ExternalStep[]} steps
 * @returns {Boolean}
 */
export const isLastStepCompleted = (steps) => {
  const lastStep = lastElementIn(steps);
  return isStepCompleted(lastStep);
};

/**
 * Check if a step has been completed by the current user
 * @param {ExternalStep} step
 * @returns {Boolean}
 */
export const isStepCompletedByCurrentUser = (step) => Boolean(step.completion?.completedForCurrentUser);

/**
 * Transformer function that returns step with timestamp removed if not completed by current user
 * @param {ExternalStep} step
 * @returns {ExternalStep}
 */
export const removeCompletionTimestampFromStepNotByCurrentUser = (step) => ({
  ...step,
  completion: {
    ...step.completion,
    timestamp: ternary(isStepCompletedByCurrentUser(step), step.completion.timestamp, null),
  },
});

/**
 * Will transform steps given by backend, so that we only show completed indicators for the current user and not other members
 * @param {ExternalStep[]} steps
 * @returns {ExternalStep[]}
 */
export const getStepsForCurrentUser = (steps = []) => steps.map(removeCompletionTimestampFromStepNotByCurrentUser);

/**
 * Will takreturn a curried function that apply given predicate function to determine if step has been completed.
 * @param {(step: ExternalStep) => Boolean} predicateFn
 * @returns {(steps:ExternalStep[]) => Number}
 */
export const createGetInitialStepIndex = (predicateFn) => (steps) => {
  const lastStep = lastElementIn(steps);
  const isLastStepComplete = predicateFn(lastStep);

  if (isLastStepComplete) {
    // Return last (confirmation) step
    return lastIndexOf(steps);
  }

  // Return first (prompt) step
  return 0;
};

/**
 * Determines what step index to show for any user
 * @param {ExternalStep[]} steps
 * @returns {Number}
 */
export const getInitialStepIndexForAnyUser = createGetInitialStepIndex(isStepCompleted);

/**
 * Determines what step index to show for the current user
 * @param {ExternalStep[]} steps
 * @returns {Number}
 */
export const getInitialStepIndexForCurrentUser = createGetInitialStepIndex(isStepCompletedByCurrentUser);

/**
 * Determines what step index to show for a user based on external flow
 * @param {ExternalStep[]} steps
 * @param {Boolean} isUpdatePayment
 * @returns {Number}
 */
export const getInitialStepIndexForExternalFlow = (steps = [], isUpdatePayment) => {
  if (isUpdatePayment) {
    return getInitialStepIndexForCurrentUser(steps);
  }

  return getInitialStepIndexForAnyUser(steps);
};

/**
 * findPreviousActiveStepIndex
 * Returns number (=the index) of the previous active step
 * @param {object} object
 * @param {number} stepIndex
 * @param {object[]} steps
 * @return {number}
 */
export const findPreviousActiveStepIndex = ({ stepIndex, steps }) => {
  // if first step is currently active, there's no step to go back to
  if (isLessOrEqual(stepIndex, 0)) {
    return 0;
  }

  // otherwise, start looking for a previous step that isn't disabled
  if (isStepDisabled(steps[stepIndex - 1])) {
    // recursion!
    return findPreviousActiveStepIndex({ stepIndex: stepIndex - 2, steps });
  }

  // go to the closest previous step that is not disabled
  return stepIndex - 1;
};

/**
 * arePreviousStepsDisabled
 * Returns boolean indicating whether all the previous steps compared to a given step are
 * @param {object} props
 * @param {object[]} steps
 * @param {number} activeStepIndex
 * @return {boolean}
 */
export const arePreviousStepsDisabled = ({ steps, activeStepIndex }) =>
  steps.slice(0, activeStepIndex).every((step) => isStepDisabled(step));

/**
 * getStepsFromExternalConfig
 * Parses the steps config and returns an array of steps decorated with easier-to-use props
 * @param {object} props
 * @param {number} props.activeStepIndex
 * @param {number} [props.activeSubstepIndex]
 * @param {ExternalStep[]} props.config
 * @param {number} props.defaultActiveStepIndex
 * @param {number} [props.defaultActiveSubstepIndex]
 * @return {object[]}
 */
export const getStepsFromExternalConfig = ({
  activeStepIndex,
  activeSubstepIndex,
  config,
  defaultActiveStepIndex,
  defaultActiveSubstepIndex,
}) =>
  config.map((step, i) => {
    const { substeps = [] } = step;
    const isCompleted = isStepCompleted(step);
    const isActive = isEqual(activeStepIndex, i);
    const isPending = and(!isCompleted, isLessThan(activeStepIndex, i), isNotEqual(defaultActiveStepIndex, i));

    const substepsConfig = getStepsFromExternalConfig({
      config: substeps,
      activeStepIndex: activeSubstepIndex,
      defaultActiveStepIndex: defaultActiveSubstepIndex,
    });

    return {
      isActive,
      isCompleted,
      isPending,
      substeps: substepsConfig,
      ...step.progressBar,
    };
  });

/**
 * Parses the steps config and returns an array of steps decorated with easier-to-use props for external flow
 * @param {Object} props
 * @param {Number} props.activeStepIndex
 * @param {Number} props.initialStepIndex
 * @param {ExternalStep[]} props.steps
 * @return {object[]}
 */
export const externalStepsToProgressBarSteps = ({ activeStepIndex, initialStepIndex, steps }) =>
  steps.map((step, stepIndex) => {
    const precedesActiveStep = stepIndex < activeStepIndex;
    const isCompletedByBackend = isStepCompleted(step);
    const isCompleted = or(precedesActiveStep, isCompletedByBackend);
    const isActive = isEqual(activeStepIndex, stepIndex);
    const isPending = and(
      !isCompleted,
      isLessThan(activeStepIndex, stepIndex),
      isNotEqual(initialStepIndex, stepIndex),
    );

    return {
      isActive,
      isCompleted,
      isPending,
      ...step.progressBar,
    };
  });

/**
 * Determines whether the back button should be disabled
 * @param {Object} opts
 * @param {ExternalStep[]} opts.steps
 * @param {Number} opts.activeStepIndex
 * @returns {Boolean}
 */
export const shouldDisableBackButton = ({ steps, activeStepIndex }) => {
  const previousStepsAreDisabled = arePreviousStepsDisabled({
    steps,
    activeStepIndex,
  });
  const activeStepIsFirstStep = isEqual(activeStepIndex, 0);
  const isBackButtonDisabled = or(activeStepIsFirstStep, previousStepsAreDisabled);

  return isBackButtonDisabled;
};
