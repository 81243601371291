/**
 * @module helpers/itemMember
 */

import { PartnershipMemberAccessChangeText } from 'constants/contacts';
import { PartnershipMemberAccess, PartnershipMemberAccessSubtitleText } from 'constants/partnershipMember';
import { colors } from 'constants/styles';
import { PartnershipMemberAccessIcon } from 'constants/ui';

import { ItemMemberProps } from 'data/resources/itemMember';

import { isPartnershipMemberStatusActive } from 'helpers/contacts';
import { getPaymentOrInvoiceText } from 'helpers/items';
import { getMembershipEmailAddress, getMembershipFullName, getMembershipPhoneNumber } from 'helpers/memberships';
import {
  isPartnershipMemberAccessActionable,
  isPartnershipMemberAccessNone,
  isPartnershipMemberAccessSet,
  isPartnershipMemberFromPartnershipPartner,
} from 'helpers/partnershipMembers';
import { getMultiSelectTagTypeForMember } from 'helpers/ui';
import getContactName from 'helpers/user';
import { allKeys, allValues, and, isEqual, isString, ternary } from 'helpers/utility';

export const isItemMemberAccessItemNone = (itemMember) =>
  isEqual(itemMember[ItemMemberProps.accessItem], PartnershipMemberAccess.NONE);

/**
 * Use the PartnershipMember as a base and add some ItemMember fields.
 * @function
 * @param {ItemMember} itemMember
 * @param {ItemMember.id} itemMember.id
 * @param {ItemMember.accessItem} itemMember.accessItem
 * @param {PartnershipMember} partnershipMember
 * @returns {ItemMemberAsPartnershipMember}
 */
export const mergeItemMemberWithPartnershipMember = ({ id, accessItem }, partnershipMember) => ({
  ...partnershipMember,
  itemMember: id,
  accessItem,
});

// *************************************
// ItemMember filtering
// *************************************

/**
 * Get only the item recipient's ItemMembers.
 * @function
 * @param {Object} itemMembersById
 * @param {Item.partner} itemPartner
 * @returns {ItemMemberAsPartnershipMember[]} ItemMembers with the current dashboard company's ItemMembers filtered out.
 */
export const filterItemMembersByItemPartner = (itemMembersById, itemPartner) =>
  allValues(itemMembersById).filter((contact) => contact.company === itemPartner);

/**
 * Given a list of ItemMembers, keep only active contacts which have read-only or contact access level for the Item.
 * @param {ItemMember[]} itemMembers
 * @returns {ItemMember[]} Active ItemMembers with some accessItem.
 */
export const filterActiveItemMembers = (itemMembers) =>
  itemMembers.filter((itemMember) => {
    const hasNoAccess = isItemMemberAccessItemNone(itemMember);
    const active = isPartnershipMemberStatusActive(itemMember.status);

    // If the ItemMember was removed from the Item, the record still exists, but the ItemMember.accessItem is NONE
    if (hasNoAccess) {
      // so it's inactive, don't include it
      return false;
    }

    // If we're this far, we've got an ItemMember with some access. If the status is active, keep it in the list,
    // otherwise it might be archived or have a bounced email and we should remove it.
    return active;
  });

/**
 * Given a list of ItemMembers, keep only active contacts with an email which have read-only or contact access level for the Item.
 * @param {import('interfaces/item').ItemMember[]} itemMembers
 * @returns {ItemMember[]} Active ItemMembers with some accessItem.
 */
export const filterActiveItemMembersWithEmail = (itemMembers) => {
  const activeItemMembers = filterActiveItemMembers(itemMembers);
  const activeItemMembersWithEmail = activeItemMembers.filter((itemMember) => itemMember.hasEmail);

  return activeItemMembersWithEmail;
};

/**
 * From a list of ItemMembers, only return past ItemMembers whose accessItem is set to none.
 * If the access is set to none, that means this ItemMember was removed from the item.
 * @function
 * @param {ItemMember[]} itemMembers
 * @returns {ItemMember[]}
 */
export const filterPastItemMembers = (itemMembers) => itemMembers.filter(isItemMemberAccessItemNone);

/**
 * Given an array of ItemMembersAsPartnershipMembers, get the one that matches the partnershipMemberId.
 * @function
 * @param {ItemMemberAsPartnershipMember[]} itemMembers - ID here is actually the ID of the PartnershipMember
 * @param {PartnershipMember.id} partnershipMemberId
 * @returns {ItemMemberAsPartnershipMember|undefined}
 */
export const findItemMemberByPartnershipMemberId = (itemMembers, partnershipMemberId) =>
  itemMembers.find(({ id }) => isEqual(id, partnershipMemberId));

/**
 * Filter through PartnershipMembers to generate a list of contacts which are not yet associated with the Item as
 * ItemMembers.
 * @function
 * @param {ItemMemberAsPartnershipMember[]} itemMembers - All of the contacts which are already ItemMembers, but actually a mix of
 * PartnershipMember and ItemMember model
 * @param {Object} partnershipMembersById - All of the PartnershipMembers on this Item
 * @param {Partnership} partnership - Partnership the Item belongs to
 * @returns {PartnershipMember[]} - PartnershipMembers which aren't ItemMembers yet
 */
export const getPartnershipMembersAsPossibleNewItemMembers = (itemMembers, partnershipMembersById, partnership) =>
  allKeys(partnershipMembersById)
    // flatten PartnershipMembers into an array
    .map((partnershipMemberId) => partnershipMembersById[partnershipMemberId])
    // filter the PartnershipMembers so we only have them from this partnership
    .filter((partnershipMember) => isPartnershipMemberFromPartnershipPartner(partnershipMember, partnership))
    // filter out PartnershipMembers which are already ItemMembers
    .filter(({ id: partnershipMemberId }) => !findItemMemberByPartnershipMemberId(itemMembers, partnershipMemberId));

/**
 * From a list of ItemMembers, only return ItemMembers whose accessItem is set (read-only or contact).
 * @function
 * @param {ItemMember[]} itemMembers
 * @returns {ItemMember[]}
 */
export const filterItemMembersWithAccessItemSet = (itemMembers) =>
  itemMembers.filter(({ accessItem }) => isPartnershipMemberAccessSet(accessItem));

// *************************************
// ItemMember access levels
// *************************************

/**
 * Flip the access for the ItemMember between read-only and actionable, probably on the accessItem key.
 * @function
 * @param {ItemMember} itemMember
 * @param {StringMaybe} [targetAccess='accessItem']
 * @returns {PartnershipMemberAccess}
 */
export const flipItemMemberAccess = (itemMember, targetAccess = ItemMemberProps.accessItem) => {
  const access = itemMember[targetAccess];
  const { ACTIONABLE, READ_ONLY } = PartnershipMemberAccess;

  return ternary(isPartnershipMemberAccessActionable(access), READ_ONLY, ACTIONABLE);
};

/**
 * Gets subtitle text depending on the access level of the ItemMember.
 * @function
 * @param {Object} options
 * @param {Item} options.item
 * @param {ItemMember} options.itemMember
 * @param {StringMaybe} [options.targetAccess='accessItem']
 * @returns {string}
 */
export const getItemMemberAccessSubtitleText = (options) => {
  const { item, itemMember, targetAccess = ItemMemberProps.accessItem } = options;

  const access = itemMember[targetAccess];
  const accessText = PartnershipMemberAccessSubtitleText[access];
  const paymentOrInvoice = getPaymentOrInvoiceText(item);

  // E.g. Can act on this payment
  return `${accessText} ${paymentOrInvoice}`;
};

/**
 * Get the IconName based on the ItemMember's access.
 * @function
 * @param {ItemMember} itemMember
 * @param {StringMaybe} [targetAccess='accessItem']
 * @returns {string} IconName
 */
export const getItemMemberAccessIconName = (itemMember, targetAccess = ItemMemberProps.accessItem) => {
  const access = itemMember[targetAccess];
  return PartnershipMemberAccessIcon[access] ?? PartnershipMemberAccessIcon[PartnershipMemberAccess.ACTIONABLE];
};

/**
 * Gets the icon props depending on the access level of the PartnershipMember.
 * @param {ItemMember} itemMember
 * @param {StringMaybe} [targetAccess='accessItem']
 * @returns {Object} props for icon
 */
export const getItemMemberAccessIconProps = (itemMember, targetAccess = ItemMemberProps.accessItem) => ({
  color: colors.colorMainJordanHex,
  name: getItemMemberAccessIconName(itemMember, targetAccess),
});

/**
 * Unify getItemMemberAccessIconProps and getItemMemberAccessSubtitleText for the correct icon props and text
 * depending on the ItemMember access, probably accessItem.
 * @param {import('interfaces/item').Item} item
 * @param {PartnershipMember} itemMember
 * @param {StringMaybe} [targetAccess='accessItem']
 * @returns {Object}
 */
export const getItemMemberAccessSubtitleUi = (item, itemMember, targetAccess = ItemMemberProps.accessItem) => {
  const iconProps = getItemMemberAccessIconProps(itemMember, targetAccess);
  const text = getItemMemberAccessSubtitleText({
    item,
    itemMember,
    targetAccess,
  });

  return {
    ...iconProps,
    text,
  };
};

// *************************************
// ItemMember menu actions
// *************************************

/**
 * Get edit/remove text for ItemMembers on items.
 * @function
 * @param {import('interfaces/item').ItemMember} itemMember
 * @param {import('interfaces/item').Item} item
 * @returns {Object}
 */
export const getItemMemberMenuText = (itemMember, item) => {
  const newAccess = flipItemMemberAccess(itemMember, ItemMemberProps.accessItem);
  const paymentOrInvoice = getPaymentOrInvoiceText(item);

  return {
    editText: `${PartnershipMemberAccessChangeText[newAccess]} ${paymentOrInvoice}`,
    removeText: `Remove from this ${paymentOrInvoice}`,
  };
};

/**
 * Get actionable/read-only text for past ItemMembers on items.
 * @function
 * @param {unknown} _
 * @param {import('interfaces/item').Item} item
 * @returns {Object}
 */
export const getPastItemMemberMenuText = (_, item) => {
  const paymentOrInvoice = getPaymentOrInvoiceText(item);

  return {
    makeActionableText: `Allow contact to take action on this ${paymentOrInvoice}`,
    makeReadOnlyText: `Allow contact to view this ${paymentOrInvoice}`,
  };
};

/**
 * Determine the access level for an contact when adding the contact to an existing item.
 * @function
 * @param {ItemMemberAsPartnershipMember} itemMember
 * @param {PartnershipMemberAccess} itemMember.defaultItem
 * @param {PartnershipMemberAccess} itemMember.accessItem
 * @returns {PartnershipMemberAccess}
 */
export const deriveItemMemberAccess = ({ defaultItem, accessItem }) => {
  const accessItemSet = and(isString(accessItem), !isPartnershipMemberAccessNone(accessItem));
  const defaultItemSet = and(isString(defaultItem), !isPartnershipMemberAccessNone(defaultItem));

  // If the access item is set, use it
  if (accessItemSet) {
    return accessItem;
  }

  // No access item set, try to use the default item
  if (defaultItemSet) {
    return defaultItem;
  }

  // If the default item is none, use actionable, because we've got to add the ItemMember to the item with some kind
  // of access level
  return PartnershipMemberAccess.ACTIONABLE;
};

/**
 * Create/decorate ItemMembers (combined with PartnershipMember data) to display in the PopoverSelect to add existing
 * ItemMembers to an existing Item.
 * @function
 * @param {Object} options
 * @param {ItemMemberAsPartnershipMember[]} options.itemMembers
 * @returns {ItemMemberAsPartnershipMember[]}
 */
export const createPossibleItemMembersForPopoverSelect = ({ itemMembers }) => {
  return itemMembers?.map((itemMember) => {
    const access = deriveItemMemberAccess(itemMember);
    const fullName = getMembershipFullName(itemMember);
    const additionalInfo = getMembershipEmailAddress(itemMember) || getMembershipPhoneNumber(itemMember) || '';
    const label = fullName || additionalInfo;
    const verboseLabel = fullName ? additionalInfo : '';

    return {
      value: itemMember,
      label,
      verboseLabel,
      accessItem: access,
      type: getMultiSelectTagTypeForMember(itemMember),
    };
  });
};

/**
 * Gets success message for successfully added item member to item.
 * @function
 * @param {Membership} membership
 * @returns {string}
 */
export const getMembershipSuccessIndicatorMessages = (membership) =>
  `Successfully added ${getContactName(membership)} as a contact`;

/**
 * Gets error message if error occurred while adding item member to item.
 * @function
 * @param {Membership} membership
 * @returns {string}
 */
export const getMembershipErrorIndicatorMessages = (membership) =>
  `Error adding ${getContactName(membership)} as a contact`;

/**
 * Gets success message for successfully updated item member.
 * @function
 * @param {ItemMember} itemMember
 * @param {Item} item
 * @returns {string}
 */
export const getMembershipSuccessUpdateMessages = (itemMember, item) => {
  const contactName = getContactName(itemMember);
  const paymentOrInvoice = getPaymentOrInvoiceText(item);

  return ternary(
    isEqual(itemMember.accessItem, PartnershipMemberAccess.NONE),
    `${contactName} has been removed from this ${paymentOrInvoice}`,
    `Successfully updated access level for ${contactName}`,
  );
};

/**
 * Gets error message if error occurred while updating an item.
 * @function
 * @param {ItemMember} itemMember
 * @param {Item} item
 * @returns {string}
 */
export const getMembershipErrorUpdateMessages = (itemMember, item) => {
  const contactName = getContactName(itemMember);
  const paymentOrInvoice = getPaymentOrInvoiceText(item);

  return ternary(
    isEqual(itemMember.accessItem, PartnershipMemberAccess.NONE),
    `Error removing ${contactName} from this ${paymentOrInvoice}`,
    `Error updating access level for ${contactName}`,
  );
};

/**
 * Returns the item members to prefill the createItem form in Item Edit
 * At this point the itemMembers include itemMembers for both the company that created
 * the item and for those to whom the item was sent to. We only want to include the latter
 * so that the select partner component can show the correct item members
 * @param {Item} item
 * @param {itemMembers[]} itemMembers
 * @param {PartnershipMember[]} partnershipMembers
 * @returns {[]}
 */
export const getItemMembersInitialValuesForItemEdit = ({ item, itemMembers, partnershipMembers }) => {
  const itemMembersForItem = [];

  item?.itemMembers?.forEach((itemMemberId) => {
    const itemMember = itemMembers?.[itemMemberId];
    const partnershipMember = partnershipMembers?.[itemMember?.partnershipMember] || {};

    if (isPartnershipMemberFromPartnershipPartner(partnershipMember, item)) {
      itemMembersForItem.push({
        // itemMember has to be spread after partnershipMember so that we keep shared key/vals like `id` mapped to the
        // itemMember, while augmenting this itemMember with meta data from the partnershipMember
        ...partnershipMember,
        ...itemMember,
      });
    }
  });

  return itemMembersForItem;
};
