import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';

import { ButtonAppearance } from 'constants/button';
import { colors, sizes } from 'constants/styles';

import { ternary } from 'helpers/utility';

import TableV2Context from '../../TableContext';
import TableCell from '../TableCell';
import TableRow from '../TableRow';

/**
 * Add line item button variant encapsulated in a TableRow
 * @param {function} onClick
 * @return {StatelessComponent}
 */
const AddLineItemButtonRow = ({ onClick }) => {
  const { compact } = React.useContext(TableV2Context);

  const iconSize = ternary(compact, sizes.iconSizes.SMALL, sizes.iconSizes.LARGE);

  return (
    <TableRow className="add-line-item--row">
      <TableCell colSpan={99}>
        <ButtonV2
          appearance={ButtonAppearance.BORDERLESS}
          className="inverted add-line-item--button"
          leftIconClassName="margin-right--xm"
          leftIconColor={colors.colorBlueBoldHex}
          leftIconName={IconNames.ADD}
          leftIconSize={iconSize}
          onClick={onClick}
        >
          Add line item
        </ButtonV2>
      </TableCell>
    </TableRow>
  );
};

AddLineItemButtonRow.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AddLineItemButtonRow;
