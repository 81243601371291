import _get from 'lodash/get';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { formNamesItem } from 'constants/forms';

import { createGetFormValuesSelector } from 'selectors/createFormSelectors';

export const FieldWrapper = React.memo(({ Component, ...props }) => {
  const allValues = useSelector(createGetFormValuesSelector(formNamesItem.CREATE_ITEM));

  const {
    getIsRequired: { dependencyFieldsPaths },
    getIsDisabled: { dependencyFieldsPaths: disabledDependencyFieldPaths },
    rowValues,
  } = props;

  const dependencies = (dependencyFieldsPaths || [])
    .concat(disabledDependencyFieldPaths || [])
    .filter(Boolean)
    .map((fieldPath) => {
      if (fieldPath.includes('root.')) {
        const dataPath = fieldPath.replace('root.', '');
        return _get(allValues, dataPath);
      }

      const dataPath = fieldPath.replace('data.', '').replace('extended_', 'extended.');
      return _get(rowValues, dataPath);
    })
    .filter(Boolean);

  // eslint-disable-next-line
  const formValues = useMemo(() => allValues, dependencies);
  // eslint-disable-next-line
  const memoProps = useMemo(() => props, dependencies);

  return <Component {...memoProps} formValues={formValues} />;
});
