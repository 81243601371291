import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { externalOnboardingChangeStep } from 'actions/externalOnboarding';

import ProgressBarStepper from 'complexComponents/ProgressBarStepper';

import {
  externalStepsToProgressBarSteps,
  findPreviousActiveStepIndex,
  shouldDisableBackButton,
} from 'helpers/progressBarStepper';
import { ternary } from 'helpers/utility';

import { initialStepIndexSelector } from 'queries/currentWorkflowStepSelector';

import { externalOnboardingStepSelector } from 'selectors/externalOnboardingSelectors';
import { isPartnershipRequestUpdatePaymentSelector } from 'selectors/partnershipRequestSelectors';

/**
 * ExternalOnboardingProgressBarStepperContainer
 * Parses config and defines navigation logic for the stepper
 * @param {ComponentProps} props
 * @param {Number} props.activeStepIndex
 * @param {Boolean} props.allowBackButtonNavigation
 * @param {Boolean} props.allowStepButtonNavigation
 * @param {Number} props.initialStepIndex
 * @param {Function} props.setActiveStepIndex
 * @param {ExternalStep[]} props.steps
 * @return {FunctionComponent}
 */
export const ExternalOnboardingProgressBarStepperContainer = ({
  activeStepIndex,
  allowBackButtonNavigation,
  allowStepButtonNavigation,
  initialStepIndex,
  setActiveStepIndex,
  steps,
}) => {
  const progressBarSteps = externalStepsToProgressBarSteps({
    activeStepIndex,
    initialStepIndex,
    steps,
  });
  const onClickBackButton = () => {
    const previousActiveStepIndex = findPreviousActiveStepIndex({
      stepIndex: activeStepIndex,
      steps,
    });

    setActiveStepIndex(previousActiveStepIndex);
  };
  const isBackButtonDisabled = shouldDisableBackButton({
    steps,
    activeStepIndex,
  });
  const backButtonProps = ternary(
    allowBackButtonNavigation,
    {
      onClick: onClickBackButton,
      isDisabled: isBackButtonDisabled,
    },
    undefined,
  );
  const onClickStep = ternary(allowStepButtonNavigation, setActiveStepIndex, undefined);

  return <ProgressBarStepper backButton={backButtonProps} onClickStep={onClickStep} steps={progressBarSteps} />;
};

ExternalOnboardingProgressBarStepperContainer.propTypes = {
  activeStepIndex: PropTypes.number.isRequired,
  allowBackButtonNavigation: PropTypes.bool,
  allowStepButtonNavigation: PropTypes.bool,
  initialStepIndex: PropTypes.number.isRequired,
  setActiveStepIndex: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ExternalOnboardingProgressBarStepperContainer.defaultProps = {
  allowBackButtonNavigation: undefined,
  allowStepButtonNavigation: undefined,
};

export const mapStateToProps = createStructuredSelector({
  activeStepIndex: externalOnboardingStepSelector,
  initialStepIndex: initialStepIndexSelector,
  isUpdatePayment: isPartnershipRequestUpdatePaymentSelector,
});

export const mapDispatchToProps = {
  setActiveStepIndex: externalOnboardingChangeStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalOnboardingProgressBarStepperContainer);
