import _debounce from 'lodash/debounce';
import _fromPairs from 'lodash/fromPairs';
import _toPairs from 'lodash/toPairs';
import { uppercaseToUnderscore } from '../stringHelpers';
import * as atomic from './atomic';
export const asyncDebounce = (func, wait) => {
    const debounced = _debounce((resolve, reject, args) => {
        func(...args)
            .then(resolve)
            .catch(reject);
    }, wait);
    return (...args) => new Promise((resolve, reject) => {
        debounced(resolve, reject, args);
    });
};
export const isDefined = (value) => !atomic.isUndef(value);
export const isNotArray = (value) => !atomic.isArray(value);
export const isStringOrNum = (value) => atomic.or(atomic.isString(value), atomic.isNum(value));
export const notExists = (value) => !atomic.exists(value);
export const isArrayLengthEqual = (value, expectedLength) => {
    const isArray = atomic.isArray(value);
    const matchedExpectedLength = atomic.isEqual(atomic.lengthOf(value), expectedLength);
    return atomic.and(isArray, matchedExpectedLength);
};
export const isNotIncluded = (objOrArray, value, searchIndex) => !atomic.isIncluded(objOrArray, value, searchIndex);
export const isLastIndex = (array, index) => atomic.isEqual(index, atomic.lengthOf(array) - 1);
export const hasLength1 = (array) => atomic.isEqual(array.filter((el) => !!el)?.length, 1);
export const valueForFirstKey = (object = {}) => atomic.valueForKey(object, atomic.firstKey(object));
export const valueOrZeroIfNull = (val) => atomic.ternary(atomic.isNull(val), 0, val);
export const arrayIncludesValue = (array, value) => atomic.isNotEqual(array.indexOf(value), -1);
export const arrayDoesNotIncludeValue = (array, value) => !arrayIncludesValue(array, value);
export const findById = (id) => (i) => i.id === id;
export const deCamelCaseObjectKeys = (obj) => {
    const camels = _toPairs(obj);
    const snakes = camels.map(([key, value]) => [uppercaseToUnderscore(key), value]);
    return _fromPairs(snakes);
};
