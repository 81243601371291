import { User } from '@carbon/icons-react';
import pluralize from 'pluralize';
import React from 'react';
import { useSelector } from 'react-redux';
import { FormFieldLabel } from 'components';
import { createItemFormFields } from 'constants/formFields';
import { ItemKinds, PaymentOrInvoiceByItemKindText } from 'constants/item';
import { PartnershipTypes } from 'constants/partnership';
import { PartnershipMemberProps } from 'data/resources/member';
import { getMembershipNameOrEmailOrPhoneNumber } from 'helpers/memberships';
import { hasLength } from 'helpers/utility';
import { useMountedValue } from 'hooks';
import { getItemKindFromLocationSelector } from 'selectors/routerSelectors';
import { GroupedContactDynamicFormFieldDescription } from './components';
import {} from './GroupedContactSelectsSharedWrapper.types';
import { textHelpers } from './helpers';
const GroupedContactSelectsSharedWrapper = ({ accessProperty = PartnershipMemberProps.defaultItem, children, className, fieldName, formUI, isCollapsed, onCreateContact, partnershipType, partnershipMembers, selectedContacts, setIsCollapsed, showDescription = true, showLabel = true, }) => {
    const { formSubmitted, selectedCompany } = formUI;
    const isReceivable = useSelector(getItemKindFromLocationSelector) === 'receivable';
    const isOnCreateItemForm = fieldName === createItemFormFields.ITEM_MEMBERS;
    const hasContactsOnInitialRender = useMountedValue(hasLength(selectedContacts));
    const areSelectsCollapsed = isCollapsed && isOnCreateItemForm && hasContactsOnInitialRender;
    if (formSubmitted) {
        return null;
    }
    if (areSelectsCollapsed) {
        const [firstContact] = selectedContacts;
        const numOfOtherContacts = selectedContacts.length - 1;
        const suffixText = numOfOtherContacts > 0 ? ` and ${numOfOtherContacts} ${pluralize('other', numOfOtherContacts)}` : '';
        return (React.createElement("div", { className: "flex items-center gap-2 text-grey-60 text-xs pl-2" },
            React.createElement(User, null),
            React.createElement("span", null,
                `Send ${PaymentOrInvoiceByItemKindText[isReceivable ? ItemKinds.RECEIVABLE : ItemKinds.PAYABLE]} confirmation to: ${getMembershipNameOrEmailOrPhoneNumber(firstContact)}${suffixText}. `,
                React.createElement("span", { className: "text-blue-40 cursor-pointer", onClick: () => setIsCollapsed(false) }, `Edit ${isReceivable ? PartnershipTypes.CUSTOMER : PartnershipTypes.VENDOR} contacts`))));
    }
    return (React.createElement("div", { className: className },
        showLabel && (React.createElement(FormFieldLabel, { className: "has-description pl-2" }, textHelpers.getGroupContactSelectsFieldLabelForAccessProp(accessProperty))),
        showDescription && (React.createElement(GroupedContactDynamicFormFieldDescription, { onCreateContact: onCreateContact, partnershipMembers: partnershipMembers, partnershipType: partnershipType, selectedCompany: selectedCompany, selectedContacts: selectedContacts })),
        children));
};
export default GroupedContactSelectsSharedWrapper;
