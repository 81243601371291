import { Upload } from '@carbon/icons-react';
import { View } from '@routable/components';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactDropzone from 'react-dropzone';

import { colors } from 'constants/styles';

import { Container, DropzoneCaption } from './Dropzone.styled';

import './Dropzone.scss';

const Dropzone = ({ accept, children, multiple, onDragEnter, onDragLeave, onFilesDrop, rejectedFiles, ...rest }) => {
  const hasChildren = Boolean(children);
  const [dropZoneActive, setDropzoneActive] = useState(false);

  const handleOnDragEnter = (...args) => {
    setDropzoneActive(true);
    onDragEnter?.(...args);
  };

  const handleOnDragLeave = (...args) => {
    setDropzoneActive(false);
    onDragLeave?.(...args);
  };

  const handleOnDrop = (...args) => {
    setDropzoneActive(false);
    onFilesDrop?.(...args);
  };

  return (
    <ReactDropzone
      accept={accept}
      className={clsx('dropzone__container', {
        dropzone__available: !hasChildren,
        dropzone__active: dropZoneActive,
      })}
      disableClick={hasChildren}
      multiple={multiple}
      onDragEnter={handleOnDragEnter}
      onDragLeave={handleOnDragLeave}
      onDrop={handleOnDrop}
      {...rest}
    >
      {children}

      <div
        className={clsx('dropzone__dashed_overlay', {
          'dropzone__dashed_overlay--visible': !hasChildren,
          'dropzone__dashed_overlay--invisible': hasChildren && !dropZoneActive,
          'dropzone__dashed_overlay--dragging-active': hasChildren && dropZoneActive,
        })}
        data-testid="dropzone__hashed_overlay"
      >
        {rejectedFiles?.length > 0 && (
          <div className="dropzone__rejected_files">
            <span className="dropzone__rejected_files_text">
              Whoops! We do not support this file type. Try another one!
            </span>
          </div>
        )}

        <View scrollable>
          <Container>
            <Upload fill={colors.colorBluePrimaryHex} size={16} />
            <DropzoneCaption className="drag-drop-caption">Drag & drop here</DropzoneCaption>
            <DropzoneCaption>or</DropzoneCaption>
            <DropzoneCaption className="browse-files-caption">Browse files</DropzoneCaption>
            <DropzoneCaption className="file-types">
              PDF, PNG, CSV, JPEG, DOCX, XLXS & TXT files are allowed
            </DropzoneCaption>
          </Container>
        </View>
      </div>
    </ReactDropzone>
  );
};

Dropzone.propTypes = {
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  onFilesDrop: PropTypes.func.isRequired,
  rejectedFiles: PropTypes.arrayOf(PropTypes.shape()),
};

Dropzone.defaultProps = {
  accept: 'image/*, application/pdf',
  multiple: undefined,
  rejectedFiles: [],
};

export default Dropzone;
