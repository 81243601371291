import { ArrowsHorizontal, Link, TrashCan, Unlink } from '@carbon/icons-react';
import { ModalLookupTable } from '@routable/components';
import { Button, Dialog, Menu, Tooltip, useModalState } from '@routable/gross-ds';
import { formNamesItem } from '@routable/shared';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayRemove, change } from 'redux-form';
import {} from 'hooks/purchaseOrders/purchaseOrder.models';
import { LineItemStyles } from 'constants/lineItems';
import { getDefaultLineItem, getLineItemsPathFromStyle } from 'helpers/lineItems';
import { usePurchaseOrderItemData } from 'hooks/purchaseOrders';
import { fillLineItemWithPropertiesFromPoLineItem } from 'modules/dashboard/createItems/createItemForm/helpers/poMatching';
import { isLineItemSimilarToDefault } from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/summarizeAndTransfer.helpers';
import { getLinkBillLineToPoLineTableConfig, PoLineItemsTable } from 'modules/dashboard/createItems/PoLineItemsTable';
import { useTransferSingleLineItem } from 'modules/table/hooks';
import { createItemsTableViewModelManagerForKindSelector } from 'queries/tableCompoundSelectors';
import { createItemFormAccountLineItemsSelector, createItemFormItemLineItemsSelector, createItemFormItemSelector, createItemFormPurchaseOrderSelector, createItemFormUiIsItemEditSelector, createItemFormUiSkipPurchaseOrderSelector, } from 'selectors/forms';
export const LineItemActions = ({ rowIdx: lineItemIndex, sectionPath }) => {
    const lineItemStyle = sectionPath?.includes('account') ? LineItemStyles.ACCOUNT : LineItemStyles.ITEM;
    const isStyleItem = lineItemStyle === LineItemStyles.ITEM;
    const deleteLineItemModalState = useModalState({});
    const linkToPoLineModalState = useModalState({});
    const unlinkPoLineModalState = useModalState({});
    const transferLineItemDialogState = useModalState({});
    const dispatch = useDispatch();
    const isSkipped = useSelector(createItemFormUiSkipPurchaseOrderSelector);
    const purchaseOrder = useSelector(createItemFormPurchaseOrderSelector);
    const item = useSelector(createItemFormItemSelector);
    const isItemEdit = useSelector(createItemFormUiIsItemEditSelector);
    const accountBillLineItems = useSelector(createItemFormAccountLineItemsSelector);
    const itemBillLineItems = useSelector(createItemFormItemLineItemsSelector);
    const activeLineItems = isStyleItem ? itemBillLineItems : accountBillLineItems;
    const activeLineItem = activeLineItems[lineItemIndex] || {};
    const activeSectionPath = getLineItemsPathFromStyle(lineItemStyle);
    const hasBothSections = Boolean(accountBillLineItems && itemBillLineItems);
    const results = usePurchaseOrderItemData(!isSkipped ? purchaseOrder?.value : undefined, item?.ledgerRef);
    const poLineItems = (results?.data?.data?.attributes?.line_items || []).filter((lineItem) => lineItem.style === lineItemStyle);
    const data = useMemo(() => {
        return poLineItems.map((lineItem) => ({
            ...lineItem,
            isDisabled: activeLineItems.find((billLineItem) => billLineItem.purchase_order_line_item_id === lineItem.purchase_order_line_item_id),
        }));
    }, [activeLineItems, poLineItems]);
    const tableData = { ...results, data };
    const viewModelManager = useSelector(createItemsTableViewModelManagerForKindSelector);
    const { destinationSectionDisplayName, transferLineItem } = useTransferSingleLineItem({
        lineItem: activeLineItem,
        lineItemIndex,
        viewModelManager,
    });
    const currentSectionDefaultLineItem = getDefaultLineItem(viewModelManager, activeSectionPath);
    const onLinkLineItem = (selectedIds) => {
        const poLineItem = poLineItems.find((lineItem) => lineItem.purchase_order_line_item_id === selectedIds[0]);
        const lineItemsToUpdate = [...activeLineItems];
        lineItemsToUpdate[lineItemIndex] = fillLineItemWithPropertiesFromPoLineItem({
            targetLineItem: lineItemsToUpdate[lineItemIndex],
            poLineItem,
            isOverride: isLineItemSimilarToDefault(lineItemsToUpdate[lineItemIndex], currentSectionDefaultLineItem),
        });
        dispatch(change(formNamesItem.CREATE_ITEM, activeSectionPath, lineItemsToUpdate));
        linkToPoLineModalState.close();
    };
    const onUnlinkLineItem = () => {
        const lineItemsToUpdate = lineItemStyle === LineItemStyles.ACCOUNT ? [...accountBillLineItems] : [...itemBillLineItems];
        lineItemsToUpdate[lineItemIndex] = {
            ...lineItemsToUpdate[lineItemIndex],
            purchase_order_line_item_id: '',
            purchase_order_id: '',
        };
        dispatch(change(formNamesItem.CREATE_ITEM, activeSectionPath, lineItemsToUpdate));
        unlinkPoLineModalState.close();
    };
    const onDeleteLineItem = () => {
        if (activeLineItems.length === 1) {
            dispatch(change(formNamesItem.CREATE_ITEM, activeSectionPath, [currentSectionDefaultLineItem]));
        }
        else {
            dispatch(arrayRemove(formNamesItem.CREATE_ITEM, activeSectionPath, lineItemIndex));
        }
        deleteLineItemModalState.close();
    };
    const handleTransferLineItem = () => {
        if (!activeLineItem.purchase_order_line_item_id) {
            transferLineItem();
        }
        else {
            transferLineItemDialogState.open();
        }
    };
    const onTransferLineItem = () => {
        transferLineItem();
        transferLineItemDialogState.close();
    };
    const isLinkActionVisible = purchaseOrder?.value && !activeLineItem.purchase_order_line_item_id;
    const isUnlinkActionVisible = Boolean(activeLineItem.purchase_order_line_item_id);
    const isOnlyDeleteActionVisible = !isLinkActionVisible && !isUnlinkActionVisible && !hasBothSections;
    if (isOnlyDeleteActionVisible) {
        return (React.createElement(Tooltip, { "data-testid": "delete-button-tooltip", tooltip: "Delete line item" },
            React.createElement(Button, { "aria-label": "line item delete", "data-testid": "line-item-delete", onPress: onDeleteLineItem, variant: "floating" },
                React.createElement(TrashCan, { className: "w-5 h-5", title: "remove" }))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "ledger-form-field" },
            React.createElement("div", { className: "flex items-center justify-center" },
                React.createElement(Menu, { buttonVariant: "floating", "data-testid": "line-items-action-menu", intent: "secondary", placement: "top left", size: "small" },
                    isLinkActionVisible && (React.createElement(Menu.Item, { disabled: isItemEdit, key: "link-line-item", onAction: linkToPoLineModalState.open, textValue: "Link to PO line" },
                        React.createElement(Tooltip, { "data-testid": "item-edit--link-po-line", position: "left", size: "small", tooltip: isItemEdit
                                ? "You can't link an existing line item to a PO line while editing. You'll need to delete and recreate this payable to take this action."
                                : undefined, variant: "light" },
                            React.createElement("div", { className: "flex items-center text-sm" },
                                React.createElement(Link, { className: "w-5 h-5 mr-4" }),
                                "Link to PO line")))),
                    isUnlinkActionVisible && (React.createElement(Menu.Item, { key: "unlink-line-item", onAction: isItemEdit ? unlinkPoLineModalState.open : onUnlinkLineItem, textValue: "Unlink from PO line" },
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement(Unlink, { className: "w-5 h-5 mr-4" }),
                            "Unlink from PO line"))),
                    hasBothSections && (React.createElement(Menu.Item, { key: "move-to-expenses-item", onAction: handleTransferLineItem, textValue: `Move to ${destinationSectionDisplayName}` },
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement(ArrowsHorizontal, { className: "w-5 h-5 mr-4" }),
                            `Move to ${destinationSectionDisplayName}`))),
                    React.createElement(Menu.Item, { key: "delete-line-item", onAction: isItemEdit && activeLineItem.purchase_order_line_item_id
                            ? deleteLineItemModalState.open
                            : onDeleteLineItem, textValue: "Delete line item" },
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement(TrashCan, { className: "w-5 h-5 mr-4" }),
                            "Delete line item"))))),
        React.createElement(ModalLookupTable, { modalState: linkToPoLineModalState, title: "Link bill line to PO line" },
            React.createElement(PoLineItemsTable, { isMultiSelect: false, onClose: linkToPoLineModalState.close, onSubmit: onLinkLineItem, purchaseOrder: purchaseOrder, submitText: "Link", tableConfig: getLinkBillLineToPoLineTableConfig(isStyleItem), tableData: tableData })),
        React.createElement(Dialog, { footer: React.createElement(Dialog.Footer, null,
                React.createElement(Button, { "data-testid": "move-cancel", intent: "secondary", onPress: transferLineItemDialogState.close, variant: "outline" }, "Cancel"),
                React.createElement(Button, { "data-testid": "move-confirm", onPress: onTransferLineItem }, "Move line item")), header: React.createElement(Dialog.Header, { label: "Are you sure you want to move this line item?" }), intent: "primary", state: transferLineItemDialogState }, isItemEdit
            ? 'This action will unlink the line item from the PO and is irreversible. You will not be able to relink this PO line.'
            : 'Moving this line item will unlink it from the PO. You can re-link it after it is moved.'),
        React.createElement(Dialog, { footer: React.createElement(Dialog.Footer, null,
                React.createElement(Button, { "data-testid": "move-cancel", intent: "secondary", onPress: unlinkPoLineModalState.close, variant: "outline" }, "Cancel"),
                React.createElement(Button, { "data-testid": "move-confirm", onPress: onUnlinkLineItem }, "Unlink PO line")), header: React.createElement(Dialog.Header, { label: "Are you sure you want to unlink this PO line?" }), intent: "primary", state: unlinkPoLineModalState }, "This action is irreversible. You will not be able to relink this PO line."),
        React.createElement(Dialog, { footer: React.createElement(Dialog.Footer, null,
                React.createElement(Button, { "data-testid": "delete-cancel", intent: "secondary", onPress: deleteLineItemModalState.close, variant: "outline" }, "Cancel"),
                React.createElement(Button, { "data-testid": "delete-confirm", onPress: onDeleteLineItem }, "Delete line item")), header: React.createElement(Dialog.Header, { label: "Are you sure you want to delete this line item?" }), intent: "primary", state: deleteLineItemModalState }, "You will not be able to add new line items that are linked to this PO. Any new line items will be processed as non-PO lines.")));
};
