import { cva } from 'class-variance-authority';
export const filterTagStyles = cva([
    'border',
    'border-grey-20',
    'bg-white',
    'rounded',
    'inline-flex',
    'gap-3',
    'items-center',
    'font-medium',
    'text-grey-70',
    'first-letter:capitalize',
], {
    variants: {
        size: {
            default: ['py-[6px]', 'px-3', 'text-sm'],
            medium: ['py-[6px]', 'px-3', 'text-sm'],
            large: ['py-3', 'px-3', 'text-sm'],
            small: ['py-2', 'px-3', 'text-xs'],
        },
    },
    defaultVariants: {
        size: 'default',
    },
});
