import React from 'react';
import { DashboardToDashboardW9RestrictionTooltipContent, Text } from 'components';
import { VendorWithoutValidContactTooltipContent, VendorNeedsToAcceptInviteTooltipContent } from './components';
import { vendorRequestedTaxInfoBefore } from './TaxInfoRequestButton.constants';
export const getTaxInfoRequestButtonTooltipProps = ({ hasBeenTriggered, isDisabled, isLoading, isPartnerStatusInvited, isTaxCollectionDisabled, isR2R, isWithoutContact, partnerName, permissions, }) => {
    if (hasBeenTriggered) {
        return {
            tooltip: React.createElement(Text.Regular, null, vendorRequestedTaxInfoBefore),
        };
    }
    if (!isDisabled && !isLoading) {
        return {
            tooltip: React.createElement(Text.Regular, null, "Vendor will receive an email request to provide tax info"),
        };
    }
    if (!permissions.hasPermission) {
        return permissions.tooltipProps;
    }
    if (isTaxCollectionDisabled) {
        return {
            tooltip: React.createElement(Text.Regular, null, "Tax collection settings must be enabled for this request"),
        };
    }
    if (isR2R) {
        return {
            tooltip: React.createElement(DashboardToDashboardW9RestrictionTooltipContent, null),
        };
    }
    if (isWithoutContact) {
        return { tooltip: React.createElement(VendorWithoutValidContactTooltipContent, null) };
    }
    if (isPartnerStatusInvited) {
        return {
            tooltip: React.createElement(VendorNeedsToAcceptInviteTooltipContent, { partnerName: partnerName }),
        };
    }
    return null;
};
