import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { ReduxFormFieldRenderInput, ExternalLink } from 'components';
import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';

import { digitsOnly } from 'helpers/fieldNormalizers';
import { requiredValidator, EINValidator } from 'helpers/fieldValidation';
import helpDocs from 'helpers/helpDocs';
import { formatAsEIN } from 'helpers/numbers';

import { containerWidthMap } from 'modules/signup-v3/constants/container';

import {
  currentCompanyVerificationUpdateAllowedSelector,
  isUpdatingCurrentCompanySelector,
} from 'selectors/currentCompanySelectors';

import { mapViewToFields } from '../fields';
import FlowStepperMain from '../FlowStepperMain';
import { viewSteps } from '../helpers/viewSteps';

import { EINContainer, Container, HelperLink } from './EIN.styles';

const EINField = ({ name, isDisabled }) => (
  <Container>
    <Field
      component={ReduxFormFieldRenderInput}
      dataFullStory
      dataTestId="einField"
      format={formatAsEIN}
      isDisabled={isDisabled}
      isLocked={isDisabled}
      isRequired
      maxLength="10"
      name={name}
      normalize={digitsOnly}
      placeholder="Employer Identification Number (EIN)"
      type="text"
      validate={[requiredValidator, EINValidator]}
    />
    <ExternalLink href={helpDocs.BUSINESS_VERIFICATION_DETAILS} iconClassName="margin-left--sm">
      <HelperLink>Where can I find my EIN letter?</HelperLink>
    </ExternalLink>
  </Container>
);

export const EINMain = ({ activeView, invalid }) => {
  const updatedAllowed = useSelector(currentCompanyVerificationUpdateAllowedSelector);
  const isUpdating = useSelector(isUpdatingCurrentCompanySelector);
  return (
    <FlowStepperMain
      containerWidth={containerWidthMap.large}
      fields={
        <EINContainer>
          {!updatedAllowed && <CannotUpdateFieldHint />}
          {mapViewToFields[activeView]?.map(({ name, ...props }) => (
            <EINField key={name} name={name} {...props} isDisabled={!updatedAllowed} />
          ))}
        </EINContainer>
      }
      invalid={invalid || !updatedAllowed}
      onBack={viewSteps[activeView].previous}
      onNext={{
        ...viewSteps[activeView].next,
        isLoading: isUpdating,
      }}
      onSkip={updatedAllowed && viewSteps[activeView].skip}
      subTitle={{ content: '' }}
      title={{ content: "What's your Employer Identification Number (EIN)?" }}
    />
  );
};

export default EINMain;
