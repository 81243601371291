import { colors, sizes } from 'constants/styles';

import { ternary } from 'helpers/utility';

const getIconTooltipProps = (iconTooltipProps) => ({
  ...iconTooltipProps,
  content: iconTooltipProps.content || '',
  iconProps: {
    color: colors.colorGreyDarkHex,
    marginLeft: sizes.spacing.SMALL,
    marginRight: sizes.spacing.SMALL,
    size: sizes.iconSizes.LARGE,
    ...iconTooltipProps.iconProps,
  },
  tooltipProps: {
    // Disable if no content. Tooltips are optional.
    isShown: ternary(iconTooltipProps.content, undefined, false),
    ...iconTooltipProps.tooltipProps,
  },
});

export default getIconTooltipProps;
