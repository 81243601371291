import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import pluralize from 'pluralize';
import React from 'react';

import { getOptionDataFromPartnerMember } from 'components/selectTypes/helpers';
import { partnershipMemberSelectAnyApproverPlaceholder } from 'components/selectTypes/text';

import { ItemApprovalTypes, ItemKinds } from 'constants/item';

import { getApproverFieldName } from 'helpers/createItem';
import { getObjDate } from 'helpers/date';
import { getSelectedApproversForItemEdit } from 'helpers/initialValues/editItem/approvers';
import {
  getAmountDueForBulkUploadItem,
  getBillOrInvoiceText,
  getItemKindText,
  getPaymentOrInvoiceText,
  getPaymentsOrInvoicesText,
  isItemKindPayable,
} from 'helpers/items';
import { parseCurrency } from 'helpers/numbers';
import { convertNumberToOrdinalString } from 'helpers/stringHelpers';
import {
  and,
  any,
  firstValue,
  hasLength,
  hasZeroLength,
  isEqual,
  isGreaterOrEqual,
  isNotEqual,
  lastElementIn,
  lengthOf,
  or,
  ternary,
  uniqueArray,
} from 'helpers/utility';

/**
 * Converts item approval type to quantity
 * @param {ItemApprovalType} levelType
 * @return {string} 'all'|'one'|''
 */
export const convertItemApprovalTypeToQuantity = (levelType) => {
  switch (levelType) {
    case ItemApprovalTypes.ALL:
      return 'all';
    case ItemApprovalTypes.ANY:
      return 'one';

    default:
      return '';
  }
};

/**
 * Checks if the approval amount is larger than the item amount
 * @param {string} amount - the items current amount
 * @param {string} limit - the approval limit for the item kind
 */
export const itemAmountNeedsApproval = (amount, limit) => isGreaterOrEqual(parseFloat(amount), parseFloat(limit));

/**
 * Creates a filter function which evaluates whether or not the approval level is applicable to the item kind and amount.
 * @param {ItemKind} itemKind
 * @param {Item.amount} itemAmount
 * @returns {function} - function to pass to array filter of ApprovalSelectConfigs
 */
export const createItemNeedsApprovalFilter = (itemKind, itemAmount) => (level) =>
  and(isEqual(itemKind, level.itemKind), itemAmountNeedsApproval(itemAmount, level.amount));

/**
 * If amountDue is filled, we use that. Otherwise, use amount.
 * @param {Item} item
 * @param {Item.amountDue} [item.amountDue]
 * @param {Item.amount} [item.amount]
 * @returns {boolean} True if using amountDue key
 */
export const shouldUseAmountDue = (item) => !!item.amountDue;

/**
 * Look through the list of approvers on an item and see if the current membership is on the list.
 * Returns the first itemSideApproval required for the current user.
 * We don't care if the approval has already occurred or not.
 * @param {Membership.id} currentMembershipId
 * @param {ItemApprovalLevels[]} itemApprovals
 * @returns {ItemSideApproval|undefined}
 */
export const isCurrentMembershipApproverOnItem = (currentMembershipId, itemApprovals = []) =>
  itemApprovals.flat().find((approval) => isEqual(approval.membership, currentMembershipId));

/**
 * Returns the approval select config array for a single invoice
 * @param {OptionsArg} options
 * @param {ApprovalSelectConfig[]} options.approvalLevels
 * @param {Item} options.item
 * @param {number} options.itemAmount
 * @param {boolean} [options.useAmountDue] - determines if amount or amountDue should be used
 * @return {ApprovalSelectConfig[]}
 */
export const getSingleInvoiceApprovalLevels = ({ approvalLevels, item = {}, itemAmount, useAmountDue = false }) => {
  const amountFromItemAmountOrItem = itemAmount ?? item.amount;
  const amount = useAmountDue ? item.amountDue : amountFromItemAmountOrItem;
  const filter = createItemNeedsApprovalFilter(item.kind, amount);

  return approvalLevels.filter(filter);
};

/**
 * Returns the approval select config array for multiple items
 * @param {OptionsArg} options
 * @param {number[]} options.amounts
 * @param {ApprovalSelectConfig[]} options.approvalLevels
 * @param {Item} options.item
 * @return {ApprovalSelectConfig[]}
 */
export const getUniqueApprovalLevelsForGivenAmounts = ({ amounts, approvalLevels, item }) => {
  const allApprovalsForAllItems = amounts
    .map((amount) => {
      const mockItem = { kind: item.kind };

      return getSingleInvoiceApprovalLevels({
        approvalLevels,
        item: mockItem,
        itemAmount: amount,
      });
    })
    .flat();

  // de-duplicated approvals shared between items
  return uniqueArray(allApprovalsForAllItems);
};

/**
 * Filters approval levels by item kind and item amount for either a single item or multiple invoices
 * @param {OptionsArg} options
 * @param {ApprovalSelectConfig[]} options.approvalLevels
 * @param {Item} options.item
 * @param {number} options.itemAmount
 * @param {Item[]} [options.items=[]]
 * @param {number[]} [options.selectedInvoicesAmounts=[]]
 * @param {boolean} [options.useAmountDue] - determines if amount or amountDue should be used
 * @return {ApprovalSelectConfig[]}
 */
export const filterApprovalLevelsByKindAndAmount = ({
  approvalLevels,
  item,
  itemAmount,
  items = [],
  selectedInvoicesAmounts = [],
  useAmountDue,
}) => {
  // multiple invoices
  if (hasLength(selectedInvoicesAmounts)) {
    return getUniqueApprovalLevelsForGivenAmounts({
      amounts: selectedInvoicesAmounts,
      approvalLevels,
      item,
    });
  }

  // bulk import items
  if (hasLength(items)) {
    const itemFromArray = {
      ...firstValue(items),
      // bulk import is only supporting payables at the moment, and as
      // such, we aren't currently receiving this data from the BE
      kind: ItemKinds.PAYABLE,
    };
    const amounts = items.map(getAmountDueForBulkUploadItem);

    return getUniqueApprovalLevelsForGivenAmounts({
      amounts,
      approvalLevels,
      item: itemFromArray,
    });
  }

  // single invoice
  return getSingleInvoiceApprovalLevels({
    approvalLevels,
    item,
    itemAmount,
    useAmountDue,
  });
};

/**
 * Approval dates
 */
export const getApprovalDate = (approval) => getObjDate(approval, 'approvalDate', 'll');

/**
 * Construct approval title tooltip text based on current approval levels, item, and whether or not this is for one
 * item or multiple invoices.
 * @param {Object} options
 * @param {ApprovalSelectConfig[]} options.approvalLevels
 * @param {Item} options.item
 * @param {boolean} options.singleInvoice - Is this a single invoice or multiple?
 * @return {string}
 */
export const getApprovalTooltipText = ({ approvalLevels, item, singleInvoice }) => {
  const lastApprovalLevel = lastElementIn(approvalLevels);

  // pieces of the string
  const oneOrMore = ternary(singleInvoice, 'This', 'At least 1 selected');
  const paymentOrInvoice = getPaymentOrInvoiceText(item);
  const howMany = lengthOf(approvalLevels);
  const approvals = ternary(isEqual(approvalLevels.length, 1), 'approval', 'approvals');
  const amountThreshold = parseCurrency(lastApprovalLevel.amount);

  return `${oneOrMore} ${paymentOrInvoice} requires ${howMany} ${approvals} because it is over ${amountThreshold}.`;
};

/**
 * Returns the approval requirement by kind, when we don't have an item,
 * but a view or filter, etc, instead.
 * @param {object} kind - type of view/etc
 * @param {object} approvalSettings - the current company's item approval settings
 */
export const isApprovalRequiredByKind = (kind, approvalSettings) =>
  isItemKindPayable({ kind }) ? approvalSettings.payableRequiresApprover : approvalSettings.receivableRequiresApprover;

/**
 * Returns the approval requirement based on the item kind
 * @param {object} item - item object
 * @param {object} approvalSettings - the current company's item approval settings
 */
export const isApprovalRequiredByItemKind = (item, approvalSettings) =>
  isItemKindPayable(item) ? approvalSettings.payableRequiresApprover : approvalSettings.receivableRequiresApprover;

/**
 * Helper to figure out whether an approval is required.
 * To support dynamic data in create items, the item amount is provided separately so it can be derived
 * from jsonLogic calculations, but when dealing with existing items, `item.amount` can safely be used
 * as the itemAmount value.
 * @param {Object} options
 * @param {ApprovalSelectConfig[]} options.approvalLevels
 * @param {import('interfaces/item').Item} options.item
 * @param {number|string} [options.itemAmount] - Will be provided if item is being created or is existing, but not provided if selecting invoices
 * @param {Boolean} [options.useAmountDue]
 * @param {Boolean} [options.showApprovalLevelsTable]
 * @param {Boolean} [options.showManualApprovalRow]
 * @return {boolean}
 */
export const isItemApprovalRequired = ({
  approvalLevels,
  item,
  itemAmount,
  useAmountDue,
  showApprovalLevelsTable,
  showManualApprovalRow,
}) =>
  showApprovalLevelsTable ||
  showManualApprovalRow ||
  hasLength(
    filterApprovalLevelsByKindAndAmount({
      approvalLevels,
      item,
      itemAmount,
      useAmountDue: useAmountDue ?? shouldUseAmountDue(item),
    }),
  );

/**
 * Helper to figure out whether an approval is required
 * @param {ApprovalSelectConfig[]} approvalLevels
 * @param {Item} invoice
 * @return {boolean}
 */
export const isInvoiceApprovalRequired = (approvalLevels, invoice) =>
  hasLength(
    filterApprovalLevelsByKindAndAmount({
      approvalLevels,
      item: invoice,
      useAmountDue: true,
    }),
  );

/**
 * Helper function that checks if a given level's type is 'ALL'
 * @param {ApprovalSelectConfig} level
 * @param {ItemApprovalType} level.levelType
 * @return {boolean}
 */
export const isLevelTypeAll = (level) => isEqual(level.levelType, ItemApprovalTypes.ALL);

/**
 * Helper function that checks if a given level's type is 'ANY'
 * @param {Object} level
 * @param {ItemApprovalType} level.levelType
 * @return {boolean}
 */
export const isLevelTypeAny = (level) => isEqual(level.levelType, ItemApprovalTypes.ANY);

/**
 * Helper function that checks if the given member is in the list of given approvers
 * @param {Membership} member
 * @param {Id[]} approvers
 * @return {boolean}
 */
export const isMemberInListOfApprovers = (member, approvers = []) => approvers.includes(member.id);

/**
 * Helper to filter out members who are approvers
 * @param {Object} props
 * @param {Id[]} props.approvers
 * @param {Id} props.currentUserID - member id to check against
 * @param {Membership[]} props.members
 * @param {Object} props.approvalSettings
 * @return {Membership[]}
 */
export const getApproverMembers = ({ approvers, currentUserID, members, approvalSettings }) => {
  if (approvalSettings.enforceForApprovers) {
    // Ignore current user
    return members.filter(
      (member) => isMemberInListOfApprovers(member, approvers) && isNotEqual(member.id, currentUserID),
    );
  }

  return members.filter((member) => isMemberInListOfApprovers(member, approvers));
};

/**
 * groupAndFilterApprovalsByLevels
 * Given an array of approval objects (aka ItemSideApprovals),
 * this returns an array of approval levels sorted by position
 * @param {ItemSideApproval[]} approvals
 * @return {ItemApprovalLevels}
 */
export const groupAndFilterApprovalsByLevels = (approvals) => {
  const approvalsByLevelID = _groupBy(approvals, (approval) => approval.levelId);
  const sortedByPosition = _sortBy(approvalsByLevelID, (approvalLevel) => approvalLevel[0]?.levelPosition);

  // If level type is ANY and someone has already approved
  // then filter out the rest of the approvers
  sortedByPosition.forEach((levelApprovals, index) => {
    if (!isLevelTypeAll(levelApprovals[0])) {
      const approver = levelApprovals.find((approval) => approval.hasApproved);
      if (approver) {
        sortedByPosition[index] = [approver];
      }
    }
  });

  return sortedByPosition;
};

/**
 * Helper functions that maps given approvalLevels
 * and adds missing properties based on the attributes
 * @param {Object} options
 * @param {ApproverLevel[]} options.approvalLevels
 * @param {boolean} options.creatingNewInvoice - If we're creating a new invoice v.s. selecting one from the ledger
 * @param {Membership} options.members
 * @param {Object} options.approvalSettings
 * @param {Component} TextComponent
 * @return {*[]}
 */
export const mapApprovalLevelConfig = ({
  approvalLevels,
  creatingNewInvoice,
  currentUserID,
  members,
  approvalSettings,
  TextComponent,
}) =>
  approvalLevels.map((level) => ({
    ...level,
    fieldName: getApproverFieldName(level.position),
    isDisabled: isLevelTypeAll(level),
    label: `${convertNumberToOrdinalString(level.position)} approval`,
    approvers: getApproverMembers({
      approvers: level.qualifiedApprovers,
      currentUserID,
      members,
      approvalSettings,
    }),
    // Same placeholder for both types,
    // because the type 'all' is already pre-filled and disabled
    placeholder: partnershipMemberSelectAnyApproverPlaceholder,
    textComponent: (
      <TextComponent
        importingInvoices={!creatingNewInvoice}
        itemKind={level.itemKind}
        position={level.position}
        thresholdAmount={level.amount}
        type={level.levelType}
      />
    ),
  }));

/**
 * Logic for showing the NoApprovalNeeded component depends on whether or not we have amounts, either by creating the
 * item on Routable or selecting invoices from a ledger.
 * @param {Object} options
 * @param {ApprovalSelectConfig[]} [options.approvalLevels] - Approval levels from company settings
 * @param {boolean} [options.invoicesApprovalRequired] - If any of the selected invoices require approval
 * @param {Item} [options.item] - The item being created in the invoice generator
 * @param {number[]} [options.selectedInvoicesAmounts] - Selected invoices for sending multiple invoices from the ledger
 * @param {boolean} options.showInvoiceGenerator - If we're creating an item or sending one from the ledger
 * @returns {boolean} True if no approval needed
 */
export const hideNoApprovalNeeded = (options) => {
  const {
    approvalLevels,
    invoicesApprovalRequired,
    item,
    isItemEdit,
    itemAmount,
    selectedInvoicesAmounts,
    showInvoiceGenerator,
  } = options;

  // creating a new item on Routable or editing an existing one
  if (showInvoiceGenerator) {
    const noItemAmount = !itemAmount;
    const isItemEditAndHasApprovalLevels = and(isItemEdit, hasLength(approvalLevels));
    const itemRequiresApproval = isItemApprovalRequired({
      approvalLevels,
      item,
      itemAmount,
    });

    return or(noItemAmount, itemRequiresApproval, isItemEditAndHasApprovalLevels);
  }

  // selecting invoices from a ledger
  const noInvoicesSelected = hasZeroLength(selectedInvoicesAmounts);
  return or(noInvoicesSelected, invoicesApprovalRequired);
};

/**
 * Returns count of items needing approval
 * @param {Object} props
 * @param {ApprovalSelectConfig[]} props.approvalLevels
 * @param {Array} props.items
 * @param {boolean} props.singleInvoice - Is this for a single invoice or multiple?
 * @return {number}
 */
export const getItemsNeedingApprovalCount = ({ approvalLevels, items, singleInvoice }) => {
  let itemsNeedingApprovalCount = 0;

  if (!singleInvoice && !!items) {
    itemsNeedingApprovalCount = items.filter((item) => isItemApprovalRequired({ approvalLevels, item })).length;
  }

  return itemsNeedingApprovalCount;
};

/**
 * Returns title for bulk import items approval section
 * Note: This has multiple calls to pluralize since e.g. "1 invoice"/"1 requires", but "2 invoices"/"2 require"
 * @param {String} itemCount
 * @param {ItemKind} itemKind
 * @return {String}
 */
export const getBulkItemsApprovalsSectionTitle = (itemCount, itemKind) => {
  const numericCount = parseInt(itemCount, 10);
  const invoicesOrPaymentsText = pluralize(getPaymentsOrInvoicesText(itemKind, itemCount), numericCount);
  const requiresText = ternary(isEqual(numericCount, 1), 'requires', 'require');

  return `${itemCount} ${invoicesOrPaymentsText} ${requiresText} approval`;
};

export const getNoApprovalText = (item, creatingOneItem) => {
  const billsOrInvoices = pluralize(getBillOrInvoiceText(item));
  const forTheseItems = ternary(creatingOneItem, '', ` for these ${billsOrInvoices}`);

  return `No approval is needed${forTheseItems}`;
};

/**
 * Pseudo-hint to show if we don't have enough information to determine if approvals are required.
 * @param {Item} item
 * @param {boolean} showInvoiceGenerator - are we creating an item on Routable or selecting an invoice from the ledger
 * @returns {string}
 */
export const getAddAmountToDetermineApprovalText = (item, showInvoiceGenerator) => {
  if (showInvoiceGenerator) {
    const paymentOrRequest = getItemKindText(item).toLowerCase();
    return `Add ${paymentOrRequest} amount to determine approval requirements...`;
  }

  const billsOrInvoices = pluralize(getBillOrInvoiceText(item));
  return `Select ${billsOrInvoices} to determine approval requirements...`;
};

/**
 * Returns true if the item has an approvals history.
 * @param {import('interfaces/item').Item|{ approvals: string[] }} item An item or item-like object.
 */
export const doesItemHavePreviousApprovals = (item) => hasLength(item?.approvals);

/**
 * Given the properties of an existing item, and the proposed changes to that item, returns whether
 * re-approval will be required if the changes are committed.
 * @param {Object|Item} existingItemData
 * @param {string} existingItemData.fundingAccount
 * @param {string} existingItemData.partnerReceivableAccount
 * @param {Object|Item} updatedItemData
 * @param {string} updatedItemData.fundingAccount
 * @param {string} updatedItemData.partnerReceivableAccount
 * @param {{ approvals: string[] }|Item} options
 * @param {Object} options.approvals
 * @return {boolean}
 */
export const willItemReapprovalBeRequired = (existingItemData, updatedItemData, options) => {
  if (!doesItemHavePreviousApprovals(options)) {
    return false;
  }

  return any([
    isNotEqual(existingItemData.partnerReceivableAccount, updatedItemData.partnerReceivableAccount),
    isNotEqual(existingItemData.fundingAccount, updatedItemData.fundingAccount),
  ]);
};

/**
 * Helper method to get the itemSideApproval objects (from approvals reducer) for every id in the item.approvals array
 * @param {object} props
 * @param {Item} props.item
 * @param {ItemApprovalLevels[]} props.allApprovals
 * @returns {ItemApprovalLevels[]}
 */
export const getItemSideApprovalsForItemApprovals = ({ item, allApprovals }) =>
  item.approvals
    .map((approvalId) => {
      const itemSideApproval = allApprovals.find((approval) => approval.id === approvalId);
      if (itemSideApproval) {
        return itemSideApproval;
      }
      return undefined;
    })
    .filter(Boolean);

/**
 * Helper function which is used on MutuallyExclusiveSelects component mount.
 * Pre-fills the given field if there is only one option or if type === 'all'
 * @param {Array} allApprovals
 * @param {Object} approvalSettings
 * @param {ApprovalSelectConfig} levelConfig
 * @param {string} formName
 * @param {boolean} isItemEdit
 * @param {function} onFieldChange
 */
export const prefillMutuallyExclusiveSelectsData = ({
  allApprovals,
  approvalSettings,
  levelConfig,
  formName,
  isItemEdit,
  onFieldChange,
}) => {
  const { uiFlagPrePopulateApprovers } = approvalSettings;

  const { approvers, fieldName, levelType } = levelConfig;

  // Pre-fill if only one option or level type is 'all' or the uiFlagPrePopulateApprovers checkbox was checked in RAD
  const shouldPrefill = or(
    // to help users, 'any' levels prefill if they only have 1 possible approver. otherwise RCTMs need to select
    isEqual(approvers.length, 1),
    // 'all' levels are always disabled and prefilled
    isEqual(levelType, ItemApprovalTypes.ALL),
    // client can have a setting to force prefill
    uiFlagPrePopulateApprovers,
    // on ItemEdit we always want to try and pre-fill
    isItemEdit,
  );

  if (shouldPrefill) {
    const itemEditApprovers = getSelectedApproversForItemEdit({
      levelConfig,
      allApprovals,
    });

    const approversToPrefill = isItemEdit ? itemEditApprovers : approvers;

    onFieldChange(formName, fieldName, approversToPrefill.map(getOptionDataFromPartnerMember));
  }
};
