import { PaymentDeliveryMethodType, PaymentDeliveryOptionType } from '@routable/shared';
import pluralize from 'pluralize';

import { ItemPaymentDeliveryOptionText } from 'constants/item';

import { getDeliveryOptionBillingCodesForAP } from 'helpers/billing/base';
import { formatCurrencyUSD } from 'helpers/currencyFormatter';
import { parseCurrency } from 'helpers/numbers';
import {
  isPaymentDeliveryOptionInternationalSwift,
  isPaymentDeliveryOptionInternationalAch,
} from 'helpers/paymentDeliveryOption';
import { and, isEqual, ternary } from 'helpers/utility';

const APBillingCodes = getDeliveryOptionBillingCodesForAP();

const isBillingCodeSwiftSha = (code) => {
  return code === APBillingCodes[PaymentDeliveryOptionType.INTERNATIONAL_SWIFT_SHA_TIER_2];
};

const isBillingCodeSwiftOur = (code) => {
  return code === APBillingCodes[PaymentDeliveryOptionType.INTERNATIONAL_SWIFT_OUR_TIER_2];
};

/**
 * Returns the title for an option, trimming the 'Check' text.
 *
 * @example
 * const trimmed = getBillingCodeDisplayTitleWithMethodTrimmed({ title: 'Expedited ACH' });
 * // result: 'Expedited ACH'
 * @example
 * const trimmed = getBillingCodeDisplayTitleWithMethodTrimmed({ title: 'Standard Check' });
 * // result: 'Standard'
 *
 * @param {{ paymentDeliveryOptionExternalName: string }} [billingData]
 * @return {StringMaybe}
 */
export const getBillingCodeDisplayTitleWithMethodTrimmed = (billingData) => {
  if (!billingData) {
    return undefined;
  }

  if (isBillingCodeSwiftOur(billingData.code)) {
    return 'SWIFT OUR*';
  }
  if (isBillingCodeSwiftSha(billingData.code)) {
    return 'SWIFT SHA**';
  }

  const deliveryOptionTitle = billingData.paymentDeliveryOptionExternalName || billingData.title || '';
  return deliveryOptionTitle.replace(/Check/g, '').trim();
};

/**
 * Returns formatted payment delivery option text of given billingData
 * @param {BillingCodeData} billingData
 * @returns {String}
 */
export const getBillingCodePaymentDeliveryOptionText = (billingData) => {
  if (!billingData) {
    return '';
  }

  if (
    isPaymentDeliveryOptionInternationalAch(billingData.paymentDeliveryOption) ||
    isPaymentDeliveryOptionInternationalSwift(billingData.paymentDeliveryOption)
  ) {
    return ItemPaymentDeliveryOptionText[billingData.paymentDeliveryOption];
  }

  return getBillingCodeDisplayTitleWithMethodTrimmed(billingData);
};

/**
 * Calculates the cost per transaction amount for given billing code
 * @param {Number|String} itemAmount
 * @param {BillingCodeData} billingData
 * @return {String}
 */
export const getCostPerTransactionAmount = (itemAmount, billingData) => {
  const { maxAmount, minAmount, multiplier, rate } = billingData;

  const amount = parseFloat(itemAmount) || 0;
  const rateVal = parseFloat(rate) || 0;
  const multiplierVal = parseFloat(multiplier) || 0;
  const min = parseFloat(minAmount) || 0;
  const max = parseFloat(maxAmount) || 0;

  // TODO after merging [FRON-2587]: use the new FloatingPointMath utilities
  let costPerTransaction = rateVal + multiplierVal * amount;

  if (min) {
    costPerTransaction = Math.max(min, costPerTransaction);
  }

  if (max) {
    costPerTransaction = Math.min(max, costPerTransaction);
  }

  return formatCurrencyUSD(costPerTransaction);
};

/**
 * Returns the formatted "{earliest}-{latest} business days"
 * @param {BillingCodeData} billingData
 * @return {StringMaybe}
 */
export const getFormattedBillingCodeEarliestLatestDesc = (billingData) => {
  if (!billingData?.paymentDeliveryOptionSpeed) {
    return undefined;
  }

  const { paymentDeliveryOptionSpeed } = billingData;

  if (!paymentDeliveryOptionSpeed) {
    return undefined;
  }

  const { earliest, latest } = paymentDeliveryOptionSpeed;

  if (isEqual(earliest, latest)) {
    return `${earliest} business ${pluralize('day', earliest)}`;
  }

  return `${earliest}-${latest} business days`;
};

/**
 * Returns the formatted billing code speed description.
 * @param {BillingCodeData} billingData
 * @return {StringMaybe}
 */
export const getFormattedBillingCodeSpeedDescription = (billingData) => {
  if (!billingData?.paymentDeliveryOptionSpeed) {
    return undefined;
  }

  const {
    paymentDeliveryOption,
    paymentDeliveryOptionSpeed: { earliest, latest },
  } = billingData;

  // We cannot import helper here because of circular import issues
  if (paymentDeliveryOption === PaymentDeliveryOptionType.RTP) {
    return 'Within seconds';
  }
  if (and(isEqual(earliest, 0), isEqual(latest, 0))) {
    return 'End of business day';
  }
  if (isEqual(earliest, latest)) {
    return `${earliest} business ${pluralize('day', earliest)}`;
  }

  return getFormattedBillingCodeEarliestLatestDesc(billingData);
};

/**
 * Returns the formatted title for an option, in the general format of:
 * OptionName: #-# business days
 * @param {BillingCodeData} billingData
 * @param {string} paymentDeliveryOption
 * @return {StringMaybe}
 */
export const getFormattedBillingCodeDisplayTitle = (billingData, paymentDeliveryOption) => {
  if (!billingData?.paymentDeliveryOptionSpeed) {
    return undefined;
  }

  const speedDescription = getFormattedBillingCodeSpeedDescription(billingData);

  // If the paymentDeliveryOption is passed, we want to check if the delivery
  // option is SWIFT and, if so, display that the delivery option is SWIFT.
  // IMPORTANT NOTE: The delivery option really isn't SWIFT, but the
  // billingData we have here is for the transaction moving money from the\
  // client's bank to the currency exchange wallet. But we want to display
  // "SWIFT" because the client doesn't need to know how we route money around
  // and really only cares about the delivery option for the transaction where
  // the currency exchange delivers the money to the vender's bank account.
  const deliveryOptionTitle = isPaymentDeliveryOptionInternationalSwift(paymentDeliveryOption)
    ? 'SWIFT'
    : getBillingCodePaymentDeliveryOptionText(billingData);

  return `${deliveryOptionTitle} (${speedDescription})`;
};

/**
 * Given a billing code multiplier, will return it in percentage format
 * ex.) formatMultiplierIntoPercent("0.02") -> "2%"
 * @param {string} multiplier
 * @returns {string}
 */
export const formatMultiplierIntoPercent = (multiplier) => {
  const multiplierVal = parseFloat(multiplier) || 0;
  const multiplierAsPercent = multiplierVal * 100;

  return `${multiplierAsPercent}%`;
};

/**
 * Returns a description of the rate or multiplier + max amount for a billing code.
 * @param {BillingCodeData} optionBillingData
 * @return {string}
 */
export const getFormattedDeliveryOptionRateForTableDisplay = (optionBillingData) => {
  if (!optionBillingData) {
    return undefined;
  }

  const { code, multiplier, rate } = optionBillingData;

  const rateVal = parseFloat(rate) || 0;

  if (code === APBillingCodes[PaymentDeliveryOptionType.INTERNATIONAL_ACH]) {
    return `${formatCurrencyUSD(rateVal)} for payments under $500; $0 otherwise`;
  }

  if (isBillingCodeSwiftOur(code) || isBillingCodeSwiftSha(code)) {
    return `Starting at ${formatCurrencyUSD(rateVal)}, depending on the country`;
  }

  if (rateVal > 0) {
    return formatCurrencyUSD(rateVal);
  }

  return formatMultiplierIntoPercent(multiplier);
};

/**
 * Returns a description of the rate or multiplier + max amount for a billing code.
 * @param {BillingCodeData} optionBillingData
 * @return {string}
 */
export const getFormattedDeliveryOptionRateDescription = (optionBillingData) => {
  if (!optionBillingData) {
    return undefined;
  }

  const { multiplier, paymentDeliveryMethod, rate } = optionBillingData;

  const rateVal = parseFloat(rate) || 0;

  const transferType = ternary(isEqual(paymentDeliveryMethod, PaymentDeliveryMethodType.CHECK), 'check', 'transfer');

  if (rateVal > 0) {
    return `${formatCurrencyUSD(rateVal)} per ${transferType}`;
  }

  return `${formatMultiplierIntoPercent(multiplier)} of transfer`;
};

/**
 * Returns formmated min and max amount when available for a billing code
 * @param {string} minAmount
 * @param {string} maxAmount
 * @returns {string}
 */
export const getMinMaxAmount = (minAmount, maxAmount) => {
  if (!parseFloat(minAmount) && !parseFloat(maxAmount)) {
    return '';
  }

  return `Min ${parseCurrency(minAmount)} - Max ${parseCurrency(maxAmount)}`;
};
