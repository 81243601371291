import { TruncatableText } from '@routable/components';
import classNames from 'classnames';
import React from 'react';
import { useMedia } from 'react-use';
import { ButtonV2, Col, FullPageModal, Modal, ModalBodyContent, ModalFooter, Row, SearchBar, Text } from 'components';
import { ButtonSize } from 'constants/button';
import { mobileMediaQuery } from 'constants/mediaQuery';
import { TextColor, TextSize } from 'constants/styles/typography';
import { Intent, ZIndexLayers } from 'constants/ui';
import { ternary } from 'helpers/utility';
import { getZIndex } from 'helpers/zIndex';
import {} from 'types/quickswitch';
import './QuickswitchModal.scss';
import { generateModalStyleOverride } from './helpers';
import QuickswitchModalContent, {} from './QuickswitchModalContent';
const QuickswitchModal = (props) => {
    const { searchTerm, setSearchTerm, loadingMembershipsList, memberships, currentCompanyName, fetchMembershipSearch, executeSwitchMembership, closeModal, isModalOpen, showSearchBar, } = props;
    const searchBar = showSearchBar && (React.createElement(Row, null,
        React.createElement(Col, { className: "quickswitch-modal--search-box-row" },
            React.createElement(SearchBar, { onSearchExecuted: fetchMembershipSearch, onTermChange: setSearchTerm, placeholderText: "Search by company name or URL", searchTerm: searchTerm }))));
    const isMobile = useMedia(mobileMediaQuery);
    const modalStyle = generateModalStyleOverride(showSearchBar);
    const body = (React.createElement(React.Fragment, null,
        React.createElement(ModalBodyContent, { className: "quickswitch-modal--content" },
            React.createElement("div", { className: "quickswitch-modal--sticky" },
                React.createElement(Row, { className: classNames('quickswitch-modal--header', {
                        'quickswitch-modal--header--extra-bottom-padding': !showSearchBar,
                    }) },
                    React.createElement(Col, null,
                        React.createElement(Row, { className: "padding-bottom--m" },
                            React.createElement(Col, null,
                                React.createElement(Text.ExtraBold, { color: TextColor.BLACK, size: TextSize.LEVEL_400 }, "Switch to another Routable account."))),
                        React.createElement(Row, null,
                            React.createElement(Col, null,
                                React.createElement("div", { className: "flex flex-nowrap items-center overflow-hidden gap-2 w-full" },
                                    React.createElement(Text.Semibold, { className: "whitespace-nowrap overflow-hidden", color: TextColor.GREY_XX_DARK, size: TextSize.LEVEL_200 }, `You are currently logged into `),
                                    React.createElement(TruncatableText, { className: "font-sm font-bold text-grey-70 w-[80px] md:w-[135px] lg:w-[400px]", position: "top", text: currentCompanyName })))))),
                searchBar),
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement(QuickswitchModalContent, { executeSwitchMembership: executeSwitchMembership, loadingMembershipsList: loadingMembershipsList, memberships: memberships, onUndoSearchClick: () => setSearchTerm('') })))),
        React.createElement(ModalFooter, { className: "quickswitch-modal--footer" },
            React.createElement("div", { className: "modal-footer--button margin-left--auto" },
                React.createElement(ButtonV2, { intent: Intent.NEUTRAL, onClick: closeModal, size: ButtonSize.MEDIUM }, "Cancel")))));
    return ternary(isMobile, React.createElement(FullPageModal, { body: body, hasInlineFooter: false, modalClassname: "quickswitch-modal", modalState: isModalOpen, onCloseModal: closeModal, overrideStyle: {
            ...modalStyle,
            ...{ overlay: { zIndex: getZIndex(ZIndexLayers.MODAL.OVERLAY, 25) } },
        }, showCloseButton: false }), React.createElement(Modal, { body: body, hasInlineFooter: true, hideHeader: true, modalState: isModalOpen, onCloseModal: closeModal, overrideStyle: modalStyle }));
};
export default QuickswitchModal;
