import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import { submitCreatePartnershipRoutine, updatePartnershipGeneralInfoRoutine } from 'actions/routines/partnership';
import {
  createPartnershipMemberRoutine,
  fetchPartnershipMembersRoutine,
  fetchSinglePartnershipMemberRoutine,
  updatePartnershipMemberRoutine,
} from 'actions/routines/partnershipMember';

import { derivePartnershipMemberStatusFromAttributes } from 'helpers/partnershipMembers';
import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import { FETCH_ITEM_SUCCESS, UPDATE_EXISTING_ITEMS_SUCCESS } from 'types/item';
import { SUBMIT_MESSAGE_SUCCESS } from 'types/messages';
import { FETCH_PARTNERSHIP_SUCCESS, FETCH_PARTNERSHIPS_SUCCESS } from 'types/partnership';

const options = {
  getAttributes: (currentState, objects, objectId) => ({
    status: derivePartnershipMemberStatusFromAttributes(objects[objectId]),
  }),
};

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case createPartnershipMemberRoutine.SUCCESS:
    case FETCH_ITEM_SUCCESS:
    case FETCH_PARTNERSHIP_SUCCESS:
    case FETCH_PARTNERSHIPS_SUCCESS:
    case fetchPartnershipMembersRoutine.SUCCESS:
    case fetchSinglePartnershipMemberRoutine.SUCCESS:
    case submitCreatePartnershipRoutine.SUCCESS:
    case SUBMIT_MESSAGE_SUCCESS:
    case UPDATE_EXISTING_ITEMS_SUCCESS:
    case updatePartnershipMemberRoutine.SUCCESS:
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case updatePartnershipGeneralInfoRoutine.SUCCESS:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.partnershipMember, ['company', 'membership'], options),
      );

    default:
      return state;
  }
};

export default byIdReducer;
