import { ExternalPaymentMethodHintLabel } from 'constants/external';

import { isItemKindPayable } from 'helpers/items';
import { ternary } from 'helpers/utility';

/**
 * Returns item payment method hint label text based on the passed item kind.
 * @param {ItemKind} itemKind
 * @returns {string}
 */
export const getItemPaymentMethodHintLabelText = (itemKind) =>
  ternary(
    isItemKindPayable({ kind: itemKind }),
    ExternalPaymentMethodHintLabel.ITEM_PAYABLE,
    ExternalPaymentMethodHintLabel.ITEM_RECEIVABLE,
  );
