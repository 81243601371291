import classNames from 'classnames';
import React from 'react';
import { Icon, IconNames } from 'components/icon';
import InputFieldWrapper from 'components/input/InputFieldWrapper';
import { Text } from 'components/text';
import { paginationDataTestId } from 'constants/dataTestId';
import { colors, sizes } from 'constants/styles';
import { isLessOrEqual, ternary } from 'helpers/utility';
import { useQuickJumperAddonClick, useQuickJumperInputChange, useQuickJumperKeyPress } from '../../hooks';
import './PageQuickJumper.scss';
import {} from './PageQuickJumper.types';
export const PageQuickJumper = (props) => {
    const { pageCount, pageIndex } = props;
    const isDisabled = isLessOrEqual(pageCount, 1);
    const [pageInput, setPageInput] = React.useState(pageIndex);
    const [isFocused, setIsFocused] = React.useState(false);
    React.useEffect(() => {
        setPageInput(pageIndex);
    }, [pageIndex]);
    const handleChange = useQuickJumperInputChange({ setPageInput });
    const handleAddonClick = useQuickJumperAddonClick({
        ...props,
        isDisabled,
        pageInput,
        setPageInput,
    });
    const handleKeyPress = useQuickJumperKeyPress({
        ...props,
        isDisabled,
        pageInput,
        setPageInput,
    });
    const handleToggleFocus = React.useCallback(() => {
        setIsFocused(!isFocused);
    }, [isFocused]);
    return (React.createElement("div", { className: "tlp--quick-jumper" },
        React.createElement(Text.Regular, { className: "quick-jumper--text", color: ternary(isFocused, colors.colorBlueBoldHex, colors.colorGreyXDarkHex) }, "Go to page"),
        React.createElement(InputFieldWrapper, { addon: React.createElement(Icon, { className: classNames({
                    'cursor--pointer': !isDisabled,
                }), color: ternary(isDisabled, colors.colorGreyMedHex, colors.colorGreyDarkHex), "data-testid": paginationDataTestId.paginationComponent.GO_TO_PAGE_ACTION, icon: IconNames.ARROW_RIGHT, onClick: handleAddonClick, size: sizes.iconSizes.LARGE, type: "button" }), className: "h-m", dataTestId: paginationDataTestId.paginationComponent.GO_TO_PAGE_INPUT, isDisabled: isDisabled, name: "goToPage", onBlur: handleToggleFocus, onChange: handleChange, onFocus: handleToggleFocus, onKeyPress: handleKeyPress, value: pageInput }, ` `)));
};
export default PageQuickJumper;
