import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';

import { AddressTypes } from 'constants/address';

import { getCurrentCompanyId } from 'helpers/localStorage';

import { currentMembershipPersonalInfoSelector } from 'queries/membershipPersonalInfoCompoundSelectors';

import {
  currentCompanySelector,
  currentCompanyAddressSelector,
  currentCompanyInfoSelector,
} from 'selectors/currentCompanySelectors';
import { currentUserSelector } from 'selectors/currentUserSelectors';
import { companyDocumentsSelector, currentMembershipDocumentsSelector } from 'selectors/documentsSelectors';
import { currentMembershipAddressSelector, currentMembershipSelector } from 'selectors/membershipsSelector';

import submitCompanyReps from 'thunks/submitCompanyReps';
import updateCurrentCompany from 'thunks/updateCurrentCompany';
import updateMembership from 'thunks/updateMembership';

import { signupFlowForm } from './constants';
import { handleAsyncValidate } from './helpers/forms';
import { getBusinessLegalInfoInitialState, getRepresentativeInitialState } from './helpers/initialValues';
import submit from './helpers/submit';
import SignUpFlowForm from './SignUpFlowForm';

const mapStateToProps = (state) => ({
  initialValues: {
    ...getRepresentativeInitialState({
      currentMembership: currentMembershipSelector(state),
      currentMembershipAddress: currentMembershipAddressSelector(state),
      currentMembershipPersonalInfo: currentMembershipPersonalInfoSelector(state),
      documentsOwned: currentMembershipDocumentsSelector(state),
      currentUser: currentUserSelector(state),
    }),
    ...getBusinessLegalInfoInitialState({
      currentCompany: currentCompanySelector(state),
      currentCompanyInfo: currentCompanyInfoSelector(state),
      physicalAddress: currentCompanyAddressSelector(state, AddressTypes.PHYSICAL_ADDRESS),
      mailingAddress: currentCompanyAddressSelector(state, AddressTypes.MAILING_ADDRESS),
      documents: companyDocumentsSelector(state, getCurrentCompanyId()),
    }),
  },
});

const mapDispatchToProps = {
  // Used in the submit method
  onUpdateCurrentCompany: updateCurrentCompany,
  onSubmitRepresentativeForm: updateMembership,
  onSubmitCurrentCompanyReps: submitCompanyReps,
};

const reduxFormSignUpFlowForm = reduxForm({
  form: signupFlowForm,
  onSubmit: submit,
  enableReinitialize: true,
  asyncValidate: handleAsyncValidate,
})(SignUpFlowForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxFormSignUpFlowForm));
