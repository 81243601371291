import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTaxFormOptions } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.context';
import { taxFormEntryType, w8LimitationOnBenefits, } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.models';
import { isCompanyTypeBusiness } from 'helpers/currentCompany';
import { getLabelValuePairsFromEnum } from '../helpers';
import RenderCheckbox from '../RenderCheckbox';
import RenderSelect from '../RenderSelect';
import RenderTextInput from '../RenderTextInput';
import { Group, GroupSlot } from '../styles';
const FieldGroupTaxTreatyBenefits = () => {
    const { watch } = useFormContext();
    const { entryType } = useTaxFormOptions();
    const claimTaxTreatyBenefits = watch('claimingTreatyBenefits');
    const companyType = watch('companyType');
    const isBusiness = isCompanyTypeBusiness(companyType);
    return (React.createElement(Group, null,
        React.createElement(GroupSlot, { size: 6 },
            React.createElement(RenderCheckbox, { label: `${entryType === taxFormEntryType.Enum.external ? 'I claim' : 'The vendor claims'} treaty benefits under an income tax treaty between the United States and the country of residence.`, name: "claimingTreatyBenefits" })),
        claimTaxTreatyBenefits && (React.createElement(React.Fragment, null,
            isBusiness && (React.createElement(GroupSlot, { size: 6 },
                React.createElement(RenderSelect, { isRequired: false, label: "Limitation on benefits provision", name: "treatyBenefitsLimitationOnBenefits", options: getLabelValuePairsFromEnum(w8LimitationOnBenefits), placeholder: "Select limitation type" }))),
            React.createElement(GroupSlot, { size: 2 },
                React.createElement(RenderTextInput, { name: "treatyBenefitsArticleAndParagraph", placeholder: "Article & Paragraph", shouldUnregister: true })),
            React.createElement(GroupSlot, { size: 2 },
                React.createElement(RenderTextInput, { name: "treatyBenefitsWithholdingRate", placeholder: "Withholding Rate", shouldUnregister: true })),
            React.createElement(GroupSlot, { size: 2 },
                React.createElement(RenderTextInput, { name: "treatyBenefitsTypeOfIncome", placeholder: "Type of Income", shouldUnregister: true }))))));
};
export default FieldGroupTaxTreatyBenefits;
