import { BulkImportItemsStep } from 'constants/bulkActions';
import { ItemKinds } from 'constants/item';
import { DateScheduledTypes } from 'constants/temporal';

import { isBulkActionEligibleForChangingSendDate } from 'helpers/bulkActions';
import { getCurrentDateLocal } from 'helpers/date';
import { ternary } from 'helpers/utility';

/**
 * Returns an object to use as the bulkImport form's initialValues prop.
 * @return {ReduxFormCommonInitialValuesShape}
 */
export const getBulkImportItemsFormInitialValues = () => ({
  form: {
    approvers: null,
    id: undefined,
    file: undefined,
    kind: ItemKinds.PAYABLE,
  },
  meta: {},
  ui: {
    isFileProcessing: false,
    maxStep: BulkImportItemsStep.upload,
    showConfirm: false,
    status: null,
    step: BulkImportItemsStep.upload,
  },
});

/**
 * Returns an object to use as the bulkActions form's initialValues prop.
 * @param {BulkActionTypes} bulkAction
 * @return {ReduxFormCommonInitialValuesShape}
 */
export const getBulkActionsSummaryFormInitialValues = (bulkAction) => {
  const dateScheduled = ternary(isBulkActionEligibleForChangingSendDate(bulkAction), getCurrentDateLocal(), null);

  return {
    item: {
      dateScheduled,
    },
    ui: {
      dateScheduledType: DateScheduledTypes.TODAY,
      hasSubmitted: false,
      showConfirm: false,
    },
  };
};
