import React from 'react';
import { DropdownMenuOption } from 'complexComponents/DropdownMenuButton';
import { NumberChip } from 'components/index';
import { isGreaterOrEqual } from 'helpers/utility';
const TableToolbarSelectionManagerOption = ({ actionableCount, isDisabled, isDisabledWhenNoActionable, title, ...rest }) => {
    const hasActionableCount = isGreaterOrEqual(actionableCount, 1);
    return (React.createElement(DropdownMenuOption, { ...rest, className: "table-toolbar--dd-option", isDisabled: isDisabled || (isDisabledWhenNoActionable && !hasActionableCount), title: React.createElement("span", null, title) }, hasActionableCount && React.createElement(NumberChip, { className: "action-count" }, actionableCount)));
};
TableToolbarSelectionManagerOption.defaultProps = {
    actionableCount: undefined,
    className: undefined,
    children: undefined,
    icon: undefined,
    isDisabled: undefined,
    isDisabledWhenNoActionable: true,
};
export default TableToolbarSelectionManagerOption;
