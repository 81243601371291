import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';

import { Intent } from 'constants/ui';

import { ternary } from 'helpers/utility';

import { useClientRect } from 'hooks';

import LoadMoreChild from './LoadMoreChild';

/*
  A note about margins...

  This LoadMore component takes a list of children elements and decides whether:
  - the child should be rendered
  - or a button should be shown instead to hide a set of rows from view

  To figure this out, children elements need to calculate where they are based on:
  - their own rect values
  - the rect values of the LoadMore container
  - and choose whether they are part or an allowed `visibleRows` or should be hidden

  The browser's rect object doesn't include margin values which may affect where are
  on the Y-axis. As a result, each child also needs to figure out its margin values to calculate its display status.

  To keep this simple, this component currently depends on 2 rules:
  - the top/bottom margins separating each child needs to be the same
  - those margins need to be defined directly on the child element given to LoadMoreChild
   and not on any element further down that child tree of elements:

   <LoadMoreChild>
     {child} // child.marginTop && child.marginBottom should defined on this child
   </LoadMoreChild>
 */

/**
 * LoadMore
 * Displays the full list of given children
 * or a subset of the list and a "Load More" button to expand
 * @return {FunctionComponent}
 * @constructor
 */
const LoadMore = ({
  children,
  visibleRows,
  isExpanded,
  onShowLoadMore,
  onClickLoadMore,
  buttonIsVisible,
  reRenderCount,
}) => {
  // we could define this in the Container but then we'd have to use forwardRef
  // which complicates propTypes. I kept it here as the ref is just used by this component
  const { rect, ref } = useClientRect();

  const wrappedChildren = React.Children.map(children, (child) => {
    // adding the reRenderCount to the key prop will force LoadMoreChild to re-render
    // when the count changes
    const key = `${child.id}-${reRenderCount}`;

    return (
      <LoadMoreChild key={key} onShowLoadMore={onShowLoadMore} visibleRows={visibleRows} wrapperRectTop={rect.top}>
        {child}
      </LoadMoreChild>
    );
  });

  const childrenToDisplay = ternary(isExpanded, children, wrappedChildren);

  return (
    <div ref={ref}>
      {childrenToDisplay}
      {buttonIsVisible && (
        <ButtonV2 className="margin-top--sm" intent={Intent.NEUTRAL} onClick={onClickLoadMore}>
          Load more
        </ButtonV2>
      )}
    </div>
  );
};

LoadMore.propTypes = {
  children: PropTypes.node.isRequired,
  visibleRows: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onShowLoadMore: PropTypes.func.isRequired,
  onClickLoadMore: PropTypes.func.isRequired,
  buttonIsVisible: PropTypes.bool.isRequired,
  reRenderCount: PropTypes.number.isRequired,
};

export default LoadMore;
