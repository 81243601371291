import React from 'react';
import { Field } from 'redux-form';

import { InternationalPhoneInput } from 'components';

import * as propTypes from 'constants/propTypes';

import { useGetTableFieldProps } from 'modules/table/hooks';

/**
 * Renders a text input field to be rendered in a block section
 * @param {ComponentProps} props
 * @param {Object} props.formValues
 * @param {Function} props.getIsDisabled
 * @param {Function} props.getIsRequired
 * @param {string} props.name
 * @param {number} props.maxLength
 * @param {Function} props.validate
 * @returns {StatelessComponent}
 */
const PhoneTableField = ({ getIsDisabled, getIsRequired, validate, formValues, sectionPath, rowIdx, name }) => {
  const fieldPath = `${sectionPath}.${rowIdx}`;
  const { isDisabled, isRequired, validators } = useGetTableFieldProps({
    formValues,
    getIsDisabled,
    getIsRequired,
    fieldPath,
    rowIdx,
    validate,
  });

  return (
    <Field
      className="ledger-form-field"
      component={InternationalPhoneInput}
      debounceDelay={200}
      isDebounced
      isDisabled={isDisabled}
      isRequired={isRequired}
      name={name}
      validate={validators}
    />
  );
};

PhoneTableField.propTypes = propTypes.tableFieldSharedPropTypes;

PhoneTableField.defaultProps = propTypes.tableFieldSharedPropTypes;

export default React.memo(PhoneTableField);
