import { SelectPlaceholderText } from 'constants/ui';

import { ternary } from 'helpers/utility';

/**
 * If creating a contact is enabled, provide label text that says you can search or create a new contact. Otherwise,
 * only provide language that you can search for contacts.
 * @function
 * @param {boolean} isContactCreatable
 * @returns {SelectPlaceholderText}
 */
export const getAddContactPopoverSelectLabelText = (isContactCreatable) =>
  ternary(
    isContactCreatable,
    SelectPlaceholderText.SEARCH_OR_ADD_NEW_CONTACT,
    SelectPlaceholderText.SEARCH_FOR_CONTACTS,
  );
