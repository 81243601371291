export const views = {
    businessVerification: 'businessVerification',
    legalBusinessName: 'legalBusinessName',
    EIN: 'EIN',
    businessCategory: 'businessCategory',
    businessType: 'businessType',
    uploadDocument: 'uploadDocument',
    businessAddress: 'businessAddress',
    businessPhoneNumber: 'businessPhoneNumber',
    businessRepresentative: 'businessRepresentative',
    representativeIDNumber: 'representativeIDNumber',
    representativeAddress: 'representativeAddress',
    qualification: 'qualification',
    personalInformation: 'personalInformation',
    representativeUploadDocument: 'representativeUploadDocument',
    review: 'review',
    processing: 'processing',
};
export const businessVerificationViews = [
    views.businessVerification,
    views.legalBusinessName,
    views.businessType,
    views.EIN,
    views.businessCategory,
    views.businessAddress,
    views.businessPhoneNumber,
    views.uploadDocument,
];
export const representativeVerificationViews = [
    views.businessRepresentative,
    views.qualification,
    views.personalInformation,
    views.representativeAddress,
    views.representativeIDNumber,
    views.representativeUploadDocument,
];
export const signupFlowForm = 'signupFlow';
export const inviteTeamMemberForm = 'inviteTeamMember';
export const SAVE_STATUSES = {
    saved: 'saved',
    saving: 'saving',
    notSaved: 'notSaved',
};
