import PropTypes from 'prop-types';

import { fieldValidatorPropType } from 'helpers/propTypes';

/**
 * These prop types are common to all components used as field component.
 * @type {Object<string, *>}
 */
export const baseFieldSharedPropTypes = {
  formValues: PropTypes.shape().isRequired,
  getIsDisabled: PropTypes.func.isRequired,
  getIsRequired: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  validate: fieldValidatorPropType,
};

/**
 * These default props are common to all components used as field component.
 * @type {Object<string, *>}
 */
export const baseFieldSharedDefaultProps = {
  validate: undefined,
};

/**
 * These prop types are common to all components used as field component within a table section.
 * @type {Object<string, *>}
 */
export const tableFieldSharedPropTypes = {
  ...baseFieldSharedPropTypes,
  initialValue: PropTypes.oneOfType([PropTypes.any, PropTypes.exact(null)]),
  setIsDisabled: PropTypes.func.isRequired,
};

/**
 * These default props are common to all components used as field component within a table section.
 * @type {Object<string, *>}
 */
export const tableFieldSharedDefaultProps = {
  ...baseFieldSharedDefaultProps,
  initialValue: undefined,
};

/**
 * These prop types are common to the lookup field component within a table section.
 * @type {Object<string, *>}
 */
export const tableLookupFieldPropTypes = {
  ...tableFieldSharedPropTypes,
  lookupOptionLabelKey: PropTypes.string.isRequired,
  lookupOptionValueKey: PropTypes.string.isRequired,
};

/**
 * These default props are common to the lookup field component within a table section.
 * @type {Object<string, *>}
 */
export const tableLookupFieldDefaultProps = {
  ...tableFieldSharedDefaultProps,
};

/**
 * These prop types are common to any numeric field component within a table section.
 * @type {Object<string, *>}
 */
export const tableAnyNumericFieldPropTypes = {
  ...tableFieldSharedPropTypes,
  decimalScale: PropTypes.number.isRequired,
  fixedDecimalScale: PropTypes.bool,
  minDecimalScale: PropTypes.number.isRequired,
};

/**
 * These default props are common to any numeric field component within a table section.
 * @type {Object<string, *>}
 */
export const tableAnyNumericFieldDefaultProps = {
  ...tableFieldSharedDefaultProps,
  fixedDecimalScale: undefined,
};

/**
 * These prop types are common to any text-like field component within a table section.
 * (e.g. text, textarea, date, static)
 * @type {Object<string, *>}
 */
export const tableTextFieldPropTypes = {
  ...tableFieldSharedPropTypes,
  placeholder: PropTypes.string.isRequired,
};

/**
 * These default props are common to any text-like field component within a table section.
 * (e.g. text, textarea, date, static)
 * @type {Object<string, *>}
 */
export const tableTextFieldDefaultProps = {
  ...tableFieldSharedDefaultProps,
};
