import React from 'react';
import { IconNames, Icon } from 'components/icon';
import InputFieldWrapper from 'components/input';
import { colors, sizes } from 'constants/styles';
import { ternary } from 'helpers/utility';
import './SearchBar.scss';
const SearchBar = ({ placeholderText, onTermChange, onSearchExecuted, searchTerm }) => {
    const handleClearSearch = () => {
        onTermChange('');
    };
    const handleOnChange = (e) => {
        onTermChange(e.target.value);
    };
    const handleSubmitSearch = (e) => {
        e.preventDefault();
        onSearchExecuted(searchTerm);
    };
    const inputAddon = ternary(searchTerm, React.createElement("span", { className: "icon-ic-x-out general-cursor-hover search-x-icon", onClick: handleClearSearch, role: "presentation" }), React.createElement(Icon, { color: colors.colorMainJordanHex, icon: IconNames.SEARCH, size: sizes.iconSizes.MEDIUM }));
    return (React.createElement("form", { className: "form margin-left--auto", id: "company-match-form", onSubmit: handleSubmitSearch },
        React.createElement(InputFieldWrapper, { addon: inputAddon, children: undefined, className: "h-m", isRequired: false, name: "search", onChange: handleOnChange, placeholder: placeholderText, type: "text", value: searchTerm })));
};
export default SearchBar;
