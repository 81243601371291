import React from 'react';
import { ItemWithoutContactsHint } from 'components/hintTypes';
import { getNextPartnerMembersForGroupedContactSelects, shouldDisplayOptionInContactSelectMenu, } from 'components/selectTypes/helpers';
import { useGroupedContactSelectsData } from 'components/selectTypes/hooks';
import { partnershipMemberSelectContactText, partnershipMemberSelectReadOnlyText } from 'components/selectTypes/text';
import { createItemFormFields } from 'constants/formFields';
import { PartnershipMemberAccess } from 'constants/partnershipMember';
import { PartnershipMemberProps } from 'data/resources/member';
import { onBlurForGroupedContactSelects } from 'helpers/selects';
import { capitalize } from 'helpers/stringHelpers';
import { getClassNames } from 'helpers/ui';
import { hasLength } from 'helpers/utility';
import { SelectOption } from '../components';
import TagMultiSelect from '../TagMultiSelect';
const GroupedContactSelects = (props) => {
    const { accessProperty = PartnershipMemberProps.defaultGeneral, className, components = {}, errors, input, isVendorContactOptional, members, onCreate, valueKey = 'data', ...rest } = props;
    const { name, onChange } = input;
    const { actionMembers, options, readOnlyMembers } = useGroupedContactSelectsData(props);
    const handleSelectionsChanged = React.useCallback((params) => {
        const { action } = params;
        const nextValue = getNextPartnerMembersForGroupedContactSelects(accessProperty, params);
        onChange(nextValue);
        if (action === 'remove-value') {
            requestAnimationFrame(() => {
                if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                }
            });
        }
    }, [accessProperty, onChange]);
    const selectConfigs = [
        {
            access: PartnershipMemberAccess.ACTIONABLE,
            secondaryMembers: readOnlyMembers,
            targetMembers: actionMembers,
            text: partnershipMemberSelectContactText,
        },
        {
            access: PartnershipMemberAccess.READ_ONLY,
            secondaryMembers: actionMembers,
            targetMembers: readOnlyMembers,
            text: partnershipMemberSelectReadOnlyText,
        },
    ];
    const isOnCreateItemForm = name === createItemFormFields.ITEM_MEMBERS;
    const selectedMembers = actionMembers.concat(readOnlyMembers);
    const hasValue = hasLength(selectedMembers);
    const isRequired = !isVendorContactOptional && !hasValue;
    const showItemWithoutContactsHint = !hasValue && isVendorContactOptional && isOnCreateItemForm;
    return (React.createElement("div", { className: getClassNames(props, {
            'grouped-selects': true,
            'grouped-selects--error': !!errors,
        }) },
        selectConfigs.map((config, index) => {
            const value = hasLength(config.targetMembers) ? config.targetMembers : null;
            const isLast = index === selectConfigs.length - 1;
            return (React.createElement(TagMultiSelect, { ...rest, components: {
                    ...components,
                    Option: SelectOption,
                }, dataFullStory: true, filterOption: shouldDisplayOptionInContactSelectMenu, hideErrors: !isLast, idPrefix: config.access, input: {
                    ...input,
                    name: name.concat(capitalize(config.access)),
                    onBlur: () => onBlurForGroupedContactSelects(input, config),
                    onChange: (selectValue, { action }) => {
                        handleSelectionsChanged({
                            access: config.access,
                            action,
                            currentSecondaryMembers: config.secondaryMembers,
                            currentTargetMembers: config.targetMembers,
                            selectValue,
                        });
                    },
                    value,
                }, isRequired: isRequired, key: config.access, label: config.text, onCreate: (inpt) => onCreate(inpt, config.access), options: options, placeholder: config.text, valueKey: valueKey }));
        }),
        showItemWithoutContactsHint && (React.createElement("div", { className: "margin-top--m" },
            React.createElement(ItemWithoutContactsHint, null)))));
};
export default GroupedContactSelects;
