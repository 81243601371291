import React from 'react';
import { Dialog, Item, Modal, Panel, Tabs } from '@routable/gross-ds';
import { ModalLookupTableWrapper } from './components/ModalLookupTableWrapper';
import { LookupBody, lookupWindowPanel } from './ModalLookupTable.styled';
import {} from './ModalLookupTable.types';
export const ModalLookupTable = ({ children, isDismissable = true, modalState, onClose, title, }) => {
    const shouldUseTabs = React.Children.toArray(children).length > 1;
    return (React.createElement(Modal, { isDismissable: isDismissable, state: modalState },
        React.createElement(Modal.Backdrop, { className: "bg-grey-70/30" }),
        React.createElement(Modal.Body, null,
            React.createElement(Panel, { className: lookupWindowPanel() },
                React.createElement(LookupBody, { hasTabs: shouldUseTabs },
                    React.createElement(Dialog.Header, { size: 'large', label: title, onClose: onClose }),
                    shouldUseTabs ? React.createElement(Tabs, { className: "mx-4" }, children) : React.createElement("div", { className: "px-1" }, children))))));
};
ModalLookupTable.Item = Item;
ModalLookupTable.TableWrapper = ModalLookupTableWrapper;
