import PropTypes from 'prop-types';
import React from 'react';
import NumberFormatInput from 'react-number-format';

import * as commonProps from './commonProps';

const NumericInput = ({
  allowNegative,
  autoFocus,
  className,
  errName,
  dataId,
  validator,
  decimalScale,
  isDisabled,
  displayType,
  fixedDecimalScale,
  format,
  setInputRef,
  id,
  isAllowed,
  isNumericString,
  max,
  maxLength,
  min,
  minLength,
  name,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onValue,
  pattern,
  placeholder,
  currency,
  required,
  step,
  style,
  suffix,
  thousandSeparator,
  type,
  value,
  defaultValue,
}) => (
  <NumberFormatInput
    allowNegative={allowNegative}
    autoFocus={autoFocus}
    className={className}
    data-errname={errName}
    data-id={dataId}
    data-validator={validator}
    decimalScale={decimalScale}
    disabled={isDisabled}
    displayType={displayType}
    fixedDecimalScale={fixedDecimalScale}
    format={format}
    getInputRef={setInputRef}
    id={id}
    isAllowed={isAllowed}
    isNumericString={isNumericString}
    max={max}
    maxLength={maxLength}
    min={min}
    minLength={minLength}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    onClick={onClick}
    onFocus={onFocus}
    onValueChange={onValue}
    pattern={pattern}
    placeholder={placeholder}
    prefix={currency}
    required={required}
    step={step}
    style={style}
    suffix={suffix}
    thousandSeparator={thousandSeparator}
    type={type}
    value={value || defaultValue}
  />
);

NumericInput.propTypes = {
  ...commonProps.propTypes,
  allowNegative: PropTypes.bool,
  autoFocus: PropTypes.bool,
  fieldErrors: PropTypes.shape({}),
  fixedDecimalScale: PropTypes.bool,
  isAllowed: PropTypes.bool,
  isNumericString: PropTypes.bool,
  onValue: PropTypes.func,
  required: PropTypes.bool,
  setInputRef: PropTypes.func.isRequired,
};

NumericInput.defaultProps = {
  ...commonProps.defaultProps,
  // NumberFormatInput's default value for allowNegative is true,
  // so we need this false override
  allowNegative: false,
  autoFocus: undefined,
  fieldErrors: undefined,
  fixedDecimalScale: true,
  isAllowed: undefined,
  isNumericString: undefined,
  onValue: null,
  required: undefined,
};

export default NumericInput;
