import { createItemFormFields, formNamesItem } from '@routable/shared';
import React from 'react';
import { useDispatch } from 'react-redux';
import { change, Field, touch } from 'redux-form';
import { SelectFieldV2 } from 'components/selectV2';
import { usePartnershipPaymentOptions, usePrevious } from 'hooks';
import { isCreateItemVendorReceivingCurrencyEnabled } from 'modules/dashboard/createItems/helpers/allTheCurrenciesLogic';
import CurrencyCodeBlockFieldTooltipText from './components/CurrencyCodeBlockFieldTooltipText';
import {} from './CurrencyCodeBlockField.types';
const customValidation = (validCurrencies) => (value) => {
    if (value && validCurrencies && validCurrencies.indexOf(value) === -1) {
        return [`${value} is not supported for this company`];
    }
    return undefined;
};
const CurrencyCodeBlockField = ({ companyIntegrationSettings, formValues, getIsDisabled, ledger, partnership, ...rest }) => {
    const dispatch = useDispatch();
    const billCurrency = formValues?.main?.general?.bill_currency;
    const annotationBillCurrency = formValues?.main?.general?.annotation_bill_currency;
    const { currencyCodesBillOptions, isFetching, validCurrencies } = usePartnershipPaymentOptions({
        partnershipId: partnership?.id,
        addCurrency: annotationBillCurrency,
    });
    const prevIsFetching = usePrevious(isFetching);
    const onlyOneOptionAvailable = currencyCodesBillOptions?.length === 1;
    const isDisabled = getIsDisabled.getIsDisabled(formValues);
    const validation = React.useMemo(() => [customValidation(validCurrencies)], [validCurrencies]);
    React.useEffect(() => {
        if (prevIsFetching && !isFetching) {
            dispatch(touch(formNamesItem.CREATE_ITEM, rest.name));
            if (annotationBillCurrency && isCreateItemVendorReceivingCurrencyEnabled()) {
                dispatch(change(formNamesItem.CREATE_ITEM, createItemFormFields.ITEM_CURRENCY_CODE_RECEIVER, billCurrency));
            }
        }
    }, [dispatch, prevIsFetching, isFetching, rest.name, billCurrency, annotationBillCurrency]);
    return (React.createElement(Field, { component: SelectFieldV2, options: currencyCodesBillOptions, ...rest, isDisabled: isDisabled || isFetching || onlyOneOptionAvailable, isLoading: isFetching, isLocked: onlyOneOptionAvailable, lockedTooltipProps: {
            title: (React.createElement(CurrencyCodeBlockFieldTooltipText, { companyIntegrationSettings: companyIntegrationSettings, countryCodePartner: partnership?.countryCodePartner, currencyCodesBillOptions: currencyCodesBillOptions, ledger: ledger, onlyOneOptionAvailable: onlyOneOptionAvailable })),
        }, validate: validation }));
};
export default React.memo(CurrencyCodeBlockField);
