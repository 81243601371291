import * as types from 'types/partnership';

const lastFetchedReducer = (state = null, action) => {
  switch (action.type) {
    case types.FETCH_PARTNERSHIP_SUCCESS:
    case types.FETCH_PARTNERSHIPS_SUCCESS:
      return new Date();

    default:
      return state;
  }
};

export default lastFetchedReducer;
