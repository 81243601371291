import PropTypes from 'prop-types';
import React from 'react';

/**
 * Component rendering anchor element with href which opens a link in a new page (new tab).
 * @deprecated Use ExternalLink instead
 * @param {ComponentProps} props
 * @param {Node} props.children
 * @param {string} [props.className]
 * @param {string} props.href
 * @param {boolean} props.dataFullStory
 * @param {RestOfProps} rest
 * @returns {StatelessComponent}
 */
const LinkNewPage = ({ children, className, dataFullStory, href, ...rest }) => (
  <a
    className={className}
    data-fullstory={dataFullStory}
    href={href}
    rel="noopener noreferrer"
    target="_blank"
    {...rest}
  >
    {children}
  </a>
);

LinkNewPage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
};

LinkNewPage.defaultProps = {
  className: undefined,
};

export default LinkNewPage;
