import accounting from 'accounting';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import { DefaultCurrencyDigitPrecision } from 'constants/currency';
import { RoundingStyle } from 'constants/numeric';

import { digitsAndLetters, digitsOnly } from 'helpers/fieldNormalizers';
import { isEqual } from 'helpers/utility';

/**
 * WARNING: This method will be deprecated.
 * In order to better support international payments this method will be phased out.
 * [DEV-1597] Replace parseCurrency usage
 *
 * Parse a number to currency format
 * @param {number|string} amount
 * @param {Object} options - Formatting options
 * @return {string}
 */
export const parseCurrency = (amount, options = {}) => {
  const { format = {}, precision = DefaultCurrencyDigitPrecision, separator = '.', symbol = '$' } = options;

  return accounting.formatMoney(amount, {
    format,
    precision,
    separator,
    symbol,
  });
};

/**
 * Format a string according to a specific format
 * @param str
 * @param format (where # are replaced by characters in the string, all others are modifiers)
 * @return {*}
 */
export function formatWithPattern(str, format) {
  if (!format) {
    return str;
  }

  let formattedString = '';

  let j = 0;
  for (let i = 0; i < str.length; i += 1) {
    while (format[j] && format[j] !== '#') {
      formattedString += `${format[j]}`;
      j += 1;
    }

    if (!format[j]) {
      break;
    }

    formattedString += str[i];
    j += 1;
  }

  // remove leading dashes
  while (formattedString.substr(0, 1) === '-') {
    formattedString = formattedString.substr(1);
  }

  // remove trailing dashes
  while (formattedString.substr(-1) === '-') {
    formattedString = formattedString.slice(0, -1);
  }

  return formattedString;
}

/**
 * Parse a number to EIN format
 * @param num {number|string} Value to parse (as number or string)
 * @return {string} Formatted string value
 */
export const formatAsEIN = (num) => formatWithPattern(digitsOnly(String(num)), '##-#######');

/**
 * Parse a number to GIIN format (https://www.irs.gov/pub/irs-utl/giin_composition.pdf)
 * @param num {number|string} Value to parse (as number or string)
 * @return {string} Formatted string value
 */
export const formatAsGIIN = (num) => {
  if (!num) {
    return '';
  }

  return formatWithPattern(digitsAndLetters(String(num)), '######.#####.##.###');
};

/**
 * Parse a phone number object to local phone number format with helper from our international phone input library.
 * Only use when you know you have a phone number. Not for use as a formatter for a Field; just for display purposes,
 * as in PhoneListPhoneNumber.
 * @param {PhoneNumber} phoneNumber
 * @return {string} Formatted string value
 */
export const formatAsInternationalPhoneNumber = (phoneNumber) => formatPhoneNumberIntl(phoneNumber.number);

/**
 * Parse a number to SSN format
 * @param num {number|string} Value to parse (as number or string)
 * @return {string} Formatted string value
 */
export const formatAsSSN = (num) => formatWithPattern(digitsOnly(String(num)), '###-##-####');

/**
 * Calculate decimal percent rate (0-1)
 * @param {number} value
 * @param {number} decimalPlaces
 * @return {number}
 */
export const roundValue = (value, decimalPlaces) => accounting.unformat(accounting.toFixed(value, decimalPlaces));

/**
 * Gets a numeric amount from a currency string, e.g. '$1.13' to 1.13
 * @param {string} value
 * @return {number} Numeric amount
 */
export const unformatCurrency = (value) => accounting.unformat(value);

export const isRoundingStyleCeil = (roundingStyle) => isEqual(roundingStyle, RoundingStyle.CEIL);

export const isRoundingStyleFloor = (roundingStyle) => isEqual(roundingStyle, RoundingStyle.FLOOR);

/**
 * Formats the provided number as a string.
 * @param {number} num
 * @param {OptionsArg} options
 * @param {number} [options.precision]
 * @param {string} [options.separator]
 * @return {string}
 */
export const formatNumber = (num, options = {}) => {
  const { precision, separator } = options;
  return accounting.formatNumber(num, precision, separator);
};
