import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { partnershipFromCreateItemFormSelector } from 'queries/partnershipCompoundSelectors';
import { currentCompanySettingsIntegrationSelector } from 'selectors/currentCompanySelectors';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import CurrencyCodeBlockField from './CurrencyCodeBlockField';
import {} from './CurrencyCodeBlockField.types';
export const mapStateToProps = createStructuredSelector({
    companyIntegrationSettings: currentCompanySettingsIntegrationSelector,
    ledger: ledgerIntegrationSelector,
    partnership: partnershipFromCreateItemFormSelector,
});
export default connect(mapStateToProps)(CurrencyCodeBlockField);
