import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { Checkbox } from 'components';

import * as propTypes from 'constants/propTypes';

import { useGetTableFieldProps } from 'modules/table/hooks';

/**
 * Renders a checkbox field for boolean values to be rendered in a table cell.
 * @param {ComponentProps} props
 * @param {Function} props.getIsDisabled
 * @param {Function} props.getIsRequired
 * @param {string} props.id
 * @param {string} props.name
 * @param {Function|Function[]} props.validate
 * @returns {StatelessComponent}
 */
const CheckboxTableField = ({ formValues, getIsDisabled, getIsRequired, id, name, rowIdx, sectionPath, validate }) => {
  const fieldPath = `${sectionPath}.${rowIdx}`;
  const { isDisabled, isRequired, validators } = useGetTableFieldProps({
    formValues,
    getIsDisabled,
    getIsRequired,
    fieldPath,
    rowIdx,
    validate,
  });

  return (
    <div className="column-content align-items--center">
      <Field
        component={Checkbox}
        id={id}
        isDebounced
        isDisabled={isDisabled}
        isRequired={isRequired}
        name={name}
        validate={validators}
      />
    </div>
  );
};

CheckboxTableField.propTypes = {
  ...propTypes.tableFieldSharedPropTypes,
  id: PropTypes.string.isRequired,
};

CheckboxTableField.defaultProps = {
  ...propTypes.tableFieldSharedDefaultProps,
};

export default React.memo(CheckboxTableField);
