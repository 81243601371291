import _get from 'lodash/get';
import * as reduxForm from 'redux-form';
import { createSelector } from 'reselect';

import { ternary } from 'helpers/utility';

import { allStateSelector, propsSelector } from 'selectors/globalSelectors';

export const getFormState = (state) => state.form;

/**
 * Returns a redux-form async errors selector for the given form.
 * @param {string} form
 * @return {StandardSelector}
 */
export const createFormAsyncErrorSelector = (form) => reduxForm.getFormAsyncErrors(form);

/**
 * Returns a redux-form hasSubmitFailed selector for the given form.
 * @param {string} form
 * @return {StandardSelector}
 */
export const createFormHasSubmitFailedSelector = (form) => reduxForm.hasSubmitFailed(form);

/**
 * Returns a redux-form hasSubmitSucceeded selector for the given form.
 * @param {string} form
 * @return {StandardSelector}
 */
export const createFormHasSubmitSucceededSelector = (form) => reduxForm.hasSubmitSucceeded(form);

/**
 * Returns a redux-form initial values selector for the given form.
 * @param {string} form
 * @return {StandardSelector}
 */
export const createFormInitialValuesSelector = (form) => reduxForm.getFormInitialValues(form);

/**
 * Returns a redux-form isInvalid selector for the given form.
 * @param {string} form
 * @return {StandardSelector}
 */
export const createFormIsInvalidSelector = (form) => reduxForm.isInvalid(form);

/**
 * Returns a redux-form isValid selector for the given form.
 * @param {string} form
 * @return {StandardSelector}
 */
export const createFormIsValidSelector = (form) => reduxForm.isValid(form);

/**
 * Returns a selector, which returns whether the form state has been initialized yet.
 * @param {string} formName
 * @return {StandardSelector}
 */
export const createIsFormStateInitializedSelector = (formName) =>
  createSelector([getFormState], (form) => Boolean(form && form[formName]));

/**
 * Returns a redux-form isSubmitting selector for the given form.
 * @param {string} form
 * @return {StandardSelector}
 */
export const createFormIsSubmittingSelector = (form) => reduxForm.isSubmitting(form);

/**
 * Returns a redux-form meta selector for the given form.
 * @param {string} form
 * @return {StandardSelector}
 */
export const createFormMetaSelector = (form) => reduxForm.getFormMeta(form);

/**
 * Returns a redux-form single-value, nonstandard selector for the given form.
 * @param {string} form
 * @return {ReduxFormValueSelector}
 */
export const createFormSingleValueSelector = (form) => reduxForm.formValueSelector(form);

/**
 * Returns a redux-form sync errors selector for the given form.
 * @param {string} form
 * @return {StandardSelector}
 */
export const createFormSyncErrorsSelector = (form) => reduxForm.getFormSyncErrors(form);

/**
 * Returns a redux-form sync warnings selector for the given form.
 * @param {string} form
 * @return {StandardSelector}
 */
export const createFormSyncWarningsSelector = (form) => reduxForm.getFormSyncWarnings(form);

/**
 * Returns a redux-form sync submitErrors selector for the given form.
 * @param {string} form
 * @return {StandardSelector}
 */
export const createFormSubmitErrorsSelector = (form) => reduxForm.getFormSubmitErrors(form);

/**
 * Returns a redux-form getFormValues selector for the given form.
 * @param {string} form
 * @return {StandardSelector}
 */
export const createGetFormValuesSelector = (form) => reduxForm.getFormValues(form);

/**
 * Returns a redux-form async errors selector for the given form field.
 * @param {string} form
 * @param {string} fieldName
 * @return {StandardSelector}
 */
export const createFieldAsyncErrorSelector = (form, fieldName) => {
  const getAsyncErrors = createFormAsyncErrorSelector(form);

  return createSelector([getAsyncErrors], (asyncErrors) => _get(asyncErrors, fieldName));
};

/**
 * Returns a redux-form submit errors selector for the given form field.
 * @param {string} form
 * @param {string} fieldName
 * @return {StandardSelector}
 */
export const createFieldSubmitErrorSelector = (form, fieldName) => {
  const getSubmitErrors = createFormSubmitErrorsSelector(form);

  return createSelector([getSubmitErrors], (asyncErrors) => _get(asyncErrors, fieldName));
};

/**
 * Returns a redux-form initial value selector for the given form field.
 * @param {string} form
 * @param {string} fieldName
 * @return {StandardSelector}
 */
export const createFieldInitialValueSelector = (form, fieldName) => {
  const getInitialValues = createFormInitialValuesSelector(form);

  return createSelector([getInitialValues], (initialValues) => _get(initialValues, fieldName));
};

/**
 * Returns a redux-form meta selector for the given form field.
 * @param {string} form
 * @param {string} fieldName
 * @return {StandardSelector}
 */
export const createFieldMetaSelector = (form, fieldName) => {
  const getMeta = createFormMetaSelector(form);

  return createSelector([getMeta], (fields) => ternary(fields && fields.meta, _get(fields.meta, fieldName), undefined));
};

/**
 * Returns a redux-form sync errors selector for the given form field.
 * @param {string} form
 * @param {string} fieldName
 * @return {StandardSelector}
 */
export const createFieldSyncErrorsSelector = (form, fieldName) => {
  const getSyncErrors = createFormSyncErrorsSelector(form);

  return createSelector([getSyncErrors], (syncErrors) => _get(syncErrors, fieldName));
};

/**
 * Returns a redux-form sync warnings selector for the given form field.
 * @param {string} form
 * @param {string} fieldName
 * @return {StandardSelector}
 */
export const createFieldSyncWarningsSelector = (form, fieldName) => {
  const getSyncWarnings = createFormSyncWarningsSelector(form);

  return createSelector([getSyncWarnings], (syncWarnings) => _get(syncWarnings, fieldName));
};

/**
 * Returns a redux-form value selector for the given form field, converted to a standard selector.
 * @param {string} form
 * @param {string} fieldName
 * @return {StandardSelector}
 */
export const createFieldValueSelector = (form, fieldName) => {
  const getSingleValue = createFormSingleValueSelector(form);

  return createSelector([allStateSelector], (state) => getSingleValue(state, fieldName));
};

const formPropSelector = createSelector([propsSelector], (props) => props.form);

/**
 * Returns a redux-form value selector for the given form field, converted to a standard selector.
 * Accesses the value in props.form to determine the form.
 * @param {string} fieldName
 * @return {StandardSelector}
 */
export const createFieldValueUsingFormPropSelector = (fieldName) =>
  createSelector([allStateSelector, formPropSelector], (state, form) =>
    createFieldValueSelector(form, fieldName)(state),
  );

/**
 * Given a form name and a field name, returns a set of redux-form field specific selectors,
 * doing a partial right apply on the the value selector, allowing all of them to be used
 * as standard selectors for any state selection related to the given field.
 * @param {ImmutableString} formName
 * @param {ImmutableString} fieldName
 * @return {Object.<string, StandardSelector>}
 */
export const createFormFieldSelectors = (formName, fieldName) => ({
  fieldAsyncErrorsSelector: createFieldAsyncErrorSelector(formName, fieldName),
  fieldInitialValueSelector: createFieldInitialValueSelector(formName, fieldName),
  fieldMetaSelector: createFieldMetaSelector(formName, fieldName),
  fieldSyncErrorsSelector: createFieldSyncErrorsSelector(formName, fieldName),
  fieldSyncWarningsSelector: createFieldSyncWarningsSelector(formName, fieldName),
  fieldValueSelector: createFieldValueSelector(formName, fieldName),
});
