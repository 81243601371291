import { statusVariants } from '@routable/gross-ds';
import React from 'react';

import { AmountAboveBalanceTransferLimitTooltip } from 'complexComponents';

import { LinkNewPage, Text } from 'components';

import {
  ADDENDA_MULTIPLE_PAYMENTS_TEXT,
  DIFFENT_BILL_CURRENCY_MULTIPLE_PAYMENTS_TEXT,
} from 'constants/fundingProviderMemo';
import { typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { isInvoiceApprovalRequired } from 'helpers/approvals';
import { isAmountOverLimitForBillingData } from 'helpers/billing';
import { isCurrencyCodeNonUSD } from 'helpers/currency';
import helpDocs from 'helpers/helpDocs';
import {
  isItemKindPayable,
  isItemKindReceivable,
  isItemLedgerStatusError,
  isItemStatusExternallyPaid,
  isItemStatusNeedsApproval,
  isItemStatusPoDiscrepancy,
  isItemStatusScheduled,
} from 'helpers/items';
import { getStatusToConsider, itemStatusToVariant } from 'helpers/status';
import { getOriginal } from 'helpers/tables';
import { lengthOf } from 'helpers/utility';

import {
  getSelectedInvoicesCurrencyCode,
  isCreateItemVendorReceivingCurrencyEnabled,
} from '../../helpers/allTheCurrenciesLogic';

/**
 * Get ItemImport invoice list table text when on the confirmation state (using item status)
 * @param {Object} rowData
 * @return {string}
 */
export const getConfirmedTableText = (rowData) => {
  if (isItemStatusNeedsApproval(rowData)) {
    return 'Awaiting approval';
  }

  if (isItemStatusScheduled(rowData)) {
    return 'Scheduled';
  }

  if (isItemStatusExternallyPaid(rowData)) {
    return 'Paid';
  }

  if (isItemStatusPoDiscrepancy(rowData)) {
    return 'PO discrepancy';
  }

  return 'Preparing...';
};

/**
 * Returns status message for the invoice list table
 * @param {Object} options
 * @param {Boolean} options.isConfirmed
 * @param {Object} options.rowData
 * @param {Boolean} options.shouldDisplayWarn
 * @return {import('@routable/gross-ds').StatusVariant}
 */
export const getInvoiceListStatus = ({ isConfirmed, rowData, shouldDisplayWarn }) => {
  const hasLedgerError = !isConfirmed && isItemLedgerStatusError(rowData);

  if (hasLedgerError) {
    return statusVariants.error;
  }

  if (shouldDisplayWarn) {
    return statusVariants.warning;
  }

  return itemStatusToVariant[getStatusToConsider(rowData)] || statusVariants.success;
};

/**
 * Get ItemImport invoice list table text when not on the confirmation state (using item ledger status)
 * @param {Object} options
 * @param {Boolean} options.isBaseCurrencyCodeNotSupported
 * @param {Boolean} options.isBaseCurrencyCodeSupported
 * @param {Boolean} [options.isCountryUnsupported]
 * @param {Boolean} options.isCurrencyInvalid
 * @param {Boolean} options.isCurrencyUnsupported
 * @param {Object} options.rowData
 * @return {string}
 */
export const getUnconfirmedTableText = ({
  isBaseCurrencyCodeNotSupported,
  isBaseCurrencyCodeSupported,
  isCountryUnsupported,
  isCurrencyInvalid,
  isCurrencyUnsupported,
  rowData,
}) => {
  if (isItemLedgerStatusError(rowData)) {
    return 'Error. Try resending?';
  }

  // returns same text as below, but it is not grouped because:
  // - "BCS is enabled and BCC is supported" has higher priority than all other cases
  if (isBaseCurrencyCodeSupported) {
    return 'Awaiting payment';
  }

  // returns same text as below, but it is not grouped because:
  // - "BCS is enabled and BCC is not supported" has higher priority than unsupported currency (by platform)
  // - unsupported currency (by platform) has higher priority than invalid currency
  if (isBaseCurrencyCodeNotSupported) {
    return 'Invalid currency';
  }

  if (isCountryUnsupported) {
    return 'Unsupported country';
  }

  if (isCurrencyUnsupported) {
    return 'Unsupported currency';
  }

  if (isCurrencyInvalid) {
    return 'Invalid currency';
  }

  return 'Awaiting payment';
};

/**
 * Get status cell text
 * @param {Object} options
 * @param {Boolean} options.isBaseCurrencyCodeNotSupported
 * @param {Boolean} options.isBaseCurrencyCodeSupported
 * @param {Boolean} [options.isCountryUnsupported]
 * @param {Boolean} options.isConfirmed
 * @param {Boolean} options.isCurrencyInvalid
 * @param {Boolean} options.isCurrencyUnsupported
 * @param {Object} options.rowData
 * @return {string}
 */
export const getTableText = ({
  isBaseCurrencyCodeNotSupported,
  isBaseCurrencyCodeSupported,
  isConfirmed,
  isCountryUnsupported,
  isCurrencyInvalid,
  isCurrencyUnsupported,
  rowData,
}) => {
  if (isConfirmed) {
    return getConfirmedTableText(rowData);
  }

  return getUnconfirmedTableText({
    isBaseCurrencyCodeNotSupported,
    isBaseCurrencyCodeSupported,
    isCountryUnsupported,
    isCurrencyInvalid,
    isCurrencyUnsupported,
    rowData,
  });
};

export const isRequiredApprovalIconShown = (invoice, { approvalLevels, submittedItem }) =>
  !submittedItem && isInvoiceApprovalRequired(approvalLevels, invoice);

/**
 * Checkes whether the item currency code matches the selected bill currency code, if any
 * Also checks if company/item is set up to enable receiving currency code field
 * @param {Item} item
 * @returns {boolean}
 */
export const itemCurrencyCodeMatchesSelectedBillCurrencyCode = (item) => {
  const selectedCurrencyCode = getSelectedInvoicesCurrencyCode();
  const isReceivingCurrencyEnabled = isCreateItemVendorReceivingCurrencyEnabled();

  // We consider a match when there are no rows have been selected yet
  // And when the item doesn't match the requirements for enabling the receiving currency code
  // So users can select said row
  if (!selectedCurrencyCode || !isReceivingCurrencyEnabled) {
    return true;
  }

  return selectedCurrencyCode === item.currencyCode;
};

/**
 * Checks if tooltip should be shown for given row and returns an object with tooltip props if it should
 * @param {Object} params
 * @param {StringMaybe} params.addendaRecord
 * @param {BillingCodeData} [params.billingData={}]
 * @param {CurrentCompanySettingsIntegration} params.companyIntegrationSettings
 * @param {Function} params.isConfirmed
 * @param {Function} params.isRowSelected
 * @param {Ledger} params.ledger
 * @param {Object} params.row
 * @param {Object[]|undefined} params.selectedInvoices
 * @param {String[]} params.supportedCurrencies
 * @return {ObjectMaybe}
 */
export const getTooltipPropsForTableRow = ({
  addendaRecord,
  billingData = {},
  companyIntegrationSettings,
  isConfirmed,
  isRowSelected,
  ledger,
  row,
  selectedInvoices,
  supportedCurrencies,
}) => {
  // do not return any tooltip on confirmation table
  if (isConfirmed) {
    return null;
  }

  const sharedProps = {
    arrow: true,
    padding: TooltipPadding.LARGE,
    placement: TooltipPlacement.TOP,
  };
  const item = getOriginal({ row });

  // BILL IS NOT CODED IN BASE CURRENCY CODE TOOLTIP
  if (
    companyIntegrationSettings?.enableBaseCurrencyMode &&
    isItemKindPayable(item) &&
    item.currencyCode !== ledger.baseCurrencyCode
  ) {
    return {
      ...sharedProps,
      padding: TooltipPadding.SKINNY,
      title: (
        <Text.Regular lineHeight={typography.TextLineHeight.MEDIUM} size={typography.TextSize.LEVEL_100}>
          {`Your account settings only allow you to import ${ledger.baseCurrencyCode} bills from ${ledger.name}.`}
        </Text.Regular>
      ),
    };
  }

  // AMOUNT ABOVE TRANSFER LIMIT TOOLTIP
  if (isAmountOverLimitForBillingData(item.total, billingData)) {
    // disable row if row.amount exceeds transaction limit
    return {
      ...sharedProps,
      title: <AmountAboveBalanceTransferLimitTooltip item={item} />,
    };
  }

  // UNSUPPORTED CURRENCY TOOLTIP
  let unsupportedOrInvalidCurrencyPrefix;
  if (!supportedCurrencies.includes(item.currencyCode)) {
    // Currency is not supported by Routable
    unsupportedOrInvalidCurrencyPrefix = 'We do';
  } else if (item.isValidCurrency === false) {
    // Currency is not supported by the vendor
    // isValidCurrency must be false (and not null) for the tooltip to be shown
    unsupportedOrInvalidCurrencyPrefix = `${item.name} does`;
  }

  if (
    !companyIntegrationSettings.enableBaseCurrencyMode &&
    isItemKindPayable(item) &&
    unsupportedOrInvalidCurrencyPrefix
  ) {
    return {
      ...sharedProps,
      padding: TooltipPadding.SKINNY,
      title: (
        <Text.Regular lineHeight={typography.TextLineHeight.MEDIUM} size={typography.TextSize.LEVEL_100}>
          {`${unsupportedOrInvalidCurrencyPrefix} not currently support the currency this bill was coded in, so you are unable to take action. `}
          <LinkNewPage className="primary" href={helpDocs.PARTNER_COUNTRY_CURRENCY_HINT}>
            Read more
          </LinkNewPage>
          {` about supported countries and currencies.`}
        </Text.Regular>
      ),
    };
  }

  // REQUIRES PURPOSE CODE
  if (isItemKindPayable(item) && item.requiresPurposeCode) {
    return {
      ...sharedProps,
      padding: TooltipPadding.SKINNY,
      title: (
        <Text.Regular lineHeight={typography.TextLineHeight.MEDIUM} size={typography.TextSize.LEVEL_100}>
          We do not currently support multiple payments in currencies for countries requiring Purpose Codes.
          {` This feature only works for single bills at this time.`}
        </Text.Regular>
      ),
    };
  }

  // NON-USD INVOICE (NOT SUPPORTED)
  if (isItemKindReceivable(item) && isCurrencyCodeNonUSD(item.currencyCode)) {
    return {
      ...sharedProps,
      padding: TooltipPadding.SKINNY,
      title: (
        <Text.Regular
          color={typography.TextColor.GREY_X_DARK}
          lineHeight={typography.TextLineHeight.MEDIUM}
          size={typography.TextSize.LEVEL_100}
        >
          We do not currently support sending invoices to customers based in any country other than the United States
          {` and its territories, so you are unable to take action. `}
          <LinkNewPage className="primary" href={helpDocs.PARTNER_COUNTRY_CURRENCY_HINT}>
            Read more about supported countries and currencies ↗
          </LinkNewPage>
        </Text.Regular>
      ),
    };
  }

  // ADDENDA RECORD TOOLTIP
  const isOnlyOneBillSelected = lengthOf(selectedInvoices) === 1;
  const showAddendaRecordTooltip = Boolean(isOnlyOneBillSelected && addendaRecord && !isRowSelected(item.id));

  if (showAddendaRecordTooltip) {
    return {
      ...sharedProps,
      title: <Text.Regular size={typography.TextSize.LEVEL_100}>{ADDENDA_MULTIPLE_PAYMENTS_TEXT}</Text.Regular>,
    };
  }

  if (!itemCurrencyCodeMatchesSelectedBillCurrencyCode(item)) {
    return {
      ...sharedProps,
      padding: TooltipPadding.SKINNY,
      title: (
        <Text.Regular size={typography.TextSize.LEVEL_100}>{DIFFENT_BILL_CURRENCY_MULTIPLE_PAYMENTS_TEXT}</Text.Regular>
      ),
    };
  }

  return null;
};
