import * as types from 'types/partnership';

const linksInitialState = {
  current: null,
  first: null,
  last: null,
  next: null,
  prev: null,
};

const linksReducer = (state = linksInitialState, action) => {
  switch (action.type) {
    case types.FETCH_PARTNERSHIPS_SUCCESS:
      return {
        current: action.payload.links.current,
        first: action.payload.links.first,
        last: action.payload.links.last,
        next: action.payload.links.next,
        prev: action.payload.links.prev,
      };

    default:
      return state;
  }
};

export default linksReducer;
