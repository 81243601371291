import { TruncatableText } from '@routable/components';
import classNames from 'classnames';
import React from 'react';
import { FlexCol, FlexRow, Icon, IconNames, Text } from 'components';
import { colors, typography } from 'constants/styles';
import { getDomainWithOrWithoutCompanyNamespace } from 'helpers/urls';
import { isArrayLengthEqual, isGreaterThanZero } from 'helpers/utility';
import { Auth0Client } from 'services/auth0';
import {} from 'types/quickswitch';
import QuickswitchMembershipListIcon from './QuickswitchMembershipListIcon';
import './QuickswitchMembershipList.scss';
const QuickswitchMembershipList = ({ memberships, onSwitchMembership }) => {
    if (isArrayLengthEqual(memberships, 0)) {
        return null;
    }
    const makeClickableProps = (membership, tabIndex) => {
        const onClick = () => onSwitchMembership(membership.id);
        return {
            onClick,
            onKeyDown: onClick,
            role: 'button',
            tabIndex,
        };
    };
    return (React.createElement("section", null, memberships.map((targetMembership, index) => {
        const clickableProps = makeClickableProps(targetMembership, index);
        const brandedWorskpaceDomain = getDomainWithOrWithoutCompanyNamespace(targetMembership.companyNamespace).split('//')[1];
        const isReauthenticationRequired = Boolean(targetMembership.isSSORequired && !Auth0Client.isAuthenticated);
        return (React.createElement(FlexRow, { className: classNames('cursor--pointer padding--l flex-direction--row justify-content--space-between quickswitch-member', {
                'quickswitch-membership-list--border-top': isGreaterThanZero(index),
            }), key: targetMembership.id, stackOnMobile: false, ...clickableProps },
            React.createElement(FlexCol, { className: "quickswitch-member--container" },
                React.createElement("div", { className: "quickswitch-member--header" },
                    React.createElement(TruncatableText, { className: "quickswitch-member--header-company-name font-bold", position: "top", text: targetMembership.companyName })),
                React.createElement("div", { className: "quickswitch-member--body" },
                    React.createElement("div", null,
                        React.createElement(QuickswitchMembershipListIcon, { isReauthenticationRequired: isReauthenticationRequired })),
                    React.createElement(Text.Regular, { className: "quickswitch-member--body-company-name-space", color: colors.colorGreyXDarkHex, size: typography.TextSize.LEVEL_200 }, brandedWorskpaceDomain))),
            React.createElement(FlexCol, { className: "quickswitch-member--arrow-icon" },
                React.createElement("div", null,
                    React.createElement("button", { className: "cursor--pointer", type: "button" },
                        React.createElement(Icon, { color: colors.colorGreyXDarkHex, icon: IconNames.ARROW_RIGHT }))))));
    })));
};
export default QuickswitchMembershipList;
