import PropTypes from 'prop-types';
import React from 'react';

import BaseHint from 'components/hintTypes/BaseHint';
import { textHelpers } from 'components/hintTypes/helpers';

import { PartnershipTypes } from 'constants/partnership';
import { Intent } from 'constants/ui';

import { getItemKindText, isItemKindPayable } from 'helpers/items';
import { ternary } from 'helpers/utility';

/**
 * Hint describes that the RCTM may not reply to a thread because the item hasn't been sent yet ("ready to send"). It
 * would be weird for the contacts to receive a message about an item they've not received yet.
 * @param {ComponentProps} props
 * @param {Item} props.item
 * @returns {StatelessComponent}
 */
const CannotReplyItemNotSentHint = ({ item }) => {
  const paymentOrRequest = getItemKindText(item).toLowerCase();
  const vendorOrCustomer = ternary(isItemKindPayable(item), PartnershipTypes.VENDOR, PartnershipTypes.CUSTOMER);

  const hintText = textHelpers.getCannotReplyItemNotSentHintText(paymentOrRequest, vendorOrCustomer);

  return <BaseHint intent={Intent.WARNING} title={hintText} titleProps={{ marginBottom: false }} />;
};

CannotReplyItemNotSentHint.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default CannotReplyItemNotSentHint;
