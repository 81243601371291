import { updateCompany } from 'actions/companies';
import {
  fetchExternalPartnershipRequestRoutine,
  submitPartnerCompanyGeneralInfoRoutine,
} from 'actions/routines/external';
import { fetchSingleIntegrationLedgerPartnershipsRoutine } from 'actions/routines/integrations';
import { fetchItemsRoutine } from 'actions/routines/item';
import { updateMembershipRoutine } from 'actions/routines/membership';
import { submitCreatePartnershipRoutine, updatePartnershipGeneralInfoRoutine } from 'actions/routines/partnership';

import { fetchedCompaniesRelationships } from 'constants/relationships';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import {
  GET_CURRENT_COMPANY_SUCCESS,
  UPDATE_CURRENT_COMPANY_SUCCESS,
  UPDATE_ONBOARDING_COMPANY_SUCCESS,
  UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS,
} from 'types/currentCompany';
import { FETCH_LEDGER_PARTNERSHIPS_SUCCESS } from 'types/integrations';
import { FETCH_MEMBERSHIP_INVITE_SUCCESS } from 'types/inviteTeamMember';
import { FETCH_ITEM_SUCCESS } from 'types/item';
import { UPDATE_MEMBERSHIP_SUCCESS } from 'types/memberships';
import {
  FETCH_PARTNERSHIP_ITEMS_SUCCESS,
  FETCH_PARTNERSHIP_SUCCESS,
  FETCH_PARTNERSHIPS_SUCCESS,
} from 'types/partnership';

const companiesByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case fetchItemsRoutine.SUCCESS:
    case fetchSingleIntegrationLedgerPartnershipsRoutine.SUCCESS:
    case FETCH_LEDGER_PARTNERSHIPS_SUCCESS:
    case FETCH_ITEM_SUCCESS:
    case FETCH_MEMBERSHIP_INVITE_SUCCESS:
    case FETCH_PARTNERSHIP_ITEMS_SUCCESS:
    case FETCH_PARTNERSHIP_SUCCESS:
    case GET_CURRENT_COMPANY_SUCCESS:
    case submitCreatePartnershipRoutine.SUCCESS:
    case submitPartnerCompanyGeneralInfoRoutine.SUCCESS:
    case UPDATE_CURRENT_COMPANY_SUCCESS:
    case UPDATE_MEMBERSHIP_SUCCESS:
    case updateMembershipRoutine.SUCCESS:
    case UPDATE_ONBOARDING_COMPANY_SUCCESS:
    case UPDATE_ONBOARDING_COMPANY_TYPE_SUCCESS:
    case updatePartnershipGeneralInfoRoutine.SUCCESS:
    case updateCompany.type:
      return deepMergeWithArrayReplacement(
        state,
        getObjectsByIdWithRelationships(action.payload.company, fetchedCompaniesRelationships),
      );

    case FETCH_PARTNERSHIPS_SUCCESS:
      return getObjectsByIdWithRelationships(action.payload.company, fetchedCompaniesRelationships);

    default:
      return state;
  }
};

export default companiesByIdReducer;
