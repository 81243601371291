import React from 'react';
import { ButtonV2, FlexRow, LinkNewPage, Text } from 'components';
import { typography } from 'constants/styles';
import { getExternalItemsItemButtonTextFromItemKind } from 'helpers/external';
import { isExternalItemActionable } from 'helpers/items';
import { getExternalV2ItemSimilarRoute } from 'helpers/routeHelpers';
import { getOriginal } from 'helpers/tables';
import { ternary } from 'helpers/utility';
import {} from 'interfaces/item';
import ExternalItemsListTableText from '../ExternalItemsListTableText';
import {} from './ExternalItemsListTableInvoiceNumber.types';
const ExternalItemsListTableInvoiceNumber = ({ fallbackText, hideLink = false, row, value, }) => {
    const item = getOriginal({ row });
    const itemUrl = getExternalV2ItemSimilarRoute(item);
    const buttonText = getExternalItemsItemButtonTextFromItemKind(item.kind);
    const displayValue = value || fallbackText;
    return (React.createElement(FlexRow, { className: "align-items--center flex--1 justify-content--space-between", stackOnMobile: false }, ternary(hideLink, React.createElement(ExternalItemsListTableText, { color: ternary(value, typography.TextColor.GREY_X_DARK, typography.TextColor.GREY_DARK), value: displayValue }), React.createElement(React.Fragment, null,
        React.createElement(LinkNewPage, { href: itemUrl },
            React.createElement(Text.Bold, { color: typography.TextColor.BLUE_BOLD, size: typography.TextSize.LEVEL_100 }, displayValue)),
        isExternalItemActionable(item) && (React.createElement(LinkNewPage, { className: "enable-pointer-events", href: itemUrl },
            React.createElement(ButtonV2, null, buttonText)))))));
};
export default ExternalItemsListTableInvoiceNumber;
