import { submitCreatePartnershipRoutine } from 'actions/routines/partnership';

import { createIsFetchingReducer } from 'store/redux';

import * as types from 'types/partnership';

export const requestCases = [
  types.CREATE_ITEMS_FETCH_PARTNERSHIP_REQUEST,
  types.FETCH_PARTNERSHIP_REQUEST,
  types.FETCH_PARTNERSHIPS_REQUEST,
  types.FETCH_PARTNERSHIPS_EXPORT_REQUEST,
  submitCreatePartnershipRoutine.REQUEST,
];

export const finishCases = [
  types.CLEAR_PARTNERSHIP,
  types.FETCH_PARTNERSHIP_FAILURE,
  types.FETCH_PARTNERSHIP_SUCCESS,
  types.FETCH_PARTNERSHIPS_FAILURE,
  types.FETCH_PARTNERSHIPS_SUCCESS,
  types.FETCH_PARTNERSHIPS_EXPORT_FAILURE,
  types.FETCH_PARTNERSHIPS_EXPORT_SUCCESS,
  submitCreatePartnershipRoutine.FAILURE,
  submitCreatePartnershipRoutine.FULFILL,
  submitCreatePartnershipRoutine.SUCCESS,
];

const fetchReducer = createIsFetchingReducer(finishCases, requestCases);

export default fetchReducer;
