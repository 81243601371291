import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { isActiveSideLedger } from 'helpers/ledger';
import { getLedgerTaxCodeNameText, transformLedgerItemSelectorData } from 'helpers/ledgerInfo';
import { getLineItemOptionAccountText, getLineItemOptionClassText } from 'helpers/lineItems';
import { filterPartnershipByActiveFilter } from 'helpers/partnerships';
import { sortObjectArray } from 'helpers/sort';
import { allValues, isEqual, ternary } from 'helpers/utility';

import { currentCompanyIdSelector } from 'selectors/currentCompanySelectors';
import {
  matchPartnershipUIActiveFilterSelector,
  matchPartnershipUIActiveSideSelector,
  matchPartnershipUISearchSelector,
} from 'selectors/forms';
import { idSelector, propsSelector } from 'selectors/globalSelectors';
import { ledgerIntegrationApplicationSelector, ledgerIntegrationSelector } from 'selectors/integrationsSelectors';

const getState = (state) => state.integrationConfigs;

/**
 * Selects the ledger options, a collection of lots of ledger meta
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const ledgerOptionsSelector = createSelector(
  [getState],
  (integrationConfigs) => integrationConfigs.ledgerOptions,
);

/**
 * Create a selector for ledgerOptions by passing the lookupKey to find any path within ledgerOptions.
 * @function
 * @param {string} lookupKey
 * @returns {StandardSelector}
 */
export const createLedgerOptionsSelector = (lookupKey) =>
  createSelector([ledgerOptionsSelector], (ledgerOptions) => _get(ledgerOptions, lookupKey));

/**
 * Select the ledgerAccounts.
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const ledgerOptionsAccountsSelector = createSelector(
  [ledgerOptionsSelector],
  (ledgerOptions) => ledgerOptions.ledgerAccounts ?? {},
);

/**
 * Selects the ledgerAccounts byId
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const ledgerAccountsByIdSelector = createSelector(
  [ledgerOptionsAccountsSelector],
  (ledgerAccounts) => ledgerAccounts.byId ?? {},
);

/**
 * Select the ledgerBankAccounts.
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const ledgerOptionsBankAccountsSelector = createSelector(
  [ledgerOptionsSelector],
  (ledgerOptions) => ledgerOptions.ledgerBankAccounts ?? {},
);

/**
 * Selects the ledgerBankAccounts byId
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const ledgerBankAccountsByIdSelector = createSelector(
  [ledgerOptionsBankAccountsSelector],
  (ledgerBankAccounts) => ledgerBankAccounts.byId ?? {},
);

/**
 * Selects the ledgerBankAccounts lastFetched
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {DateMaybe}
 */
export const ledgerBankAccountsLastFetchedSelector = createSelector(
  [ledgerOptionsBankAccountsSelector],
  (ledgerBankAccounts) => ledgerBankAccounts.lastFetched,
);

/**
 * Selects the ledgerBankAccounts allIds
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {string[]|[]}
 */
export const ledgerBankAccountsAllIdsSelector = createSelector(
  [ledgerOptionsBankAccountsSelector],
  (ledgerBankAccounts) => ledgerBankAccounts.allIds ?? [],
);

/**
 * Selects all ledgerBankAccounts
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object[]|[]}
 */
export const allLedgerBankAccountsSelector = createSelector(
  [ledgerBankAccountsByIdSelector],
  (ledgerBankAccountsById) => allValues(ledgerBankAccountsById),
);

/**
 * Selects one ledgerAccount from the ID passed to the component
 * @type {CustomSelector}
 * @param {ReduxState} state
 * @param {string} ledgerAccountId
 * @return {ObjectMaybe}
 */
export const ledgerAccountSelector = createSelector(
  [ledgerAccountsByIdSelector, idSelector],
  (ledgerAccounts, ledgerAccountId) => ledgerAccounts[ledgerAccountId],
);

/**
 * Selects the bank account either from ledgerBankAccount (Xero) or ledgerAccount (QBO)
 * @type {CustomSelector}
 * @param {ReduxState} state
 * @param {string} accountId -- the ledgerAccount.id or ledgerBankAccount.id
 * @return {ObjectMaybe}
 */
export const ledgerBankAccountSelector = createSelector(
  [ledgerBankAccountsByIdSelector, ledgerAccountsByIdSelector, idSelector],
  (ledgerBankAccounts, ledgerAccounts, id) =>
    ternary(ledgerBankAccounts[id], ledgerBankAccounts[id], ledgerAccounts[id]),
);

/**
 * Selects all the ledgerAccounts from the state and preps them for React select
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object[]}
 */
export const allLedgerAccountsSelector = createSelector([ledgerAccountsByIdSelector], (ledgerAccounts) => {
  const augmentedLedgerAccounts = allValues(ledgerAccounts).map((account) => ({
    text: getLineItemOptionAccountText(account),
    ...account,
  }));

  return sortObjectArray(augmentedLedgerAccounts, 'text');
});

/**
 * Select the ledgerClasses
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const ledgerOptionsClassesSelector = createSelector(
  [ledgerOptionsSelector],
  (ledgerOptions) => ledgerOptions.ledgerClasses ?? {},
);

/**
 * Selects the ledgerClasses byId
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const ledgerClassesByIdSelector = createSelector(
  [ledgerOptionsClassesSelector],
  (ledgerClasses) => ledgerClasses.byId ?? {},
);

/**
 * Selects all the ledger classes from the state and preps them for React select
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object[]}
 */
export const allLedgerClassesSelector = createSelector(
  [ledgerClassesByIdSelector, ledgerIntegrationApplicationSelector],
  (ledgerClassesById, application) => {
    const augmentedLedgerClasses = allValues(ledgerClassesById).map((classAccount) => ({
      text: getLineItemOptionClassText(classAccount, application),
      ...classAccount,
    }));

    return sortObjectArray(augmentedLedgerClasses, 'code');
  },
);

/**
 * Select the ledgerCurrencies
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {ObjectMaybe}
 */
export const ledgerOptionsLedgerCurrenciesSelector = createSelector(
  [ledgerOptionsSelector],
  (ledgerOptions) => ledgerOptions.ledgerCurrencies,
);

/**
 * Select the ledgerCurrencies
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {ObjectMaybe}
 */
export const ledgerOptionsLedgerCurrenciesByIdSelector = createSelector(
  [ledgerOptionsLedgerCurrenciesSelector],
  (ledgerCurrencies) => ledgerCurrencies?.byId,
);

/**
 * Select the ledgerCurrencies
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {string[]}
 */
export const ledgerOptionsLedgerCurrenciesArraySelector = createSelector(
  [ledgerOptionsLedgerCurrenciesByIdSelector],
  (ledgerCurrenciesById) => allValues(ledgerCurrenciesById).map(({ name }) => name),
);

/**
 * Select the ledgerItems
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const ledgerOptionsItemsSelector = createSelector(
  [ledgerOptionsSelector],
  (ledgerOptions) => ledgerOptions.ledgerItems ?? {},
);

/**
 * Selects the ledgerItems byId from the state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object.<string, Item>}
 */
export const ledgerItemsByIdSelector = createSelector(
  [ledgerOptionsItemsSelector],
  (ledgerItems) => ledgerItems.byId ?? {},
);

/**
 * Selects a specific ledger item from the state
 * @type {CustomSelector}
 * @param {ReduxState} state
 * @param {Item.id} id
 * @return {Item|undefined}
 */
export const ledgerItemSelector = createSelector(
  [ledgerItemsByIdSelector, idSelector],
  (ledgerItemsById, itemId) => ledgerItemsById[itemId],
);

/**
 * Selects all the ledger items from the state and preps them for React select
 * @type {CustomSelector}
 * @param {ReduxState} state
 * @param {Item.kind} itemKind
 * @return {Item[]}
 */
export const allLedgerItemsSelector = createSelector(
  [ledgerItemsByIdSelector, ledgerIntegrationApplicationSelector, idSelector],
  (ledgerItemsById, application, itemKind) =>
    transformLedgerItemSelectorData({
      ledgerItems: ledgerItemsById,
      application,
      itemKind,
    }),
);

/**
 * Select ledgerTaxCodes
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const ledgerOptionsTaxCodesSelector = createSelector(
  [ledgerOptionsSelector],
  (ledgerOptions) => ledgerOptions.ledgerTaxCodes ?? {},
);

/**
 * Selects the ledgerTaxCodes byId
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object}
 */
export const ledgerTaxCodesByIdSelector = createSelector(
  [ledgerOptionsTaxCodesSelector],
  (ledgerTaxCodes) => ledgerTaxCodes.byId ?? {},
);

/**
 * Selects all the ledgerTaxCodes and preps them for React select
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object[]}
 */
export const allLedgerTaxCodesSelector = createSelector([ledgerTaxCodesByIdSelector], (ledgerTaxCodesById) => {
  const augmentedLedgerTaxCodes = allValues(ledgerTaxCodesById).map((taxCode) => ({
    text: getLedgerTaxCodeNameText({
      name: taxCode.name,
      taxRate: taxCode.taxRate,
    }),
    ...taxCode,
  }));

  return sortObjectArray(augmentedLedgerTaxCodes, 'code');
});

/**
 * Selects the unmatched platform funding accounts from the state
 * @function
 * @param {ReduxState} state
 * @return {Object.<FundingAccount>}
 */
export const ledgerUnmatchedPlatformFundingAccountsSelector = createSelector(
  [getState],
  (integrationConfigs) => integrationConfigs.ledgerUnmatchedPlatformFundingAccounts.byId,
);

/**
 * Selects the unmatched platform funding accounts from the state
 * @function
 * @param {ReduxState} state
 * @return {boolean}
 */
export const isFetchingUnmatchedPlatformFundingAccountsSelector = createSelector(
  [getState],
  (integrationConfigs) => integrationConfigs.ledgerUnmatchedPlatformFundingAccounts.isFetching,
);

/**
 * Selects the unmatched platform funding accounts last submitted from the state
 * @function
 * @param {ReduxState} state
 * @return {DateMaybe}
 */
export const lastSubmittedUnmatchedPlatformFundingAccountsSelector = createSelector(
  [getState],
  (integrationConfigs) => integrationConfigs.ledgerUnmatchedPlatformFundingAccounts.lastSubmitted,
);

/**
 * Selects all the ledger unmatched platform funding accounts from the state
 * and preps them for React select
 * @function
 * @param {ReduxState} state
 * @return {FundingAccount[]}
 */
export const allLedgerUnmatchedPlatformFundingAccountsSelector = createSelector(
  [ledgerUnmatchedPlatformFundingAccountsSelector],
  (fundingAccounts) => sortObjectArray(allValues(fundingAccounts), 'name'),
);

/**
 * Selects the unmatched ledger funding accounts from the state
 * @function
 * @param {ReduxState} state
 * @return {Object.<FundingAccount>}
 */
export const ledgerUnmatchedLedgerFundingAccountsSelector = createSelector(
  [getState],
  (integrationConfigs) => integrationConfigs.ledgerUnmatchedLedgerFundingAccounts.byId,
);

/**
 * Selects the unmatched ledger funding accounts from the state
 * @function
 * @param {ReduxState} state
 * @return {boolean}
 */
export const isFetchingUnmatchedLedgerFundingAccountsSelector = createSelector(
  [getState],
  (integrationConfigs) => integrationConfigs.ledgerUnmatchedLedgerFundingAccounts.isFetching,
);

/**
 * Selects all the ledger unmatched ledger funding accounts from the state
 * and preps them for React select
 * @function
 * @param {ReduxState} state
 * @return {FundingAccount[]}
 */
export const allLedgerUnmatchedLedgerFundingAccountsSelector = createSelector(
  [ledgerUnmatchedLedgerFundingAccountsSelector],
  (fundingAccounts) => allValues(fundingAccounts).filter((account) => !account.matched),
);

/**
 * Selects the unmatched ledger partnership contacts from the state
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const partnershipsContactsSelector = createSelector(
  [getState],
  (integrationConfigs) => integrationConfigs.ledgerUnmatchedPartnershipContacts.byId,
);

/**
 * Selects platform partnerships from the state
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const platformPartnershipsSelector = createSelector(
  [getState],
  (integrationConfigs) => integrationConfigs.platformPartnerships,
);

/**
 * Selects platform partnerships byId from the state
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const platformPartnershipsByIdSelector = createSelector(
  [platformPartnershipsSelector],
  (platformPartnerships) => platformPartnerships.byId,
);

/**
 * Selects ledger partnerships from the state
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const ledgerPartnershipsSelector = createSelector(
  [getState],
  (integrationConfigs) => integrationConfigs.ledgerPartnerships,
);

/**
 * Selects all ledger partnerships byId from the state
 * @function
 * @param {ReduxState} state
 * @return {Object.<Partnership>}
 */
export const ledgerPartnershipsByIdsSelector = createSelector(
  [ledgerPartnershipsSelector],
  (ledgerPartnerships) => ledgerPartnerships.byId,
);

/**
 * Selects either isFetching platform or ledger partnerships from the state
 * @function
 * @param {ReduxState} state
 * @return {boolean}
 */
export const isFetchingLedgerOrPlatformPartnershipsSelector = createSelector(
  [platformPartnershipsSelector, ledgerPartnershipsSelector],
  (platformPartnerships, ledgerPartnerships) => !!(platformPartnerships.isFetching || ledgerPartnerships.isFetching),
);

/**
 * Selects either isFetching unmatched platform or ledger funding accounts from the state
 * @function
 * @param {ReduxState} state
 * @return {boolean}
 */
export const isFetchingUnmatchedLedgerOrPlatformFundingAccountsSelector = createSelector(
  [isFetchingUnmatchedPlatformFundingAccountsSelector, isFetchingUnmatchedLedgerFundingAccountsSelector],
  (isFetchingPlatform, isFetchingLedger) => isFetchingPlatform || isFetchingLedger,
);

/**
 * Selects the count of unmatched platform partnerships from the state.
 * @function
 * @param {ReduxState}
 * @return {NumberMaybe}
 */
export const platformUnmatchedLedgerCounterSelector = createSelector(
  [platformPartnershipsSelector],
  (platformPartnerships) => platformPartnerships.unmatchedCounter,
);

/**
 * Selects the count of unmatched platform funding accounts from the state.
 * @function
 * @param {ReduxState}
 * @return {NumberMaybe}
 */
export const platformUnmatchedFundingAccountCounterSelector = createSelector(
  [getState],
  (integrationConfigs) => integrationConfigs.ledgerUnmatchedPlatformFundingAccounts.unmatchedCounter,
);

/**
 * Selects the partnership
 * @param {ReduxState} state
 * @param {string} partnershipId - Partnership id
 * @return {Partnership}
 */
export const platformUnmatchedPartnershipSelector = createSelector(
  [platformPartnershipsByIdSelector, idSelector],
  (partnershipsById, partnershipId) => partnershipsById[partnershipId],
);

/**
 * Selects the partnership
 * @function
 * @param {ReduxState} state
 * @param {string} partnershipId - Partnership id
 * @return {Partnership}
 */
export const ledgerUnmatchedPartnershipSelector = createSelector(
  [ledgerPartnershipsSelector, idSelector],
  (partnerships, partnershipId) => partnerships.byId[partnershipId],
);

/**
 * Selects the partnership by item vendorRef
 * @function
 * @param {ReduxState} state
 * @param {string} vendorRef
 * @return {Object|Partnership}
 */
export const ledgerUnmatchedPartnershipByVendorRefSelector = createSelector(
  [ledgerPartnershipsByIdsSelector, idSelector],
  (partnerships, vendorRef) => allValues(partnerships).find((partnership) => partnership.vendorRef === vendorRef) || {},
);

/**
 * Selects the partnership by item customerRef
 * @function
 * @param {ReduxState} state
 * @param {string} customerRef
 * @return {Object|Partnership}
 */
export const ledgerUnmatchedPartnershipByCustomerRefSelector = createSelector(
  [ledgerPartnershipsByIdsSelector, idSelector],
  (partnerships, customerRef) =>
    allValues(partnerships).find((partnership) => partnership.customerRef === customerRef) || {},
);

export const partnershipFromAllUnmatchedSelector = createSelector(
  [ledgerUnmatchedPartnershipSelector, platformUnmatchedPartnershipSelector],
  (ledgerPartnership, platformPartnership) => ledgerPartnership || platformPartnership,
);

export const matchingPartnershipContactsSelector = createSelector(
  [
    partnershipsContactsSelector,
    ledgerUnmatchedPartnershipSelector,
    platformUnmatchedPartnershipSelector,
    currentCompanyIdSelector,
  ],
  (contacts, ledgerPartnership, platformPartnership, currentCompanyId) => {
    const partnershipContacts = [];
    const allPartnershipContactIds = [];

    // Add ledger partnership contacts
    if (ledgerPartnership) {
      ledgerPartnership.partnershipMembers.forEach((partnershipMemberId) => {
        // Skip partnershipMemberId that already exists
        if (allPartnershipContactIds.includes(partnershipMemberId)) {
          return;
        }

        const contact = contacts[partnershipMemberId];
        if (contact && !isEqual(contact.company, currentCompanyId)) {
          allPartnershipContactIds.push(partnershipMemberId);
          partnershipContacts.push(contact);
        }
      });
    }

    // Add platform partnership contacts
    if (platformPartnership) {
      platformPartnership.partnershipMembers.forEach((partnershipMemberId) => {
        // Skip partnershipMemberId that already exists
        if (allPartnershipContactIds.includes(partnershipMemberId)) {
          return;
        }

        const contact = contacts[partnershipMemberId];
        if (contact && !isEqual(contact.company, currentCompanyId)) {
          allPartnershipContactIds.push(partnershipMemberId);
          partnershipContacts.push(contact);
        }
      });
    }

    return partnershipContacts;
  },
);

export const matchingPartnershipsSelector = createSelector(
  [platformPartnershipsSelector, ledgerPartnershipsSelector, matchPartnershipUIActiveSideSelector],
  (platformPartnerships, ledgerPartnerships, activeSide) => {
    if (isActiveSideLedger(activeSide)) {
      return ledgerPartnerships;
    }

    return platformPartnerships;
  },
);

/**
 * Selects a single partnership from the state (depending on active side)
 * @function
 * @param {ReduxState} state
 * @param {string} partnershipId - Partnership id
 * @return {ObjectMaybe}
 */
export const matchingPartnershipSelector = createSelector(
  [matchingPartnershipsSelector, idSelector],
  (partnerships, partnershipId) => partnerships.byId[partnershipId],
);

export const matchingPartnershipsPaginationSelector = createSelector(
  [matchingPartnershipsSelector],
  (partnerships) => partnerships.pagination,
);

export const allMatchingPartnershipsSelector = createSelector(
  [matchingPartnershipsSelector, matchPartnershipUIActiveFilterSelector, matchPartnershipUISearchSelector],
  (partnerships, activeFilter, search) =>
    allValues(partnerships.byId).filter((partnership) =>
      filterPartnershipByActiveFilter({ partnership, activeFilter, search }),
    ),
);

export const matchingPartnershipsIsSubmittingSelector = createSelector(
  [matchingPartnershipsSelector],
  (partnerships) => partnerships.isSubmitting,
);

/**
 * Selects application string from ledger state.
 * @function
 * @param {ReduxState} state
 * @return {string}
 */
export const ledgerApplicationSelector = createSelector([ledgerIntegrationSelector], (ledger) => ledger.application);

/**
 * Selects ledger settings from the state
 * Returns an empty Object if ledger is not available so calculateInvoiceTotals can run
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const ledgerSettingsSelector = createSelector([ledgerIntegrationSelector], (ledger) => ledger.settings || {});

/**
 * Selects the lastFetched date for partnerships from the state
 * @function
 * @param {ReduxState} state
 * @return {DateMaybe}
 */
export const lastFetchedLedgerPartnershipSelector = createSelector(
  [ledgerPartnershipsSelector],
  (ledgerPartnerships) => ledgerPartnerships.lastFetched,
);

/**
 * Selects the lastFetched date for partnerships from the state
 * @function
 * @param {ReduxState} state
 * @return {DateMaybe}
 */
export const lastFetchedPlatformPartnershipSelector = createSelector(
  [platformPartnershipsSelector],
  (platformPartnerships) => platformPartnerships.lastFetched,
);

/**
 * @function
 * @param {ReduxState} state
 * @return {DateMaybe}
 */
export const lastSubmittedPlatformPartnershipSelector = createSelector(
  [platformPartnershipsSelector],
  (platformPartnerships) => platformPartnerships.lastSubmitted,
);

/**
 * Selects the partnership by customerRef or vendorRef
 * @type {StandardSelector}
 * @return {Object|Partnership}
 */
export const platformPartnershipByCustomerOrVendorRefSelector = createSelector(
  [platformPartnershipsByIdSelector, propsSelector],
  (partnerships, partnershipFromProps) =>
    allValues(partnerships).find(
      (partnership) =>
        (partnership.customerRef && partnership.customerRef === partnershipFromProps.customerRef) ||
        (partnership.vendorRef && partnership.vendorRef === partnershipFromProps.vendorRef),
    ) || {},
);
