import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import React from 'react';

import FormErrorList from 'components/error/components/FormErrorList';

import { formatAddressFromPlace } from 'helpers/addressHelpers';
import { getAuthToken } from 'helpers/auth';
import { areDevtoolsEnabled } from 'helpers/env';
import { buildServerErrorAlert, parseServerErrors } from 'helpers/errors';
import { alertAllFormErrors, hasErrors, scrollToFormError, validateForm } from 'helpers/formValidation';

import { payloadToUnderscore } from 'services/api/formatHelpers';
import FetchService from 'services/fetch';

import { isBool, ternary } from './utility';

/**
 * Generic container class
 */
class GenericContainer extends React.Component {
  stateErrorKey = 'errors';

  stateFormKey = 'form';

  stateFormUIKey = 'formUI';

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.genericErrorFromProps(nextProps);
  }

  genericErrorFromProps = (nextProps, errorFields = ['errors'], flattenErrors = []) => {
    let fieldErrors = {};
    const hasFieldErrors = (propsObj, errorKey, nestedKey = null) => {
      if (!propsObj || !propsObj[errorKey] || !propsObj[errorKey].fields) {
        return false;
      }

      const errObject = nestedKey ? propsObj[errorKey].fields[nestedKey] : propsObj[errorKey].fields;

      return errObject && Object.keys(errObject).length > 0;
    };

    errorFields.forEach((errorKey) => {
      // Flatten nested errors
      flattenErrors.forEach((flattenErrorKey) => {
        // Skip if no errors
        if (!nextProps[errorKey] || !nextProps[errorKey].fields) {
          return;
        }

        // Add if found new errors
        if (hasFieldErrors(nextProps, errorKey, flattenErrorKey)) {
          fieldErrors = {
            ...fieldErrors,
            ...nextProps[errorKey].fields[flattenErrorKey],
          };
        }
      });

      // Parent errors
      if (hasFieldErrors(nextProps, errorKey)) {
        fieldErrors = {
          ...fieldErrors,
          ...nextProps[errorKey].fields,
        };
      }
    });

    // Either set errors or clear them
    if (Object.keys(fieldErrors).length > 0) {
      // Set errors
      this.setState({
        [this.stateErrorKey]: {
          ...fieldErrors,
        },
      });
    } else {
      // Clear errors
      this.setState({
        [this.stateErrorKey]: {},
      });
    }
  };

  genericHandleFormValidation = (formId, muteAlert) => {
    const errorState = this.state[this.stateErrorKey];

    const formErrors = validateForm(formId, errorState);

    if (!hasErrors(formErrors, formId)) {
      // Clear local state errors
      this.setState({ [this.stateErrorKey]: {} });

      // Form passed validation
      return true;
    }

    // Set the local state errors
    this.setState({ [this.stateErrorKey]: formErrors });

    // Show a toast notification listing all errors on the form
    if (!muteAlert) {
      alertAllFormErrors(<FormErrorList errors={formErrors} formId={formId} />);
    }

    scrollToFormError(formErrors, formId);

    // Form failed validation
    return false;
  };

  genericFormSubmit = (payload, successFunc, formId, additionalSuccessFuncArgs = [], muteAlert = false) => {
    // Form validation
    if (!this.genericHandleFormValidation(formId, muteAlert)) {
      return;
    }

    // Make server call
    const successFuncArgs = [payload].concat(additionalSuccessFuncArgs);
    successFunc(...successFuncArgs);
  };

  genericLocalStateFormSubmit = async (payload) => {
    const formErrors = validateForm(this.state.formId, this.state[this.stateErrorKey]);

    if (this.state.formId === undefined || !hasErrors(formErrors, this.state.formId)) {
      // Clear local state errors
      this.setState({ [this.stateErrorKey]: {} });

      const body = payload ? payloadToUnderscore(payload) : this.state.form;

      const config = {
        method: this.state.formSubmitMethod || 'POST',
        headers: {
          Accept: this.state.formSubmitAccept || 'application/json',
          'Content-Type': this.state.formSubmitContentType || 'application/json',
        },
        body: JSON.stringify(body),
      };

      if (this.state.useAuth) {
        config.headers.Authorization = getAuthToken();
      }

      try {
        this.setState({
          formSubmitIsFetching: true,
        });

        const response = await FetchService.request({
          method: 'POST',
          endpoint: this.state.formSubmitEndpoint,
          payload: body,
        });

        const jsonResponse = response.originalData;

        if (response.ok) {
          this.setState({
            formSubmitIsFetching: false,
            formSubmitLastUpdated: new Date(),
            formSubmitResponse: jsonResponse,
          });
        } else {
          const parsedErrors = parseServerErrors(response);
          this.setState({
            formSubmitIsFetching: false,
            [this.stateErrorKey]: parsedErrors.fields,
          });
        }
      } catch (error) {
        buildServerErrorAlert([this.state.formSubmitErrorMsg]);

        this.setState({
          formSubmitIsFetching: false,
        });

        if (areDevtoolsEnabled()) {
          console.log('Request failed', error); // eslint-disable-line no-console
        }
      }
    } else {
      this.setState({ [this.stateErrorKey]: formErrors });
    }
  };

  handleClearErrorState = (key, newState) => {
    // If errors exist, clear error for this key
    if (Object.keys(newState[this.stateErrorKey]).length === 0) {
      return;
    }

    const updateErrorObj = _get(newState, this.stateErrorKey);
    updateErrorObj[key] = [];
  };

  handleStateChange = (key, value, path, additionalKeysToClear = [], callback = null) => {
    const newState = _cloneDeep(this.state);
    const updateObj = _get(newState, path);
    updateObj[key] = value;

    // Clear error for this key and additional keys
    this.handleClearErrorState(key, newState);

    if (additionalKeysToClear.length > 0) {
      additionalKeysToClear.forEach((additionalKey) => {
        this.handleClearErrorState(additionalKey, newState);
      });
    }

    this.setState(newState, callback);
  };

  genericCheckboxInputChange = (event, fallbackEvent, path) => {
    // If the checkbox change is triggered by pressing Enter key, the value of the
    // first "event" param will be boolean, representing the true/false state of the checkbox.
    // If the change is triggered by clicking on the checkbox, the first param will be the actual
    // event
    const isEventTriggeredByKeyPress = isBool(event);
    const eventToUse = ternary(isEventTriggeredByKeyPress, fallbackEvent, event);

    if (isEventTriggeredByKeyPress) {
      // In case of Enter key press, we want to prevent default behavior of the event, where we submit the form
      // and set the value of event.target.checked to the boolean value received as the first parameter
      eventToUse.preventDefault();
      eventToUse.target.checked = event;
    }

    this.handleCheckboxInputChange(eventToUse, path);
  };

  handleCheckboxInputChange = (event, path = `${this.stateFormKey}`) => {
    this.handleStateChange(event.target.name, !!event.target.checked, path);
  };

  /**
   * Generic input change handler
   * @param event
   * @param path - state path to update (defaults to the stateFormKey)
   */
  handleInputChange = (event, path = this.stateFormKey) => {
    this.handleStateChange(event.target.name, event.target.value, path);
  };

  /**
   * Generic input change handler
   * @param {string} name
   * @param {Object} option
   * @param path - state path to update (defaults to the stateFormKey)
   */
  handleSelectChange = (name, option, path = this.stateFormKey) => {
    this.handleStateChange(name, option, path);
  };

  /**
   * Generic numeric input change handler (onValue callback for inputs not wrapped in `Field`)
   * @param node {HTMLElement}
   * @param values {{ formattedValue: string, value: string, floatValue: number }} From ReactNumberFormat input
   * @param path {string} State path to update (defaults to the stateFormKey)
   * @param useStringValue {boolean} For React NumberFormatInput; to use the string or numeric values
   */
  handleNumericInputChange = (node, values, path = this.stateFormKey, useStringValue = false) => {
    let newValue;

    if (values && values.floatValue) {
      // We have 'values' => InputNumberFormat
      newValue = useStringValue ? values.value : values.floatValue;
    } else {
      // We don't have 'values' => regular Input
      newValue = node.value;
    }

    this.handleStateChange(node.name, newValue, path);
  };

  handleFormUIChange = (event) => {
    this.handleStateChange(event.target.name, event.target.value, `${this.stateFormUIKey}`);
  };

  handleAddressAutoComplete = (place, onAddressAutoCompleteSelect) => {
    if (!place || !place.address_components) {
      return;
    }

    const newAddress = formatAddressFromPlace(place);
    // Update the state
    onAddressAutoCompleteSelect(newAddress);
  };

  /**
   * Handle address autocomplete select for single address
   * @param {object} updateAddressInfo
   * @param {string} updatePath
   * @param {string} path
   */
  handleAddressAutoCompleteSelect = (updateAddressInfo, updatePath = 'address', path = `${this.stateFormKey}`) => {
    const newState = _cloneDeep(this.state);
    const addressState = _get(newState, `${path}.${updatePath}`);

    // Update address and clear errors
    Object.keys(updateAddressInfo).forEach((addressKey) => {
      addressState[addressKey] = updateAddressInfo[addressKey];
      this.handleClearErrorState(addressKey, newState);
    });

    this.setState(newState);
  };
}

export default GenericContainer;
