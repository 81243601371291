/**
 * @module sagas/createItem/tasks
 */
import { getDynamicTableOutput } from '@routable/tablematic';
import { all, call, put, select } from 'redux-saga/effects';

import { handleRequestErrors, showTimeoutErrorAlert } from 'actions/errors';
import * as routines from 'actions/routines/item';

import { APPROVER_FIELD_PREFIX } from 'constants/createItem';
import { PartnershipMemberAccess } from 'constants/partnershipMember';

import { itemSubmitTransformers } from 'data/submitTransformers';

import { isItemApprovalRequired } from 'helpers/approvals';
import { getRateEstimateOrItemAmount } from 'helpers/createItem';
import { valueOrUndefined } from 'helpers/forms';
import * as itemHelpers from 'helpers/items';
import { cleanObjectOfEmptyValues } from 'helpers/transform';
import { withUserActivityTracker } from 'helpers/UserActivityTracker';
import { allKeys, hasZeroLength, isLength, objectHasKey, ternary } from 'helpers/utility';
import Difference, { itemPayloadDifferConfig } from 'helpers/utility/deepObjectDifference';

import * as metaHelpers from 'modules/dashboard/createItems/helpers/metaFields';

import { selectedInvoicesAmountsSelector } from 'queries/createItemFormSelectors';
import { fundingAccountsIdsWhereUsableForCurrentCompanySelector } from 'queries/fundingCompoundSelectors';

import {
  currentCompanyItemApprovalLevelsAllSelector,
  currentCompanyPayablePaymentDeliveryMethodsSelector,
  currentCompanyReceivablePaymentDeliveryMethodsSelector,
} from 'selectors/currentCompanySelectors';
import { fundingAccountForIdSelector } from 'selectors/fundingSelectors';
import { hasLedgerIntegrationSelector, ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import { allInvoicesSelector, invoicesMetaSelector, invoicesSelector } from 'selectors/invoicesSelectors';
import { itemMembersByIdSelector } from 'selectors/itemMemberSelectors';
import {
  partnershipsFundingAccountForIdSelector,
  allPartnershipReceivableFundingAccountsSelector,
} from 'selectors/partnershipsSelectors';

import {
  getItemMembersFromFormValues,
  resolveItemEditStatus,
  getFormattedItemMembersForPayload,
  putIdsOnDynamicOutputsFormattedLineItems,
  getMergedFormOutputValues,
} from './helpers';

/**
 * Submitting one or multiple invoices requires a bit of additional data from state, to- e.g.-
 * set a partner receivable funding account properly, set accepted delivery methods, etc.
 * This task can be used to retrieve said data from state, by submit invoice(s) sagas.
 * @param {Object} params
 * @param {ReduxFormValues} params.values
 * @return {IterableIterator<*>}
 */
export function* getAdditionalDataForSubmitInvoice(params) {
  const { options, values } = params;
  const { viewModelManager } = options;
  const { item } = values;

  const approvalLevels = yield select(currentCompanyItemApprovalLevelsAllSelector);
  const invoices = yield select(invoicesSelector);
  const invoicesMeta = yield select(invoicesMetaSelector);
  const partnershipFundingAccount = valueOrUndefined(
    yield select(partnershipsFundingAccountForIdSelector, item.partnerReceivableAccount),
  );
  const selectedPartnerFundingAccount = yield select(
    fundingAccountForIdSelector,
    partnershipFundingAccount?.fundingAccount,
  );
  const payablePaymentDeliveryMethods = yield select(currentCompanyPayablePaymentDeliveryMethodsSelector);
  const receivablePaymentDeliveryMethods = yield select(currentCompanyReceivablePaymentDeliveryMethodsSelector);

  // are we sending multiple invoices to 2 or more different partners?
  // note that this will be true if, in the ui, the user has selected bills/invoices
  // from the tab that shows all items awaiting payment, even if the items selected
  // are all attached to the same partner.getRequestActionForSingleItem
  const isMultiPartner = itemHelpers.isCurrentPathCreateItemStateAwaitingPayment();

  const constrainedMeta = viewModelManager.getConstrainedMeta({ values });

  return {
    approvalLevels,
    constrainedMeta,
    invoices,
    invoicesMeta,
    isMultiPartner,
    partnerFundingAccount: selectedPartnerFundingAccount,
    payablePaymentDeliveryMethods,
    receivablePaymentDeliveryMethods,
  };
}

/**
 * Submitting multiple invoices requires deriving whether any selected invoices
 * will need approval, from the values in state.
 * This task can be used to retrieve said data from state, by submit invoice(s) sagas.
 * @param {Object} params
 * @return {IterableIterator<*>}
 */
export function* getAdditionalDataForSubmitMultipleInvoices(params) {
  const data = yield call(getAdditionalDataForSubmitInvoice, params);

  const approvalLevels = yield select(currentCompanyItemApprovalLevelsAllSelector);
  const invoiceAmounts = yield select(selectedInvoicesAmountsSelector);

  return {
    ...data,
    approvalLevels,
    invoiceAmounts,
  };
}

/**
 * Submitting a new, single item requires a bit of additional data from state, in reference
 * to funding data, the ledger, etc.
 * This task can be used to retrieve said data from state, by create item sagas.
 * @param {Object} params
 * @return {IterableIterator<*>}
 */
export function* getSubmitSingleItemAdditionalData(params) {
  const data = yield call(getAdditionalDataForSubmitInvoice, params);

  const hasLedgerIntegration = yield select(hasLedgerIntegrationSelector);
  const ledger = yield select(ledgerIntegrationSelector);

  return {
    ...data,
    hasLedgerIntegration,
    ledger,
  };
}

/**
 * Submitting the create items form requires a bit of additional data from state, to help
 * determine which submission handler to call on for the item.
 * This task can be used to retrieve said data from state, by create item sagas.
 * @return {IterableIterator<*>}
 */
export function* getSubmitCreateItemsAdditionalData() {
  const fundingAccountIds = yield select(fundingAccountsIdsWhereUsableForCurrentCompanySelector);

  return {
    fundingAccountIds,
  };
}

/**
 * Handles packaging up all data needed to submit multiple invoices,
 * and returns a redux action that, when dispatched, submits the request.
 * @param {Object} params
 * @param {Object} params.options
 * @param {ReduxFormValues} params.values
 * @return {ReduxAction}
 */
export function* getRequestActionForMultipleInvoices(params) {
  const { options, values } = params;
  const { selectedLedgerInvoices } = options;

  const { item, meta } = values;

  const {
    approvalLevels,
    invoiceAmounts,
    invoicesMeta,
    isMultiPartner,
    partnerFundingAccount,
    payablePaymentDeliveryMethods,
    receivablePaymentDeliveryMethods,
  } = yield call(getAdditionalDataForSubmitMultipleInvoices, params);

  const allInvoices = yield select(allInvoicesSelector);
  const ledgerRefs = allInvoices.flatMap((invoice) =>
    selectedLedgerInvoices.includes(invoice.id) ? [invoice.ledgerRef] : [],
  );

  const rawMeta = {
    ...meta,
    ...(item?.meta || {}),
    ledgerRefs,
    modifiedBefore: invoicesMeta.modifiedBefore,
    // if multi-partner, tell the API to use all default funding accounts,
    // as there is no selection on a per partner level
    usePrimaryPartnerReceivableAccount: isMultiPartner,
  };

  // package up the request meta
  const parsedMeta = metaHelpers.parseProcessInvoiceMetaFields(rawMeta, true);
  // package up the request payload
  const parsedPayload = itemSubmitTransformers.multipleInvoicesForCreateItem(values, {
    approvalLevels,
    invoiceAmounts,
    partnerFundingAccount,
    payablePaymentDeliveryMethods,
    receivablePaymentDeliveryMethods,
  });

  const submitData = {
    meta: parsedMeta,
    payload: parsedPayload,
  };

  return routines.sendSubmitMultiInvoiceRequestRoutine.trigger(submitData);
}

/**
 * Handles packaging up all data needed to submit a single invoice,
 * and returns a redux action that, when dispatched, submits the request.
 * @param {Object} params
 * @param {Object} params.options
 * @param {ReduxFormValues} params.values
 * @return {ReduxAction}
 */
export function* getRequestActionForSingleInvoice(params) {
  const { options, values } = params;
  const { selectedLedgerInvoices: invoiceId } = options;

  const { item, meta } = values;

  const {
    approvalLevels,
    invoices,
    invoicesMeta,
    isMultiPartner,
    partnerFundingAccount,
    payablePaymentDeliveryMethods,
    receivablePaymentDeliveryMethods,
  } = yield call(getAdditionalDataForSubmitInvoice, params);

  const invoice = invoices[invoiceId];
  const rawMeta = {
    ...meta,
    ...(item?.meta || {}),
    ledgerRef: invoice?.ledgerRef,
    modifiedBefore: invoicesMeta.modifiedBefore,
    usePrimaryPartnerReceivableAccount: isMultiPartner,
  };

  // package up the request meta
  const parsedMeta = metaHelpers.parseProcessInvoiceMetaFields(rawMeta, false);
  // package up the request payload
  const parsedPayload = itemSubmitTransformers.singleInvoiceForCreateItem(values, {
    approvalLevels,
    invoice,
    isMultiPartner,
    partnerFundingAccount,
    payablePaymentDeliveryMethods,
    receivablePaymentDeliveryMethods,
  });

  const submitData = {
    meta: parsedMeta,
    payload: parsedPayload,
  };

  const userActivityTracker = withUserActivityTracker();
  const eventInfo = userActivityTracker.getEventInfo(({ getMostRecentEventByName }) =>
    getMostRecentEventByName('create-item', 'start'),
  );
  if (eventInfo) {
    submitData.payload.userCodingSeconds = Math.round(eventInfo.elapsedActiveTime / 1000);
  }

  return routines.sendSubmitInvoiceRequestRoutine.trigger(submitData);
}

/**
 * Gets necessary data from lineItems and values for payload
 * @param {Object} dynamicSectionKeys
 * @param {Array} lineItems
 * @param {Object} params
 * @param {ReduxFormValues} params.values
 * @return {Object}
 */
export function* getParsedPayload({ dynamicSectionKeys, lineItems, params }) {
  const { values, isDiffingInitialValues } = params;
  const {
    approvalLevels,
    constrainedMeta,
    ledger,
    partnerFundingAccount,
    payablePaymentDeliveryMethods,
    receivablePaymentDeliveryMethods,
  } = yield call(getSubmitSingleItemAdditionalData, params);

  // package up the request payload
  const parsedPayload = itemSubmitTransformers.singleItemForCreateItem(values, {
    approvalLevels,
    dynamicSectionKeys,
    itemAmount: constrainedMeta.total,
    lineItems,
    partnerFundingAccount,
    payablePaymentDeliveryMethods,
    receivablePaymentDeliveryMethods,
    isDiffingInitialValues,
  });

  return { parsedPayload, ledger };
}

/**
 * Returns removed item members
 * @param {Object} params
 * @param {Object[]} params.initialItemMembers
 * @param {Object[]} params.finalItemMembers
 * @return {Array}
 */
export function getRemovedItemMembersFromParsedPayloads({ initialItemMembers = [], finalItemMembers = [] }) {
  // Find removed item members
  const removedItemMembers = initialItemMembers.filter(
    (initItemMember) => !finalItemMembers.find((itemMember) => itemMember.id === initItemMember.id),
  );

  // Modify their access to be none
  return removedItemMembers.map((itemMember) => ({
    ...itemMember,
    accessItem: PartnershipMemberAccess.NONE,
  }));
}

/**
 * Handles packaging up all data needed to submit a single new item,
 * and returns a redux action that, when dispatched, submits the request.
 * @param {Object} params
 * @param {Object} params.options
 * @param {ReduxFormValues} params.values
 * @param {ReduxFormValues} params.initialValues
 * @return {ReduxAction}
 */
export function* getRequestActionForSingleItem(params) {
  const { options, values, initialValues } = params;
  const { dynamicSectionKeys, lineItems, initialLineItems, viewModelManager } = options;
  const { idempotencyKey, item, meta, ui, rateEstimate } = values;
  const { isItemEdit } = ui;
  const { meta: dynamicMeta } = item;

  const rawMeta = { ...meta, ...dynamicMeta };

  // On item edit, amount due is the old value even if amount total is changed. The calculation of approvals on
  // singleItemForCreateItem uses amountDue, we need amountDue to reflect the acutal item amount.
  if (isItemEdit && values?.item?.amountDue) {
    values.item.amountDue = String(values.item.amount);
  }

  const { parsedPayload, ledger } = yield call(getParsedPayload, {
    dynamicSectionKeys,
    lineItems,
    params: { options, values },
  });

  const approvalLevels = yield select(currentCompanyItemApprovalLevelsAllSelector);

  // we need to get the form's initial values as a payload (formatted the same as the form values payload)
  // so that we can later diff both and return the json:api compliant payload for this patch request
  let initialParsedPayload;
  if (isItemEdit) {
    ({ parsedPayload: initialParsedPayload } = yield call(getParsedPayload, {
      dynamicSectionKeys,
      lineItems: initialLineItems,
      params: {
        options,
        values: initialValues,
        isDiffingInitialValues: true,
      },
    }));

    // Build initial item members, needed because getParsedPayload returns a truncated itemMembers list if an item
    //  member was removed
    if (initialParsedPayload.itemMembers) {
      initialParsedPayload.itemMembers = getItemMembersFromFormValues(initialValues);
    }

    const removedItemMembers = getRemovedItemMembersFromParsedPayloads({
      initialItemMembers: initialParsedPayload.itemMembers,
      finalItemMembers: parsedPayload.itemMembers,
    });

    // Add removed item members to the start of the array
    if (removedItemMembers.length > 0) {
      parsedPayload.itemMembers = [...removedItemMembers, ...parsedPayload.itemMembers];
    }
  }

  // package up the request meta
  const parsedMeta = itemSubmitTransformers.metaForCreateItem(rawMeta, {
    item,
    ledger,
  });

  let diffedPayload;
  if (isItemEdit) {
    const difference = new Difference();
    diffedPayload = difference.calculateDifference(initialParsedPayload, parsedPayload, itemPayloadDifferConfig, false);
    diffedPayload.version = initialValues.item.version;
    diffedPayload = resolveItemEditStatus({
      item: parsedPayload,
      diffedPayload,
      sendItem: ui.sendItem,
    });

    // On item edit, amount due key in item attributes is rejected by the backend cause is calculated from the amount.
    if (objectHasKey(diffedPayload, 'amountDue')) {
      delete diffedPayload.amountDue;
    }
    const itemAmountInCompanyCurrency = getRateEstimateOrItemAmount(rateEstimate, parsedPayload.amount);

    if (
      isItemApprovalRequired({
        approvalLevels,
        item,
        itemAmount: itemAmountInCompanyCurrency,
        useAmountDue: false,
      })
    ) {
      const itemDataWithApprovers = { ...values, ...item };
      diffedPayload.itemApprovers = itemSubmitTransformers.itemApproversForCreateItem(
        approvalLevels,
        itemDataWithApprovers,
        itemAmountInCompanyCurrency,
      );
    } else {
      diffedPayload.itemApprovers = [];
    }
  }

  const submitData = {
    idempotencyKey,
    isItemEdit,
    meta: parsedMeta,
    payload: ternary(isItemEdit, diffedPayload, parsedPayload),
    fullItem: parsedPayload,
    viewModelManager,
    ui,
  };

  if (isItemEdit) {
    const itemEditSubmitRoutine = routines.sendSubmitItemRequestRoutine.trigger(submitData);
    return {
      parsedPayload, // full item including changes
      ...itemEditSubmitRoutine,
    };
  }

  const userActivityTracker = withUserActivityTracker();
  // if the item id is already present and it's not item edit, we're creating an item from one
  // that was imported from the ledger
  if (item.id && !isItemEdit) {
    submitData.itemId = item.id;
    const eventInfo = userActivityTracker.getEventInfo(({ getMostRecentEventByName }) =>
      getMostRecentEventByName('create-item', 'start'),
    );
    if (eventInfo) {
      submitData.payload.userCodingSeconds = Math.round(eventInfo.elapsedActiveTime / 1000);
    }
    return routines.sendSubmitBillToItemRequestRoutine.trigger(submitData);
  }

  // at this point, we're creating a new item
  // Add the time it took for the user to go through the create item flow
  const eventInfo = userActivityTracker.getEventInfo(({ getMostRecentEventByName }) =>
    getMostRecentEventByName('create-item', 'start'),
  );
  if (eventInfo) {
    submitData.payload.userCodingSeconds = Math.round(eventInfo.elapsedActiveTime / 1000);
  }
  return routines.sendSubmitItemRequestRoutine.trigger(submitData);
}

export function* prepareSubmitRequestParams(action) {
  const {
    props: { initialValues, rateEstimate, viewModelManager },
    values,
  } = action.payload;
  const { isItemEdit } = initialValues.ui;

  // We are cleaning approvers on initial values so approvers are always kept after diffing, we need to change this
  // when working on changing the payload to match the backend structure and when the backend supports approvers
  // diffing. See DEV-2835
  allKeys(values).forEach((key) => {
    if (key.startsWith(APPROVER_FIELD_PREFIX)) {
      initialValues[key] = [];
    }
  });

  // filter out "item" here, as in this case, this isn't really a dynamic key; it's shared by FE redux state
  const dynamicSectionKeys = viewModelManager.parser.schemaKeys.filter((key) => key !== 'item');

  const initialDynamicOutput = getDynamicTableOutput(viewModelManager.parser, initialValues);
  const dynamicOutput = getDynamicTableOutput(viewModelManager.parser, values);

  if (isItemEdit) {
    // We are relying on getDynamicTableOutput function to use the same object so the line items are in the same
    // order, so we can put the correct IDs regardless of key order in the object.
    initialDynamicOutput.item.lineItems = putIdsOnDynamicOutputsFormattedLineItems(
      initialValues.line_items,
      initialDynamicOutput.item.lineItems,
    );
    dynamicOutput.item.lineItems = putIdsOnDynamicOutputsFormattedLineItems(
      values.line_items,
      dynamicOutput.item.lineItems,
    );

    initialDynamicOutput.item.amount = parseFloat(initialValues.item.amount);
  }

  const initialMergedValues = getMergedFormOutputValues({
    dynamicOutput: initialDynamicOutput,
    allFormValues: initialValues,
    viewModelManager,
  });

  const mergedValues = getMergedFormOutputValues({
    dynamicOutput,
    allFormValues: values,
    viewModelManager,
  });

  mergedValues.rateEstimate = rateEstimate;

  if (isItemEdit) {
    // Format itemMembers payloadresolveItemEditStatus
    const itemMembersById = yield select(itemMembersByIdSelector);

    mergedValues.itemMembers = getFormattedItemMembersForPayload(values.itemMembers, itemMembersById);

    // Set correct initial partnerReceivableAccount
    const partnerFundingAccounts = yield select(allPartnershipReceivableFundingAccountsSelector);
    const isInitialPartnerFundingAccount = (partnerFundingAccount) =>
      partnerFundingAccount.fundingAccount === initialMergedValues.item?.partnerFundingAccount;
    const initialSelectedFundingAccount = partnerFundingAccounts.find(isInitialPartnerFundingAccount);

    if (initialSelectedFundingAccount?.id) {
      initialMergedValues.item.partnerReceivableAccount = initialSelectedFundingAccount?.id;
    }
  }

  const { item: initialItem } = initialMergedValues;
  const { item } = mergedValues;
  const cleanEmptyValuesOnLineItem = (lineItem) => cleanObjectOfEmptyValues(lineItem, { allowEmptyString: false });
  const lineItems = item.lineItems.map(cleanEmptyValuesOnLineItem);
  const initialLineItems = initialItem.lineItems.map(cleanEmptyValuesOnLineItem);

  // remove purchaseOrder field from payload as this is not needed
  // PO is linked via the line items (it has additional PO fields in every line item)
  if (mergedValues.item.purchaseOrder) {
    delete mergedValues.item.purchaseOrder;
  }

  return {
    dynamicSectionKeys,
    initialLineItems,
    initialValues: initialMergedValues,
    lineItems,
    values: mergedValues,
    viewModelManager,
  };
}

/**
 * Handles packaging up all data needed to submit an item of any type (e.g. a single
 * item, single invoice, multiple invoices), and returns the appropriate action
 * bundled with that data.
 * @param {Object} params
 * @param {Object[]} params.lineItems
 * @param {Object[]} params.initialLineItems
 * @param {ReduxFormValues} params.values
 * @param {ReduxFormValues} params.initialValues
 * @param {SchemaViewModelManager} params.viewModelManager
 * @return {ReduxAction}
 */
export function* getPreparedSubmitRequestAction(params) {
  const { dynamicSectionKeys, lineItems, initialLineItems, values, initialValues, viewModelManager } = params;
  const {
    ledgerInvoiceRefs,
    ui: { showInvoiceGenerator },
  } = values;

  if (!showInvoiceGenerator) {
    // when selecting invoices from ledger
    const selectedLedgerInvoices = allKeys(ledgerInvoiceRefs).filter((ledgerRef) => ledgerInvoiceRefs[ledgerRef]);

    if (hasZeroLength(selectedLedgerInvoices)) {
      // no invoices selected - don't submit anything
      return undefined;
    }

    if (isLength(selectedLedgerInvoices, 1)) {
      // return the action to submit a single invoice
      return yield call(getRequestActionForSingleInvoice, {
        options: {
          selectedLedgerInvoices: selectedLedgerInvoices[0],
          viewModelManager,
        },
        values,
      });
    }

    // return the action to submit multiple invoices
    return yield call(getRequestActionForMultipleInvoices, {
      options: { selectedLedgerInvoices, viewModelManager },
      values,
    });
  }

  // return the action to submit a single new item
  return yield call(getRequestActionForSingleItem, {
    options: {
      dynamicSectionKeys,
      lineItems,
      initialLineItems,
      viewModelManager,
    },
    values,
    initialValues,
  });
}

/**
 * Handles a timed-out request on item submit
 * @yields {ReduxAction}
 */
export function* handleItemSubmitTimeout(itemKind) {
  const options = { itemKind };

  yield all([
    put(handleRequestErrors(routines.submitItemRoutine.failure, {})),
    put(showTimeoutErrorAlert({ options })),
  ]);
}
