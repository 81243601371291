import _get from 'lodash/get';
import { dashTextToCamelCase } from 'helpers/stringHelpers';
import { hasLength, isObject } from 'helpers/utility';
import { getItemLedgerDisplayStatus } from 'modules/dashboard/existingItem/helpers';
export const getFieldProps = ({ item, ledger, minDecimalScale, name, options }) => {
    let [fieldPath] = name.split('.').slice(-1);
    if (fieldPath.includes('extended_')) {
        fieldPath = fieldPath.replace('extended_', 'extended.').replace('_value', '.value');
    }
    fieldPath = dashTextToCamelCase(fieldPath);
    const isAccountFieldLineItems = name.includes('lineItems.') && fieldPath === 'account';
    const isItemOrAccountFieldInLineItems = (name.includes('lineItems.') && fieldPath === 'item') || isAccountFieldLineItems;
    if (isItemOrAccountFieldInLineItems) {
        fieldPath = `${fieldPath}.name`;
    }
    let value = _get(item, fieldPath);
    if (isAccountFieldLineItems) {
        const accountValue = _get(item, 'account');
        value = `${accountValue?.code ? `${accountValue.code}: ` : ''}${accountValue?.name}`;
    }
    if (fieldPath === 'taxStyle') {
        value = item.extended?.taxStyle;
    }
    if (fieldPath === 'billCurrency') {
        value = item.currencyCode;
    }
    else if (fieldPath === 'ledgerStatus') {
        value = getItemLedgerDisplayStatus(item, ledger);
    }
    else if (isObject(value)) {
        const option = options ? options.find((opt) => opt.id === value.id) : value;
        value = option?.name || option?.label || option?.value || option?.number;
    }
    else if (hasLength(options) && !isItemOrAccountFieldInLineItems) {
        const option = options.find((opt) => opt.id === value);
        value = option?.label || option?.text;
    }
    else if (fieldPath === 'poMatchType' && !value) {
        value = 'Non-PO';
    }
    if (typeof minDecimalScale === 'number' && value) {
        value = parseFloat(value).toFixed(minDecimalScale);
    }
    return {
        fieldPath,
        value,
    };
};
