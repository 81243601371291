import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { ReduxFormFieldRenderInput } from 'components';

import * as propTypes from 'constants/propTypes';

import { useGetTableFieldProps } from 'modules/table/hooks';

/**
 * Renders a text input field to be rendered in a table cell.
 * @param {ComponentProps} props
 * @param {Function} props.getIsDisabled
 * @param {Function} props.getIsRequired
 * @param {string} props.name
 * @param {number} props.maxLength
 * @param {RestOfProps} props.rest
 * @param {Function|Function[]} props.validate
 * @returns {StatelessComponent}
 */
const TextBlockField = ({
  formValues,
  getIsDisabled,
  getIsRequired,
  maxLength,
  name,
  rowIdx,
  sectionPath,
  validate,
}) => {
  const fieldPath = `${sectionPath}.${rowIdx}`;
  const { isDisabled, isRequired, validators } = useGetTableFieldProps({
    formValues,
    getIsDisabled,
    getIsRequired,
    fieldPath,
    rowIdx,
    validate,
  });

  return (
    <Field
      className="ledger-form-field"
      component={ReduxFormFieldRenderInput}
      isDebounced
      isDisabled={isDisabled}
      isRequired={isRequired}
      maxLength={maxLength}
      name={name}
      props={{ hideLabel: true }}
      type="text"
      validate={validators}
    />
  );
};

TextBlockField.propTypes = {
  ...propTypes.tableTextFieldPropTypes,
  maxLength: PropTypes.number,
};

TextBlockField.defaultProps = {
  ...propTypes.tableTextFieldDefaultProps,
  maxLength: undefined,
};

export default React.memo(TextBlockField);
