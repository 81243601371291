import classNames from 'classnames';
import React, { useState } from 'react';
import FormFieldErrors from 'components/error/components/FormFieldErrors';
import * as inputHelpers from 'components/form/helpers/inputs';
import * as commonProps from 'components/form/NumericInput/commonProps';
import NumericInput from 'components/form/NumericInput/NumericInput';
import { InputAddon, InputLabel, InputPlaceholder } from 'components/input';
import { getFieldErrors } from 'helpers/errors';
const InputWrapper = (props) => {
    const { addon, children, contentBefore, errors, hasErrors: hasErrorsProp, hideLabel, inputClassName, isRequired, label, name, placeholder, showValidationIcon, value, } = props;
    const [inputRef, setInputRef] = useState(null);
    const handleChange = (event) => {
        const { onChange } = props;
        if (onChange) {
            onChange(event);
        }
    };
    const handleValueChange = (values) => {
        const { onValueChange } = props;
        if (onValueChange) {
            onValueChange(inputRef, values);
        }
    };
    const hasErrors = hasErrorsProp || getFieldErrors(errors, name);
    const { widthClasses, otherClasses } = inputHelpers.getInputClasses(props, { hasErrors });
    return (React.createElement("div", { className: classNames(widthClasses) },
        React.createElement("div", { className: classNames('input-container', otherClasses) },
            Boolean(contentBefore) && contentBefore,
            React.createElement(InputLabel, { hideLabel: hideLabel, label: label, name: name, placeholder: placeholder, value: value }),
            React.createElement(NumericInput, { ...props, className: inputClassName, fieldErrors: errors, onChange: handleChange, onValue: handleValueChange, required: isRequired, setInputRef: setInputRef }),
            React.createElement(InputPlaceholder, { isRequired: isRequired, placeholder: placeholder, value: value }),
            children,
            React.createElement(InputAddon, { addon: addon, hasErrors: hasErrors, showValidationIcon: showValidationIcon, value: value })),
        React.createElement(FormFieldErrors, { errors: errors, fieldName: name })));
};
InputWrapper.defaultProps = {
    ...commonProps.defaultProps,
    addon: undefined,
    errors: undefined,
    hasErrors: undefined,
    inputClassName: '',
    onValueChange: null,
    showValidationIcon: undefined,
    value: undefined,
};
export default InputWrapper;
