import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { Radio, FlexRow } from 'components';

import { UserTypes } from 'constants/user';

import { requiredValidator } from 'helpers/fieldValidation';
import { callIfIsFn, ternary } from 'helpers/utility';

import './PartnerCompanyType.scss';

/**
 * Dual radio buttons to select company type.
 * @param {ComponentProps} props
 * @param {Function} [props.onChange]
 * @param {Boolean} props.split
 * @returns {StatelessComponent}
 */
const PartnerCompanyType = ({ onChange, split }) => {
  // Stacked is the variant used by the original onboarding flow
  // Split is the variant used by the new onboarding flow
  const versionType = ternary(split, 'split', 'stacked');

  // If we receive onChange handler as a prop, we want to call
  // it on field change. We don't want to do anything if we don't
  // receive the handler.
  const handleChange = () => callIfIsFn(onChange);

  return (
    <FlexRow className={`partner-company-type--wrapper ${versionType}`}>
      <Field
        className="partner-company-type--business-radio"
        component={Radio}
        id="radio1"
        name="company.companyType"
        onChange={handleChange}
        optionText="I represent a business"
        subText="e.g. Sole proprietorship, LLC, or Corporation"
        type="radio"
        validate={requiredValidator}
        value={UserTypes.BUSINESS}
      />

      <Field
        className="partner-company-type--individual-radio"
        component={Radio}
        id="radio2"
        name="company.companyType"
        onChange={handleChange}
        optionText="I am an individual"
        subText="e.g. Contractor or Freelancer"
        type="radio"
        validate={requiredValidator}
        value={UserTypes.PERSONAL}
      />
    </FlexRow>
  );
};

PartnerCompanyType.propTypes = {
  onChange: PropTypes.func,
  split: PropTypes.bool,
};

PartnerCompanyType.defaultProps = {
  split: undefined,
};

export default PartnerCompanyType;
