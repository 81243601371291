import PropTypes from 'prop-types';
import React from 'react';

import { reduxFormFieldDefaultProps, reduxFormFieldPropTypes } from 'components/commonProps';

import { ternary } from 'helpers/utility';

/**
 * Wrapper for any component rendered by redux-form's Field.
 * Spreads `input` props onto the rendered `Component`, and
 * adds an `errors` prop. Also passes down `meta` and any other props
 * received.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const ReduxFormFieldWrapper = (props) => {
  const { Component, input, meta, requireTouchedForError, ...rest } = props;

  let errors;

  if (requireTouchedForError) {
    const hasError = (meta.touched || meta.submitFailed) && meta.error;
    errors = ternary(hasError, meta.error, undefined);
  } else {
    errors = meta.error;
  }

  return (
    <Component
      meta={meta}
      {...rest}
      {...input}
      errors={errors}
      // if input value is not defined just return null, but it can have false values like '0'.
      value={input.value ?? null}
    />
  );
};

ReduxFormFieldWrapper.propTypes = {
  ...reduxFormFieldPropTypes,
  Component: PropTypes.elementType.isRequired,
  requireTouchedForError: PropTypes.bool,
};

ReduxFormFieldWrapper.defaultProps = {
  ...reduxFormFieldDefaultProps,
  requireTouchedForError: true,
};

export default ReduxFormFieldWrapper;
