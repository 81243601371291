import { change, initialize, reset, submit } from 'redux-form';

import { formNamesPartnership, formNamesSettings } from 'constants/forms';

import { getDefaultCreatePartnershipInitialValue } from 'helpers/createPartnership';
import { getIsVendorAndIsCustomer } from 'helpers/partnerships';

import { MODAL_MERGE_LEDGER_PARTNERSHIP_OPEN } from 'types/modals';
import * as types from 'types/partnership';

// Create partnership
export const clearCreatePartnership = () => ({ type: types.CLEAR_PARTNERSHIP });
export const closeCreatePartnershipModal = () => ({
  type: types.MODAL_CREATE_PARTNERSHIP_CLOSE,
});

/**
 * Redux action responsible for opening the create partnership modal
 * @param {Object} options
 * @param {Boolean} [options.doesCompanyExist]
 * @param {Partnership} options.partnership
 * @param {Boolean} [options.sendEmail]
 * @param {Function} [options.successCallback]
 */
export const openCreatePartnershipModal = ({ doesCompanyExist = false, partnership, sendEmail, successCallback }) => ({
  type: types.MODAL_CREATE_PARTNERSHIP_OPEN,
  payload: {
    doesCompanyExist,
    partnership,
    sendEmail,
    successCallback,
  },
});

export const submitCreatePartnershipForm = () => submit(formNamesPartnership.CREATE);

export const resetCreatePartnershipForm = () => reset(formNamesPartnership.CREATE);

// Merge partnership

/**
 * Open the merge ledger partnership modal.
 * @param {string} partnershipId - Partnership we're trying to merge
 * @param {function} [successCallback] Called when merge happens successfully via thunkFetch
 * @returns {ReduxAction}
 */
export const openMergeLedgerPartnershipModal = ({ partnershipId, successCallback }) => ({
  type: MODAL_MERGE_LEDGER_PARTNERSHIP_OPEN,
  payload: { partnershipId, successCallback },
});

// Update partner payment method
export const closeUpdatePartnerPaymentMethodModal = () => ({
  type: types.MODAL_UPDATE_PARTNERSHIP_PAYMENT_METHOD_CLOSE,
});
export const openUpdatePartnerPaymentMethodModal = () => ({
  type: types.MODAL_UPDATE_PARTNERSHIP_PAYMENT_METHOD_OPEN,
});

// Clear partnership info
export const clearPartnershipAddresses = () => ({
  type: types.CLEAR_PARTNERSHIP_ADDRESSES,
});
export const clearPartnershipFundingAccount = () => ({
  type: types.CLEAR_PARTNERSHIP_FUNDING_ACCOUNT,
});
export const clearSendPartnershipUpdatePaymentMethodUrlStatus = (partnershipId) => ({
  payload: { id: partnershipId },
  type: types.SEND_PARTNERSHIP_UPDATE_PAYMENT_METHOD_URL_CLEAR,
});

export const updatePartnershipPrimaryReceivableFundingAccount = (partnershipFundingAccountId) => ({
  payload: { partnershipFundingAccountId },
  type: types.UPDATE_PARTNERSHIP_PRIMARY_RECEIVABLE_FUNDING_ACCOUNT,
});

export const fetchPartnershipsRequest = (params = {}) => ({
  payload: { params },
  type: types.FETCH_PARTNERSHIPS_REQUEST,
});

export const fetchPartnershipsSuccess = (payload = {}, meta = {}) => ({
  // Add any meta values passed in to the existing payload
  payload: {
    ...payload,
    meta: {
      ...payload.meta,
      ...meta,
    },
  },
  type: types.FETCH_PARTNERSHIPS_SUCCESS,
});

export const fetchPartnershipsFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_PARTNERSHIPS_FAILURE,
});

export const fetchPartnershipsExportRequest = (params = {}) => ({
  payload: { params },
  type: types.FETCH_PARTNERSHIPS_EXPORT_REQUEST,
});

export const fetchPartnershipsExportSuccess = () => ({
  type: types.FETCH_PARTNERSHIPS_EXPORT_SUCCESS,
});

export const fetchPartnershipsExportFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_PARTNERSHIPS_EXPORT_FAILURE,
});

export const createItemsFetchSinglePartnershipRequest = (
  partnershipId,
  partnershipData = {},
  updateMethod,
  queryParams = {},
) => ({
  payload: { partnershipData, partnershipId, queryParams, updateMethod },
  type: types.CREATE_ITEMS_FETCH_PARTNERSHIP_REQUEST,
});

export const fetchSinglePartnershipRequest = (partnershipId, queryParams = {}) => ({
  payload: { partnershipId, queryParams },
  type: types.FETCH_PARTNERSHIP_REQUEST,
});

export const fetchSinglePartnershipSuccess = (payload) => ({
  payload,
  type: types.FETCH_PARTNERSHIP_SUCCESS,
});

export const fetchSinglePartnershipFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_PARTNERSHIP_FAILURE,
});

export const fetchPartnershipItemsRequest = (partnershipId, params = {}) => ({
  payload: { params, partnershipId },
  type: types.FETCH_PARTNERSHIP_ITEMS_REQUEST,
});

export const fetchPartnershipItemsSuccess = (payload) => ({
  payload,
  type: types.FETCH_PARTNERSHIP_ITEMS_SUCCESS,
});

export const fetchPartnershipItemsFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_PARTNERSHIP_ITEMS_FAILURE,
});

export const createItemsFetchPartnershipReceivableFundingAccountsRequest = (
  partnershipId,
  itemKind,
  queryParams = {},
) => ({
  payload: { itemKind, partnershipId, queryParams },
  type: types.CREATE_ITEMS_FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_REQUEST,
});

export const fetchPartnershipReceivableFundingAccountsRequest = (partnershipId, queryParams = {}) => ({
  payload: { partnershipId, queryParams },
  type: types.FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_REQUEST,
});

export const fetchPartnershipReceivableFundingAccountsSuccess = (payload) => ({
  payload,
  type: types.FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS,
});

export const fetchPartnershipReceivableFundingAccountsFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_FAILURE,
});

/**
 * @type {ReduxActionCreator}
 */
export const submitVendorListFilterForm = () => submit(formNamesSettings.VENDOR_LIST_FILTER);

/**
 * initializeCreatePartnershipFormAction
 * @param {object} props
 * @param {string} props.formName
 * @param {boolean} props.isInternationalPaymentsEnabled
 * @param {string} props.partnershipType
 * @param {unknown[]} props.paymentMethodOptions
 * @return {ReduxAction|undefined}
 */
export const initializeCreatePartnershipFormAction = ({ formName, partnershipType, ...rest }) => {
  // The createPartnership form is sometimes pre-filled with initialValues
  // and can also be reset (when clicking on 'cancel' for eg).
  // For it to reset to default blank values, we need to re-initialize it first with blank values
  // Otherwise, reduxForm resets to the initialValues
  // See https://warrenpay.atlassian.net/browse/BUGS-525 for more

  const partnershipTypesBooleans = getIsVendorAndIsCustomer(partnershipType);
  const defaultValues = getDefaultCreatePartnershipInitialValue({
    partnership: { ...partnershipTypesBooleans },
    ...rest,
  });
  return initialize(formName, defaultValues);
};

/**
 * Redux action for updating partner field value in the form.
 * @param {string} fieldName
 * @param {string} value
 */
export const updatePartnerField = (fieldName, value) => change(formNamesPartnership.CREATE, fieldName, value);

/**
 * Redux action for updating the partnerships companies list pagination count by 1 after adding a new vendor
 * @returns {{type: string}}
 */
export const updatePartnershipPaginationCountAdded = () => ({
  type: types.UPDATE_PARTNERSHIPS_PAGINATION_COUNT_ADDED_ONE,
});
