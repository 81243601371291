import { convertToLowerCase } from 'helpers/stringHelpers';
import { isStringOrNum, or, ternary } from 'helpers/utility';

/**
 * Default sorting function to be used with react-table.
 * Sorts by the row's 'text' or 'subtext' property.
 * @param {Object} rowA
 * @param {Object} rowB
 * @param {String} id
 * @return {Number}
 */
export const defaultTableSort = (rowA, rowB, id) => {
  let valA;
  let valB;

  if (or(isStringOrNum(rowA.original[id]), isStringOrNum(rowB.original[id]))) {
    // value is string or num
    valA = convertToLowerCase(rowA.original[id]);
    valB = convertToLowerCase(rowB.original[id]);
  } else {
    // we need to extract the value from the object
    valA = convertToLowerCase(rowA.original[id].text || rowA.original[id].subtext);
    valB = convertToLowerCase(rowB.original[id].text || rowB.original[id].subtext);
  }

  return ternary(valA > valB, 1, -1);
};

/**
 * Adds memoized sort function to columns which are sortable.
 * @param {Object[]} columns
 * @param {Function} sortType
 * @return {Object[]}
 */
export const addSortTypeToColumns = (columns, sortType) =>
  columns.map((column) => {
    const sortProps = {};
    if (column.props?.isSortable && !column.sortType) {
      sortProps.sortType = sortType;
    }

    return {
      ...column,
      ...sortProps,
    };
  });
