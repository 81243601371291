import { Badge, Button } from '@routable/gross-ds';
import React from 'react';
import { TooltipMUIConditionalWrapper } from 'components';
import { usePermissions } from 'hooks';
const TableToolbarPrimaryAction = ({ actionableCount = 0, children, isDisabledWhenNoActionable = true, hideWhenNotAllowed, onClick, requiredPermissions = [], ...rest }) => {
    const { hasPermission, tooltipProps } = usePermissions(requiredPermissions);
    const hasActionableCount = actionableCount >= 1;
    const isDisabled = !hasPermission || (isDisabledWhenNoActionable && !hasActionableCount);
    if (hideWhenNotAllowed && !hasPermission) {
        return null;
    }
    return (React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: tooltipProps },
        React.createElement(Button, { "data-testid": "btn-bulk-action", ...rest, isDisabled: isDisabled, onPress: onClick, size: "small", variant: "outline" },
            children,
            !isDisabled && hasActionableCount && (React.createElement(Badge, { "aria-label": "Item count", intent: "blue", size: "small" }, actionableCount)))));
};
export default TableToolbarPrimaryAction;
