import React from 'react';

import { arrayWithout, allValues } from 'helpers/utility';

import {
  areSameItemMembers,
  getContactSelectValuesByAccess,
  getOptionDataFromPartnerMember,
  sortPartnerMemberOptionsByNameAndTagText,
} from './helpers';

/**
 * Hook used by GroupedContactSelects to generate select options and derive
 * the value of each select field.
 * @param {ComponentProps} props
 * @return {{ actionMembers: *[], options: *[], readOnlyMembers: *[] }}
 */
export const useGroupedContactSelectsData = (props) => {
  const {
    members,
    accessProperty,
    input: { value },
  } = props;

  // group the value property by what level of access has been set on each member
  const { actionMembers, readOnlyMembers } = getContactSelectValuesByAccess(value, accessProperty);

  // the `members` prop should come from a selector and will very rarely change; we
  // should avoid re-renders due to regenerating `options` when nothing relevant has changed
  const options = React.useMemo(() => {
    const removeValues = value || []; // value is null when no options are selected

    // remove anyone already selected from the set of available options
    const availableValues = arrayWithout(allValues(members), removeValues, areSameItemMembers);

    // members available for selection get mapped to the option format
    return sortPartnerMemberOptionsByNameAndTagText(availableValues.map(getOptionDataFromPartnerMember));
  }, [members, value]);

  return {
    actionMembers: actionMembers.map(getOptionDataFromPartnerMember),
    options,
    readOnlyMembers: readOnlyMembers.map(getOptionDataFromPartnerMember),
  };
};

/**
 * Hook used by MutuallyExclusiveSelects to generate select options and return current value
 * @param {Object} props
 * @param {PartnershipMember[]} props.config.approvers
 * @param {PartnershipMemberSelectOption[]} props.input.value
 * @return {{ currentlySelected: PartnershipMemberSelectOption[], options: PartnershipMemberSelectOption[] }}
 */
export const useMutuallyExclusiveSelectsData = ({ config: { approvers }, input: { value } }) => {
  const options = React.useMemo(
    () => sortPartnerMemberOptionsByNameAndTagText(approvers.map(getOptionDataFromPartnerMember)),
    [approvers],
  );

  const currentlySelected = value || [];

  return {
    currentlySelected,
    options,
  };
};
