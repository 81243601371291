import { getMembershipEmailAddress, getMembershipFullName, getMembershipPhoneNumber } from 'helpers/memberships';
import { isGreaterThan, lengthOf, ternary } from 'helpers/utility';

/**
 * Create "and N other(s)" text to end the AddedContactsSummaryTitleBlock.
 * @function
 * @example
 * const contacts = [testContact];
 * getAndOthersSummaryOfContactsText(contacts);
 * // returns ''
 * @example
 * const contacts = [testContact, testContact];
 * getAndOthersSummaryOfContactsText(contacts);
 * // returns 'and 1 other'
 * @example
 * const contacts = [testContact, testContact, testContact];
 * getAndOthersSummaryOfContactsText(contacts);
 * // returns 'and 2 others'
 * @param {PartnershipMember[]} partnershipMembers
 * @return {string} - '', 'and 1 other', or 'and n others'
 */
export const getAndOthersSummaryOfContactsText = (partnershipMembers) => {
  const contactCount = lengthOf(partnershipMembers);
  const moreThanOneContact = isGreaterThan(contactCount, 1);
  const moreThanTwoContacts = isGreaterThan(contactCount, 2);

  // if there is more than one contact, we're going to count how many "other" contacts there are
  let andOthers = ternary(moreThanOneContact, `and ${contactCount - 1} other`, '');

  if (moreThanTwoContacts) {
    // and other => and others
    andOthers += 's';
  }

  return andOthers;
};

/**
 * Create tooltip content text string for hidden membership contacts
 * @param {object} options
 * @param {boolean} isNotLast - is current member not the last item in the array
 * @param {PartnershipMember} options.partnershipMember - current partnership member object
 * @returns {string|null} - Summaries of hidden contacts inside AddedContactsSummary block
 */
export const getSummaryOfContactsTooltipContent = ({ isNotLast, partnershipMember }) => {
  if (!partnershipMember) {
    return null;
  }

  const fullName = getMembershipFullName(partnershipMember);
  const emailAddress = getMembershipEmailAddress(partnershipMember);
  const phoneNumber = getMembershipPhoneNumber(partnershipMember);

  // We need to add comma to all entries except the last one.
  const suffix = ternary(isNotLast, ',', '');

  if (fullName && emailAddress) {
    return `${fullName} (${emailAddress})${suffix}`;
  }
  if (fullName) {
    return `${fullName}${suffix}`;
  }
  if (emailAddress) {
    return `${emailAddress}${suffix}`;
  }
  if (phoneNumber) {
    return `${phoneNumber}${suffix}`;
  }
  return '';
};
