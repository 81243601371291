import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';

import { ButtonLabels } from 'constants/buttons';
import { Intent } from 'constants/ui';

import { ternary } from 'helpers/utility';

/**
 * Neutral button that displays either 'Show All' or 'Show fewer' based on app state.
 * Usable for any lists where only N number of items are displayed by default.
 * @param {ComponentProps} props
 * @return {null|StatelessComponent}
 */
const ToggleAllButton = (props) => {
  const { onClick, shouldDisplayFewer, ...rest } = props;

  const text = React.useMemo(
    () => ternary(shouldDisplayFewer, ButtonLabels.SHOW_FEWER, ButtonLabels.SHOW_ALL),
    [shouldDisplayFewer],
  );

  return (
    <ButtonV2 intent={Intent.NEUTRAL} onClick={onClick} {...rest}>
      {text}
    </ButtonV2>
  );
};

ToggleAllButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  shouldDisplayFewer: PropTypes.bool,
};

ToggleAllButton.defaultProps = {
  shouldDisplayFewer: undefined,
};

export default ToggleAllButton;
