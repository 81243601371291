import { BulkActionTypesApiMap } from 'constants/bulkActions';
import { DateFormats } from 'constants/date';
import { BULK_ACTION_ITEM_QUEUED } from 'constants/debug/bulkActions';

import { formatDate } from 'helpers/date';
import { getIsItemQueueingOnFromLocalStorage } from 'helpers/debug/bulkActions';
import { areDevtoolsEnabled } from 'helpers/env';
import { and, ternary } from 'helpers/utility';

import { itemApproversForCreateItem } from './item';

/**
 * @param {ReduxFormValues} values
 * @param {ComponentProps} props
 * @param {OptionsArg} options
 * @param {ItemKind} options.kind
 * @returns {{ id: Id, itemApprovers: Object[] }}
 */
export const bulkImportForFinalizeItems = (values, props, options) => {
  const {
    form: { id },
  } = values;
  const { approvalLevels, currentImport } = props;
  const { kind } = options;

  const maxItemAmount = parseFloat(currentImport.summary.totals.max);

  return {
    id,
    itemApprovers: itemApproversForCreateItem(approvalLevels, { ...values, kind }, maxItemAmount),
  };
};

/**
 * Transform payload before submitting bulk action
 * @param {Object} params
 * @param {String} params.bulkAction
 * @param {String} params.currentMembershipId
 * @param {Item[]} params.items
 * @param {Object} params.values
 * @return {{ entities: Object[], meta: {}, action: String }}
 */
export const transformPayloadForBulkActionSubmit = ({ bulkAction, currentMembershipId, items, values }) => {
  const additionalProps = {};

  if (values.item?.dateScheduled) {
    additionalProps.actionMeta = {
      dateScheduled: formatDate(values.item?.dateScheduled, DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY),
    };
  }

  return {
    action: ternary(
      and(areDevtoolsEnabled(), getIsItemQueueingOnFromLocalStorage()),
      BULK_ACTION_ITEM_QUEUED,
      BulkActionTypesApiMap[bulkAction],
    ),
    meta: {
      entities: items.map((item) => ({ id: item.id, version: item.version })),
    },
    requester: { id: currentMembershipId },
    ...additionalProps,
  };
};
