import { z } from 'zod';
export const businessType = z.enum([
    'c_corporation',
    's_corporation',
    'partnership',
    'sole_proprietorship',
    'trust_estate',
    'llc_single_member',
    'llc_c_corp',
    'llc_s_corp',
    'llc_partnership',
    'other',
]);
export const governmentIdType = z.enum(['ssn', 'ein']);
