import React from 'react';
import { useMedia } from 'react-use';
import { FullPageModal } from 'components';
import { Modal } from 'components/modal';
import { mobileMediaQuery } from 'constants/mediaQuery';
import { ZIndexLayers } from 'constants/ui';
import { noop, ternary } from 'helpers/utility';
import { getZIndex } from 'helpers/zIndex';
import { modalContentHeight, modalContentWidth } from '../constants';
import { QuickswitchErrorModalContent } from './components';
import './QuickswitchErrorModal.scss';
const QuickswitchErrorModal = ({ isModalOpen }) => ternary(useMedia(mobileMediaQuery), React.createElement(FullPageModal, { body: React.createElement(QuickswitchErrorModalContent, null), modalState: isModalOpen, onCloseModal: noop, overrideStyle: {
        overlay: { zIndex: getZIndex(ZIndexLayers.MODAL.OVERLAY, 25) },
    }, showCloseButton: false }), React.createElement(Modal, { body: React.createElement(QuickswitchErrorModalContent, null), hasInlineFooter: true, hideHeader: true, modalState: isModalOpen, onCloseModal: noop, overrideStyle: {
        content: { maxHeight: modalContentHeight, width: modalContentWidth },
    } }));
export default QuickswitchErrorModal;
