export const CompanyBeneficialOwnerStatus = {
  DOCUMENT: 'document',
  INCOMPLETE: 'incomplete',
  PENDING: 'pending',
  VERIFIED: 'verified',
};

/**
 * TODO: [FRON-2153] Refactor CompanyPlatformTypes/PartnershipPartnerStatus enum to dedupe and create a single source of
 * truth.
 * @enum {string}
 */
export const CompanyPlatformTypes = {
  ADDED: 'added',
  ARCHIVED: 'archived',
  EXTERNAL: 'external',
  FRAUD: 'fraud',
  INVITED: 'invited',
  REGISTERED: 'registered',
  SIGNUP: 'signup',
};

export const CompanySearchPartnerTypes = {
  EXISTING: 'existing',
  NEW: 'new',
};

export const commonBusinessTypes = {
  C_CORP: 'c_corporation',
  S_CORP: 's_corporation',
  PARTNERSHIP: 'partnership',
  SOLE_PROPRIETOR: 'sole_proprietorship',
};

export const companyBusinessTypes = {
  ...commonBusinessTypes,
  LLC: 'llc',
};

export const commonBusinessTypeLabels = {
  [commonBusinessTypes.C_CORP]: 'C-Corporation',
  [commonBusinessTypes.S_CORP]: 'S-Corporation',
  [commonBusinessTypes.PARTNERSHIP]: 'Partnership',
  [commonBusinessTypes.SOLE_PROPRIETOR]: 'Sole Proprietor',
};

export const companyBusinessTypeLabels = {
  ...commonBusinessTypeLabels,
  [companyBusinessTypes.LLC]: 'LLC',
};

export const companySizeList = [
  { id: '77c96bed-6890-4f63-8d78-01beb9149278', value: '1-50', text: '1-50' },
  {
    id: 'a862c587-351b-487d-a4df-88ade3459d7d',
    value: '51-100',
    text: '51-100',
  },
  {
    id: 'cd344a0e-0058-4735-903e-4a00a10b75be',
    value: '101-250',
    text: '101-250',
  },
  {
    id: '87c62bdd-3d74-4e2f-8d1b-024f60b77b11',
    value: '251-1000',
    text: '251-1000',
  },
  { id: '35c94b7b-1dfe-4247-ae74-0fb99d54aa1e', value: '1001+', text: '1001+' },
];

export const companyBusinessTypeOptions = [
  {
    id: 'ed6aa747-12fb-423f-a06d-4d54c7b5e5cd',
    value: companyBusinessTypes.LLC,
    text: companyBusinessTypeLabels[companyBusinessTypes.LLC],
  },
  {
    id: '44fe8211-9807-4533-8b43-e51935ccfe31',
    value: companyBusinessTypes.C_CORP,
    text: companyBusinessTypeLabels[companyBusinessTypes.C_CORP],
  },
  {
    id: '788d0757-92dc-4e84-b195-5649da346274',
    value: companyBusinessTypes.S_CORP,
    text: companyBusinessTypeLabels[companyBusinessTypes.S_CORP],
  },
  {
    id: '3f9b00e0-88a6-414e-bcaf-cff0c778f819',
    value: companyBusinessTypes.PARTNERSHIP,
    text: companyBusinessTypeLabels[companyBusinessTypes.PARTNERSHIP],
  },
  {
    id: 'ec34899a-ae94-4d54-a763-59131201ea86',
    value: companyBusinessTypes.SOLE_PROPRIETOR,
    text: companyBusinessTypeLabels[companyBusinessTypes.SOLE_PROPRIETOR],
  },
];
