import React from 'react';
import { Icon, IconNames, Text } from 'components';
import { TooltipMUI } from 'components/tooltip';
import { colors, typography, sizes } from 'constants/styles';
import { TooltipPadding } from 'constants/tooltip';
import './QuickswitchMembershipList.scss';
const QuickswitchMembershipListIcon = ({ isReauthenticationRequired, }) => {
    const iconName = isReauthenticationRequired ? IconNames.LOG_IN : IconNames.LINK;
    if (isReauthenticationRequired) {
        return (React.createElement(TooltipMUI, { arrow: true, padding: TooltipPadding.SKINNY, style: { backgroundColor: 'white' }, title: React.createElement(Text.Regular, { color: typography.TextColor.GREY_X_DARK, size: typography.TextSize.LEVEL_100 }, "Reauthentication is required for this account.") },
            React.createElement(Icon, { color: colors.colorBlueBoldHex, icon: iconName, size: sizes.iconSizes.MEDIUM })));
    }
    return React.createElement(Icon, { color: colors.colorBlueBoldHex, icon: iconName, size: sizes.iconSizes.MEDIUM });
};
export default QuickswitchMembershipListIcon;
