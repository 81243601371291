import { anyValues, ternary } from 'helpers/utility';

import { baseLockedIndicatorTooltipProps } from '../constants';

/**
 * If there are tooltip props we should show, merge them with base props for consistent styling.
 *
 * @param {ObjectMaybe} [tooltipProps]
 * @returns {Object|undefined}
 */
export const mergeLockedIndicatorTooltipProps = (tooltipProps) =>
  ternary(
    anyValues(tooltipProps),
    {
      ...baseLockedIndicatorTooltipProps,
      ...tooltipProps,
    },
    undefined,
  );
