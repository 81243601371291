import _cloneDeep from 'lodash/cloneDeep';
import _compact from 'lodash/compact';
import _difference from 'lodash/difference';
import _differenceBy from 'lodash/differenceBy';
import _differenceWith from 'lodash/differenceWith';
import _flatMapDeep from 'lodash/flatMapDeep';
import _flatten from 'lodash/flatten';
import _flattenDeep from 'lodash/flattenDeep';
import _get from 'lodash/get';
import _gt from 'lodash/gt';
import _head from 'lodash/head';
import _includes from 'lodash/includes';
import _intersection from 'lodash/intersection';
import _isArray from 'lodash/isArray';
import _isBoolean from 'lodash/isBoolean';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _isFunction from 'lodash/isFunction';
import _isNull from 'lodash/isNull';
import _isNumber from 'lodash/isNumber';
import _isObject from 'lodash/isObject';
import _last from 'lodash/last';
import _lt from 'lodash/lt';
import _map from 'lodash/map';
import _pullAll from 'lodash/pullAll';
import _pullAllBy from 'lodash/pullAllBy';
import _pullAllWith from 'lodash/pullAllWith';
import _set from 'lodash/set';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import {} from 'interfaces/global';
import {} from './atomic.types';
export const isArray = (value) => _isArray(value);
export const isBool = (value) => _isBoolean(value);
export const isFn = (value) => _isFunction(value);
export const isNaN = (value) => Number.isNaN(value);
export const isNotNaN = (value) => !isNaN(value);
export const isNull = (value) => _isNull(value);
export const isNum = (value) => _isNumber(value);
export const isObject = (value) => _isObject(value) && !isArray(value);
export const isNotObject = (value) => !isObject(value);
export const isString = (value) => typeof value === 'string';
export const isUndef = (value) => typeof value === 'undefined';
export const allEntries = (obj) => {
    if (!obj || !isObject(obj)) {
        return [];
    }
    return Object.entries(obj);
};
export const allKeys = (obj) => {
    if (!obj || !isObject(obj)) {
        return [];
    }
    return Object.keys(obj).filter((key) => !!key);
};
export const objectHasKey = (obj, key) => {
    if (!obj || !isObject(obj) || !key || (!isString(key) && !isNum(key))) {
        return false;
    }
    return Object.prototype.hasOwnProperty.call(obj, key);
};
export const allValues = (obj) => {
    if (!obj || !isObject(obj)) {
        return [];
    }
    return Object.values(obj);
};
export const firstValue = (arr = []) => _head(arr);
export const lastValue = (arr = []) => _last(arr);
export const firstKey = (value = {}) => firstValue(allKeys(value));
export const forEachEntry = (obj, callback) => allEntries(obj).forEach(callback);
export const commonKeyExtractor = (component) => component?.key;
export const valueForKey = _get;
export const firstValueForKeyInArray = (items, itemProp) => {
    const firstItem = items.find((item) => item?.[itemProp]);
    return valueForKey(firstItem, itemProp);
};
export const asArrayElement = (val, options = {}) => {
    const { nestArrays = false } = options;
    if (isUndef(val) || (isArray(val) && !nestArrays)) {
        return val;
    }
    return [val];
};
export const concat = (...args) => ''.concat(...args.filter(isString));
export const getObjectDiff = (previous, current) => allKeys(previous).reduce((map, key) => {
    if (previous[key] !== current[key]) {
        return {
            ...map,
            [key]: current[key],
        };
    }
    return map;
}, {});
export const lengthOf = (value) => value?.length ?? 0;
export const valueOrDefault = (value, defaultValue) => value || defaultValue;
export const firstValueOrDefault = (values, defaultValue) => valueOrDefault(firstValue(values?.filter(Boolean)), defaultValue);
export const firstValueOrEmptyString = (values) => firstValueOrDefault(values, '');
export const any = (array) => array.some((val) => !!val);
export const anyValues = (obj) => (isObject(obj) ? any(Object.values(obj)) : false);
export const areAllEqual = (array) => array.every((val) => val === array[0]);
export const becameFalsy = (prevValue, nextValue) => Boolean(prevValue && !nextValue);
export const becameTruthy = (prevValue, nextValue) => Boolean(!prevValue && nextValue);
export const call = (fn, ...givenArgs) => (...callbackArgs) => fn(...givenArgs, ...callbackArgs);
export const every = (array) => array.every((val) => !!val);
export const exists = (value) => Boolean(value);
export const identity = (value) => value;
export const getValuePropOrIdentity = (val) => {
    if (isObject(val) && val.value) {
        return val.value;
    }
    return identity(val);
};
export const hasLength = (value) => lengthOf(value) > 0;
export const callIfIsFn = (value) => (isFn(value) ? value() : false);
export const callWithArgsIfIsFn = (value, ...args) => (isFn(value) ? value(...args) : false);
export const flattenArray = (arr, options = {}) => {
    const { deep = true } = options;
    if (deep) {
        return _flattenDeep(arr);
    }
    return _flatten(arr);
};
export const compact = (array) => _compact(array);
export const hasValueOrZero = (value) => !!(value || value === 0);
export const hasZeroLength = (value) => value?.length === 0;
export const intersection = (...arrays) => _intersection(...arrays);
export const isEmptyObject = (value) => isObject(value) && Object.keys(value).length === 0;
export const isEqual = (val1, val2) => _isEqual(val1, val2);
export const isLessOrEqual = (val1, val2) => val1 <= val2;
export const isEqualToAny = (value, array) => Boolean(array.find((arrayElement) => isEqual(arrayElement, value)));
export const isNotEqual = (val1, val2) => !_isEqual(val1, val2);
export const isIncluded = _includes;
export const isLength = (value, length) => isEqual(lengthOf(value), length);
export const isLessThan = (val1, val2) => _lt(val1, val2);
export const isGreaterThan = (val1, val2) => _gt(val1, val2);
export const isGreaterOrEqual = (val1, val2) => isGreaterThan(val1, val2) || isEqual(val1, val2);
export const isGreaterThanZero = (val1) => _gt(val1, 0);
export const isLtLastIndex = (index, arr) => Boolean(hasLength(arr) && index < arr.length - 1);
export const getBiggestNumberInList = (list) => {
    if (list.length === 0) {
        return undefined;
    }
    return Math.max(...list);
};
export const isOptionSelected = isEqual;
export const isValueEmpty = (value, allowEmptyString = false) => {
    if (typeof value === 'object') {
        return _isEmpty(value);
    }
    if (allowEmptyString && value === '') {
        return false;
    }
    return value === '' || value === undefined || value === null;
};
export const lastIndexOf = (arr) => lengthOf(arr) - 1;
export const lastElementIn = (arr) => arr[lastIndexOf(arr)];
export const secondLastElementIn = (arr) => arr[lastIndexOf(arr) - 1];
export const loopOverKeys = (obj, callback) => allKeys(obj).forEach(callback);
export const mapOverKeys = (obj, callback) => allKeys(obj).map(callback);
export const diffArrays = (checkArray, ...rest) => {
    const arrayOrProperty = lastElementIn(rest);
    if (!isArray(arrayOrProperty)) {
        const checkAgainstArrays = rest.slice(0, lastIndexOf(rest));
        return _differenceBy(checkArray, ...checkAgainstArrays, arrayOrProperty);
    }
    return _difference(checkArray, ...rest);
};
export const differenceWith = (arr1, arr2, comparator = _isEqual) => _differenceWith(arr1, arr2, comparator);
export const mapSet = (collection, setPath, setValue) => _map(collection, (obj) => {
    const objCopy = _cloneDeep(obj);
    _set(objCopy, setPath, setValue);
    return objCopy;
});
export const arrayWithout = (checkArray, removeValues, propOrCallback) => {
    const mutable = [...checkArray];
    if (isFn(propOrCallback)) {
        _pullAllWith(mutable, removeValues, propOrCallback);
    }
    else if (isString(propOrCallback)) {
        _pullAllBy(mutable, removeValues, propOrCallback);
    }
    else {
        _pullAll(mutable, removeValues);
    }
    return mutable;
};
export const narrow = (arr, ...filters) => {
    const [activeFilter, ...rest] = filters;
    if (activeFilter) {
        return narrow(arr.filter(activeFilter), ...rest);
    }
    return arr;
};
export const none = (array) => !any(array);
export const reduceKeys = (obj, accumulator, initial) => {
    if (!isObject(obj)) {
        return initial;
    }
    return Object.keys(obj).reduce(accumulator, initial);
};
export const ternary = (condition, ifTruthy, ifFalsy) => (condition ? ifTruthy : ifFalsy);
export const ternaryCall = (condition, callIfTruthy, callIfFalsy) => (condition ? callIfTruthy() : callIfFalsy());
export const toPathString = (...strings) => strings.reduce((path, str) => {
    if (isString(str) && hasLength(str)) {
        if (hasLength(path)) {
            return `${path}.${str}`;
        }
        return str;
    }
    return path;
}, '');
export const uniqueArray = (arr = []) => _uniq(arr);
export const uniqueArrayByProperty = (arr = [], property = 'id') => _uniqBy(arr, property);
export const handleDoesMemberIncludeItem = (item, searchKey, defaultXForm = undefined) => {
    if (isObject(item)) {
        if (searchKey) {
            return item[searchKey];
        }
        return Object.values(item);
    }
    return defaultXForm?.(item) ?? item;
};
export const doesMemberInclude = (collection, value, options = {}) => {
    const { searchIndex, searchKey } = options;
    let searchValues;
    if (isArray(collection)) {
        searchValues = _flatMapDeep(collection, (item) => handleDoesMemberIncludeItem(item, searchKey));
    }
    else {
        searchValues = _flatMapDeep(collection, (item) => handleDoesMemberIncludeItem(item, searchKey, Object.values));
    }
    return isIncluded(searchValues, value, searchIndex);
};
export const and = (firstVal, ...rest) => firstVal && (hasLength(rest) ? and(...rest) : firstVal);
export const or = (firstVal, ...rest) => firstVal || (hasLength(rest) ? or(...rest) : firstVal);
export const noop = () => { };
export const isIndexNotFound = (index) => isEqual(index, -1);
export const defaultToValueIfNotFound = (index, defaultIndex = 0) => ternary(isIndexNotFound(index), defaultIndex, index);
export const getValidValueOrDefault = (value, validValues, defaultValue) => allValues(validValues).includes(value) ? value : defaultValue;
