import { DateFormats } from '@routable/shared';
import pluralize from 'pluralize';

import { PAYMENTS_LIST_FILTERS } from 'constants/routes';

import { getFilterLabelForBreadcrumbs } from 'helpers/breadcrumbs';
import { getMembershipFullName } from 'helpers/memberships';
import { isSearchExecuted } from 'helpers/search';
import { capitalize } from 'helpers/stringHelpers';
import { formatDateWithZoneAbbr } from 'helpers/timezone';
import { isTabInbox } from 'helpers/urls';
import { isObject, isUndef } from 'helpers/utility';

/**
 * Check whether or not a PaginationBlockButton should be disabled.
 * @param {String} url
 * @param {Boolean} isFetching
 * @return {Boolean}
 */
export const isPaginationBlockButtonDisabled = (url, isFetching) => !url || isFetching;

/**
 * Returns appropriate search results text depending on parameters passed in.
 * @param {Object} props
 * @param {Number} [props.currentPage]
 * @param {Boolean} [props.isFetching]
 * @param {Number} [props.pageSize]
 * @param {Object} [props.searchData]
 * @param {Number} [props.totalResults]
 * @return {String}
 */
export const getPaginationBlockResultText = (props) => {
  const { currentPage, isFetching, pageSize, searchData, totalResults } = props;

  if (isFetching || isUndef(currentPage)) {
    return 'Loading ...';
  }

  if (totalResults < 1) {
    return 'No results found';
  }

  const previousPage = currentPage - 1;
  const isLastPage = currentPage * pageSize >= totalResults;

  const firstValue = totalResults > 0 ? pageSize * previousPage + 1 : 1;
  const lastValue = isLastPage ? totalResults : currentPage * pageSize;
  let resultText = `Showing ${firstValue}-${lastValue} of ${totalResults}`;

  // If there's a search result, add the word 'results' to the end of the text
  if (isSearchExecuted(searchData)) {
    resultText += ' results';
  }

  return resultText;
};

/**
 * Assemble filters object into an array of objects to allow for duplicate filter keys
 * If filter value is an object, split into separate array elements
 * @param {Object} filters
 * @example
  filters: {
    businessType: {
      0: 'llc',
      1: 'partnership',
      2: 'sole_proprietorship',
    },
    companyType: "personal",
    totalPaidGte: "600",
  }

  result: [
    {businessType: "llc"},
    {businessType: "partnership"},
    {businessType: "sole_proprietorship"},
    {companyType: "personal"},
    {totalPaidGte: "600"},
  ]
 *
 * @return {Array}
 */
export const getPaginationBlockFiltersList = (filters) => {
  const splitFilters = [];

  Object.entries(filters).forEach(([key, value]) => {
    // Filters with multiple values per filter will come back as an object
    if (isObject(value)) {
      Object.values(value).forEach((filterValue) => {
        splitFilters.push({
          [key]: filterValue,
        });
      });
      return;
    }

    // Otherwise, value is a string, push directly to splitFilters
    splitFilters.push({
      [key]: value,
    });
  });

  return splitFilters;
};

/**
 * Returns label used on the pagination block menu additional filters button
 * @param {Number} filterCount
 * @return {String}
 */
export const getPaginationBlockFilterCountButtonLabel = (filterCount) =>
  `+${filterCount} ${pluralize('filter', filterCount)}`;

/**
 * Returns title for pagination block based on current filter and tab
 * @param {String} filter
 * @param {String} tab
 * @param {Object} bulkImport
 * @return {String}
 */
export const getPaginationBlockTitle = (filter, tab, bulkImport) => {
  if (bulkImport) {
    const { created, user } = bulkImport;
    const name = getMembershipFullName(user);
    return `Uploaded by ${name}, ${formatDateWithZoneAbbr(created, DateFormats.SLASH_DATE_TIME_TIMEZONE)}`;
  }
  if (filter === PAYMENTS_LIST_FILTERS.ALL) {
    return isTabInbox(tab)
      ? capitalize(`${PAYMENTS_LIST_FILTERS.ALL} bills`)
      : capitalize(`${PAYMENTS_LIST_FILTERS.ALL} ${tab}`);
  }

  return getFilterLabelForBreadcrumbs(filter, tab);
};
