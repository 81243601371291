import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import { SelectFieldV2 } from 'components';
import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';

import { companyBusinessTypes } from 'constants/company';
import { usStates } from 'constants/i18n';

import { requiredValidator } from 'helpers/fieldValidation';

import { signupFlowForm } from 'modules/signup-v3/constants';
import { containerWidthMap } from 'modules/signup-v3/constants/container';

import {
  currentCompanyVerificationUpdateAllowedSelector,
  isUpdatingCurrentCompanySelector,
} from 'selectors/currentCompanySelectors';

import { fields } from '../fields';
import FlowStepperMain from '../FlowStepperMain';
import { viewSteps } from '../helpers/viewSteps';

const STATE_OF_INCORPORATION_OPTIONAL = [companyBusinessTypes.SOLE_PROPRIETOR, companyBusinessTypes.PARTNERSHIP];

const businessTypeOptions = {
  llc: {
    text: 'LLC',
    value: companyBusinessTypes.LLC,
  },
  'c-corporation': {
    text: 'C-Corporation',
    value: companyBusinessTypes.C_CORP,
  },
  's-corporation': {
    text: 'S-Corporation',
    value: companyBusinessTypes.S_CORP,
  },
  partnership: {
    text: 'Partnership',
    value: companyBusinessTypes.PARTNERSHIP,
  },
  'sole-proprietor': {
    text: 'Sole Proprietor',
    value: companyBusinessTypes.SOLE_PROPRIETOR,
  },
};

const BusinessTypeField = ({ name, isDisabled }) => (
  <Field
    component={SelectFieldV2}
    dataFullStory
    dataTestId="business-type"
    isDisabled={isDisabled}
    isLocked={isDisabled}
    isRequired
    label="Business type"
    name={name}
    options={businessTypeOptions}
    type="text"
    validate={requiredValidator}
  />
);

const StateOfIncorporationField = ({ name, isRequired, isDisabled }) => (
  <Field
    component={SelectFieldV2}
    dataFullStory
    dataTestId="state-of-incorporation"
    isClearable
    isDisabled={isDisabled}
    isLocked={isDisabled}
    isRequired={isRequired}
    label="State of incorporation"
    name={name}
    options={usStates}
    type="text"
    validate={isRequired && requiredValidator}
  />
);

const BusinessTypeMain = ({ activeView, invalid }) => {
  const updatedAllowed = useSelector(currentCompanyVerificationUpdateAllowedSelector);
  const isUpdating = useSelector(isUpdatingCurrentCompanySelector);
  const formValues = useSelector(getFormValues(signupFlowForm)) || {};
  const businessTypeValue = get(formValues, fields.businessType);
  const stateOfIncorporationIsOptional = STATE_OF_INCORPORATION_OPTIONAL.includes(businessTypeValue);

  return (
    <FlowStepperMain
      containerWidth={containerWidthMap.small}
      fields={
        <div className="max-w-[332px] flex flex-col gap-6">
          {!updatedAllowed && <CannotUpdateFieldHint />}
          <BusinessTypeField isDisabled={!updatedAllowed} name={fields.businessType} />

          {businessTypeValue && (
            <StateOfIncorporationField
              isDisabled={!updatedAllowed}
              isRequired={!stateOfIncorporationIsOptional}
              name={fields.stateOfIncorporation}
            />
          )}
        </div>
      }
      invalid={invalid || !updatedAllowed}
      onBack={viewSteps[activeView].previous}
      onNext={{
        ...viewSteps[activeView].next,
        isLoading: isUpdating,
      }}
      onSkip={updatedAllowed && viewSteps[activeView].skip}
      subTitle={{ content: 'This must match what’s on your W-9 form.' }}
      title={{ content: 'What’s your business type?' }}
    />
  );
};

export default BusinessTypeMain;
