import { views } from '../constants';

export const viewSteps = {
  businessVerification: {
    previous: { path: undefined },
    next: { path: views.legalBusinessName, content: 'Continue' },
    skip: {
      path: views.businessRepresentative,
      content: 'Skip to representative',
    },
    type: views.businessVerification,
  },
  legalBusinessName: {
    previous: { path: views.businessVerification },
    next: { path: views.businessType },
    skip: { path: views.businessType },
    type: views.businessVerification,
  },
  businessType: {
    previous: { path: views.legalBusinessName },
    next: { path: views.EIN },
    skip: { path: views.EIN },
    type: views.businessVerification,
  },
  EIN: {
    previous: { path: views.businessType },
    next: { path: views.businessCategory },
    skip: { path: views.businessCategory },
    type: views.businessVerification,
  },
  businessCategory: {
    previous: { path: views.EIN },
    next: { path: views.businessAddress },
    skip: { path: views.businessAddress },
    type: views.businessVerification,
  },
  businessAddress: {
    previous: { path: views.businessCategory },
    next: { path: views.businessPhoneNumber },
    skip: { path: views.businessPhoneNumber },
    type: views.businessVerification,
  },
  businessPhoneNumber: {
    previous: { path: views.businessAddress },
    next: { path: views.uploadDocument },
    skip: { path: views.uploadDocument },
    type: views.businessVerification,
  },
  [views.uploadDocument]: {
    previous: { path: views.businessPhoneNumber },
    next: { path: views.businessRepresentative, content: 'Continue' },
    skip: { path: views.businessRepresentative },
    type: views.businessVerification,
  },
  [views.businessRepresentative]: {
    previous: { path: views.uploadDocument },
    next: { path: views.qualification },
    skip: { path: views.review, content: 'Skip to Review' },
    type: views.businessRepresentative,
  },
  [views.qualification]: {
    previous: { path: views.businessRepresentative },
    next: { path: views.personalInformation },
    skip: { path: views.personalInformation },
    type: views.businessRepresentative,
  },
  [views.personalInformation]: {
    previous: { path: views.qualification },
    next: { path: views.representativeAddress },
    skip: { path: views.representativeAddress },
    type: views.businessRepresentative,
  },
  [views.representativeAddress]: {
    previous: { path: views.personalInformation },
    next: { path: views.representativeIDNumber },
    skip: { path: views.representativeIDNumber },
    type: views.businessRepresentative,
  },
  [views.representativeIDNumber]: {
    previous: { path: views.representativeAddress },
    next: { path: views.representativeUploadDocument },
    skip: { path: views.representativeUploadDocument },
    type: views.businessRepresentative,
  },
  [views.representativeUploadDocument]: {
    previous: { path: views.representativeIDNumber },
    next: { path: views.review, content: 'Continue' },
    skip: { path: views.review },
    type: views.businessRepresentative,
  },
  [views.review]: {
    previous: { path: views.businessRepresentative },
    next: { path: undefined },
    skip: { path: undefined },
    type: views.review,
  },
};
