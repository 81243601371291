import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Icon, IconNames } from 'components/icon';

import { colors, sizes } from 'constants/styles';

import { callOnEnterKeyEvent, createPersistedEventCallback } from 'helpers/events';
import { getSortParamAfterClick } from 'helpers/itemSort';
import { callWithArgsIfIsFn, ternary } from 'helpers/utility';

import TableCellText from '../TableCellText';

/**
 * Table header cell <th> component for new tables used with react-table
 * @param {ComponentProps} props
 * @param {String} [props.className]
 * @param {Boolean} [props.isSortable]
 * @param {String} [props.itemSortParam]
 * @param {function} [props.onClick] - blank onClick to register event
 * @param {function} [props.onSort] - event dispatch for sort
 * @param {Node} [props.rightIndicator]
 * @param {Object} [props.style]
 * @param {Object} [props.sortType]
 * @return {StatelessComponent}
 */
const TableHeaderCell = ({
  children,
  className,
  isSortable,
  itemSortParam,
  onClick,
  onSort,
  rightIndicator,
  sortType,
  style,
}) => {
  const isSorted = itemSortParam?.includes(sortType?.PARAM) && isSortable;
  const isDescending = isSorted && itemSortParam?.charAt(0) === '-';
  const [isHovered, setIsHovered] = useState(false);

  const handleColor = () => {
    if (!isSorted && isHovered) {
      return colors.colorGreyMedHex;
    }
    if (isSorted) {
      return colors.colorGreyDarkHex;
    }
    return colors.colorWhiteHex;
  };

  const handleIcon = () => {
    if (isSorted && !isDescending) {
      return IconNames.ARROW_UP;
    }
    return IconNames.ARROW_DOWN;
  };

  const indicator = ternary(
    isSortable && (isSorted || isHovered),
    <Icon color={handleColor()} icon={handleIcon()} size={sizes.iconSizes.MEDIUM} />,
    rightIndicator,
  );

  const handleSort = ternary(isSortable, onClick, undefined);

  const handleClick = (event) => {
    if (isSortable) {
      onSort(getSortParamAfterClick(isDescending, isSorted, sortType.PARAM));
    }
    callWithArgsIfIsFn(handleSort, event);
  };

  const handleKeyPress = React.useCallback(
    callOnEnterKeyEvent(
      createPersistedEventCallback((event) => {
        if (isSortable) {
          onSort(getSortParamAfterClick(isDescending, isSorted, sortType.PARAM));
        }
        callWithArgsIfIsFn(handleSort, event);
      }),
    ),
    [handleSort],
  );

  const tabIndex = ternary(isSortable, '0', undefined);

  return (
    <th
      className={classNames({
        'cursor--default--important': !isSortable,
        [className]: !!className,
      })}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={style}
      tabIndex={tabIndex}
    >
      <TableCellText
        className={classNames({
          'table-header--text': true,
          semibold: true,
          'font-color--grey--x-dark': true,
        })}
      >
        {children}
      </TableCellText>

      {indicator && <div className="right-indicator">{indicator}</div>}
    </th>
  );
};

TableHeaderCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isSortable: PropTypes.bool,
  itemSortParam: PropTypes.string,
  onClick: PropTypes.func,
  onSort: PropTypes.func,
  rightIndicator: PropTypes.node,
  sortType: PropTypes.shape({}),
  style: PropTypes.shape({}),
};

TableHeaderCell.defaultProps = {
  children: undefined,
  className: undefined,
  isSortable: undefined,
  itemSortParam: undefined,
  onClick: undefined,
  onSort: undefined,
  rightIndicator: undefined,
  sortType: undefined,
  style: undefined,
};

export default TableHeaderCell;
