import { APPROVER_FIELD_PREFIX } from 'constants/createItem';

import { isCurrentPathCreateItemStateNew, isCurrentPathItemEdit } from 'helpers/items';
import { allKeys, isGreaterThanZero } from 'helpers/utility';

/**
 * Are we creating or editing a single item v.s. importing many from a ledger.
 * @param {Object} [location=window.location]
 * @returns {boolean}
 */
export const isSingleEntityInvoice = (location = window.location) =>
  isCurrentPathCreateItemStateNew(location) || isCurrentPathItemEdit(location);

/**
 * Are we importing multiple items from a ledger
 * @param {Object} [location=window.location]
 * @returns {boolean}
 */
export const isMultipleEntitiesInvoices = (location = window.location) => !isSingleEntityInvoice(location);

/**
 * In CreateItems, before submitting an item, we parse the dynamic table/form data to see which (if any)
 * fields have the `required` property set to 'recommended'. If there are recommended fields that were
 * left empty, we'll show a confirmAlert before submitting. This function constructs the text to display
 * inside the confirmAlert as blockContent.
 * @param {Object.<string, number>} warnings
 * @return {string}
 */
export const getCreateItemMissingRecommendedFieldsAlertBlockText = (warnings) =>
  allKeys(warnings).reduce((str, warningMessage, index) => {
    if (isGreaterThanZero(index)) {
      return `${str}, ${warningMessage} fields`;
    }

    return `${warningMessage} fields`;
  }, '');

/**
 * Pairs with getCreateItemMissingRecommendedFieldsAlertBlockText (above). This function constructs the text to display
 * inside the confirmAlert as topContent.
 * @param {string} kindText
 * @return {string}
 */
export const getCreateItemMissingRecommendedFieldsAlertTopText = (kindText) =>
  `This ${kindText} is missing one or more optional fields:`;

/**
 * Pairs with getCreateItemMissingRecommendedFieldsAlertBlockText (above). This function constructs the text to display
 * inside the confirmAlert as bottomContent.
 * @return {string}
 */
export const getCreateItemMissingRecommendedFieldsAlertBottomText = () => 'Are you sure you want to proceed?';

/**
 * Detects whether the field error object includes errors for bounced contacts
 * and returns which emails (and partnership member IDs) have bounced
 * This is a pretty ugly but unfortunately necessary parsing
 * see comments on https://warrenpay.atlassian.net/browse/FRON-2081 for more
 * @param {object} submitErrors object returned by parseErrorResponse
 * @param {ItemMember[]} itemMembers sent to the API when creating the item
 * @param {object} partnershipMembersById
 * @return {PartnershipMember[]}
 */
export const getBouncedContactsFromSubmitErrors = ({ submitErrors = {}, itemMembers, partnershipMembersById }) => {
  const bouncedPartnershipMembers = [];

  // first check if there are errors for the given itemMembers
  if (submitErrors.fields && submitErrors.fields.itemMembers) {
    // the keys of fields.itemMembers are the indices of the itemMembers[] initially sent in
    // we need these to find the correct itemMembers to then find the correct partnershipMembers in state
    const itemMemberErrorIndices = Object.keys(submitErrors.fields.itemMembers);

    itemMemberErrorIndices.forEach((itemMemberIndex) => {
      // figure out if there is an error returned for a given itemMember
      const itemMemberError = submitErrors.fields.itemMembers[itemMemberIndex];
      const { partnershipMember: bouncedPartnershipMemberErrors = [] } = itemMemberError;

      // if there is an error, does it indicate that the contact has bounced
      if (bouncedPartnershipMemberErrors.length) {
        const hasBouncedError = bouncedPartnershipMemberErrors.some(
          // unfortunately this isn't super clean but the API doesn't provide a clearer key yet :(
          (el) => el.includes('bounce'),
        );

        // if the contact has bounced, find the correct partnershipMember
        if (hasBouncedError) {
          const bouncedPartnershipMemberId = itemMembers[itemMemberIndex]?.partnershipMember?.id;
          const partnershipMember = partnershipMembersById[bouncedPartnershipMemberId];

          // now that we have the partnershipMember, return the email and ID
          if (partnershipMember) {
            bouncedPartnershipMembers.push(partnershipMember);
          }
        }
      }
    });
  }
  return bouncedPartnershipMembers;
};

export const getApproverFieldName = (position) => `${APPROVER_FIELD_PREFIX}${position}`;

/**
 * This helper is meant for the cases where an rateEstimate is present. These cases imply there's
 * a conversion rate between sender / receiver currencies.
 * Approvals need to be adjusted to the right currency.
 * e.g. Company is sending a payment to Hong Kong AND it has an approval limit of 1000 USD
 * rateEstimate will be present since there's a conversion, then the prop amountSender will have
 * the USD equivalent to the amount being sent in Hong Kong's currency.
 * */
export const getRateEstimateOrItemAmount = (rateEstimateResult, itemAmount = 0) =>
  rateEstimateResult?.amountSender ? Number(rateEstimateResult.amountSender) : itemAmount;
