import { updateCompany } from 'actions/companies';
import { createAddressRoutine, updateAddressRoutine } from 'actions/routines/addresses';
import {
  fetchExternalPartnershipRequestRoutine,
  submitPartnerCompanyGeneralInfoRoutine,
} from 'actions/routines/external';
import { updateMembershipRoutine } from 'actions/routines/membership';
import { submitCreatePartnershipRoutine } from 'actions/routines/partnership';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import {
  GET_CURRENT_COMPANY_SUCCESS,
  UPDATE_CURRENT_COMPANY_SUCCESS,
  UPDATE_ONBOARDING_COMPANY_SUCCESS,
} from 'types/currentCompany';
import { FETCH_MEMBERSHIP_SUCCESS, FETCH_MEMBERSHIPS_SUCCESS, UPDATE_MEMBERSHIP_SUCCESS } from 'types/memberships';
import { FETCH_PARTNERSHIPS_SUCCESS, FETCH_PARTNERSHIP_SUCCESS } from 'types/partnership';

const addressesByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
    case FETCH_PARTNERSHIPS_SUCCESS:
    case FETCH_PARTNERSHIP_SUCCESS:
    case GET_CURRENT_COMPANY_SUCCESS:
    case submitPartnerCompanyGeneralInfoRoutine.SUCCESS:
    case UPDATE_CURRENT_COMPANY_SUCCESS:
    case UPDATE_ONBOARDING_COMPANY_SUCCESS:
    case createAddressRoutine.SUCCESS:
    case updateAddressRoutine.SUCCESS:
    case submitCreatePartnershipRoutine.SUCCESS:
    case updateCompany.type:
      return deepMergeWithArrayReplacement(state, getObjectsByIdWithRelationships(action.payload.address));

    case FETCH_MEMBERSHIP_SUCCESS:
    case FETCH_MEMBERSHIPS_SUCCESS:
    case UPDATE_MEMBERSHIP_SUCCESS:
    case updateMembershipRoutine.SUCCESS:
      return deepMergeWithArrayReplacement(state, getObjectsByIdWithRelationships(action.payload.membershipAddress));

    default:
      return state;
  }
};

export default addressesByIdReducer;
