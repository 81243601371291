import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ToggleSwitch } from 'components/form';
import { FlexRow } from 'components/layout';
import { HeadingTitle, Text } from 'components/text';

import { typography } from 'constants/styles';

import { ternary } from 'helpers/utility';

/**
 * Header for the CompanyContactsTable. Includes a toggle to show/hide archived contacts.
 * @param {ComponentProps} props
 * @param {boolean} props.toggleIsVisible
 * @param {boolean} props.toggleIsDisabled
 * @param {function} props.onClickArchivedContactsToggle
 * @param {boolean} props.toggleIsActive
 * @returns {StatelessComponent}
 * @constructor
 */
const CompanyContactsTableSectionHeader = ({
  toggleIsVisible,
  toggleIsDisabled,
  onClickArchivedContactsToggle,
  toggleIsActive,
}) => (
  <FlexRow className="align-items--center margin-bottom--m justify-content--space-between" stackOnMobile={false}>
    <HeadingTitle>Contact list</HeadingTitle>

    {toggleIsVisible && (
      <ToggleSwitch
        isChecked={toggleIsActive}
        isDisabled={toggleIsDisabled}
        name="viewArchivedContacts"
        onChange={onClickArchivedContactsToggle}
      >
        <Text.Bold
          className={classNames({
            'margin-left--sm': true,
            'display--flex': true,
            'align-items--center': true,
          })}
          color={ternary(toggleIsActive, typography.TextColor.DEFAULT, typography.TextColor.DARK_JORDAN)}
          size={typography.TextSize.LEVEL_100}
        >
          View archived contacts
        </Text.Bold>
      </ToggleSwitch>
    )}
  </FlexRow>
);

CompanyContactsTableSectionHeader.propTypes = {
  toggleIsVisible: PropTypes.bool.isRequired,
  toggleIsDisabled: PropTypes.bool.isRequired,
  onClickArchivedContactsToggle: PropTypes.func.isRequired,
  toggleIsActive: PropTypes.bool.isRequired,
};

export default CompanyContactsTableSectionHeader;
