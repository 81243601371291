import PropTypes from 'prop-types';
import React from 'react';

import { IconNames, StepHeaderAction } from 'components';

import { ternary } from 'helpers/utility';

const DiscountToggle = ({ formUI, onToggle }) => (
  <StepHeaderAction
    actionText={ternary(formUI.showInvoiceDiscount, 'Remove discount', 'Add discount')}
    iconName={ternary(formUI.showInvoiceDiscount, IconNames.CROSS, IconNames.ADD)}
    onActionClick={() => onToggle(!formUI.showInvoiceDiscount)}
  />
);

DiscountToggle.propTypes = {
  formUI: PropTypes.shape().isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default DiscountToggle;
