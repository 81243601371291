import React from 'react';

import { FieldWrapper } from '../FieldWrapper';

import ApiLookupTableFieldBasic from './ApiLookupTableField';
import CheckboxTableFieldBasic from './CheckboxTableField';
import CurrencyTableFieldBasic from './CurrencyTableField';
import DateTableFieldBasic from './DateTableField';
import LookupTableFieldBasic from './LookupTableField';
import NumberTableFieldBasic from './NumberTableField';
import PercentTableFieldBasic from './PercentTableField';
import PhoneTableFieldBasic from './PhoneTableField';
import StaticTableFieldBasic from './StaticTableField';
import TextareaTableFieldBasic from './TextareaTableField';
import TextTableFieldBasic from './TextTableField';

const ApiLookupTableField = React.memo((props) => <FieldWrapper Component={ApiLookupTableFieldBasic} {...props} />);
const CheckboxTableField = React.memo((props) => <FieldWrapper Component={CheckboxTableFieldBasic} {...props} />);
const CurrencyTableField = React.memo((props) => <FieldWrapper Component={CurrencyTableFieldBasic} isMe {...props} />);
const DateTableField = React.memo((props) => <FieldWrapper Component={DateTableFieldBasic} {...props} />);
const LookupTableField = React.memo((props) => <FieldWrapper Component={LookupTableFieldBasic} {...props} />);
const NumberTableField = React.memo((props) => <FieldWrapper Component={NumberTableFieldBasic} isMe {...props} />);
const PercentTableField = React.memo((props) => <FieldWrapper Component={PercentTableFieldBasic} {...props} />);
const PhoneTableField = React.memo((props) => <FieldWrapper Component={PhoneTableFieldBasic} {...props} />);
const StaticTableField = React.memo((props) => <FieldWrapper Component={StaticTableFieldBasic} {...props} />);
const TextareaTableField = React.memo((props) => <FieldWrapper Component={TextareaTableFieldBasic} {...props} />);
const TextTableField = React.memo((props) => <FieldWrapper Component={TextTableFieldBasic} {...props} />);

export * from './PresentationalTableField';
export {
  ApiLookupTableField,
  CheckboxTableField,
  CurrencyTableField,
  DateTableField,
  LookupTableField,
  NumberTableField,
  PercentTableField,
  PhoneTableField,
  StaticTableField,
  TextareaTableField,
  TextTableField,
};
