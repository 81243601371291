import PropTypes from 'prop-types';
import React from 'react';

import { ReduxFormFieldRenderInput } from 'components/input';

import { InviteTeamMemberEmailTooltipText } from 'constants/ui';

import { isMembershipDuplicated } from 'helpers/memberships';
import { getClassNames } from 'helpers/ui';
import { isDefined, isValueEmpty, ternary } from 'helpers/utility';

import { AsyncInputStatusIcon } from './components';

import './AsyncInput.scss';

/**
 * Extension of ReduxFormFieldRenderInput to simplify the asynchronous validation UI. The component displays the correct
 * icon based on the isWarn and meta props.
 *
 * This component DOES NOT validate itself. To validate, pass the following to reduxForm():
 * - Define asyncValidate function
 * - Add this field name to asyncBlurFields
 *
 * @see Storybook > Form > AsyncInput
 * @param {ComponentProps} props
 * @returns {StatelessComponent}
 * @constructor
 */
const AsyncInput = (props) => {
  const { isWarn, meta, input } = props;
  const { warning } = meta;

  // can rely on the meta.warning property given by redux-form,
  // and/or on the isWarn prop for additional/external conditions
  const derivedIsWarn = ternary(isDefined(isWarn), isWarn, Boolean(warning));
  const isEmpty = isValueEmpty(input.value);

  const classNames = getClassNames(props, {
    'async-input': true,
    /**
     * Set the .warn class if the field has been async validated with a warning, instead of an error. The warn state
     * does not include our normal red highlighting around the field.
     */
    warn: derivedIsWarn,
  });

  const tooltip = isMembershipDuplicated(warning?.warningType) ? InviteTeamMemberEmailTooltipText : '';

  return (
    <ReduxFormFieldRenderInput {...props} className={classNames}>
      <AsyncInputStatusIcon isEmpty={isEmpty} isWarn={derivedIsWarn} meta={meta} tooltip={tooltip} />
    </ReduxFormFieldRenderInput>
  );
};

AsyncInput.propTypes = {
  isWarn: PropTypes.bool,
  meta: PropTypes.shape({
    warning: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }).isRequired,
};

AsyncInput.defaultProps = {
  isWarn: undefined,
};

export default AsyncInput;
