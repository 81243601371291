import { PaginationSizes } from 'constants/pagination';

import * as types from 'types/partnership';

const paginationInitialState = {
  count: 0,
  page: 1,
  pages: 1,
  pageSize: PaginationSizes.PARTNERSHIP_LIST,
};

const paginationReducer = (state = paginationInitialState, action) => {
  switch (action.type) {
    case types.FETCH_PARTNERSHIPS_SUCCESS: {
      const { count, page, pages, pageSize } = action.payload.meta.pagination;

      return {
        count,
        page,
        pages,
        pageSize,
      };
    }
    case types.UPDATE_PARTNERSHIPS_PAGINATION_COUNT_ADDED_ONE:
      return { ...state, count: state.count + 1 };
    default:
      return state;
  }
};

export default paginationReducer;
