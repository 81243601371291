import { dateModel } from '@routable/shared';
import { z } from 'zod';
import { taxEmailContactStatus, taxInfoStatus, taxRequestStatus, taxRiskSummary } from '../status';
export const taxRequestActionMemberDataModel = z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
});
export const taxRequestDataModel = z.object({
    id: z.string(),
    status: taxRequestStatus,
    expiresAt: z.string().nullable(),
    createdAt: z.string().nullable(),
    actionMember: taxRequestActionMemberDataModel,
});
export const baseTaxVendorDataModel = z.object({
    countryCode: z.string(),
    createdAt: dateModel,
    entityType: z.string(),
    name: z.string(),
    taxEmailContactStatus: taxEmailContactStatus,
    taxInfoStatus: taxInfoStatus,
    totalPaidInYear: z.string(),
    taxRequest: taxRequestDataModel.nullish(),
    riskSummary: taxRiskSummary.nullish().default('not_evaluated'),
});
