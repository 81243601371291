import { DateElement } from '@routable/shared';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { ReduxFormFieldRenderInput } from 'components';

import { tableTextFieldDefaultProps, tableTextFieldPropTypes } from 'constants/propTypes';

import { oneOfValuesFromObject } from 'helpers/propTypes';

import { useGetTableFieldProps } from 'modules/table/hooks';

import { formatDate, normalizeDate } from './helpers';

/**
 * Renders a date picker field for date values to be rendered in a table cell.
 * @param {ComponentProps} props
 * @param {Function} props.getIsDisabled
 * @param {Function} props.getIsRequired
 * @param {string} props.initialValue
 * @param {string} props.name
 * @param {string} props.placeholder
 * @param {RestOfProps} props.rest
 * @param {Function|Function[]} props.validate
 * @returns {StatelessComponent}
 */
const DateTableField = ({ formValues, getIsDisabled, name, getIsRequired, rowIdx, validate, sectionPath }) => {
  const fieldPath = `${sectionPath}.${rowIdx}`;
  const { isDisabled, isRequired, validators } = useGetTableFieldProps({
    formValues,
    getIsDisabled,
    getIsRequired,
    fieldPath,
    rowIdx,
    validate,
  });

  return (
    <Field
      className="ledger-form-field"
      component={ReduxFormFieldRenderInput}
      format={formatDate}
      isDebounced
      isDisabled={isDisabled}
      isRequired={isRequired}
      name={name}
      normalize={normalizeDate}
      type="text"
      validate={validators}
    />
  );
};

DateTableField.propTypes = {
  ...tableTextFieldPropTypes,
  /* date and time props used in component hooks */
  /* eslint-disable react/no-unused-prop-types */
  dateElements: PropTypes.arrayOf(oneOfValuesFromObject(DateElement)).isRequired,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  /* eslint-enable react/no-unused-prop-types */
};

DateTableField.defaultProps = {
  ...tableTextFieldDefaultProps,
  dateFormat: undefined,
  timeFormat: undefined,
};

export default React.memo(DateTableField);
