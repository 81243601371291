import dayjs from 'dayjs';
import pluralize from 'pluralize';
import React from 'react';

import { LinkNewPage } from 'components/link';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { ItemKinds, ItemStatuses } from 'enums/items';

import { formatCurrencyUSDExplicit } from 'helpers/currencyFormatter';
import { getPaymentsOrInvoicesText, getPaymentsOrInvoicesTextForExternalFlow } from 'helpers/items';
import { getMembershipNameOrEmail } from 'helpers/memberships';
import { getVendorOrCustomerTitleFromItemKind } from 'helpers/partnerships';
import {
  isPaymentDeliveryMethodAch,
  isPaymentMethodDeliveryCheck,
  isPaymentMethodAvailableCheck,
} from 'helpers/paymentMethods';
import { getCompanyRouteByPartnershipId } from 'helpers/routeHelpers';
import { itemStatusToText } from 'helpers/status';
import { capitalize, getTextWithLeadingArticle } from 'helpers/stringHelpers';
import { getTimeStringFormattedForProbableTimezone } from 'helpers/timezone';
import { ternary } from 'helpers/utility';

const pendingApprovalsText = 'Incomplete approvals can cause delays in payment release.';

/**
 * Get the title for the CannotInviteTeamMemberAlreadyUserHint.
 * @param {Membership} member
 * @returns {string}
 */
export const getCannotInviteTeamMemberAlreadyUserHintTitle = (member) => {
  const nameOrEmail = getMembershipNameOrEmail(member);

  return `${nameOrEmail} is already a ${PLATFORM_DISPLAY_SHORT_NAME} user`;
};

/**
 * Get the title for the EmailAlreadyInvitedHint.
 * @param {Membership} member
 * @returns {string}
 */
export const getEmailAlreadyInvitedHintTitle = (member) => {
  const nameOrEmail = getMembershipNameOrEmail(member);
  return `${nameOrEmail} has already been invited to ${PLATFORM_DISPLAY_SHORT_NAME}, so you cannot invite them again.`;
};

/**
 * Returns the option title text, along with the appropriate leading 'a' or 'an'.
 * @param {string} title
 * @return {string}
 */
export const getLeadingTextForOptionTitle = (title) => getTextWithLeadingArticle(title, { capitalize: true });

/**
 * Gets amount over limit title for given max amount.
 * @param {string} title
 * @param {string} max
 * @return {string}
 */
export const getAmountAboveTransferLimitHintText = (title, max) =>
  `${getLeadingTextForOptionTitle(title)} payment cannot exceed ${formatCurrencyUSDExplicit(max, {
    symbol: '',
  })} per transfer. To proceed, switch to another delivery method or contact support to increase the limit.`;

/**
 * Gets balance too low hint text when prompting switch to expedited delivery.
 * @param {string} title
 * @return {string}
 */
export const getBalanceTooLowSwitchToExpeditedText = (title) =>
  `${getLeadingTextForOptionTitle(
    title,
  )} transaction is withdrawn from your account balance. This bill payment is more than the amount you have in your available balance.`;

/**
 * Gets ItemsNotSentHint title
 * @param {String} itemCount
 * @param {ItemKind} itemKind
 * @return {string}
 */
export const getItemsNotSentHintTitle = (itemCount, itemKind) =>
  `${itemCount} ${getPaymentsOrInvoicesText(
    itemKind,
    itemCount,
  )} with no send date will remain in "Ready to send" status until a payment date is selected.`;

/**
 * Gets ItemsScheduledHint title
 * @param {String} itemCount
 * @return {string}
 */
export const getItemsScheduledHintTitle = (itemCount) =>
  `${itemCount} ${getPaymentsOrInvoicesText(
    ItemKinds.PAYABLE,
    itemCount,
  )} will initiate once your vendors select their payment methods`;

/**
 * Returns the text to use for InvalidFundingAccount hint button.
 * @param {boolean} isNoValidAccounts - Whether we're displaying the hint due to no accounts added.
 * @return {string}
 */
export const getMatchAccountHintButtonText = (isNoValidAccounts) =>
  isNoValidAccounts ? 'Add a bank account' : 'Match bank account';

/**
 * Returns the text to use for InvalidFundingAccount clearing account matching hint.
 * @param {string} ledgerName
 * @return {string}
 */
export const getMatchClearingAccountHintText = (ledgerName) =>
  `To continue, you need to match this bank account on ${PLATFORM_DISPLAY_SHORT_NAME} with a clearing account on ${ledgerName}.`;

/**
 * Returns the text to use for InvalidFundingAccount bank account matching hint.
 * @param {string} ledgerName
 * @return {string}
 */
export const getMatchFundingAccountHintText = (ledgerName = 'your ledger') =>
  `To continue, you need to match this bank account on ${PLATFORM_DISPLAY_SHORT_NAME} with a bank account on ${ledgerName}.`;

/**
 * Returns the text to use for InvalidFundingAccount hint body.
 * @param {string} ledgerName
 * @param {Object} options
 * @param {boolean} options.isClearingAccount
 * @param {boolean} options.isNoValidAccounts
 * @return {string}
 */
export const getMatchAccountHintText = (ledgerName, options = {}) => {
  const { isClearingAccount, isNoValidAccounts } = options;

  if (isNoValidAccounts) {
    return 'To continue, you need to have at least one verified bank account.';
  }

  if (isClearingAccount) {
    return getMatchClearingAccountHintText(ledgerName);
  }

  return getMatchFundingAccountHintText(ledgerName);
};

/**
 * Returns the title for NumberOfErrorsShownHint
 * @param {Number} errorsCount
 * @return {String}
 */
export const getNumberOfErrorsShownHintTitle = (errorsCount) =>
  `1000 of ${errorsCount.toLocaleString()} errors are displayed below`;

/**
 * Returns payment-after-cutoff hint body text that describes the action for a given delivery method and option.
 * @param {String} deliveryMethod
 * @param {string} title
 * @return {string}
 */
export const getPaymentAfterCutoffMethodActionText = (deliveryMethod, title) =>
  isPaymentDeliveryMethodAch(deliveryMethod)
    ? `${title} transactions must initiate`
    : `${getLeadingTextForOptionTitle(title)} must be sent`;

/**
 * Returns arrival day text for payment after cutoff hint title from given
 * earliest speed
 * @param {number} earliest
 * @return {string}
 */
export const getPaymentAfterCutoffDayArrivalText = (earliest = 1) => `${earliest + 1} business days`;

/**
 * Returns the title to use for PaymentAfterCutoffHint.
 * @param {Object} params
 * @param {StringMaybe} params.title - Title of this delivery option (Same Day ACH, etc)
 * @param {StringMaybe} params.cutoff
 * @param {Object} params.speed
 * @param {String} [params.itemCount='1']
 * @param {StringMaybe} params.deliveryMethod
 * @return {string}
 */
export const getPaymentAfterCutoffBody = ({
  cutoff,
  deliveryMethod,
  itemCount = '1',
  speed = {},
  title,
  isPendingApproval,
}) => {
  let rangeArrivalText;
  let dayArrivalText;

  switch (speed.earliest) {
    case 0:
      rangeArrivalText = 'same';
      dayArrivalText = 'tomorrow';
      break;
    case 1:
    default:
      rangeArrivalText = 'next';
      dayArrivalText = `in ${getPaymentAfterCutoffDayArrivalText(speed.earliest)}`;
      break;
  }

  const methodActionText = getPaymentAfterCutoffMethodActionText(deliveryMethod, title);

  const tzTimeString = getTimeStringFormattedForProbableTimezone(cutoff);

  return `${methodActionText} by ${tzTimeString} in order to arrive on the ${rangeArrivalText} business day. Since the cutoff time for today has passed, your ${getPaymentsOrInvoicesText(
    ItemKinds.PAYABLE,
    itemCount,
  )} will arrive ${dayArrivalText}.${isPendingApproval ? ` ${pendingApprovalsText}` : ''}`;
};

/**
 * Returns payment-after-cutoff hint title text that describes the action for a given delivery method and option.
 * @param {OptionsArg} params
 * @param {StringMaybe} params.deliveryMethod
 * @param {String|Number} [params.itemCount]
 * @param {String} params.title
 * @return {String}
 */
export const getPaymentAfterCutoffMethodActionTitle = ({ deliveryMethod, itemCount, title }) => {
  const paymentOrCheck = isPaymentMethodDeliveryCheck(deliveryMethod) ? 'check' : 'payment';

  const singularOrPlural = ternary(
    itemCount,
    `${itemCount} ${title} ${pluralize('payment', itemCount)}`,
    capitalize(paymentOrCheck),
  );

  return `${singularOrPlural} will not arrive`;
};

/**
 * Returns the title to use for PaymentAfterCutoffHint.
 * @param {OptionsArg} params
 * @param {StringMaybe} params.deliveryMethod
 * @param {String|Number} [params.itemCount]
 * @param {Object} params.speed
 * @param {String} params.title
 * @return {string}
 */
export const getPaymentAfterCutoffTitle = ({ deliveryMethod, itemCount, speed = {}, title }) => {
  let dayArrivalText;

  switch (speed.earliest) {
    case 0:
      dayArrivalText = 'tomorrow';
      break;
    case 1:
    default:
      dayArrivalText = `${getPaymentAfterCutoffDayArrivalText(speed.earliest)} from now`;
      break;
  }

  const methodActionText = getPaymentAfterCutoffMethodActionTitle({
    deliveryMethod,
    itemCount,
    title,
  });
  const continueText = ternary(itemCount, '', ' Do you want to continue?');

  return `${methodActionText} until ${dayArrivalText}.${continueText}`;
};

/**
 * Returns the text to use in payment cutoff countdown hint for the
 * day of earliest arrival.
 * @param {Object} speed
 * @param {number} speed.earliest
 * @return {StringMaybe}
 */
export const getPaymentCutoffCountdownArrivalTitle = (speed = {}) => {
  switch (speed.earliest) {
    case 0:
      return 'today';
    case 1:
    default:
      return 'by tomorrow';
  }
};

/**
 * Returns payment-cutoff-countdown hint body text that describes the action for a given delivery method and option.
 * @param {String} deliveryMethod
 * @return {string}
 */
export const getPaymentCutoffCountdownMethodActionText = (deliveryMethod) =>
  isPaymentDeliveryMethodAch(deliveryMethod) ? 'transaction must be initiated' : 'must be sent';

/**
 * Returns the title to use for PaymentCutoffCountdownHint.
 * @param {Object} params
 * @param {StringMaybe} params.title - Title of this delivery option (Same Day ACH, etc)
 * @param {StringMaybe} params.cutoff
 * @param {Boolean} [params.isForBulkAction]
 * @param {Boolean} [params.isPendingApproval]
 * @param {String|Number} [params.itemCount]
 * @param {Object} params.speed
 * @param {StringMaybe} params.deliveryMethod
 * @return {string}
 */
export const getPaymentCutoffCountdownBody = ({
  cutoff,
  deliveryMethod,
  isForBulkAction,
  isPendingApproval,
  itemCount,
  speed = {},
  title,
}) => {
  let rangeArrivalText;

  switch (speed.earliest) {
    case 0:
      rangeArrivalText = 'within the same business day';
      break;
    case 1:
    default:
      rangeArrivalText = 'by the next business day';
      break;
  }

  const methodActionText = getPaymentCutoffCountdownMethodActionText(deliveryMethod);

  const tzTimeString = getTimeStringFormattedForProbableTimezone(cutoff);

  const actionWord = ternary(isForBulkAction, 'bulk send', 'import');

  const processPaymentsText = ternary(
    itemCount,
    ` Depending on the size of your ${actionWord}, it can take some time to process all of your payments.`,
    '',
  );

  const approvalsText = isPendingApproval ? ` ${pendingApprovalsText}` : '';

  return `${getLeadingTextForOptionTitle(
    title,
  )} ${methodActionText} by ${tzTimeString} in order to arrive ${rangeArrivalText}.${processPaymentsText}${approvalsText}`;
};

/**
 * Returns the text to use in payment cutoff countdown hint for the
 * time until cutoff.
 * @param {Object} params
 * @param {String} params.deliveryMethod
 * @param {String|Number} [params.itemCount]
 * @param {String} params.title
 * @return {StringMaybe}
 */
export const getPaymentCutoffSendTitleText = ({ deliveryMethod, itemCount, title }) => {
  const paymentOrCheck = ternary(isPaymentMethodDeliveryCheck(deliveryMethod), 'check', 'payment');

  const releaseOrSend = ternary(isPaymentMethodDeliveryCheck(deliveryMethod), 'Send', 'Release');

  const paymentsText = ternary(itemCount, `${itemCount} ${title} ${pluralize('payment', itemCount)}`, paymentOrCheck);

  return `${releaseOrSend} ${paymentsText}`;
};

/**
 * Returns the text informing the remaining hours and minutes for a cutoff time:
 * within the next minute | in the next 5h and 30m | in the next 5h | in the next 30m.
 * Returns undefined if cutoff string was not provided or if cutoff has passed.
 * @param {Object} params
 * @param {String} params.cutoff
 * @return {StringMaybe}
 */
export const getPaymentCutoffText = ({ cutoff }) => {
  if (!cutoff) {
    return undefined;
  }

  const currentDateTime = dayjs.utc();
  const { hours, minutes, seconds } = dayjs.utc(cutoff, 'HH:mm:ss').tz(dayjs.tz.guess()).toObject();

  const cutoffDateTime = dayjs().set('hour', hours).set('minute', minutes).set('second', seconds);

  const timeDifferenceInMinutes = cutoffDateTime.diff(currentDateTime, 'minute');

  if (timeDifferenceInMinutes < 0) {
    return undefined;
  }

  const remainingHours = Math.floor(timeDifferenceInMinutes / 60);
  const remainingMinutes = timeDifferenceInMinutes % 60;

  const hoursLabel = remainingHours > 0 ? `${remainingHours}h` : '';
  const minutesLabel = remainingMinutes > 0 ? `${remainingMinutes}m` : '';
  const andLabel = hoursLabel && minutesLabel ? ' and ' : '';

  return remainingHours === 0 && remainingMinutes === 0
    ? 'within the next minute'
    : `in the next ${hoursLabel}${andLabel}${minutesLabel}`;
};

export const getPaymentCutoffCountdownTitle = ({ cutoff, deliveryMethod, itemCount, speed = {}, title }) => {
  if (!cutoff) {
    return undefined;
  }

  const cutoffText = getPaymentCutoffText({ cutoff });

  if (!cutoffText) {
    return undefined;
  }

  const sendText = getPaymentCutoffSendTitleText({
    deliveryMethod,
    itemCount,
    title,
  });

  const arrivalText = getPaymentCutoffCountdownArrivalTitle(speed);

  return `${sendText} ${cutoffText} to arrive ${arrivalText}`;
};

/**
 * Returns the title to use for SendPaymentOnSpecificDateHint.
 * @param {string} itemType
 * @return {string}
 */
export const getSendPaymentOnSpecificDateHintTitle = (itemType) =>
  `This ${itemType} will remain in "${itemStatusToText[ItemStatuses.READY_TO_SEND]}" status until:`;

/**
 * Returns the title to use for BouncedEmailHint.
 * @param {string} contactEmail
 * @return {string}
 */
export const getBouncedEmailHintTitle = (contactEmail) =>
  `The email attached to this contact (${contactEmail}) has bounced.`;

/**
 * Returns the title to use for ExistingPartnerHint.
 * @param {string} partnershipType
 * @return {string}
 */
export const getExistingPartnerHintTitle = (partnershipType) => `This ${partnershipType} name is already taken.`;

/**
 * Returns the text to use for ExistingPartnerHint.
 * @param {string} companyName
 * @param {string} partnershipType
 * @return {string}
 */
export const getExistingPartnerHintText = (companyName, partnershipType) =>
  `You already have a company named ${companyName}. Please change the ${partnershipType} or company name in order to continue creating this ${partnershipType}.`;

/**
 * Returns the title to use for EmailAssociatedWithOtherCompaniesHint.
 * @param {array} associatedCompanies
 * @return {string}
 */
export const getEmailAssociatedWithOtherCompaniesHintTitle = (associatedCompanies) => {
  const title = 'This email address is associated with at least one other company: ';

  return (
    <>
      {title}
      {associatedCompanies.map((company, index) => {
        const secondLast = associatedCompanies.length - 2;

        return (
          <React.Fragment key={company.id}>
            <LinkNewPage className="primary" dataFullStory href={getCompanyRouteByPartnershipId(company.id)}>
              {company.name}
            </LinkNewPage>
            {Boolean(associatedCompanies.length > 2 && index < secondLast) && ', '}
            {index === secondLast && ' and '}
          </React.Fragment>
        );
      })}
    </>
  );
};

/**
 * Returns the checkbox text to use for EmailAssociatedWithOtherCompaniesHint.
 * @param {string} contactName
 * @return {string}
 */
export const getEmailAssociatedWithOtherCompaniesHintCheckboxText = (contactName) =>
  `Continue creating ${contactName} as a Routable contact`;

/**
 * Returns the title to use for CompanyHasNoAddedContactsHint.
 * @param {string} companyName
 * @return {string}
 */
export const getCompanyHasNoAddedContactsHintTitle = (companyName) =>
  `${companyName} does not have any contacts added.`;

/**
 * Returns the text to use for CompanyHasNoAddedContactsHint.
 * @param {string} partnershipType
 * @return {string}
 */
export const getCompanyHasNoAddedContactsHintText = (partnershipType) =>
  `Adding a contact to a ${partnershipType} allows you to send email notifications of payments/invoices, collect the ${partnershipType}s tax information, and communicate with them through Routable.`;

/**
 * Returns text to use for getCannotReplyItemNotSentHintText.
 * @param {string} paymentOrRequest
 * @param {string} vendorOrCustomer
 * @returns {string}
 */
export const getCannotReplyItemNotSentHintText = (paymentOrRequest, vendorOrCustomer) =>
  `Replies are disabled because this ${paymentOrRequest} has not yet been sent to your ${vendorOrCustomer}`;

/**
 * Returns text to use for CannotReplyNoReachableContactsHint.
 * @param {string} vendorOrCustomer
 * @returns {string}
 */
export const getCannotReplyNoReachableContactsHintText = (vendorOrCustomer) =>
  `Replies are disabled because this ${vendorOrCustomer} doesn't have any reachable contacts.`;

/**
 * Text for a hint informing RCTMs that partnerships which do not have a primary
 * payment method on file will be asked to add one
 * @param {ItemKind} itemKind
 * @returns {Object} Hint title and text for multi-source payment hint
 */
export const getMultiSourcePaymentHintMethodTextByItemKind = (itemKind) => {
  const partnershipType = getVendorOrCustomerTitleFromItemKind(itemKind);
  const partnershipTypePlural = pluralize.plural(partnershipType);

  return {
    text: `${capitalize(
      partnershipTypePlural,
    )} that do not have a primary payment method on file will be asked to add one by ${PLATFORM_DISPLAY_SHORT_NAME}.`,
    title: `Funds will be remitted to each ${partnershipType}\u0027s primary payment method and currency.`,
  };
};

/**
 * Returns text and title for ExistingPaymentOnLedgerHint
 * @see {ExistingPaymentsOnLedgerHint}
 *
 * @param {Ledger} ledger
 * @return {{text: string, title: string}}
 */
export const getExistingPaymentsOnLedgerTextAndTitle = (ledger) => ({
  text: `If a payment already exists on ${ledger.name}, uploading that payment will create a duplicate.`,
  title: `Do not import payments that already exist on ${ledger.name}`,
});

/**
 * Returns text and title for ExternalCurrencyTypeAnnouncementHint
 * @param {OptionsArg} options
 * @param {Company.name} options.companyName
 * @param {string} options.currencyLongName
 * @param {CurrencyCode} options.currencyCode
 * @param {itemKind} options.itemKind
 * @returns {{text: string, title: string}}
 */
export const getExternalCurrencyTypeAnnouncementHintTextTitle = ({
  companyName,
  currencyLongName,
  currencyCode,
  itemKind,
}) => {
  const pluralCurrencyLongName = pluralize(currencyLongName, 2);
  const paymentOrInvoiceText = getPaymentsOrInvoicesTextForExternalFlow(itemKind, 2);

  return {
    text: `Contact ${companyName} if you need to change your currency.`,
    title: `You're set up to receive ${paymentOrInvoiceText} in ${pluralCurrencyLongName} (${currencyCode})`,
  };
};

/**
 * Returns the title for ExistingPartnerSinglePaymentMethodHint.
 * @param {array} availablePaymentMethods
 * @return {string}
 */
export const getExistingPartnerSinglePaymentMethodHintTitle = (availablePaymentMethods) => {
  const transferMethod = isPaymentMethodAvailableCheck(availablePaymentMethods) ? 'Check' : 'Bank Transfer';
  const title = `Your vendor will be able to accept payment via ${transferMethod}`;

  return title;
};
