import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { DebouncedSelectField } from 'components';

import * as propTypes from 'constants/propTypes';
import { FieldDebounceDelay } from 'constants/ui';

import { handleGetOption } from 'helpers/select';
import { returnFullOptionObjectAsValueForSelects } from 'helpers/selects';

import { useScrollAwareSelectField, useGetTableFieldProps } from 'modules/table/hooks';

/**
 * Renders a select field to be rendered in a (scrollable) table cell.
 * @param {ComponentProps} props
 * @param {Function} props.getIsDisabled
 * @param {Function} props.getIsRequired
 * @param {node} props.horizontalScrollGroupRef
 * @param {string} props.lookupOptionLabelKey
 * @param {string} props.lookupOptionValueKey
 * @param {string} props.name
 * @param {*[]} props.options
 * @param {Function|Function[]} props.validate
 * @returns {FunctionComponent}
 */
export const LookupTableField = ({
  formValues,
  getIsDisabled,
  getIsRequired,
  horizontalScrollGroupRef,
  lookupOptionLabelKey,
  lookupOptionValueKey,
  menuPlacement,
  name,
  options,
  rowIdx,
  sectionPath,
  validate,
}) => {
  const {
    menuPortalStyles: menuStyles,
    menuPortalTarget,
    onInputFocus: onFocus,
    selectFieldRef: setWrapperRef,
  } = useScrollAwareSelectField(horizontalScrollGroupRef);

  const fieldPath = `${sectionPath}.${rowIdx}`;
  const { isDisabled, isRequired, validators } = useGetTableFieldProps({
    formValues,
    getIsDisabled,
    getIsRequired,
    fieldPath,
    rowIdx,
    validate,
  });

  return (
    <Field
      className="ledger-form-field create-item-field"
      closeOnSelect
      component={DebouncedSelectField}
      debounceDelay={FieldDebounceDelay.SELECT}
      disabled={isDisabled}
      getOptionLabel={handleGetOption(lookupOptionLabelKey)}
      getOptionValue={handleGetOption(lookupOptionValueKey)}
      hidePlaceholder
      isClearable
      isCreatable={false}
      isDebounced
      isDisabled={isDisabled}
      isRequired={isRequired}
      name={name}
      onFocus={onFocus}
      options={options}
      parseValue={returnFullOptionObjectAsValueForSelects}
      props={{
        maxMenuHeight: 200,
        menuPortalTarget,
        menuPlacement: menuPlacement ?? 'bottom',
        setWrapperRef,
        styles: { menuPortal: menuStyles },
      }}
      validate={validators}
      valueKey={lookupOptionValueKey}
    />
  );
};

LookupTableField.propTypes = {
  ...propTypes.tableLookupFieldPropTypes,
  /* eslint-disable-next-line react/no-unused-prop-types */
  getIsDisabled: PropTypes.func.isRequired, // used in disabled hook function
  horizontalScrollGroupRef: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  lookupOptionValueKey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

LookupTableField.defaultProps = {
  ...propTypes.tableLookupFieldDefaultProps,
  horizontalScrollGroupRef: undefined,
};

export default React.memo(LookupTableField);
