import classNames from 'classnames';
import React from 'react';

import { UI, DEFAULT_RECEIVABLE, updatePaymentMethodFormFields } from 'constants/formFields';
import { formNamesExternal } from 'constants/forms';

import { getItemKindFromExternalPath } from 'helpers/external';
import { anyValues, isEqual, isObject, ternary } from 'helpers/utility';

import { usePrevious } from 'hooks';

import { shouldUpdateFundingAccount } from 'modules/createPartnerCompany/helpers/paymentMethodFormHelpers';
import PartnerCompanyAcceptPayment from 'modules/createPartnerCompany/presenters/PartnerCompanyAcceptPayment';
import MaxPaymentMethodsReachedHint from 'modules/external/MaxPaymentMethodsReachedHint';
import MicroDepositsNeedVerificationWithConnectHint from 'modules/external/MicroDepositsNeedVerificationWithConnectHint';
import UnableToUpdatePaymentMethodHint from 'modules/external/UnableToUpdatePaymentMethodHint';

import * as commonProps from './commonProps';
import { ApplyToFuturePendingPaymentsCheckboxField } from './components';
import { getLastUpdatedFundingAccount, getNewFormState } from './helpers';

import './UpdatePaymentMethodFormWithCheckboxAndHintsDomestic.scss';

const formName = formNamesExternal.UPDATE_PAYMENT_METHOD;

/**
 * UpdatePaymentMethodFormWithCheckboxAndHintsDomestic form used to add funding accounts for domestic partnerships
 * @param {ComponentProps} props
 * @param {Object} props.defaultReceivable
 * @param {boolean} props.errors
 * @param {Object} props.formUI
 * @param {Function} props.handleSubmit
 * @param {boolean} props.isAddressModalOpen
 * @param {boolean} props.isConnectBankModalOpen
 * @param {FundingAccount[]} props.fundingAccountsWithAchFundingSource
 * @param {FundingAccount[]} props.fundingAccountsWithAddressFundingSources
 * @param {FundingAccount} props.lastSubmittedFundingAccount
 * @param {Function} props.onOpenCreateManualAddressModal
 * @param {Function} props.onSubmitAllExistingItems
 * @param {Function} props.onValuesChange
 * @param {string} props.paymentDeliveryMethod
 * @param {boolean} props.shouldShowMaxPaymentMethodsHint
 * @param {boolean} props.shouldShowMicroDepositsHint
 * @returns {StatelessComponent}
 */
const UpdatePaymentMethodFormWithCheckboxAndHintsDomestic = (props) => {
  const {
    defaultReceivable,
    errors,
    formUI,
    handleSubmit,
    isAddressModalOpen,
    isConnectBankModalOpen,
    fundingAccountsWithAchFundingSource,
    fundingAccountsWithAddressFundingSources,
    lastSubmittedFundingAccount,
    onOpenCreateManualAddressModal,
    onSubmitAllExistingItems,
    onValuesChange,
    paymentDeliveryMethod,
    shouldShowMicroDepositsHint,
    shouldShowMaxPaymentMethodsHint,
  } = props;

  const itemKind = getItemKindFromExternalPath();
  const prevProps = usePrevious(props);

  /**  update the receivable funding account in state */
  const updateReceivableFundingAccount = () => {
    const updatedFundingAccount = getLastUpdatedFundingAccount({
      fundingAccountsWithAchFundingSource,
      fundingAccountsWithAddressFundingSources,
      lastSubmittedFundingAccount,
      paymentDeliveryMethod,
    });

    // Skip setting the state of it's the same funding account and payment method
    if (isEqual(defaultReceivable.fundingAccount, updatedFundingAccount.id)) {
      return;
    }

    const { newDefaultReceivableState, newFormUIState } = getNewFormState({
      defaultReceivable,
      formUI,
      updatedFundingAccount,
    });

    onValuesChange(formName, UI, newFormUIState);
    onValuesChange(formName, DEFAULT_RECEIVABLE, newDefaultReceivableState);
  };

  /**
   *  we use onSubmitAllExistingItems to accept all payments and update the partnership
   *  since there are no payments, we only pass the kind 'RECEIVABLE' and action 'accept'
   *  to update the receivable payment method and default funding account
   */
  const handleSubmitReceivablePaymentMethod = async (formValues) => {
    if (!isAddressModalOpen && !isConnectBankModalOpen) {
      onSubmitAllExistingItems({
        defaultReceivable: formValues.defaultReceivable,
        itemKind,
      });
    }
  };

  const handleCheckFundingAccountSelected = (evt) => {
    // When we click on the radio input, the value is stored in the evt.target.value. When we click
    // on the entire address component, the value is directly passed, meaning evt === value
    const value = ternary(isObject(evt), evt.target?.value, evt);
    onValuesChange(formName, updatePaymentMethodFormFields.FUNDING_ACCOUNT, value);
  };

  // Keep the funding account state in sync with the selected payment delivery method
  React.useEffect(() => {
    // On first render prevProps is undefined
    if (!prevProps) {
      return;
    }

    if (shouldUpdateFundingAccount(prevProps, props)) {
      updateReceivableFundingAccount();
    }
    // keep updateReceivableFundingAccount out of dependencies here as it triggers this effect on every render
    // eslint-disable-next-line
  }, [paymentDeliveryMethod, prevProps, props]);

  return (
    <form
      className={classNames({
        form: true,
        'update-payment-method-form': true,
      })}
      id={formName}
      onSubmit={handleSubmit(handleSubmitReceivablePaymentMethod)}
    >
      <PartnerCompanyAcceptPayment
        fieldNamePrefix={`${DEFAULT_RECEIVABLE}.`}
        formId={formName}
        fundingAccount={defaultReceivable.fundingAccount}
        itemKind={itemKind}
        onCheckFundingAccountSelected={handleCheckFundingAccountSelected}
        onFundingAccountAddressSelected={onOpenCreateManualAddressModal}
        paymentDeliveryMethod={paymentDeliveryMethod}
      />
      <ApplyToFuturePendingPaymentsCheckboxField />
      {shouldShowMicroDepositsHint && <MicroDepositsNeedVerificationWithConnectHint />}
      {shouldShowMaxPaymentMethodsHint && <MaxPaymentMethodsReachedHint />}
      {anyValues(errors?.parsedErrors) && <UnableToUpdatePaymentMethodHint />}
    </form>
  );
};

UpdatePaymentMethodFormWithCheckboxAndHintsDomestic.propTypes = {
  ...commonProps.propTypes,
};

UpdatePaymentMethodFormWithCheckboxAndHintsDomestic.defaultProps = {
  ...commonProps.defaultProps,
};

export default UpdatePaymentMethodFormWithCheckboxAndHintsDomestic;
